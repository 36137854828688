import { primaryColor, yellowColor } from "assets/jss/material-dashboard-pro-react.js";
import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.js";

const styles = (theme) => ({
  ...customDropdownStyle(theme),
  search: {
    margin: "0",
    paddingTop: "7px",
    paddingBottom: "7px",
  },
  searchInput: {
    paddingTop: "2px",
  },
  searchButton: {
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      //   top: "-50px !important",
      marginRight: "38px",
      float: "right",
    },
  },
  top: {
    zIndex: "4",
  },
  searchIcon: {
    width: "17px",
    zIndex: "4",
  },
  headerLinksSvg: {
    width: "20px !important",
    height: "20px !important",
  },
  topContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 60,
  },
  searchContainer: { display: "flex", alignItems: "center" },
  editIcon: { cursor: "pointer", color: yellowColor[0] },
  deleteIcon: { cursor: "pointer", color: "red" },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  exportButton: { height: 35, margin: 0 },

  searchContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: 5,
    border: "1px solid #f7f7f7",
    backgroundColor: "#F9FAFF",
    padding: "3px 2px 3px 18px",
    marginBottom: 10,
    height: "fit-content",
    border: "1px solid #eee"
  },
  searchLabel: {
    fontWeight: 500,
    fontSize: 14,
    color: "black",
    marginBottom: 5,
  },
  search: {
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    "&:focus": {
      outline: "none",
    },
    "& > div.MuiInput-root": {
      width: "100%",
    },
    "& > div > input": {
      padding: 0,
      height: 25,
    },
  },
  searchIcon: {
    width: "20px",
    height: "20px",
    marginRight: "5px",
    cursor: "pointer",
    color: primaryColor[0],
  },
  searchInput: {
    width: "100%",
    height: "100%",
    padding: 0,
    border: "none",
    backgroundColor: "transparent",
    color: "black",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.42857",
    letterSpacing: "unset",
    "&::placeholder": {
      color: "black",
    },
  },
});

export default styles;
