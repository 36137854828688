import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    type: yup.mixed().required("Required"),
    licenseId: yup.string().required("Required"),
    licenseExpirationDate: yup.mixed().required("Required"),
    licenseAttachment: yup.mixed().required("Required"),
    status: yup.string(),
    color: yup.string(),
    modelYear: yup.string().required("Required"),
    serviceProviders: yup.mixed(),
  });
};
