import React, { useMemo } from "react";

export default function TourInfo({ tour, currentOrg }) {
  const { tourTime, tourDate } = useMemo(() => {
    if (!tour)
      return {
        tourTime: undefined,
        tourDate: undefined,
      };
    const date = new Date(tour.pickupTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    return {
      tourTime: `${hour}:${minute}`,
      tourDate: `${year}-${month}-${day}`,
    };
  }, [tour]);
  const dealName = useMemo(
    () =>
      currentOrg?.deals?.find((deal) => deal.id === tour?.dealId)?.details
        ?.name,
    [currentOrg, tour]
  );
  return (
    <div>
      <div> Deal: {dealName}</div>
      <div> Tour Type: {tour?.type}</div>
      <div> Tour Status: {tour?.status}</div>
      <div> Tour Date: {tourDate}</div>
      <div> Tour Time: {tourTime}</div>
      <div> Tour ID: {tour?.id}</div>
      <div> Ordered Truck Type: {tour?.truckType?.details?.name}</div>
      <div>Driver Name: {tour?.driver?.name || "Driver Not Assigned"}</div>
      <div>
        Service Provider Name:
        {tour?.serviceProvider?.name || "Service Provider Not Assigned"}
      </div>
    </div>
  );
}
