import * as yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import enums from "enums/index";

//-------------------------------------------------------------------------------------

export default (isRowValidation) => {
  return yup.object({
    tasks: yup
      .array()
      .of(
        yup.object().shape({
          type: yup.mixed().required("Tasks type is required"),
          location: yup
            .mixed()
            .required("Tasks location is required")
            .test("task-location-required", "Task Location Required", function (
              value
            ) {
              if (!value) {
                toast.error("Task location is required.");
                return false;
              }
              return true;
            }),
          startTime: yup.mixed(),
          endTime: yup.mixed(),
        })
      )
      .test(
        "tasks-in-sequence",
        "Each task must start after the previous task",
        function (tasks) {
          const filteredTasks = tasks.filter(
            (task) => task.status !== enums.TaskStatus.CANCELLED
          );
          for (let i = 1; i < filteredTasks.length; i++) {
            const prevTask = filteredTasks[i - 1];
            const currentTask = filteredTasks[i];

            //Validate completed tour have distance
            if (
              currentTask.status === enums.TaskStatus.COMPLETED &&
              !currentTask.distance &&
              i !== 1
            ) {
              toast.error(
                `Completed Tasks Should Have a Distance, Task Number ${
                  currentTask.taskIndex + 1
                }.`
              );
              return false;
            }
            //Validate if completed tours have start and end time
            if (
              [enums.TaskStatus.COMPLETED].includes(currentTask.status) &&
              (!currentTask.startTime || !currentTask.endTime)
            ) {
              toast.error(
                `Completed Tasks Should Have a Start Time and an End Time, Task Number ${
                  currentTask.taskIndex + 1
                }.`
              );
              return false;
            }
            if (
              isRowValidation &&
              [enums.TaskStatus.ONGOING].includes(prevTask.status) &&
              (!prevTask.startTime || !prevTask.endTime)
            ) {
              toast.error(
                `Completed Tasks Should Have a Start Time and an End Time, Task Number ${
                  prevTask.taskIndex + 1
                }.`
              );
              return false;
            }
          }
          return true;
        }
      )
      .test(
        "task-distances-in-sequence",
        "Each task distance must be the subtraction of the task and previous task's odometer reading",
        function (tasks) {
          const filteredTasks = tasks.filter(
            (task) => task.status !== enums.TaskStatus.CANCELLED
          );
          for (let i = 1; i < filteredTasks.length; i++) {
            const prevTask = filteredTasks[i - 1];
            const currentTask = filteredTasks[i];
            if (currentTask.odometerReading && prevTask.odometerReading) {
              const distance =
                currentTask.odometerReading - prevTask.odometerReading;
              if (distance !== currentTask.distance) {
                toast.error(
                  `Task distance must be the subtraction of the task and previous task's odometer reading, Task Number ${
                    currentTask.taskIndex + 1
                  }.`
                );
                return false;
              }
            }
          }
          return true;
        }
      ),
  });
};
