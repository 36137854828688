import CardBody from "components/Card/CardBody";
import Loading from "components/Loading/Loading";
import styles from "./TourLoading.styles";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(styles);

export default function TourLoading({
  isLoading,
  message,
  notFound,
  className,
  children,
}) {
  const classes = useStyles();

  return (
    <Loading className={classes.loading} loading={isLoading}>
      {notFound ? (
        children
      ) : (
        <CardBody className={className || ""}>
          <h3 className={classes.noDataMessage}>{message}</h3>
        </CardBody>
      )}
    </Loading>
  );
}
