import { useForm } from "react-hook-form";
import CancelServiceProviderModalValidation from "./CancelServiceProvider.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { useContext, useState } from "react";
import api from "services/api";
import { MonthlyOrderDetailsContext } from "views/Plans/MonthlyOrderDetails/MonthlyOrderDetails.context";

export default function useCancelServiceProvider() {
  const { order, setOrder } = useContext(MonthlyOrderDetailsContext);
  const [sendOrderRequest, isOrderRequestLoading] = useHttpClient(true);
  const [modalOpen, setModalOpen] = useState(false);
  const resolver = useYupValidationResolver(
    CancelServiceProviderModalValidation()
  );
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, watch, reset } = form;

  const toggleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const onSubmit = (values) => async () => {
    try {
      const orderUpdate = {
        id: order.id,
        monthly: {
          serviceProviderId: null,
          truckId: null,
          driverId: null,
          serviceProviderCancellationReason:
            values.serviceProviderCancellationReason,
        },
      };
      await sendOrderRequest(api.orders.patch(orderUpdate));
      setOrder({
        ...order,
        monthly: {
          ...order.monthly,
          serviceProvider: null,
          serviceProviderId: null,
          truck: null,
          truckId: null,
          driver: null,
          driverId: null,
          serviceProviderCancellationReason:
            values.serviceProviderCancellationReason,
        },
      });
      toast.success("Service Provider Cancelled Successfully");
      toggleModalOpen();
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    order,
    form,
    reset,
    submitHandler,
    isOrderRequestLoading,
    toggleModalOpen,
    modalOpen,
  };
}
