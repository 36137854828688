import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import enums from "enums";
import React from "react";
import { useForm } from "react-hook-form";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import FormField from "../FormField/FormField";

export default function CustomForm(props) {
  const {
    submitHandler,
    onError,
    loading,
    edit,
    create,
    validationSchema,
    formStructure,
    data,
    type,
    submitButtonText,
    submitButtonColor,
  } = props;

  const resolver = useYupValidationResolver(validationSchema);

  const fields = React.useMemo(() => {
    return formStructure?.reduce(
      (result, current) => result.concat(current.fields),
      []
    );
  }, [formStructure]);

  const fieldsMap = React.useMemo(() => {
    return fields?.reduce((result, current) => {
      result[current.fetchedName || current.name] = current;
      return result;
    }, {});
  }, [fields]);

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue } = form;

  React.useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (fieldsMap[key]) {
          let value =
            fieldsMap[key].type === "select" && typeof data[key] === "object"
              ? data[key]?.id
              : data[key];
          setValue(fieldsMap[key].name, value);
        } else if (key === "id") {
          setValue("id", data[key]);
        }
      });
    }
  }, [data, setValue, formStructure]);

  const onSubmit = (e) => {
    if (e) {
      if (typeof e.preventDefault === "function") {
        e.preventDefault();
      }
      if (typeof e.stopPropagation === "function") {
        e.stopPropagation();
      }
    }

    let values = getValues();

    Object.keys(values).forEach((key) => {
      const field = fields.find((field) => field.name === key);
      if (field?.options?.getSubmitValue) {
        values[key] = field.options.getSubmitValue(values[key]);
      } else if (values[key] === {}) {
        delete values[key];
      }
    });

    handleSubmit(submitHandler(values), onError)();
  };

  return (
    <form
      style={{
        padding:
          type === enums.FormContainerType.MODAL ? "20px 20px 0 20px" : 40,
        paddingBottom: 0,
      }}
      onSubmit={onSubmit}
      autoComplete="off"
    >
      <GridContainer>
        {formStructure?.map((column, columnIndex) => (
          <React.Fragment key={columnIndex}>
            <GridItem xs={12} sm={12} md={formStructure.length === 1 ? 12 : 5}>
              {column.fields.map((field, fieldIndex) => (
                <FormField
                  key={fieldIndex}
                  form={form}
                  type={field.type}
                  name={field.name}
                  label={field.label}
                  options={field.options}
                />
              ))}
            </GridItem>
            {columnIndex !== formStructure.length - 1 ? (
              <GridItem xs={12} sm={12} md={1} />
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color={submitButtonColor || "primary"}
                  type="submit"
                  loading={loading}
                  style={{
                    margin:
                      type === enums.FormContainerType.MODAL
                        ? "20px 0 5px 0"
                        : "20px 0",
                  }}
                  create={create}
                  edit={edit}
                  loadFromRight
                >
                  {submitButtonText}
                </Button>
              </div>
            )}
          </React.Fragment>
        ))}
      </GridContainer>
    </form>
  );
}
