import { useForm } from "react-hook-form";
import AssignCourierModalValidation from "./AssignCourierModal.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { useContext, useEffect, useMemo, useState } from "react";
import api from "services/api";
import { MonthlyOrderDetailsContext } from "views/Plans/MonthlyOrderDetails/MonthlyOrderDetails.context";

export default function useAssignCourierModal({ onClose, order }) {
  const [sendRequest, isLoading] = useHttpClient(true);
  const [sendCouriersRequest] = useHttpClient(true);
  const [serviceProviders, setServiceProviders] = useState();
  const resolver = useYupValidationResolver(AssignCourierModalValidation());
  const { setOrder } = useContext(MonthlyOrderDetailsContext);
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const [couriers, setCouriers] = useState([]);

  const {
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { isDirty },
  } = form;
  const courier = watch("courier");
  const resetCourier = () => {
    if (order) {
      setValue("courier", order.monthly.courier);

      setValue("name", order.monthly.courier?.nameEn || "");
      setValue("phoneNumber", order.monthly.courier?.phonePrimary || "");
      setValue(
        "phoneNumberSecondary",
        order.monthly.courier?.phoneSecondary || ""
      );
      setValue("nationalId", order.monthly.courier?.nationalId || "");
      setValue("courierCost", order.monthly.courierCost || 0);
      setServiceProviders(order.monthly.courier?.serviceProviders);
      setValue("serviceProvider", order.monthly.courierServiceProvider);
    }

    const fetchCouriers = async () => {
      const response = (await sendCouriersRequest(api.couriers.getAll()))?.data;
      setCouriers(response);
    };
    fetchCouriers();
  };
  useEffect(() => {
    resetCourier();
  }, [order]);

  const courierId = useMemo(() => courier?.id, [courier]);

  useEffect(() => {
    setValue("name", courier?.nameEn || "");
    setValue("phoneNumber", courier?.phonePrimary || "");
    setValue("phoneNumberSecondary", courier?.phoneSecondary || "");
    setValue("nationalId", courier?.nationalId || "");
    setServiceProviders(courier?.serviceProviders);
    setValue("serviceProvider", courier?.serviceProviders[0]);
  }, [courierId]);

  const onSubmit = (values) => async () => {
    try {
      const newMonthly = {
        courier: {
          ...values.courier,
          nameEn: values.name,
          nationalId: values.nationalId,
          phonePrimary: values.phoneNumber,
          phoneSecondary: values.phoneNumberSecondary || null,
        },
        courierCost: values.courierCost || 0,
        courierTargetCost: order.monthly.courierTargetCost || 0,
        ...(values.serviceProvider
          ? {
              courierServiceProviderId: values?.serviceProvider.id,
            }
          : {}),
      };
      await sendRequest(
        api.orders.patch({
          id: order.id,
          monthly: newMonthly,
        })
      );
      setOrder({
        ...order,
        monthly: {
          ...order.monthly,
          ...newMonthly,
          courierServiceProvider: values?.serviceProvider,
        },
      });
      toast.success("Courier assigned successfully");
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const handleAddNew = (courier) => {
    setCouriers((prevCouriers) => [...prevCouriers, courier]);
    setValue("courier", courier);
  };

  const serviceProviderDisabled = useMemo(() => !courier, [courier]);
  return {
    form,
    couriers,
    serviceProviders,
    serviceProviderDisabled,
    serviceProvider: order?.monthly?.serviceProvider,
    isLoading,
    submitHandler,
    resetCourier,
    handleAddNew,
  };
}
