import React, { useEffect, useState, useCallback, useMemo } from "react";
import qs from "qs";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//-------------------------------------------------------------------------------------

export const useServiceProviderRentalView = ({ path }) => {
  const [sendRequest, isGetRentalLoading] = useHttpClient();
  const [sendUpdateRequest, isUpdateLoading] = useHttpClient();

  const [serviceProviderRental, setServiceProviderRental] = useState(
    null
  );

  const [currentTab, setCurrentTab] = useState(0);

  const history = useHistory();

  const dataResource = useMemo(() => api.serviceProviderRentals, []);
  const rentalDealId = window.location.pathname.split("/").pop().trim();

  useEffect(() => {
    getLatestServiceProviderRental();
  }, [getLatestServiceProviderRental]);

  const getLatestServiceProviderRental = useCallback(async () => {
    if (rentalDealId && !isNaN(rentalDealId)) {
      try {
        const response = await sendRequest(dataResource.getOne(rentalDealId));
        setServiceProviderRental({ ...response });
      } catch (err) {
        toast.error("An error has occurred");
        history.push({
          pathname: `/admin/${path}`,
        });
      }
    } else {
      toast.error("An error has occurred");
      history.push({
        pathname: `/admin/${path}`,
      });
    }
  }, [dataResource]);

  const updateRentalDeal = useCallback(
    async (data) => {
      await sendUpdateRequest(
        api.serviceProviderRentals.patch({ id: rentalDealId, ...data })
      );
      setServiceProviderRental((prevRental) => ({ ...prevRental, ...data }));
    },
    [rentalDealId]
  );

  const handleTabChange = (newTab) => {
    setCurrentTab(newTab);

    const { tab } = qs.parse(window.location.search?.split("?")[1]);

    if (newTab != tab) {
      history.replace({
        pathname: window.location.pathname,
        search: `?tab=${newTab}`,
      });
    }
  };

  return {
    currentTab,
    serviceProviderRental,
    isGetRentalLoading,
    handleTabChange,
    setServiceProviderRental,
    getLatestServiceProviderRental,
    updateRentalDeal,
  };
};
