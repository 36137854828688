import { useForm } from "react-hook-form";
import AssignServiceProviderModalValidation from "./AssignServiceProviderModal.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api";
import { useContext, useEffect, useMemo, useState } from "react";
import { MonthlyOrderDetailsContext } from "views/Plans/MonthlyOrderDetails/MonthlyOrderDetails.context";

export default function useAssignServiceProviderModal({ order, onClose }) {
  const [sendRequest, isLoading] = useHttpClient(true);
  const [
    sendServiceProvidersRequest,
    isServiceProvidersLoading,
  ] = useHttpClient(true);
  const { setOrder } = useContext(MonthlyOrderDetailsContext);
  const [serviceProviders, setServiceProviders] = useState([]);
  const resolver = useYupValidationResolver(
    AssignServiceProviderModalValidation()
  );
  const form = useForm({
    defaultValues: {
      serviceProvider: null,
      phoneNumber: null,
      phoneNumberSecondary: null,
      nationalId: null,
    },
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, setValue, getValues, watch, reset } = form;

  const serviceProvider = watch("serviceProvider");

  const fetchServiceProviders = async () => {
    const response = (
      await sendServiceProvidersRequest(api.serviceProviders.getAll())
    )?.data;
    setServiceProviders(response);
  };

  useEffect(() => {
    reset();
    setValue("baseTruckCost", order?.monthly?.baseTruckCost || 0);
    setValue("monthlyDistanceLimit", order?.monthly?.monthlyDistanceLimit || 0);
    setValue("dailyDurationLimit", order?.monthly?.dailyDurationLimit || 0);
    setValue("extraKmCost", order?.monthly?.extraKmCost || 0);
    setValue("extraHourCost", order?.monthly?.extraHourCost || 0);
    fetchServiceProviders();
  }, [order]);

  const serviceProviderId = useMemo(() => serviceProvider?.id, [
    serviceProvider,
  ]);

  useEffect(() => {
    setValue("phoneNumber", serviceProvider?.phonePrimary);
    setValue("phoneNumberSecondary", serviceProvider?.phoneSecondary);
    setValue("nationalId", serviceProvider?.nationalId);
  }, [serviceProviderId]);

  const onSubmit = (values) => async () => {
    try {
      const newServiceProvider = {
        ...values.serviceProvider,
        nationalId: values.nationalId,
        phonePrimary: values.phoneNumber,
        phoneSecondary: values.phoneNumberSecondary || null,
      };
      delete newServiceProvider["bills"];
      await sendRequest(
        api.orders.patch({
          id: order.id,
          monthly: {
            serviceProvider: newServiceProvider,
            baseTruckCost: values.baseTruckCost,
            serviceProviderMonthlyDistanceLimit:
              values.monthlyDistanceLimit || 0,
            serviceProviderDailyDurationLimit: values.dailyDurationLimit || 0,
            extraKmCost: values.extraKmCost || 0,
            extraHourCost: values.extraHourCost || 0,
          },
        })
      );
      setOrder({
        ...order,
        monthly: {
          ...order.monthly,
          serviceProvider: newServiceProvider,
          baseTruckCost: values.baseTruckCost || 0,
          serviceProviderMonthlyDistanceLimit: values.monthlyDistanceLimit || 0,
          serviceProviderDailyDurationLimit: values.dailyDurationLimit || 0,
          extraKmCost: values.extraKmCost || 0,
          extraHourCost: values.extraHourCost || 0,
        },
      });
      toast.success("Service Provider Assigned Successfully");
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };

  return {
    serviceProviders,
    isLoading,
    form,
    fetchServiceProviders,
    submitHandler,
  };
}
