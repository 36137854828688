import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "utils/index";
import styles from "./FilterSelector.styles";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function FilterSelector(props) {
  const classes = useStyles();

  const { onSelect, options, horizontal, tabs, hasSearch } = props;

  const [selected, setSelected] = useState(0);

  const containerClasses = classNames({
    [classes.containerWithSearch]: hasSearch && !horizontal,
  });

  const listClasses = classNames({
    [classes.list]: true,
    [classes.tabsList]: tabs && horizontal,
    [classes.horizontalList]: horizontal,
    [classes.listWithSearch]: hasSearch && !horizontal,
  });

  const listItemClasses = (index) =>
    classNames({
      [classes.listItem]: true,
      [classes.tabListItem]: tabs,
      [classes.listItemSelected]: !tabs && selected === index,
      [classes.tabListItemSelected]: tabs && selected === index,
      [classes.itemWithSubtitle]: options.length && options[0].id,
    });

  useEffect(() => {
    if (selected > options.length) return;
    if (options.length === 0) {
      onSelect(undefined);
      return;
    }
    onSelect(options[selected]?.value || options[selected]);
  }, [options, selected]);

  return (
    <GridContainer className={containerClasses}>
      <ul className={listClasses}>
        {options.map((option, index) => (
          <li
            onClick={() => {
              onSelect(options[index].value || options[index]);
              setSelected(index);
            }}
          >
            <a className={listItemClasses(index)}>
              <p className={classes.title}>
                {option.name || capitalizeFirstLetter(option)}
              </p>
              {option?.id ? (
                <p className={classes.subTitle}>{option.id}</p>
              ) : null}
            </a>
          </li>
        ))}
      </ul>
    </GridContainer>
  );
}

FilterSelector.propTypes = {
  options: PropTypes.array,
  horizontal: PropTypes.bool,
  tabs: PropTypes.bool,
  onSelect: PropTypes.func,
};
