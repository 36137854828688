import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TasksTableContext } from "../../TasksTable.context";

import TasksTableValidation from "../../TasksTable.validation";
import enums from "enums/index";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import TaskRowValidation from "./TaskRow.validation";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";
import translations from "constants/translations/index";
import { useTranslation } from "react-i18next";
import {
  concatListsByAttribute,
  convertTimeToISO,
  extractTimeFromISO,
} from "utils/index";
import { toast } from "react-toastify";
import { diffDays, getCurrentTaskDate } from "../../TasksTable.utils";

export default function useTaskRow(index, data) {
  const {
    form,
    tourPickupLocation,
    tourPickupLocationId,
    setTourPickupLocation,
    quotationCities,
    quotationDistricts,
    quotationLocations,
    tourType,
    setCurrentTask,
    getTourLocations,
    isServiceProcessed,
    updateTasks,
    allCities,
    allDistricts,
    tasksWithTime,
  } = useContext(TasksTableContext);
  const { currentOrg, currentTour } = useContext(TourOperationsContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  // const [deal, setDeal] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [sendRequest, isLoading] = useHttpClient(true);
  const { i18n } = useTranslation();

  const { setValue, watch } = form;
  const tasks = watch("tasks");
  const taskType = watch(`tasks.${index}.type`);
  const taskDocuments = watch(`tasks.${index}.documents`);
  const taskStatus = watch(`tasks.${index}.status`);
  const currentTask = watch(`tasks.${index}`);
  const nextTask = watch(`tasks.${index + 1}`);

  const isLocationDisabled = React.useMemo(
    () =>
      tourType === enums.TourType.PER_TRIP &&
      [enums.TaskType.DROP_OFF, enums.TaskType.LAYOVER].includes(taskType) &&
      !tourPickupLocationId,
    [tourType, taskType, tourPickupLocationId]
  );

  const toursDataResource = useMemo(() => api.tours, []);

  const startButtonShown = useMemo(() => {
    const previousTasksCompleted = tasks?.slice(0, index)?.reduce(
      (pre, task) =>
        pre &&
        [enums.TaskStatus.CANCELLED, enums.TaskStatus.COMPLETED].includes(
          task.status
        ),

      true
    );
    return previousTasksCompleted && taskStatus === enums.TaskStatus.UPCOMING;
  }, [taskStatus, tasks]);
  const rentalDropOffOrLayoverTask = useMemo(() => {
    return (
      (taskType === enums.TaskType.DROP_OFF ||
        taskType === enums.TaskType.LAYOVER) &&
      (tourType === enums.TourType.MONTHLY || tourType === enums.TourType.DAILY)
    );
  }, [taskType, tourType]);

  React.useEffect(() => {
    if (!tourPickupLocationId) {
      setTourPickupLocation(undefined);
      return;
    }
    if ([enums.TaskType.DROP_OFF, enums.TaskType.LAYOVER].includes(taskType)) {
      const selectedPickupLocation = quotationLocations?.find(
        (location) => location?.id === tourPickupLocationId
      );
      setTourPickupLocation(selectedPickupLocation);
    }
  }, [taskType, tourPickupLocationId, quotationLocations]);

  React.useEffect(() => {
    let tempCities = quotationCities?.length ? [...quotationCities] : [];
    if (!tourPickupLocation || !(tourType === enums.TourType.PER_TRIP)) {
      // daily or monthly
      tempCities = rentalDropOffOrLayoverTask ? allCities : tempCities;
      setCities(tempCities);
      return;
    } else {
      if (taskType === enums.TaskType.DROP_OFF)
        tempCities = tourPickupLocation?.dropOffCities;
      if (taskType === enums.TaskType.LAYOVER)
        tempCities = concatListsByAttribute(
          tempCities,
          tourPickupLocation.dropOffCities
        );
      setCities(tempCities);
    }
  }, [
    quotationCities,
    allCities,
    rentalDropOffOrLayoverTask,
    taskType,
    tourType,
    tourPickupLocation,
  ]);

  React.useEffect(() => {
    const getDistricts = () => {
      let tempDistricts = quotationDistricts?.length
        ? [...quotationDistricts]
        : [];
      if (!tourPickupLocation || !(tourType === enums.TourType.PER_TRIP)) {
        return rentalDropOffOrLayoverTask ? allDistricts : tempDistricts;
      }
      if (taskType === enums.TaskType.DROP_OFF)
        tempDistricts = tourPickupLocation?.dropOffDistricts;
      if (taskType === enums.TaskType.LAYOVER)
        tempDistricts = concatListsByAttribute(
          tempDistricts,
          tourPickupLocation?.dropOffDistricts
        );
      return tempDistricts;
    };

    const tempDistricts = getDistricts();
    setDistricts(tempDistricts);
  }, [
    quotationDistricts,
    allDistricts,
    rentalDropOffOrLayoverTask,
    taskType,
    tourType,
    tourPickupLocation,
  ]);

  React.useEffect(() => {
    const getLocations = () => {
      let tempLocations = quotationLocations?.length
        ? [...quotationLocations]
        : [];
      if (!(tourType === enums.TourType.PER_TRIP)) {
        return tempLocations.filter((loc) => {
          if (
            taskType === enums.TaskType.PICKUP ||
            taskType === enums.TaskType.RETURN
          )
            return [
              enums.LocationTaskType.ALL,
              enums.LocationTaskType.PICKUPS_AND_RETURNS_ONLY,
            ].includes(loc.taskType);
          else if (taskType === enums.TaskType.DROP_OFF)
            return [
              enums.LocationTaskType.ALL,
              enums.LocationTaskType.DROPOFFS_ONLY,
            ].includes(loc.taskType);
          else return true;
        });
      }
      if (!tourPickupLocation) {
        return tempLocations;
      }
      if (taskType === enums.TaskType.DROP_OFF) {
        tempLocations = tourPickupLocation?.dropOffLocations;
      }
      if (taskType === enums.TaskType.LAYOVER)
        tempLocations = concatListsByAttribute(
          tempLocations,
          tourPickupLocation?.dropOffLocations
        );
      return tempLocations;
    };
    const tempLocations = getLocations();

    setLocations(tempLocations);
  }, [taskType, tourType, quotationLocations, tourPickupLocation]);

  const statusChangeDisabled = useMemo(
    () =>
      !(
        currentTour?.driverId &&
        currentTour?.serviceProviderId &&
        currentTour?.truckId &&
        currentTour?.tasks[index] &&
        currentTask?.startTime
      ),
    [currentTour, index, currentTask]
  );
  const completeTaskDisabled = useMemo(
    () => statusChangeDisabled || !currentTask?.startTime,
    [statusChangeDisabled, currentTask]
  );
  const statusChangeMessage = useMemo(
    () =>
      !currentTour?.tasks[index]
        ? "Can't start a new task before submitting it, Please Submit First."
        : !(
            currentTour?.driverId &&
            currentTour?.serviceProviderId &&
            currentTour?.truckId
          )
        ? "You should assign service provider, driver and truck to start task status"
        : !currentTask?.startTime
        ? "You should select a start time to start the task."
        : "Start Task",
    [currentTour, currentTask, index]
  );

  const tourDisabled = useMemo(
    () =>
      currentTour?.status === enums.TourStatus.CANCELLED ||
      currentTour?.status === enums.TourStatus.COMPLETED,
    [currentTour]
  );
  const deal = useMemo(
    () =>
      currentOrg?.deals?.find((deal) => deal.id === currentTour?.order?.dealId),
    [currentOrg, currentTour]
  );

  const getLocationTaskTypes = useCallback(
    (cityId, districtId) => {
      const translationObj = translations[i18n.language]["locationTaskType"];
      const locationTaskTypeMap = Object.keys(translationObj).reduce(
        (pre, key) => {
          pre[key] = {
            value: key,
            name: translationObj[key],
          };
          return pre;
        },
        {}
      );

      let tasks = [locationTaskTypeMap[enums.LocationTaskType.ALL]];

      if (
        taskType === enums.TaskType.PICKUP ||
        taskType === enums.TaskType.RETURN
      ) {
        tasks = [
          ...tasks,
          locationTaskTypeMap[enums.LocationTaskType.PICKUPS_AND_RETURNS_ONLY],
        ];
        return tasks;
      }

      if (
        taskType === enums.TaskType.DROP_OFF ||
        (rentalDropOffOrLayoverTask && taskType === enums.TaskType.LAYOVER)
      ) {
        tasks = [
          ...(!rentalDropOffOrLayoverTask ? tasks : []),
          locationTaskTypeMap[enums.LocationTaskType.DROPOFFS_ONLY],
        ];
        return tasks;
      }

      // in case of layover
      const inPickupCities = quotationCities?.find(
        (cityEl) => cityEl.id === cityId
      );
      const inPickupDistricts = quotationDistricts?.find(
        (districtEl) => districtEl.id === districtId
      );
      if (inPickupCities && inPickupDistricts)
        tasks = [
          ...tasks,
          locationTaskTypeMap[enums.LocationTaskType.PICKUPS_AND_RETURNS_ONLY],
        ];

      const inDropOffCities = tourPickupLocation?.dropOffCities?.find(
        (cityEl) => cityEl.id === cityId
      );
      const inDropOffDistricts = tourPickupLocation?.dropOffDistricts?.find(
        (districtEl) => districtEl.id === districtId
      );
      if (inDropOffCities && inDropOffDistricts)
        tasks = [
          ...tasks,
          locationTaskTypeMap[enums.LocationTaskType.DROPOFFS_ONLY],
        ];
      return tasks;
    },
    [taskType, quotationCities, quotationDistricts, tourPickupLocation]
  );

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (key !== "id" && key !== "startTime" && key !== "endTime")
          setValue(`tasks.${index}.${key}`, data[key]);
        if (key === "startTime" && data[key])
          setValue(`tasks.${index}.startTime`, extractTimeFromISO(data[key]));
        if (key === "endTime" && data[key])
          setValue(`tasks.${index}.endTime`, extractTimeFromISO(data[key]));
      });
    }
  }, [data]);

  const validateTaskForComplete = useCallback(async () => {
    await TaskRowValidation(currentTour.pickupTime).validate(currentTask);
    if (nextTask && nextTask.startTime) {
      await TaskRowValidation(currentTour.pickupTime).validate(nextTask);
    }
  }, [currentTask, nextTask, currentTour]);
  const validateTaskForStart = useCallback(async () => {
    await TaskRowValidation(currentTour.pickupTime).validate(currentTask);
  }, [currentTask, currentTour]);

  const validateAllTasks = useCallback(async () => {
    await TasksTableValidation(true).validate({ tasks });
  }, [tasks]);

  const updateTaskStatus = async (status) => {
    try {
      if (!currentTask.id) return;
      await sendRequest(
        toursDataResource.updateTourTask(currentTour.id, data.id, {
          id: data.id,
          status: status,
        })
      );
      setValue(`tasks.${index}.status`, status);
      updateTasks();
    } catch (err) {
      console.log("error", err);
    }
  };

  const buildTasksForStatusUpdate = (newCurrentTask, newNextTask) => {
    let referenceDate = currentTour.pickupTime;
    let newCurrentTaskRefDate;
    const values = tasks?.map((task, i) => {
      const { startDate, endDate } = getCurrentTaskDate(referenceDate, i, task);
      referenceDate = endDate || startDate || referenceDate;
      if (task.id === currentTask.id) return {};
      if ((startDate || endDate) && i < index) {
        newCurrentTaskRefDate = endDate || startDate;
      }
      const newTask = {
        ...task,
        locationId: task.location?.id,
        tourId: currentTour.id,
        startTime: task.startTime
          ? convertTimeToISO(startDate, task.startTime)
          : null,
        endTime: task.endTime ? convertTimeToISO(endDate, task.endTime) : null,
      };
      delete newTask["location"];
      return newTask;
    });

    delete newCurrentTask["location"];
    newCurrentTaskRefDate = newCurrentTaskRefDate || currentTour.pickupTime;
    const {
      startDate: currentStartDate,
      endDate: currentEndDate,
    } = getCurrentTaskDate(newCurrentTaskRefDate, index, newCurrentTask);
    const newCurrentTaskWithDates = {
      ...newCurrentTask,
      startTime: newCurrentTask.startTime
        ? convertTimeToISO(currentStartDate, newCurrentTask.startTime)
        : null,
      endTime: newCurrentTask.endTime
        ? convertTimeToISO(currentEndDate, newCurrentTask.endTime)
        : null,
    };
    values[index] = newCurrentTaskWithDates;
    if (newNextTask) {
      newCurrentTaskRefDate =
        currentEndDate || currentStartDate || newCurrentTaskRefDate;
      const {
        startDate: nextStartDate,
        endDate: nextEndDate,
      } = getCurrentTaskDate(newCurrentTaskRefDate, index + 1, newNextTask);
      const newNextTaskWithDates = {
        ...newNextTask,
        startTime: newNextTask.startTime
          ? convertTimeToISO(nextStartDate, newNextTask.startTime)
          : null,
        endTime: newNextTask.endTime
          ? convertTimeToISO(nextEndDate, newNextTask.endTime)
          : null,
      };
      values[index + 1] = newNextTaskWithDates;
    }
    return values;
  };
  const startTask = async () => {
    if (!currentTask.id) return;
    const newCurrentTask = {
      ...currentTask,
      id: data.id,
      status: enums.TaskStatus.ONGOING,
    };
    const newTasks = buildTasksForStatusUpdate(newCurrentTask);
    await sendRequest(
      toursDataResource.updateTourTasks(currentTour.id, {
        id: currentTour.id,
        tasks: newTasks,
      })
    );
  };

  const completeTask = async () => {
    if (!currentTask.id) return;
    const newCurrentTask = {
      ...currentTask,
      id: data.id,
      status: enums.TaskStatus.COMPLETED,
    };
    const newNextTask = nextTask?.id &&
      nextTask?.startTime && {
        ...nextTask,
        id: nextTask.id,
        status: enums.TaskStatus.ONGOING,
      };
    const newTasks = buildTasksForStatusUpdate(newCurrentTask, newNextTask);

    await sendRequest(
      toursDataResource.updateTourTasks(currentTour.id, {
        id: currentTour.id,
        tasks: newTasks,
      })
    );
    //update tasks

    setValue(`tasks.${index}.status`, enums.TaskStatus.COMPLETED);
    if (nextTask?.id && nextTask?.startTime) {
      setValue(`tasks.${index + 1}.status`, enums.TaskStatus.ONGOING);
    }
  };

  const onCompleteTask = async () => {
    try {
      updateTasks();
      await validateAllTasks();
      await validateTaskForComplete();
      await completeTask();
      updateTasks();

      toast.success("Task Completed Successfully");
    } catch (err) {
      console.log(err);
      setErrorMessage(err.errors[0]);
      throw err;
    }
  };

  const onStartTask = async () => {
    try {
      await validateAllTasks();
      await validateTaskForStart();
      await startTask();
      setValue(`tasks.${index}.status`, enums.TaskStatus.ONGOING);
      updateTasks();
      toast.success("Task started successfully.");
    } catch (err) {
      console.log(err);
      setErrorMessage(err.errors[0]);
      throw err;
    }
  };

  const taskStatusValue = useMemo(() => {
    const value = translations[i18n.language]["taskStatus"][taskStatus];
    return value;
  }, [taskStatus]);

  const taskDisabled = useMemo(() => tourDisabled || isServiceProcessed, [
    isServiceProcessed,
    tourDisabled,
  ]);
  const handleTaskTypeChange = () => {
    setValue(`tasks.${index}.location`, undefined);
  };

  const handleAddNewLocation = useCallback(
    (location) => {
      getTourLocations();
      setValue(`tasks.${index}.location`, location);
    },
    [index]
  );

  const onModalClose = () => {
    setCurrentTask(null);
    setIsModalOpen(false);
  };
  const onModalOpen = () => {
    setIsModalOpen(true);
  };
  const { diffStart, diffEnd } = useMemo(
    () => ({
      diffStart:
        currentTour &&
        diffDays(currentTour?.pickupTime, tasksWithTime[index]?.startTime),
      diffEnd:
        currentTour &&
        diffDays(currentTour?.pickupTime, tasksWithTime[index]?.endTime),
    }),
    [tasksWithTime]
  );
  return {
    onModalClose,
    onModalOpen,
    isModalOpen,
    locations,
    handleAddNewLocation,
    cities,
    districts,
    deal,
    taskStatus,
    taskStatusValue,
    taskDisabled,
    form,
    setCurrentTask,
    updateTaskStatus,
    startTask,
    taskDocuments,
    startButtonShown,
    statusChangeDisabled,
    completeTaskDisabled,
    statusChangeMessage,
    validateTaskForStart,
    onCompleteTask,
    onStartTask,
    errorMessage,
    handleTaskTypeChange,
    isLocationDisabled,
    getLocationTaskTypes,
    currentTour,
    diffStart,
    diffEnd,
    taskType,
    tourPickupLocationId,
  };
}
