import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import React from "react";
import api from "services/api";
import permissionsForm from "views/AccessManagement/permissionsForm";
import enums from "enums/index";
import translations from "constants/translations/index";
import i18n from "i18n";

// appear in breadcrumbs and page headers
const listPageName = "Permissions";
const createPageName = "Create Permission";
const editPageName = "Edit Permission";

// appears on alerts; example: Entity created successfully
const entityName = "Permission";

const resource = enums.Resource.PERMISSIONS;

const layout = "/admin";

const path = "permissions";

const formStructure = permissionsForm;

const dataResource = api.permissions;

const columns = [
  {
    title: "Create",
    dataIndex: "create",
  },
  {
    title: "Read",
    dataIndex: "read",
  },
  {
    title: "Update",
    dataIndex: "update",
  },
  {
    title: "Delete",
    dataIndex: "delete",
  },
  {
    title: "Resource",
    dataIndex: "resource",
    getData: (data) => translations[i18n.language].resource[data],
  },
  {
    title: "Department",
    dataIndex: ["department", "name"],
  },
  {
    title: "Role",
    dataIndex: ["role", "name"],
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "security",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      resource,
    },
  ],
};
