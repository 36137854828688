import DataList from "components/DataList/DataList";
import React from "react";
import api from "services/api";
import TramIcon from "@material-ui/icons/Tram";
import TruckTypeForm from "views/TruckTypes/TruckTypeForm";
import enums from "enums/index";

//----------------------------------------------------------------------

// appear in breadcrumbs and page headers
const listPageName = "Truck Types";
const createPageName = "Create Truck Type";
const editPageName = "Edit Truck Type";

// appears on alerts; example: Entity created successfully
const entityName = "Truck Type";

const resource = enums.Resource.TRUCK_TYPES;

const layout = "/admin";

const path = "truckTypes";

const dataResource = api.truckTypes;

const columns = [
  {
    title: "Name (English)",
    dataIndex: ["details", "nameEn"],
  },
  {
    title: "Name (Arabic)",
    dataIndex: ["details", "nameAr"],
  },
  {
    title: "Base Opening Price",
    dataIndex: ["computed", "baseOpeningPrice"],
  },
  {
    title: "Base Price Per Km",
    dataIndex: ["computed", "basePricePerKm"],
  },
  {
    title: "Extra Hour Price",
    dataIndex: ["details", "extraHourPrice"],
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: TramIcon,
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => <TruckTypeForm path={path} create />,
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => <TruckTypeForm path={path} edit />,
      layout: layout,
      randomKey: true,
      action: "update",
      resource,
    },
  ],
};
