import enums from "enums/index";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import StatusButtonValidation from "./StatusButton.validation";

export default function useStatusButton({
  resource,
  statuses,
  currentStatus,
  updateInfo,
}) {
  const statusList = Object.values(statuses);
  const statusIndex = statusList.findIndex((value) => value === currentStatus);
  const nextStatus =
    statusIndex !== -1 && statusIndex !== statusList.length - 1
      ? statusList[statusIndex + 1]
      : null;
  const finalStatus = statusIndex === statusList.length - 2;

  const resolver = useYupValidationResolver(
    StatusButtonValidation(finalStatus)
  );

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const [showModal, setShowModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { handleSubmit, getValues } = form;

  const onSubmit = (values) => async () => {
    setConfirmLoading(true);
    try {
      let info = { status: nextStatus };
      if (finalStatus) {
        const actualDateKey =
          resource === enums.Resource.INVOICES
            ? "actualInvoiceDate"
            : "actualBillDate";

        info = {
          ...info,
          referenceNumber: values.referenceNumber,
          [actualDateKey]: values.actualDate,
        };
      }
      await updateInfo(info);

      setConfirmLoading(false);

      toast.success("Status changed successfully");
      closeModal();
    } catch (error) {
      setConfirmLoading(false);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return {
    form,
    showModal,
    statusList,
    statusIndex,
    nextStatus,
    finalStatus,
    confirmLoading,
    submitHandler,
    openModal,
    closeModal,
  };
}
