import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import PropTypes from "prop-types";
import { formatEnums } from "utils/index";
import styles from "./StatusButton.styles";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import useStatusButton from "./StatusButton.hook";
import Button from "components/CustomButtons/Button.js";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function StatusButton({
  resource,
  statuses,
  currentStatus,
  updateInfo,
  ...props
}) {
  const {
    form,
    showModal,
    statusIndex,
    nextStatus,
    finalStatus,
    confirmLoading,
    submitHandler,
    openModal,
    closeModal,
  } = useStatusButton({
    resource,
    statuses,
    currentStatus,
    updateInfo,
  });

  const classes = useStyles();

  const nextStatusText = nextStatus ? formatEnums(nextStatus) : null;

  const statusColors = ["primary", "info", "success"];

  return nextStatus ? (
    <div>
      <Button
        color={statusColors[statusIndex]}
        type="submit"
        className={classes.proceedButton}
        onClick={openModal}
      >
        {`Proceed to ${nextStatusText}`}
      </Button>

      <CustomModal
        open={showModal}
        onClose={closeModal}
        modalHeaderClassName={classes.modalHeader}
        modalBodyClassName={classes.modalBody}
      >
        <div className={classes.formHeader}>
          <React.Fragment>Change Status</React.Fragment>
        </div>
        <form
          className={classes.form}
          onSubmit={submitHandler}
          autoComplete="off"
        >
          {`Are you sure you want to change status to ${nextStatusText}?`}
          {finalStatus ? (
            <>
              <FormField
                md={12}
                form={form}
                name="actualDate"
                type="date"
                label="Actual Invoice Date"
              />
              <FormField
                md={12}
                form={form}
                name="referenceNumber"
                type="text"
                label="Reference Number"
              />
            </>
          ) : null}

          <div className={classes.modalButtonsContainer}>
            <Button
              color="transparent"
              textColor="black"
              onClick={closeModal}
              className={classes.cancelButton}
              loadFromRight
            >
              Cancel
            </Button>
            <Button
              color="success"
              type="submit"
              className={classes.confirmButton}
              loading={confirmLoading}
              loadFromRight
            >
              Confirm
            </Button>
          </div>
        </form>
      </CustomModal>
    </div>
  ) : null;
}

StatusButton.propTypes = {
  resource: PropTypes.string,
  statuses: PropTypes.array,
  currentStatus: PropTypes.string,
  updateInfo: PropTypes.func,
};
