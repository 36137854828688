export const monthlyKmLimit = [
  "0",
  "1000",
  "1500",
  "2000",
  "2500",
  "3000",
  "3500",
  "4000",
  "4500",
  "5000",
  "5500",
  "6000",
  "6500",
  "7000",
  "7500",
  "8000",
  "8500",
  "9000",
  "9500",
  "10000",
  "11000",
  "12000",
  "13000",
  "14000",
  "15000",
];

export const oneDayKmLimit = ["100", "150", "200", "250", "300"];

export const daysHoursLimit = ["5", "6", "7", "8", "9", "10", "11", "12", "24"];

export const daysLimit = ["1", "22", "26", "30"];

export default {
  monthlyKmLimit,
  oneDayKmLimit,
  daysHoursLimit,
  daysLimit,
};
