import React from "react";
import useTasksTable from "./TasksTable.hook";
import { TasksTableContext } from "./TasksTable.context";
import Button from "components/CustomButtons/Button.js";
import TaskRow from "./Components/TaskRow/TaskRow";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./TasksTable.styles";
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function TasksTable({ sendTasksRequest }) {
  const tasksElements = [];
  const classes = useStyles();

  const {
    form,
    formRef,
    defaultTask,
    tasks,
    tourPickupLocationId,
    tourPickupLocation,
    quotationCities,
    quotationDistricts,
    quotationLocations,
    tourType,
    currentTask,
    tourDisabled,
    isServiceProcessed,
    allCities,
    allDistricts,
    tasksWithTime,
    setCurrentTask,
    updateTasks,
    getTourLocations,
    setTourPickupLocation,
    onSubmit,
    onAdd,
    onRemove,
    handleChange,
  } = useTasksTable({ sendTasksRequest });

  if (tasks?.length)
    tasks.forEach((task, index) => {
      tasksElements.push(
        <TaskRow
          key={task.id}
          index={index}
          data={task}
          onAdd={onAdd}
          onChange={handleChange}
          onRemove={onRemove}
          formRef={formRef}
        />
      );
    });

  const firstColClasses = classNames({
    [classes.rowHead]: true,
    [classes.stickyCol]: true,
    [classes.firstCol]: true,
  });
  const lastColClasses = classNames({
    [classes.rowHead]: true,
    [classes.stickyCol]: true,
    [classes.lastCol]: true,
  });

  return (
    <TasksTableContext.Provider
      value={{
        tasks,
        tasksWithTime,
        form,
        defaultTask,
        tourPickupLocationId,
        tourPickupLocation,
        quotationCities,
        quotationDistricts,
        quotationLocations,
        tourType,
        isServiceProcessed,
        allCities,
        allDistricts,
        currentTask,
        onSubmit,
        setTourPickupLocation,
        getTourLocations,
        setCurrentTask,
        updateTasks,
      }}
    >
      <form onSubmit={onSubmit} ref={formRef}>
        <div className={classes.buttonGroup}>
          {!(tourDisabled || isServiceProcessed) && (
            <Button
              onClick={() => {
                onAdd();
              }}
              className={classes.addContactButton}
              color="primary"
            >
              Add Task
            </Button>
          )}
        </div>
        <Card raised style={{ margin: 0 }}>
          <CardBody noPadding>
            {tasksElements.length > 0 && (
              <table className={classes.table}>
                <tr>
                  <th
                    style={{ borderTopLeftRadius: "10px" }}
                    className={firstColClasses}
                  >
                    Type
                  </th>
                  <th className={classes.rowHead} style={{ width: "30%" }}>
                    Location
                  </th>
                  <th className={classes.rowHead}>Start Time</th>
                  <th className={classes.rowHead}>End Time</th>
                  <th className={classes.rowHead}>Distance</th>
                  <th className={classes.rowHead}>Distance Meter</th>
                  <th className={classes.rowHead}>Document Attachment</th>
                  <th className={classes.rowHead}>Status</th>
                  {!(tourDisabled || isServiceProcessed) && (
                    <th
                      className={lastColClasses}
                      style={{
                        borderWidth: `0 0 1px 0`,
                        borderTopRightRadius: "10px",
                      }}
                    >
                      Actions
                    </th>
                  )}
                </tr>
                {tasksElements}
              </table>
            )}
            {tasksElements.length === 0 && (
              <h3 className={classes.headerContainer}>No Tasks To Show</h3>
            )}
          </CardBody>
        </Card>
        <div className={classes.buttonsContainer}>
          {!(tourDisabled || isServiceProcessed) && (
            <Button
              color="primary"
              type="submit"
              style={{ marginTop: 20 }}
              edit
              loadFromRight
            >
              Submit
            </Button>
          )}
        </div>
      </form>
    </TasksTableContext.Provider>
  );
}
