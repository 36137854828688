import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import usePenaltyRow from "./PenaltyRow.hook";
import React from "react";
import styles from "./PenaltyRow.styles";
import api from "services/api";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";

const useStyles = makeStyles(styles);

export default function PenaltyRow({ form, index, data, onRemove }) {
  const { serviceProviders, inFinOps, rowDisabled } = usePenaltyRow(
    form,
    index,
    data
  );

  const classes = useStyles();

  return (
    <tr style={{ width: "100%" }}>
      <td className={classes.rowCell}>
        <FormField
          name={`penalties.${index}.type`}
          form={form}
          type="select"
          options={{
            disabled: rowDisabled || !inFinOps,
            enum: "penaltyType",
            isTableInput: true,
          }}
        />
      </td>
      <td className={classes.rowCell}>
        <FormField
          name={`penalties.${index}.bill.serviceProvider`}
          form={form}
          type="autocomplete"
          options={{
            disabled: rowDisabled || !inFinOps,
            getOptionLabel: (option) => option.name,
            data: serviceProviders,
            blurOnSelect: false,
            isTableInput: true,
          }}
        />
      </td>
      <td className={classes.rowCell}>
        <FormField
          name={`penalties.${index}.price`}
          form={form}
          type="number"
          options={{
            disabled: rowDisabled || !inFinOps,
            blurOnSelect: false,
            isTableInput: true,
          }}
        />
      </td>
      <td className={classes.rowCell}>
        <FormField
          name={`penalties.${index}.notes`}
          form={form}
          type="text"
          options={{
            disabled: rowDisabled,
            blurOnSelect: false,
            isTableInput: true,
          }}
        />
      </td>
      <td className={classes.rowCell}>
        <FormField
          name={`penalties.${index}.addedBy.name`}
          form={form}
          type="text"
          options={{
            disabled: true,
            blurOnSelect: false,
            isTableInput: true,
          }}
        />
      </td>
      {inFinOps && (
        <td
          className={classes.rowCell}
          style={{
            borderWidth: `0 0px 1px 0`,
          }}
        >
          <span style={{ display: "flex", justifyContent: "center" }}>
            {!rowDisabled && (
              <ConfirmationButton
                onConfirm={() => onRemove(index)}
                confirmMessage={`Are you sure you want to remove penalty number ${
                  index + 1
                }`}
                successHeaderText="Penalty Removed"
                successMessage="Penalty removed successfully"
                headerMessage="Remove Penalty"
                confirmButtonText="Confirm"
                confirmButtonColor="success"
                buttonProps={{
                  textColor: "textColor",
                  className: classes.button,
                }}
              >
                <Tooltip
                  title={"Remove"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                  arrow
                >
                  <DeleteForeverIcon style={{ cursor: "pointer" }} />
                </Tooltip>
              </ConfirmationButton>
            )}
          </span>
        </td>
      )}
    </tr>
  );
}
