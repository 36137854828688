import api from "services/api";
import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Name",
        validation: yup.string().required("Required"),
      },
      {
        name: "email",
        type: "text",
        label: "Email",
        validation: yup
          .string()
          .required("Required")
          .email("Please enter a valid email"),
      },
      {
        name: "password",
        type: "text",
        label: "Password",
        validation: yup.string().min(8, "Must be 8 characters or more"),
      },
      {
        name: "roleId",
        fetchedName: "role",
        type: "select",
        label: "Role",
        options: {
          getData: api.roles.getAll,
          rawDataConvertor: (data) =>
            data?.map((entry) => ({
              value: entry.id,
              name: entry.name,
            })),
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "departmentId",
        fetchedName: "department",
        type: "select",
        label: "Department",
        options: {
          getData: api.departments.getAll,
          rawDataConvertor: (data) =>
            data?.map((entry) => ({
              value: entry.id,
              name: entry.name,
            })),
        },
      },
    ],
  },
];
