import {
  defaultFont,
  successColor,
} from "assets/jss/material-dashboard-pro-react.js";

//-------------------------------------------------------------------------------------

const styles = (theme) => ({
  search: {
    margin: "0",
    paddingTop: "7px",
    paddingBottom: "7px",
  },
  searchInput: {
    paddingTop: "2px",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButton: {
    border: "1px solid #ddd",
    borderRadius: "50%",
    fontWeight: "700",
    marginLeft: "auto",
    padding: 5,
  },
  closeIcon: {
    width: "25px !important",
    height: "25px !important",
    margin: "0px !important",
  },
  formHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 400,
    padding: "10px 30px",
    paddingBottom: 20,
    borderBottom: "1px solid #ddd",
    margin: "0px -20px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  loadDraftContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 40,
    paddingTop: 20,
  },
  loadButton: {
    padding: "5px 15px",
    marginLeft: 15,
  },
  icon: {
    marginRight: 15,
    fontSize: 26,
    color: "#6f7f88",
  },
  form: {
    padding: 40,
    paddingBottom: 0,
  },
  section: {
    width: "100%",
    marginBottom: 80,
  },
  linkText: {
    zIndex: "4",
    ...defaultFont,
    fontSize: "14px",
    margin: "0!important",
    textTransform: "none",
  },
  buttonLink: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      margin: "5px 15px 0",
      width: "auto",
      height: "auto",
      "& svg": {
        width: "30px",
        height: "24px",
        marginRight: "19px",
        marginLeft: "3px",
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        width: "30px",
        fontSize: "24px",
        lineHeight: "30px",
        marginRight: "19px",
        marginLeft: "3px",
      },
    },
  },
  searchButton: {
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      marginRight: "38px",
      float: "right",
    },
  },
  top: {
    zIndex: "4",
  },
  searchIcon: {
    width: "17px",
    zIndex: "4",
  },
  links: {
    width: "20px",
    height: "20px",
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "30px",
      height: "30px",
      color: "inherit",
      opacity: "0.8",
      marginRight: "16px",
      marginLeft: "-5px",
    },
  },
  managerClasses: {
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
    },
  },
  headerLinksSvg: {
    width: "20px !important",
    height: "20px !important",
  },
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: 14,
    height: 14,
  },
  underChartIcons: {
    width: "17px",
    height: "17px",
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },

  topContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 60,
  },
  searchContainer: { display: "flex", alignItems: "center" },
  cancelButton: {
    marginRight: 20,
    marginTop: 20,
    height: 41.6,
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    height: 60,
  },
});

export default styles;
