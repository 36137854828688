import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import CheckboxInput from "components/CheckboxGroup/CheckboxInput/CheckboxInput";
import styles from "./FiltersList.styles";
import enums from "enums/index";
import GenericInput from "components/GenericInput/GenericInput";

const useStyles = makeStyles(styles);

export default function FiltersList(props) {
  const {
    serviceProviderIsNull,
    driverOrTruckIsNull,
    pickupDate,
    toursCompleted,
    toursReviewed,
    toggleServiceProviderIsNull,
    toggleDriverOrTruckIsNull,
    setPickupDate,
    toggleToursCompleted,
    toggleToursReviewed,
    resource,
  } = props;

  const classes = useStyles();

  return (
    <GridContainer className={classes.root}>
      <GridItem xs={12} sm={2}>
        <FormLabel
          className={
            classes.labelHorizontal + " " + classes.labelHorizontalRadioCheckbox
          }
        >
          Filter Tours By:
        </FormLabel>
      </GridItem>
      <GridItem className={classes.checkBoxList} xs={12} sm={8}>
        {resource === enums.Resource.DISPATCHING && (
          <CheckboxInput
            value={serviceProviderIsNull}
            onClick={() => toggleServiceProviderIsNull()}
            label="No Service Provider"
            notHorizontal
          />
        )}
        {resource === enums.Resource.DISPATCHING && (
          <CheckboxInput
            value={driverOrTruckIsNull}
            onClick={() => toggleDriverOrTruckIsNull()}
            label="No Driver or Truck"
            notHorizontal
          />
        )}
        {resource === enums.Resource.DISPATCHING && (
          <CheckboxInput
            value={toursCompleted}
            onClick={() => toggleToursCompleted()}
            label="Completed Tours"
            notHorizontal
          />
        )}
        {resource === enums.Resource.FIN_OPS && (
          <CheckboxInput
            value={toursReviewed}
            onClick={() => toggleToursReviewed()}
            label="Reviewed Tours"
            notHorizontal
          />
        )}
        {resource === enums.Resource.DISPATCHING && (
          <div style={{ width: 200 }}>
            <GenericInput
              value={pickupDate}
              type="date"
              onChange={(event) => {
                const value = event?.target?.value;
                setPickupDate(value);
              }}
              label="Pickup Date"
            />
          </div>
        )}
      </GridItem>
    </GridContainer>
  );
}
