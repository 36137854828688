import FormField from "components/Forms/FormField/FormField";
import {
  daysHoursLimit,
  oneDayKmLimit,
  monthlyKmLimit,
  daysLimit,
} from "constants/arrays";
import PropTypes from "prop-types";
import api from "services/api";
import useRentalQuotationForm from "./RentalQuotationForm.hook";
import React from "react";
import Button from "components/CustomButtons/Button.js";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import { toNearest } from "utils/index";

//-------------------------------------------------------------------------------------

export default function RentalQuotationForm(props) {
  const { quotation: quotationProp, onClose } = props;

  const {
    isLoading,
    submitHandler,
    form,
    numberOfDays,
  } = useRentalQuotationForm({
    quotationProp,
    onClose,
  });

  return (
    <form
      style={{ padding: 20, paddingBottom: 0 }}
      onSubmit={submitHandler}
      autoComplete="off"
    >
      <FormField
        md={12}
        name="truckType"
        type="autocomplete"
        label="Truck Type"
        form={form}
        options={{
          getData: api.truckTypes.getAll,
          getOptionLabel: (option) => option.details.name,
        }}
      />
      <FormField
        md={12}
        name="rental.pickupZones"
        type="autocomplete"
        label="Pickup Zones"
        form={form}
        options={{
          getData: api.zones.getAll,
          getOptionLabel: (option) => option.name,
          multiple: true,
          blurOnSelect: false,
        }}
      />
      <FormField
        md={12}
        name="rental.details.daysLimit"
        label="Days Limit"
        type="select"
        form={form}
        options={{
          data: daysLimit,
        }}
      />
      <FormField
        md={12}
        name="rental.details.kmLimit"
        type="autocomplete"
        label="Km Limit"
        form={form}
        options={{
          data: parseInt(numberOfDays) === 1 ? oneDayKmLimit : monthlyKmLimit,
          blurOnSelect: false,
        }}
      />
      <FormField
        md={12}
        name="rental.details.daysHoursLimit"
        type="autocomplete"
        label="Duration Allowance (hours)"
        form={form}
        options={{
          data: daysHoursLimit,
          blurOnSelect: false,
        }}
      />
      <FormField
        md={12}
        name="rental.details.idleAllowance"
        type="number"
        label="Idle Allowance (days)"
        form={form}
      />
      {quotationProp && (
        <React.Fragment>
          <InfoWithLabel
            label="Target Deviation (%)"
            info={toNearest(
              ((quotationProp?.details?.truckPrice -
                quotationProp?.details?.generatedTruckPrice) /
                quotationProp?.details?.generatedTruckPrice) *
                100
            )}
            formField
          />
          <InfoWithLabel
            label="Margin (%)"
            info={toNearest(
              ((quotationProp?.details?.truckPrice -
                quotationProp?.details?.targetTruckCost) /
                quotationProp?.details?.truckPrice) *
                100
            )}
            formField
          />
          <FormField
            md={12}
            name="details.truckPrice"
            type="number"
            label="Truck Price"
            form={form}
          />
          <InfoWithLabel
            label="System Generated Truck Price"
            info={quotationProp?.details?.generatedTruckPrice}
            formField
          />
          <FormField
            md={12}
            name="details.targetTruckCost"
            type="number"
            label="Target Truck Cost"
            form={form}
          />
          <InfoWithLabel
            label="System Generated Truck Target Cost"
            info={quotationProp?.details?.generatedTruckTargetCost}
            formField
          />
        </React.Fragment>
      )}
      <FormField
        md={12}
        name="details.notes"
        type="text"
        label="Notes"
        form={form}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          color="primary"
          type="submit"
          loading={isLoading}
          style={{ margin: "20px 0" }}
          edit={!!quotationProp}
          create={!quotationProp}
          loadFromRight
        />
      </div>
    </form>
  );
}

RentalQuotationForm.defaultProps = {
  onClose: () => {},
};

RentalQuotationForm.propTypes = {
  quotation: PropTypes.object,
  onClose: PropTypes.func,
};
