import * as yup from "yup";

//----------------------------------------------------------
//validate number of days is 1, 22, 26
export default () => {
  return yup.object().shape({
    truckType: yup.mixed().required("Required"),
    customerReference: yup.mixed().required("Required"),
    deal: yup.mixed().required("Required"),
    channel: yup.mixed().required("Required"),
    city: yup.mixed().required("Required"),
    district: yup.mixed().required("Required"),
    location: yup.mixed().required("Required"),
    monthlyDistanceQuota: yup.mixed().required("Required"),
    dailyDurationLimit: yup.mixed().required("Required"),
    offDays: yup
      .mixed()
      .test(
        "offdays-limit-error",
        "Off Days should be either 0, 1 or 2 days",
        (offDays) => {
          return (
            offDays?.length === 2 ||
            offDays?.length === 1 ||
            offDays?.length === 0 ||
            !offDays
          );
        }
      ),
    startDate: yup.mixed().required("Required"),
    endDate: yup
      .mixed()
      .required("Required")
      .when(["startDate", "offDays"], (startDate, offDays) => {
        const offDaysIds = offDays
          ? offDays.map((day) => parseInt(day.id))
          : [];
        return yup
          .mixed()
          .test(
            "endDate-after-startDate-error",
            "The end date should be after the start date.",
            (endDate) => {
              if (startDate && endDate) return startDate < endDate;
              else return true;
            }
          )
          .required("Required");
      }),

    pickupTime: yup.mixed().required("Required"),
  });
};
