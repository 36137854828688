import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import translations from "constants/translations";
import React from "react";
import api from "services/api";
import organizationsForm from "views/Organizations/organizationsForm";
import { getI18n } from "react-i18next";
import enums from "enums/index";
import OrganizationFormPage from "views/Organizations/OrganizationForm/OrganizationFormPage";

// appear in breadcrumbs and page headers
const listPageName = "Organizations";
const createPageName = "Create Organization";
const editPageName = "Edit Organization";

// appears on alerts; example: Entity created successfully
const entityName = "Organization";

const resource = enums.Resource.ORGANIZATIONS;

const layout = "/admin";

const path = "organizations";

const formStructure = organizationsForm;

const dataResource = api.organizations;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Status",
    dataIndex: "status",
    getData: (data) => {
      if (data)
        return translations[getI18n().language].organizationStatus[data];
      else return "-";
    },
  },
  {
    title: "Tax Id",
    dataIndex: "taxId",
  },
  {
    title: "Category",
    dataIndex: ["category", "name"],
  },
  {
    title: "Industries",
    dataIndex: "industries",
    attribute: "name",
  },
  {
    title: "Creator",
    dataIndex: ["createdBy", "name"],
  },
];

export default {
  path: `/${path}`,
  name: "Organizations List",
  sidebarName: "List",
  icon: "list",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => <OrganizationFormPage path={path} create />,
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => <OrganizationFormPage path={path} edit />,
      layout: layout,
      randomKey: true,
      action: "update",
      resource,
    },
  ],
};
