import { makeStyles } from "@material-ui/core/styles";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import translations from "constants/translations";
import enums from "enums";
import React from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import { getDateString } from "utils";
import contractsForm from "views/Organizations/contractsForm";
import { Delete } from "@material-ui/icons";
import UploadContractModal from "../../components/UploadContractModal/UploadContractModal";
import useContracts from "./SignedContractsSection.hook";
import styles from "./SignedContractsSection.styles";
import CheckPermission from "components/CheckPermission/CheckPermission";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ContractsSection() {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    deal,
    contracts,
    isUploadContractOpen,
    isEditContractOpen,
    isGetDealLoading,
    editItemId,
    handleUploadContract,
    onAddModalClose,
    handleEditContract,
    onEditModalClose,
    handleRemoveContract,
    onRemoveModalClose,
  } = useContracts();

  const columns = React.useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Type",
        dataIndex: "type",
        getData: (data) => {
          if (data) return translations[i18n.language].contractType[data];
        },
      },
      {
        title: "Starting Date",
        dataIndex: "startingDate",
        getData: (rawData) => {
          if (rawData) return getDateString(rawData);
        },
      },
      {
        title: "Expiry Date",
        dataIndex: "expiryDate",
        getData: (rawData) => {
          if (rawData) return getDateString(rawData);
        },
      },
      {
        title: "Signed By",
        dataIndex: ["signatureContact", "name"],
      },
      {
        title: "Created By",
        dataIndex: ["createdBy", "name"],
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              <a href={record?.documentUrl} target="_blank" download>
                <Button justIcon cloudDownload color="white" textColor="info" />
              </a>
              <CheckPermission
                resource={enums.Resource.DEAL_ORGANIZATIONS}
                action={enums.Action.UPDATE}
              >
                <Button
                  justIcon
                  edit
                  color="white"
                  textColor="success"
                  onClick={() => handleEditContract(record?.id)}
                />
              </CheckPermission>
              <CheckPermission
                resource={enums.Resource.DEAL_ORGANIZATIONS}
                action={enums.Action.UPDATE}
              >
                <ConfirmationButton
                  confirmMessage="Are you sure you want to delete this contract?"
                  successHeaderText="Contract Deleted"
                  successMessage="Contract deleted successfully"
                  headerMessage="Delete Contract"
                  confirmButtonText="Delete"
                  onConfirm={() => handleRemoveContract(record?.id)}
                  onSuccess={() => onRemoveModalClose(record?.id)}
                  buttonProps={{
                    justIcon: true,
                  }}
                />
              </CheckPermission>
            </div>
          );
        },
      },
    ],
    [deal]
  );

  return (
    <div className={classes.section}>
      {isUploadContractOpen && (
        <UploadContractModal
          isOpen={isUploadContractOpen}
          onClose={onAddModalClose}
        />
      )}
      {isEditContractOpen && (
        <FormContainer
          dataResource={api.contracts}
          formStructure={contractsForm(deal?.organization?.id)}
          type={enums.FormContainerType.MODAL}
          edit
          isOpen={isEditContractOpen}
          onClose={onEditModalClose}
          editItemId={editItemId}
        />
      )}
      <React.Fragment>
        <div className={classes.headerContainer}>
          <h3 className={classes.header}>Signed Contracts</h3>
          <CheckPermission
            resource={enums.Resource.DEAL_ORGANIZATIONS}
            action={enums.Action.UPDATE}
          >
            <Button
              onClick={handleUploadContract}
              className={classes.uploadButton}
              color="success"
            >
              <i className={"fas fa-upload " + classes.uploadIcon} />
              Upload Contract
            </Button>
          </CheckPermission>
        </div>
        <CollapseContainer
          name="contracts"
          titleComponent={<div />}
          startCollapsed={false}
        >
          <div className={classes.tableContainer}>
            <CustomTable
              columns={columns}
              data={contracts}
              loading={isGetDealLoading}
              addIndexColumn
            />
          </div>
        </CollapseContainer>
      </React.Fragment>
    </div>
  );
}
