import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import FormField from "components/Forms/FormField/FormField";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Loading from "components/Loading/Loading";
import PropTypes from "prop-types";
import React from "react";
import api from "services/api";
import { useLocationsForm } from "./LocationsForm.hooks";
import styles from "./LocationsForm.styles";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function LocationsForm(props) {
  const { edit, create, deal } = props;

  const {
    isLoading,
    isGetLoading,
    form,
    locationTaskTypes,
    data,
    districts,
    cities,
    residentialOrBusiness,
    watch,
    onSubmit,
    handleSelectCity,
  } = useLocationsForm(props);

  const classes = useStyles();

  return (
    <Card className={classes.cardRoot}>
      <CardBody>
        <div className={classes.formHeader}>
          {create ? (
            <AddIcon className={classes.icon} />
          ) : (
            <EditIcon className={classes.icon} />
          )}
          {edit ? (
            isGetLoading ? (
              <Skeleton variant="rect" height={25} width={250} />
            ) : (
              `Edit ${data?.name}`
            )
          ) : (
            `Create Location`
          )}
        </div>

        <Loading loading={isGetLoading} style={{ height: "60vh" }}>
          <form
            style={{ padding: 40, paddingBottom: 0 }}
            onSubmit={onSubmit}
            autoComplete="off"
          >
            <div className={classes.section}>
              <GridContainer>
                <FormFieldGridItem
                  name="nameEn"
                  type="text"
                  label="Name (English)"
                  form={form}
                />
                <FormFieldGridItem
                  name="nameAr"
                  type="text"
                  label="Name (Arabic)"
                  form={form}
                />
                <FormFieldGridItem
                  name="address"
                  type="text"
                  label="Address"
                  form={form}
                />
                <FormFieldGridItem
                  name="latLng"
                  type="text"
                  label="Coordinates (lat,lng)"
                  form={form}
                />

                <FormFieldGridItem
                  name="city"
                  type="autocomplete"
                  label="City"
                  options={{
                    data: cities,
                    getData: cities ? undefined : api.cities.getAll,
                    getOptionLabel: (option) => option.name,
                  }}
                  onChange={handleSelectCity}
                  form={form}
                />
                <FormFieldGridItem
                  name="district"
                  type="autocomplete"
                  label="District"
                  options={{
                    data: districts,
                    getOptionLabel: (option) => option.name,
                    getSubmitValue: (option) => option?.id,
                  }}
                  form={form}
                />
                <FormFieldGridItem
                  type="select"
                  form={form}
                  name="taskType"
                  label="Task Type"
                  options={
                    locationTaskTypes
                      ? { data: locationTaskTypes }
                      : { enum: "locationTaskType" }
                  }
                />
                <FormFieldGridItem
                  type="select"
                  form={form}
                  name="locationStatus"
                  label="Location Status"
                  options={{
                    enum: "locationStatus",
                  }}
                />
                <FormFieldGridItem
                  name="isPrivate"
                  type="boolean"
                  label="Is this location private?"
                  form={form}
                  options={{ disabled: !!deal }}
                />
                <FormFieldGridItem
                  name="locationType"
                  type="autocomplete"
                  label="Location type"
                  options={{
                    getData: api.locationTypes.getAll,
                    getOptionLabel: (option) => option.name,
                    getSubmitValue: (option) => option?.id,
                  }}
                  form={form}
                />
                <FormFieldGridItem
                  name="deal"
                  type="autocomplete"
                  label="Deal"
                  options={{
                    getData: api.deals.getAll,
                    getOptionLabel: (option) => option?.details.name,
                    getSubmitValue: (option) => option?.id,
                    disabled: !!deal,
                  }}
                  form={form}
                />
                <GridItem xs={12} sm={12} md={5}>
                  <FormField
                    name="flatNumber"
                    type="text"
                    label="Flat no."
                    form={form}
                    getShowCondition={() => residentialOrBusiness}
                    dependencyList={[watch("locationType")]}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1} />
                <GridItem xs={12} sm={12} md={5}>
                  <FormField
                    name="floor"
                    type="number"
                    label="Floor"
                    form={form}
                    getShowCondition={() => residentialOrBusiness}
                    dependencyList={[watch("locationType")]}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1} />
                <GridItem xs={12} sm={12} md={5}>
                  <FormField
                    containerStyle={{
                      marginBottom: 24,
                      paddingTop: 17,
                    }}
                    name="buildingNumber"
                    type="text"
                    label="Building Number"
                    form={form}
                    getShowCondition={() => residentialOrBusiness}
                    dependencyList={[watch("locationType")]}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1} />
              </GridContainer>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                type="submit"
                loading={isLoading}
                style={{ margin: "20px 0" }}
                create={create}
                edit={edit}
                loadFromRight
              />
            </div>
          </form>
        </Loading>
      </CardBody>
    </Card>
  );
}

LocationsForm.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
  taskTypes: PropTypes.arrayOf(PropTypes.object),
};
