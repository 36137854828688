import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import FormField from "components/Forms/FormField/FormField";
import GenericInput from "components/GenericInput/GenericInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loading from "components/Loading/Loading";
import React from "react";
import { Controller } from "react-hook-form";
import api from "services/api";
import ToursList from "./components/ToursList/ToursList";
import { PlanFormContext } from "./PlanForm.context";
import { usePlanForm } from "./PlanForm.hook";
import styles from "./PlanForm.styles";
import enums from "enums/index";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function PlanForm(props) {
  const { isNewOrder } = props;

  const classes = useStyles();

  const {
    isLoading,
    selectedPlanDetails,
    form,
    defaultTour,
    defaultTask,
    initialTask,
    plan,
    organization,
    isGetLoading,
    quotationGroups,
    allCities,
    allDistricts,
    onSubmit,
    handleViewPlan,
    handlePlanDetailsChange,
  } = usePlanForm({ isNewOrder });

  const { control, errors } = form;

  return (
    <PlanFormContext.Provider
      value={{
        plan,
        form,
        defaultTour,
        defaultTask,
        initialTask,
        isNewOrder,
        quotationGroups,
        allCities,
        allDistricts,
      }}
    >
      <form className={classes.form} onSubmit={onSubmit} autoComplete="off">
        <div className={classes.headerContainer}>
          <h3>Plan Details:</h3>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Controller
                render={() => (
                  <GenericInput
                    label="Select Date"
                    type="date"
                    value={selectedPlanDetails.date}
                    error={errors.date?.message}
                    onChange={(event) =>
                      handlePlanDetailsChange("date", event.target.value)
                    }
                  />
                )}
                name="date"
                control={control}
              />
            </GridItem>
            {isNewOrder && (
              <GridItem xs={12} sm={12} md={3}>
                <Controller
                  render={() => (
                    <GenericInput
                      label="Deal"
                      type="autocomplete"
                      value={selectedPlanDetails.deal}
                      onChange={(_, value) =>
                        handlePlanDetailsChange("deal", value)
                      }
                      error={errors.deal?.message}
                      options={{
                        loadOnFocus: true,
                        getData: () =>
                          api.deals.getAll(
                            undefined,
                            undefined,
                            undefined,
                            {
                              "details.status": enums.DealStatus.ACTIVE,
                              organizationId: organization?.id,
                            },
                            undefined
                          ),
                        getOptionLabel: (option) => option.details.name,
                      }}
                    />
                  )}
                  name="deal"
                  control={control}
                />
              </GridItem>
            )}
            {!isNewOrder && (
              <GridItem xs={12} sm={12} md={3}>
                <Button
                  className={classes.button}
                  color="primary"
                  onClick={() => handleViewPlan()}
                >
                  View Plan
                </Button>
              </GridItem>
            )}
          </GridContainer>
        </div>
        {isNewOrder && (
          <React.Fragment>
            <h3>Order Details:</h3>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <FormField
                  type="text"
                  label="Customer Ref"
                  name="customerReference"
                  form={form}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <FormField
                  type="select"
                  label="Order Channel"
                  name="orderChannel"
                  form={form}
                  options={{
                    enum: "orderChannel",
                  }}
                />
              </GridItem>
            </GridContainer>
          </React.Fragment>
        )}
        <Loading loading={isGetLoading} rootClass={classes.loadingContainer}>
          {plan?.tours?.length || isNewOrder ? (
            <React.Fragment>
              <ToursList />
              <div className={classes.buttonsContainer}>
                <Button
                  color="primary"
                  type="submit"
                  loading={isLoading}
                  style={{ marginTop: 20 }}
                  edit
                  loadFromRight
                >
                  Submit
                </Button>
              </div>
            </React.Fragment>
          ) : (
            plan && (
              <h3 className={classes.noDataMessage}>
                No tours found on this date.
              </h3>
            )
          )}
        </Loading>
      </form>
    </PlanFormContext.Provider>
  );
}
