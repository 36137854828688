import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading/Loading";
import { RentalDealStatusColors } from "constants/colors";
import translations from "constants/translations";
import enums from "enums/index";
import React from "react";
import Tabs from "components/Tabs/Tabs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./ServiceProviderRentalView.styles";
import { ServiceProviderRentalContext } from "./ServiceProviderRentalView.context";
import { useServiceProviderRentalView } from "./ServiceProviderRentalView.hook";
import ServiceProviderRentalDetails from "views/ServiceProviderRentals/components/ServiceProviderRentalDetails/ServiceProviderRentalDetails";
import ServiceProviderRentalTours from "views/ServiceProviderRentals/components/ServiceProviderRentalTours/ServiceProviderRentalTours";
import RentalDealStatusButton from "./RentalDealStatusButton/RentalDealStatusButton";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ServiceProviderRentalView({ path }) {
  const classes = useStyles();

  const {
    currentTab,
    serviceProviderRental,
    isGetRentalLoading,
    handleTabChange,
    getLatestServiceProviderRental,
    setServiceProviderRental,
    updateRentalDeal,
  } = useServiceProviderRentalView({ path });

  const { i18n } = useTranslation();

  const tabs = React.useMemo(
    () => [
      {
        name: "Details",
        component: <ServiceProviderRentalDetails />,
        resource: enums.Resource.SERVICE_PROVIDER_RENTALS,
      },
      {
        name: "Tours",
        component: (
          <ServiceProviderRentalTours
            serviceProviderRental={serviceProviderRental}
          />
        ),
        resource: enums.Resource.SERVICE_PROVIDER_RENTALS,
      },
    ],
    [serviceProviderRental]
  );

  return (
    <ServiceProviderRentalContext.Provider
      value={{
        serviceProviderRental,
        path,
        isGetRentalLoading,
        setServiceProviderRental,
        getLatestServiceProviderRental,
      }}
    >
      <div>
        <div className={classes.upperContainer}>
          <Breadcrumbs />
          <CheckPermission
            resource={enums.Resource.SERVICE_PROVIDER_RENTALS}
            action={enums.Action.CREATE}
          >
            <Link to={`/admin/${path}/create`}>
              <Button create color="white" />
            </Link>
          </CheckPermission>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card raised>
              <CardBody>
                <Loading
                  loading={isGetRentalLoading}
                  style={{ height: "70vh" }}
                >
                  {serviceProviderRental ? (
                    <React.Fragment>
                      <div className={classes.pageHeader}>
                        <div className={classes.infoContainer}>
                          <div className={classes.infoWithIcon}>
                            <AssignmentIcon className={classes.personIcon} />
                            <div className={classes.headerInfoContainer}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {`${serviceProviderRental?.serviceProvider?.nameEn} -`}{" "}
                                {`${serviceProviderRental?.truckType?.details.nameEn} -`}{" "}
                                &nbsp;
                                <div
                                  style={{
                                    color:
                                      RentalDealStatusColors[
                                        serviceProviderRental?.status
                                      ],
                                  }}
                                >
                                  {
                                    translations[i18n.language]
                                      .rentalDealStatus[
                                      serviceProviderRental?.status
                                    ]
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={classes.buttonsContainer}>
                            <RentalDealStatusButton
                              rentalDeal={serviceProviderRental}
                              targetStatus={enums.RentalDealStatus.ACTIVE}
                              updateRentalDeal={updateRentalDeal}
                            />
                            <RentalDealStatusButton
                              rentalDeal={serviceProviderRental}
                              targetStatus={enums.RentalDealStatus.CANCELLED}
                              updateRentalDeal={updateRentalDeal}
                            />
                            <RentalDealStatusButton
                              rentalDeal={serviceProviderRental}
                              targetStatus={enums.RentalDealStatus.TERMINATED}
                              updateRentalDeal={updateRentalDeal}
                            />
                          </div>
                        </div>
                      </div>
                      <Tabs
                        buttonsContainerClasses={classes.tabsContainer}
                        tabs={tabs}
                        value={currentTab}
                        onChange={handleTabChange}
                      />
                    </React.Fragment>
                  ) : (
                    <div>No available data</div>
                  )}
                </Loading>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </ServiceProviderRentalContext.Provider>
  );
}
