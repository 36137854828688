import { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";
import CancelTourModalValidation from "./CancelTourModal.validation";

export default function useCancelTourModal({ currentTour, onClose }) {
  const { getTour, getTours, getOrganizations } = useContext(
    TourOperationsContext
  );
  const [sendTourRequest, isTourRequestLoading] = useHttpClient(true);
  const resolver = useYupValidationResolver(CancelTourModalValidation());
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, setValue, getValues, watch, reset } = form;

  const onSubmit = (values) => async () => {
    try {
      if (!currentTour) {
        toast.error("no tour is selected");
        return;
      }

      await sendTourRequest(
        api.tours.cancelTour(currentTour.id, {
          cancellationReason: values.cancellationReason,
          ...(values.customCancellationReason
            ? { customCancellationReason: values.customCancellationReason }
            : {}),
        })
      );
      getTours();
      getTour();
      onClose();
      toast.success("Tour Cancelled Successfully");
    } catch (error) {
      if (error?.response?.data?.error === "Conflict") {
        onClose();
        await getOrganizations();
        await getTours();
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    form,
    reset,
    submitHandler,
    isTourRequestLoading,
    cancellationReason: watch("cancellationReason"),
  };
}
