import { createContext } from "react";

export const TourContext = createContext({
  cities: [],
  districts: [],
  locations: [],
  tourPickupLocation: null,
  selectedTourType: null,
  selectedTruckType: null,
  selectedDistanceLimit: null,
  selectedDurationLimit: null,
  tour: {},
  setLocations: () => {},
  setTourPickupLocation: () => {},
});
