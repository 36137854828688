import translations from "constants/translations";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";

export default function useGenericInput(props) {
  const { type, label, options, onChange, value } = props;
  const [data, setData] = React.useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
  const { i18n } = useTranslation();
  const [sendRequest, isLoading] = useHttpClient();

  const handleData = React.useCallback(
    (choices) => {
      if (type === "select" && options.addAllChoice) {
        setData([{ name: "All", value: null }, ...choices]);
      } else {
        setData(choices);
      }
    },
    [type, options, options?.addAllChoice, setData]
  );

  const compare = React.useCallback(
    (a, b) => {
      const temp1 =
        typeof a[options.sortBy] === "string"
          ? a[options.sortBy].toUpperCase()
          : a[options.sortBy];
      const temp2 =
        typeof b[options.sortBy] === "string"
          ? b[options.sortBy].toUpperCase()
          : b[options.sortBy];
      if (temp1 < temp2) {
        return -1;
      }
      if (temp1 > temp2) {
        return 1;
      }
      return 0;
    },
    [options, options?.sortBy]
  );

  const fetchRemoteData = React.useCallback(async () => {
    let temp = [];
    try {
      const resp = await sendRequest(
        options.getData(undefined, undefined, undefined, options.filters)
      );
      if (options.rawDataConvertor) {
        temp = [...options.rawDataConvertor(resp.data)];
      } else {
        temp = [...resp.data];
      }
      if (options?.sortBy !== undefined) {
        handleData(temp.sort(compare));
      } else {
        handleData(temp);
      }
    } catch (err) {
      toast.error(
        `An error occurred while loading ${label.toLowerCase()} options`
      );
    }
  }, [
    options.getData,
    options.filters,
    options.sortBy,
    options.rawDataConvertor,
    handleData,
    label,
    compare,
  ]);

  const handleAutocompleteChange = React.useCallback(
    (event, newValue) => {
      const addNew =
        newValue && options.multiple
          ? newValue?.[newValue.length - 1]?.addNew
          : newValue?.addNew;
      if (addNew && (!!options.formStructure || !!options.formComponent)) {
        handleCreateModalOpen();
      } else {
        onChange(event, newValue);
      }
    },
    [options, handleCreateModalOpen, onChange]
  );

  const handleCreateModalOpen = React.useCallback(() => {
    setIsCreateModalOpen(true);
  }, [setIsCreateModalOpen]);

  const handleCreateModalClose = React.useCallback(
    (newElement) => {
      setIsCreateModalOpen(false);
      document.getElementsByClassName("MuiFormLabel-root")[0].focus();
      if (newElement?.id) {
        if (options?.getData) fetchRemoteData();
        handleAutocompleteChange(
          undefined,
          options?.multiple
            ? [...(value?.length ? value : []), newElement]
            : newElement
        );
      }
    },
    [setIsCreateModalOpen, fetchRemoteData, handleAutocompleteChange]
  );

  const shouldTooltipBeEnabled = React.useMemo(() => {
    return options?.disabledTooltipText && options?.disabled;
  }, [options]);

  React.useEffect(() => {
    if (
      !data.length ||
      (options?.data && JSON.stringify(data) !== JSON.stringify(options?.data))
    ) {
      let temp = [];
      if (options?.enum) {
        const translationObj = translations[i18n.language][options?.enum];
        const data = Object.keys(translationObj).map((key) => ({
          ...(type === "autocomplete" ? { id: key } : { value: key }),
          name: translationObj[key],
        }));
        handleData(data);
      } else if (options?.data) {
        temp = [...options.data];
        if (options?.sortBy !== undefined) {
          handleData(temp.sort(compare));
        } else {
          handleData(temp);
        }
      }
      // else if (options?.getData) {
      //   fetchRemoteData();
      // }
    }
  }, [options, handleData, compare, fetchRemoteData, i18n.language]);

  React.useEffect(() => {
    if (options?.getData) {
      fetchRemoteData();
    }
  }, [options.filters]);

  return {
    data,
    isLoading,
    isCreateModalOpen,
    shouldTooltipBeEnabled,
    fetchRemoteData,
    handleCreateModalClose,
    handleAutocompleteChange,
  };
}
