import React, { useContext } from "react";
import styles from "./Cards.Styles";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import enums from "enums/index";
import { toast } from "react-toastify";
import CachedIcon from "@material-ui/icons/Cached";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

const useStyles = makeStyles(styles);

function TruckCard({ setAssignModal, currentTour }) {
  const { resource } = useContext(TourOperationsContext);
  const truck = currentTour?.truck;
  const classes = useStyles();
  return (
    <Card raised className={classes.assignCard}>
      <CardBody className={classes.assignCardBody}>
        <div className={classes.headerContainer}>
          <h3 className={classes.header}>Truck</h3>
        </div>
        <GridContainer className={classes.infoContainer}>
          <GridItem>
            <InfoWithLabel label="Type" info={truck?.type.details.name} />
          </GridItem>
          <GridItem>
            <InfoWithLabel label="License" info={truck?.licenseId} />
          </GridItem>

          <GridItem>
            <InfoWithLabel label="Color" info={truck?.color} />
          </GridItem>
          <GridItem>
            <InfoWithLabel
              label="License Expiry Date"
              info={truck?.licenseExpirationDate.split("T")[0]}
            />
          </GridItem>
        </GridContainer>

        {![enums.TourStatus.CANCELLED, enums.TourStatus.COMPLETED].includes(
          currentTour?.status
        ) && (
          <div className={classes.buttonsContainer}>
            <Button
              onClick={() => {
                const noServiceProvider =
                  currentTour?.serviceProviderId === undefined ||
                  currentTour?.serviceProviderId === null;

                if (noServiceProvider) {
                  toast.warning("Please select service provider first");
                  return;
                }
                setAssignModal(enums.DispatchModals.TRUCK);
              }}
              color="primary"
              className={classes.assignButton}
            >
              {/* TODO: check styles for change icon */}
              <CachedIcon className={classes.changeIcon} /> Assign Truck
            </Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default TruckCard;
