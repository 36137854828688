import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import styles from "./CancelTourModal.styles";
import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import useCancelTourModal from "./CancelTourModal.hook";
import Button from "components/CustomButtons/Button.js";
import enums from "enums/index";
import { formatEnums } from "utils/index";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function CancelTourModal(props) {
  const { isOpen, onClose, currentTour } = props;
  const {
    form,
    reset,
    submitHandler,
    isTourRequestLoading,
    cancellationReason,
  } = useCancelTourModal({
    onClose,
    currentTour,
  });
  const classes = useStyles();
  return (
    <CustomModal
      open={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <Card style={{ margin: 0 }}>
        <CardBody>
          <div className={classes.formHeader}>
            <React.Fragment>Cancel Tour {currentTour?.id || ""}</React.Fragment>
          </div>
          <form
            style={{ padding: 20, paddingBottom: 0 }}
            onSubmit={submitHandler}
            autoComplete="off"
          >
            <FormField
              type="select"
              name="cancellationReason"
              label="Cancellation Reason"
              options={{
                data: Object.entries(enums.CancellationReason)
                  .filter(([key, value]) =>
                    !currentTour?.serviceProviderId
                      ? ![
                          enums.CancellationReason.LATE_DRIVER,
                          enums.CancellationReason.SERVICE_PROVIDER_CANCELLED,
                          enums.CancellationReason
                            .UNABLE_TO_REACH_SERVICE_PROVIDER_OR_DRIVER,
                          enums.CancellationReason.TRUCK_MALFUNCTION,
                        ].includes(value)
                      : ![enums.CancellationReason.UNABLE_TO_MATCH].includes(
                          value
                        )
                  )
                  .map(([key, value]) => ({ value, name: formatEnums(key) })),
              }}
              form={form}
            />
            <FormField
              type="text"
              form={form}
              name="customCancellationReason"
              label="Other Reason"
              getShowCondition={() =>
                cancellationReason === enums.CancellationReason.OTHER
              }
              dependencyList={[cancellationReason]}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                type="submit"
                loading={isTourRequestLoading}
                style={{ margin: "20px 0" }}
                loadFromRight
              >
                Cancel Tour
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </CustomModal>
  );
}
