import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import useDiscountRow from "./DiscountRow.hook";
import React from "react";
import styles from "./DiscountRow.styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import InvoiceModal from "../../../InvoiceModal/InvoiceModal";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import { formatEnums } from "utils/index";

const useStyles = makeStyles(styles);

export default function DiscountRow({ form, index, data, onRemove }) {
  const { invoices, handleAddNew, rowDisabled } = useDiscountRow(
    form,
    index,
    data
  );

  const classes = useStyles();

  return (
    <tr style={{ width: "100%" }}>
      <td className={classes.rowCell}>
        <FormField
          name={`discounts.${index}.type`}
          form={form}
          type="select"
          options={{
            disabled: rowDisabled,
            enum: "discountType",
            isTableInput: true,
          }}
        />
      </td>
      <td className={classes.rowCell}>
        <FormField
          name={`discounts.${index}.invoice`}
          form={form}
          type="autocomplete"
          options={{
            disabled: rowDisabled,
            getOptionLabel: (option) =>
              option?.name ||
              [
                option?.name,
                option?.referenceNumber,
                formatEnums(option.type),
                option?.status,
                option?.expectedBillDate?.split("T")[0],
              ]
                .filter((item) => !!item)
                .join(" - "),
            data: invoices,
            blurOnSelect: false,
            isTableInput: true,
            addNew: true,
            formComponent: <InvoiceModal handleAddNew={handleAddNew} />,
          }}
        />
      </td>
      <td className={classes.rowCell}>
        <FormField
          name={`discounts.${index}.price`}
          form={form}
          type="number"
          options={{
            disabled: rowDisabled,
            blurOnSelect: false,
            isTableInput: true,
          }}
        />
      </td>
      <td className={classes.rowCell}>
        <FormField
          name={`discounts.${index}.notes`}
          form={form}
          type="text"
          options={{
            disabled: rowDisabled,
            blurOnSelect: false,
            isTableInput: true,
          }}
        />
      </td>
      <td className={classes.rowCell}>
        <FormField
          name={`discounts.${index}.addedBy.name`}
          form={form}
          type="text"
          options={{
            disabled: true,
            blurOnSelect: false,
            isTableInput: true,
          }}
        />
      </td>

      <td
        className={classes.rowCell}
        style={{
          borderWidth: `0 0px 1px 0`,
        }}
      >
        <span style={{ display: "flex", justifyContent: "center" }}>
          {!rowDisabled && (
            <ConfirmationButton
              onConfirm={() => onRemove(index)}
              confirmMessage={`Are you sure you want to remove discount number ${
                index + 1
              }`}
              successHeaderText="Discount Removed"
              successMessage="Discount removed successfully"
              headerMessage="Remove Discount"
              confirmButtonText="Confirm"
              confirmButtonColor="success"
              buttonProps={{
                textColor: "textColor",
                className: classes.button,
              }}
            >
              <Tooltip
                title={"Remove"}
                placement="bottom"
                classes={{ tooltip: classes.tooltip }}
                arrow
              >
                <DeleteForeverIcon style={{ cursor: "pointer" }} />
              </Tooltip>
            </ConfirmationButton>
          )}
        </span>
      </td>
    </tr>
  );
}
