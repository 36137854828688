import { makeStyles } from "@material-ui/core/styles";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import translations from "constants/translations/index";
import PropTypes from "prop-types";
import { getI18n } from "react-i18next";
import { useTask } from "./Task.hook";
import styles from "./Task.styles";
import classNames from "classnames";
import React from "react";
import LocationsForm from "views/Zoning/Locations/LocationsForm";
import LocationsInput from "views/Common/LocationsInput/LocationsInput";

// ------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

function Task({ disabled, ...props }) {
  const { data, onRemove, index, tourIndex } = props;

  const {
    deal,
    cities,
    formCities,
    districts,
    locations,
    form,
    tourId,
    isTypeSelectDisabled,
    isLocationDisabled,
    locationDisabledMessage,
    taskType,
    tourType,
    truckTypeId,
    kmLimit,
    daysHoursLimit,
    daysLimit,
    tourPickupLocationId,
    getLocationTaskTypes,
    handleAddNew,
    handleCityChange,
    handleDistrictChange,
  } = useTask({ index, tourIndex, data });

  const classes = useStyles();

  const typeInfoRootClasses = classNames({
    [classes.taskTypeRoot]: true,
    [classes.hidden]: !isTypeSelectDisabled,
  });

  const typeSelectContainerClasses = classNames({
    [classes.typeSelectContainer]: true,
    [classes.hidden]: isTypeSelectDisabled,
  });

  const disabledTooltipText =
    "Please select date, deal, tour type, truck type and task type first.";

  const locationsFilters = {
    dealId: deal?.id,
    taskType,
    tourType,
    truckTypeId,
    kmLimit,
    daysHoursLimit,
    daysLimit,
    pickupLocationId: tourPickupLocationId,
  };

  return (
    <div className={classes.root}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={10}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <div className={classes.inputContainer}>
                <div className={classes.index}>{`${index + 1}. `}</div>
                <InfoWithLabel
                  label="Task Type"
                  info={translations[getI18n().language].taskType[taskType]}
                  classes={{
                    root: typeInfoRootClasses,
                    label: classes.taskTypeLabel,
                  }}
                />
                <div className={typeSelectContainerClasses}>
                  <FormField
                    type="select"
                    name={`tours.${tourIndex}.tasks.${index}.type`}
                    label="Task Type"
                    form={form}
                    options={{
                      data: Object.keys(
                        translations[getI18n().language].taskType
                      ).map((key) => ({
                        value: key,
                        name: translations[getI18n().language].taskType[key],
                      })),
                      disabled,
                    }}
                  />
                </div>
              </div>
            </GridItem>
            <GridItem
              alignItems="center"
              justify="center"
              xs={12}
              sm={12}
              md={3}
            >
              <LocationsInput
                type="autocomplete"
                name={`tours.${tourIndex}.tasks.${index}.location`}
                label="Location"
                form={form}
                locations={locations}
                cities={cities}
                districts={districts}
                tourId={tourId}
                taskType={taskType}
                filters={locationsFilters}
                formComponent={
                  <LocationsForm
                    getLocationTaskTypes={getLocationTaskTypes}
                    deal={deal}
                    cities={formCities}
                    districts={districts}
                    handleAddNew={handleAddNew}
                    create
                  />
                }
                disabled={isLocationDisabled || disabled}
                disabledTooltipText={locationDisabledMessage}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          {!isTypeSelectDisabled && !disabled && (
            <ConfirmationButton
              onConfirm={() => onRemove(index)}
              confirmMessage="Are you sure you want to remove this task?"
              successHeaderText="Task Removed"
              successMessage="Task removed successfully"
              headerMessage="Remove Task"
              confirmButtonText="Remove"
              buttonProps={{
                className: classes.removeButton,
                deleteButton: true,
                justIcon: true,
              }}
              rootClass={classes.removeButtonContainer}
            />
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}

Task.propTypes = {
  onRemove: PropTypes.func,
  tourIndex: PropTypes.number,
  index: PropTypes.number,
  data: PropTypes.object,
  disabled: PropTypes.bool,
};

export default Task;
