import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import enums from "enums/index";
import React from "react";
import { useTranslation } from "react-i18next";
import AddQuotationGroupModal from "./components/AddQuotationGroupModal/AddQuotationGroupModal";
import ImportQuotationsModal from "./components/ImportQuotationsModal/ImportQuotationsModal";
import QuotationModal from "./components/QuotationModal/QuotationModal";
import useQuotations from "./Quotations.hook";
import styles from "./Quotations.styles";
import { toast } from "react-toastify";
import { getDurationString } from "utils/index";
import { QuotationsContext } from "./Quotations.context";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function Quotations({ type: quotationType }) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    deal,
    isAssignModalOpen,
    isGetDealLoading,
    editedQuotation,
    quotations,
    isAddMultipleQuotationsModalOpen,
    isImportQuotationsModalOpen,
    listData,
    currentPage,
    itemsPerPage,
    searchQuery,
    highlightedText,
    isLoading,
    onSearchChange,
    downloadExcel,
    handleSubmit,
    handleChange,
    onAssignModalClose,
    setIsAddMultipleQuotationsModalOpen,
    setIsImportQuotationsModalOpen,
    handleAssignQuotation,
    handleRemoveQuotation,
    onRemoveModalClose,
    reloadQuotations,
  } = useQuotations({ quotationType });

  const columns = React.useMemo(
    () => [
      {
        title: "Truck Type",
        dataIndex: ["truckType", "details", "name"],
        canBeHighlighted: true,
      },
      ...(quotationType === enums.QuotationType.RENTAL
        ? [
            {
              title: "Pickup Zones",
              dataIndex: ["rental", "pickupZones"],
              attribute: "name",
            },
            {
              title: "Km limit",
              dataIndex: ["rental", "details", "kmLimit"],
            },
            {
              title: "Days hours limit",
              dataIndex: ["rental", "details", "daysHoursLimit"],
            },
            {
              title: "Days Limit",
              dataIndex: ["rental", "details", "daysLimit"],
            },
          ]
        : [
            {
              title: "Pickup Zone",
              dataIndex: ["perTrip", "pickupZone", "name"],
              canBeHighlighted: true,
            },
            {
              title: "Drop-off Zone",
              dataIndex: ["perTrip", "dropOffZone", "name"],
              canBeHighlighted: true,
            },
            {
              title: "Distance (km)",
              dataIndex: ["perTrip", "zoneMapping", "distanceKm"],
            },
            {
              title: "Trip duration",
              dataIndex: ["perTrip", "zoneMapping", "tripDuration"],
              getData: (duration) => {
                return getDurationString(duration);
              },
            },
          ]),
      {
        title: "Truck Price",
        dataIndex: ["details", "truckPrice"],
      },
      {
        title: "Target Truck Cost",
        dataIndex: ["details", "targetTruckCost"],
      },
      {
        title: "Generated Truck Price",
        dataIndex: ["details", "generatedTruckPrice"],
      },
      {
        title: "Current System Truck Price",
        dataIndex: ["details", "currentSystemTruckPrice"],
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              <CheckPermission
                resource={enums.Resource.DEAL_QUOTATIONS}
                action={enums.Action.UPDATE}
              >
                <Button
                  justIcon
                  edit
                  color="white"
                  textColor="success"
                  onClick={() => handleAssignQuotation(record)}
                />
              </CheckPermission>
              <CheckPermission
                resource={enums.Resource.DEAL_QUOTATIONS}
                action={enums.Action.DELETE}
              >
                <ConfirmationButton
                  name="this quotation"
                  entityName="Quotation"
                  onConfirm={() => handleRemoveQuotation(record.id)}
                  onSuccess={() => onRemoveModalClose(record.id)}
                  buttonProps={{
                    justIcon: true,
                  }}
                />
              </CheckPermission>
            </div>
          );
        },
      },
    ],
    [deal]
  );

  return (
    <QuotationsContext.Provider value={{ reloadQuotations }}>
      <div className={classes.root}>
        <div className={classes.section}>
          {isAssignModalOpen && (
            <QuotationModal
              isOpen={isAssignModalOpen}
              onClose={onAssignModalClose}
              create={!editedQuotation}
              edit={!!editedQuotation}
              quotation={editedQuotation}
              type={quotationType}
            />
          )}
          {isAddMultipleQuotationsModalOpen && (
            <AddQuotationGroupModal
              isOpen={isAddMultipleQuotationsModalOpen}
              onClose={() => setIsAddMultipleQuotationsModalOpen(false)}
              type={quotationType}
            />
          )}

          {isImportQuotationsModalOpen ? (
            <ImportQuotationsModal
              isOpen={isImportQuotationsModalOpen}
              onClose={() => {
                setIsImportQuotationsModalOpen(false);
                reloadQuotations(true);
              }}
              type={quotationType}
            />
          ) : null}
          <React.Fragment>
            <div className={classes.headerContainer}>
              <h3 className={classes.header}>
                {quotationType === enums.QuotationType.RENTAL
                  ? "Rentals"
                  : "Per Trip Routes"}
              </h3>
              <div className={classes.buttonsContainer}>
                <CheckPermission
                  resource={enums.Resource.DEAL_QUOTATIONS}
                  action={enums.Action.CREATE}
                >
                  <Button
                    onClick={() => setIsAddMultipleQuotationsModalOpen(true)}
                    className={classes.addContactButton}
                    color="success"
                  >
                    <AddIcon className={classes.addIcon} />
                    Add Multiple Quotations
                  </Button>
                </CheckPermission>
                <CheckPermission
                  resource={enums.Resource.DEAL_QUOTATIONS}
                  action={enums.Action.CREATE}
                >
                  <Button
                    onClick={() => handleAssignQuotation()}
                    className={classes.addContactButton}
                    color="success"
                  >
                    <AddIcon className={classes.addIcon} />
                    Add Quotation
                  </Button>
                </CheckPermission>
                <Button
                  onClick={() => setIsImportQuotationsModalOpen(true)}
                  className={classes.exportButton}
                  color="success"
                >
                  Import
                </Button>
                <Button
                  onClick={downloadExcel}
                  className={classes.exportButton}
                  color="success"
                >
                  Export
                </Button>
              </div>
            </div>
            <div className={classes.tableContainer}>
              <CustomTable
                data={listData.rows}
                onChange={handleChange}
                pagination={{
                  total: listData.count,
                  current: currentPage,
                  pageSize: itemsPerPage,
                  position: ["topRight"],
                  pageSizeOptions: [10, 20, 50],
                }}
                loading={isGetDealLoading || isLoading}
                highlightedText={highlightedText}
                searchProps={{
                  onChange: onSearchChange,
                  value: searchQuery,
                  placeholder: "Search",
                }}
                handleSubmit={handleSubmit}
                columns={columns}
                addIndexColumn
              />
            </div>
          </React.Fragment>
        </div>
      </div>
    </QuotationsContext.Provider>
  );
}
