import enums from "enums/index";
import * as yup from "yup";
import moment from "moment";
import { extractTimeFromISO } from "utils/index";
import { toast } from "react-toastify";

//-------------------------------------------------------------------------------------

export default (startDate) => {
  const tourStartTime = extractTimeFromISO(startDate);

  return yup.object({
    status: yup.mixed(),
    location: yup
      .mixed()
      .required("Task Location Required")
      .test("task-location-required", "Task Location Required", function (
        value
      ) {
        if (!value) {
          toast.error("Task location is required.");
          return false;
        }
        return true;
      }),
    startTime: yup.mixed().required("Task Start Time Required"),
    endTime: yup
      .mixed()
      .test(
        "end-time-required",
        "End time is required to complete tasks.",
        function (value) {
          const { status } = this.parent;
          return status === enums.TaskStatus.ONGOING ? !!value : true;
        }
      ),
    serviceProvider: yup
      .mixed()
      .required(
        "Task Service Provider Required, try submitting before changing task status"
      ),
    driver: yup
      .mixed()
      .required(
        "Task Driver Required, try submitting before changing task status"
      ),
    truck: yup
      .mixed()
      .required(
        "Task Truck Required, try submitting before changing task status"
      ),
    courier: yup.mixed(),
  });
};
