import DataList from "components/DataList/DataList";
import enums from "enums";
import React from "react";
import api from "services/api";
import LocationsForm from "views/Zoning/Locations/LocationsForm";
import LocationsFormPage from "views/Zoning/Locations/LocationsFormPage";

// appear in breadcrumbs and page headers
const listPageName = "Locations";
const createPageName = "Create Location";
const editPageName = "Edit Location";

// appears on alerts; example: Entity created successfully
const entityName = "Location";

const resource = enums.Resource.ZONING;

const layout = "/admin";

const path = "locations";

const dataResource = api.locations;

const columns = [
  {
    title: "Location Number",
    dataIndex: "locationNo",
  },
  {
    title: "Name (English)",
    dataIndex: "nameEn",
  },
  {
    title: "Name (Arabic)",
    dataIndex: "nameAr",
  },
  {
    title: "Type",
    dataIndex: ["locationType", "name"],
  },
  {
    title: "District",
    dataIndex: ["district", "nameEn"],
  },
  {
    title: "Created By",
    dataIndex: ["createdBy", "name"],
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "location_on",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => <LocationsFormPage path={path} create />,
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => <LocationsFormPage path={path} edit />,
      layout: layout,
      randomKey: true,
      action: "update",
      resource,
    },
  ],
};
