import Button from "components/CustomButtons/Button.js";
import FormField from "components/Forms/FormField/FormField";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import PropTypes from "prop-types";
import React from "react";
import api from "services/api";
import { toNearest } from "utils/index";
import usePerTripQuotationForm from "./PerTripQuotationForm.hook";
import { toHoursAndMinutes } from "utils/index";

//-------------------------------------------------------------------------------------

export default function PerTripQuotationForm(props) {
  const { quotation: quotationProp, onClose } = props;

  const {
    isLoading,
    submitHandler,
    form,
    tripDuration,
    tripDistance,
  } = usePerTripQuotationForm({
    quotationProp,
    onClose,
  });

  return (
    <form
      style={{ padding: 20, paddingBottom: 0 }}
      onSubmit={submitHandler}
      autoComplete="off"
    >
      <FormField
        md={12}
        name="truckType"
        type="autocomplete"
        label="Truck Type"
        form={form}
        options={{
          getData: api.truckTypes.getAll,
          getOptionLabel: (option) => option.details.name,
        }}
      />
      <FormField
        md={12}
        name="perTrip.pickupZone"
        type="autocomplete"
        label="Pickup Zone"
        form={form}
        options={{
          getData: api.zones.getAll,
          getOptionLabel: (option) => option.name,
        }}
      />
      <FormField
        md={12}
        name="perTrip.dropOffZone"
        type="autocomplete"
        label="Drop-off Zone"
        form={form}
        options={{
          getData: api.zones.getAll,
          getOptionLabel: (option) => option.name,
        }}
      />
      {quotationProp && (
        <React.Fragment>
          <InfoWithLabel
            label="Trip duration"
            info={toHoursAndMinutes(tripDuration)}
            formField
          />
          <InfoWithLabel
            label="Trip distance"
            info={
              tripDistance !== undefined && tripDistance !== null
                ? `${tripDistance} km`
                : "-"
            }
            formField
          />
          <InfoWithLabel
            label="Target Deviation (%)"
            info={toNearest(
              ((quotationProp?.details?.truckPrice -
                quotationProp?.details?.generatedTruckPrice) /
                quotationProp?.details?.generatedTruckPrice) *
                100
            )}
            formField
          />
          <InfoWithLabel
            label="Margin (%)"
            info={toNearest(
              ((quotationProp?.details?.truckPrice -
                quotationProp?.details?.targetTruckCost) /
                quotationProp?.details?.truckPrice) *
                100
            )}
            formField
          />
          <FormField
            md={12}
            name="details.truckPrice"
            type="number"
            label="Truck Price"
            form={form}
          />
          <InfoWithLabel
            label="System Generated Truck Price"
            info={quotationProp?.details?.generatedTruckPrice}
            formField
          />
          <FormField
            md={12}
            name="details.targetTruckCost"
            type="number"
            label="Target Truck Cost"
            form={form}
          />
          <InfoWithLabel
            label="System Generated Truck Target Cost"
            info={quotationProp?.details?.generatedTruckTargetCost}
            formField
          />
        </React.Fragment>
      )}
      <FormField
        md={12}
        name="details.notes"
        type="text"
        label="Notes"
        form={form}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          color="primary"
          type="submit"
          loading={isLoading}
          style={{ margin: "20px 0" }}
          edit={!!quotationProp}
          create={!quotationProp}
          loadFromRight
        />
      </div>
    </form>
  );
}

PerTripQuotationForm.defaultProps = {
  onClose: () => {},
};

PerTripQuotationForm.propTypes = {
  quotation: PropTypes.object,
  onClose: PropTypes.func,
};
