import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import React from "react";
import ServiceModal from "./components/AssignServiceModal/ServiceModal";
import useServices from "./Services.hook";
import styles from "./Services.styles";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CheckPermission from "components/CheckPermission/CheckPermission";
import enums from "enums/index";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function Services() {
  const classes = useStyles();

  const {
    deal,
    isAssignModalOpen,
    isGetDealLoading,
    editedService,
    services,
    onAssignModalClose,
    handleAssignService,
    handleRemoveService,
    onRemoveModalClose,
  } = useServices();

  const columns = React.useMemo(
    () => [
      {
        title: "Service",
        dataIndex: ["service", "name"],
      },
      {
        title: "Price",
        dataIndex: ["price"],
      },
      {
        title: "Cost",
        dataIndex: ["cost"],
      },
      {
        title: "System Generated Price",
        dataIndex: ["service", "price"],
      },
      {
        title: "System Generated Cost",
        dataIndex: ["service", "cost"],
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              <CheckPermission
                resource={enums.Resource.DEAL_SERVICES}
                action={enums.Action.UPDATE}
              >
                <Button
                  justIcon
                  edit
                  color="white"
                  textColor="success"
                  onClick={() => handleAssignService(record)}
                />
              </CheckPermission>
              <CheckPermission
                resource={enums.Resource.DEAL_SERVICES}
                action={enums.Action.DELETE}
              >
                <ConfirmationButton
                  name="this service"
                  entityName="Service"
                  onConfirm={() => handleRemoveService(record)}
                  onSuccess={() => onRemoveModalClose(record)}
                  buttonProps={{
                    justIcon: true,
                  }}
                />
              </CheckPermission>
            </div>
          );
        },
      },
    ],
    [deal]
  );

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        {isAssignModalOpen && (
          <ServiceModal
            isOpen={isAssignModalOpen}
            onClose={onAssignModalClose}
            create={!editedService}
            edit={!!editedService}
            service={editedService}
          />
        )}
        <React.Fragment>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>Services</h3>
            <CheckPermission
              resource={enums.Resource.DEAL_SERVICES}
              action={enums.Action.CREATE}
            >
              <Button
                onClick={() => handleAssignService()}
                className={classes.addContactButton}
                color="success"
              >
                <AddIcon className={classes.addIcon} />
                Add Service
              </Button>
            </CheckPermission>
          </div>
          <div className={classes.tableContainer}>
            <CustomTable
              columns={columns}
              data={services}
              loading={isGetDealLoading}
              addIndexColumn
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}
