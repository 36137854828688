import * as yup from "yup";

//-------------------------------------------------------------------------------------

export default yup.object({
  penalties: yup.array().of(
    yup.object({
      type: yup.mixed().required("Discount Type Is Required"),
      bill: yup.object({
        serviceProvider: yup.mixed().required("Service provider Is Required"),
      }),
      price: yup
        .mixed()
        .required("Price Is Required")
        .test("price error", "Price cannot be 0 or less", (price) => price > 0),
    })
  ),
  discounts: yup.array().of(
    yup.object({
      type: yup.mixed().required("Discount Type Is Required"),
      invoice: yup.mixed().required("Invoice Is Required"),
      price: yup
        .mixed()
        .required("Price Is Required")
        .test("price error", "Price cannot be 0 or less", (price) => price > 0),
    })
  ),
});
