import { useContext } from "react";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { MonthlyOrderDetailsContext } from "views/Plans/MonthlyOrderDetails/MonthlyOrderDetails.context";

export default function useCancelCourier() {
  const { order, setOrder } = useContext(MonthlyOrderDetailsContext);
  const [sendOrderRequest, isOrderRequestLoading] = useHttpClient(true);

  const onConfirm = async () => {
    try {
      const orderUpdate = {
        id: order.id,
        monthly: {
          courier: null,
          courierServiceProvider: null,
        },
      };
      await sendOrderRequest(api.orders.patch(orderUpdate));
      setOrder({
        ...order,
        monthly: {
          ...order.monthly,
          courier: null,
          courierId: null,
          courierServiceProvider: null,
          courierServiceProviderId: null,
        },
      });
      toast.success("Courier removed successfully.");
    } catch (error) {
      console.log(error);
    }
  };

  return {
    onConfirm,
    isOrderRequestLoading,
  };
}
