import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const styles = (theme) => ({
  containerWithSearch: { width: "100%", margin: 0 },
  list: { listStyleType: "none" },
  horizontalList: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 0,
    marginLeft: 18,
    marginBottom: 20,
  },
  tabsList: {
    border: "1px solid #D3D3D3",
    borderRadius: 5,
  },
  listWithSearch: {
    paddingInlineStart: 0,
    width: "100%",
  },
  listItem: {
    margin: "4px 0px 8px 0px",
    font: "inherit",
    color: "#007FFF",
    display: "block",
    "-webkit-text-decoration": "none",
    textDecoration: "none",
    fontWeight: 700,
    // display: "-webkit-inline-box",
    // display: "-webkit-inline-flex",
    // display: "-ms-inline-flexbox",
    // display: "inline-flex",
    "-webkit-align-items": "center",
    "-webkit-box-align": "center",
    "-ms-flex-align": "center",
    color: "#0072E5",
    fontSize: "0.8125rem",
    padding: "0px 8px 0px 10px",
    borderLeft: "1px solid transparent",
    boxSizing: "border-box",
    fontWeight: 500,
    color: "#1C2025",
    "&:hover": {
      borderLeftColor: "#B0B8C4",
      color: "#6B7A90",
    },
  },
  tabListItem: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    margin: 0,
    height: 35,
    "&:hover": {
      borderLeftColor: "transparent",
      color: "#6B7A90",
      backgroundColor: "#D3D3D3",
      borderRadius: 5,
    },
  },
  tabListItemSelected: {
    backgroundColor: primaryColor[0],
    color: "#FFF",
    height: 35,
    borderRadius: 5,
    "&:hover": {
      backgroundColor: primaryColor[0],
      color: "#FFF",
    },
  },
  listItemSelected: {
    margin: "4px 0px 8px",
    borderLeft: "1px solid rgb(153, 204, 243)",
    color: "rgb(0, 114, 229)",
    "&:hover": {
      borderLeftColor: "rgb(0, 114, 229)",
      color: "rgb(0, 114, 229)",
    },
  },
  title: { margin: 0 },
  subTitle: { margin: 0, fontSize: "0.75rem" },
  itemWithSubtitle: { height: "auto" },
});

export default styles;
