import enums from "enums/index";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

// ------------------------------------------------------------------------------------------

export default function useDiscountsTable(form, currentTour) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tourDisabled = useMemo(
    () =>
      currentTour?.status === enums.TourStatus.CANCELLED ||
      currentTour?.status === enums.TourStatus.COMPLETED,
    [currentTour]
  );

  const { control, watch } = form;
  const { fields: discounts, remove } = useFieldArray({
    control,
    name: "discounts",
  });

  const onRemove = useCallback(
    (index) => {
      remove(index);
    },
    [remove]
  );

  const firstServiceCost = watch("discounts"); //check how to get 1st discount

  const onModalOpen = () => {
    setIsModalOpen(true);
  };
  const onModalClose = () => {
    setIsModalOpen(false);
  };

  return {
    tourDisabled,
    onRemove,
    discounts,
    onModalOpen,
    onModalClose,
    isModalOpen,
  };
}
