const styles = (theme) => ({
  root: {
    padding: "40px 20px",
    paddingBottom: 0,
  },
  upperContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  section: {
    width: "100%",
    marginBottom: 80,
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    marginBottom: 40,
  },
  header: {
    fontSize: 26,
    fontWeight: 500,
    margin: 0,
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
  },
});

export default styles;
