import { useCallback, useEffect, useMemo, useState } from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import enums from "enums/index";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export default function useTourOperationsView(resource) {
  const [organizations, setOrganizations] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [currentOrg, setCurrentOrg] = useState();
  const [currentOrgIndex, setCurrentOrgIndex] = useState(null);
  const [toursFilters, setToursFilters] = useState({
    serviceProviderIsNull: false,
    driverOrTruckIsNull: false,
    pickupDate: undefined,
    toursCompleted: false,
    toursReviewed: false,
  });

  const [invoices, setInvoices] = useState();

  const [currentTourIndex, setCurrentTourIndex] = useState(null);
  const [routingSuccessful, setRoutingSuccessful] = useState(false);
  const [currentTour, setCurrentTour] = useState(null);
  const currentTourId = useMemo(
    () => currentOrg?.tours?.[currentTourIndex]?.id,
    [currentTourIndex, currentOrg]
  );
  const [sendOrganizationsRequest, isOrganizationsLoading] = useHttpClient(
    true
  );
  const [sendToursRequest, isToursLoading] = useHttpClient(true);
  const [sendTourRequest, isTourLoading] = useHttpClient(true);
  const [serviceProviderSendRequest, isServiceProvidersLoading] = useHttpClient(
    true
  );

  const dataResource = useMemo(() => api.organizations, []);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const orgId = params.get("organizationId");
  const tourId = params.get("tourId");

  const currentToursCount = useMemo(() => currentOrg?.tours?.length, [
    currentOrg,
  ]);
  const currentOrganizationsCount = useMemo(() => organizations?.length, [
    organizations,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const response = await serviceProviderSendRequest(
          api.serviceProviders.getAll()
        );
        setServiceProviders(response.data);
      } catch {}
    })();
  }, []);

  useEffect(() => {
    if (orgId && organizations?.length) {
      const organizationIndex = organizations?.findIndex(
        (org) => org.id == orgId
      );
      if (organizationIndex >= 0) setCurrentOrgIndex(organizationIndex);
      else {
        toast.error("Organization does not exist");
      }
    }
  }, [orgId, currentOrganizationsCount]);
  useEffect(() => {
    if (!routingSuccessful && tourId && currentOrg) {
      const tourIndex = currentOrg?.tours?.findIndex(
        (tour) => tour.id == tourId
      );
      if (tourIndex >= 0) {
        setCurrentTourIndex(tourIndex);
        setRoutingSuccessful(true);
      } else {
        if (!routingSuccessful) {
          toast.error("Tour does not exist");
        }
      }
    }
  }, [tourId, currentToursCount]);

  // filters, move to it's own hook //////////////////////////////////////////////////////////////////////////////////////////////
  const {
    serviceProviderIsNull,
    driverOrTruckIsNull,
    pickupDate,
    toursCompleted,
    toursReviewed,
  } = useMemo(() => toursFilters, [toursFilters]);

  const toggleServiceProviderIsNull = () => {
    setToursFilters({
      ...toursFilters,
      serviceProviderIsNull: !serviceProviderIsNull,
    });
    setCurrentOrgIndex(0);
    setCurrentTourIndex(0);
  };

  const toggleDriverOrTruckIsNull = () => {
    setToursFilters({
      ...toursFilters,
      driverOrTruckIsNull: !driverOrTruckIsNull,
    });
    setCurrentOrgIndex(0);
    setCurrentTourIndex(0);
  };

  const setPickupDate = (value) => {
    setToursFilters({ ...toursFilters, pickupDate: value });
    setCurrentOrgIndex(0);
    setCurrentTourIndex(0);
  };

  const toggleToursCompleted = () => {
    setToursFilters({ ...toursFilters, toursCompleted: !toursCompleted });
    setCurrentOrgIndex(0);
    setCurrentTourIndex(0);
  };

  const toggleToursReviewed = () => {
    setToursFilters({
      ...toursFilters,
      toursReviewed: !toursReviewed,
    });
    setCurrentOrgIndex(0);
    setCurrentTourIndex(0);
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const getOrganizations = useCallback(async () => {
    try {
      let filters = { ...(orgId && { organizationId: orgId }) };
      if (resource === enums.Resource.FIN_OPS) {
        filters = {
          ...filters,
          toursReviewed: toursFilters.toursReviewed,
          tourReviewPending: !toursFilters.toursReviewed,
        };
      } else {
        filters = { ...filters, ...toursFilters };
        delete filters[toursReviewed];
      }
      const response = await sendOrganizationsRequest(
        dataResource.getDispatchingOrganizations(resource, {
          filters: filters,
        })
      );
      setOrganizations(response.data);
      if (currentOrgIndex === null || !response.data?.[currentOrgIndex])
        setCurrentOrgIndex(0);
    } catch (err) {}
  }, [toursFilters, orgId, currentOrgIndex, resource]);

  const getTours = useCallback(async () => {
    try {
      if (organizations?.[currentOrgIndex]) {
        let filters = { ...(tourId && { tourId }) };
        if (resource === enums.Resource.FIN_OPS) {
          filters = {
            ...filters,
            toursReviewed: toursFilters.toursReviewed,
            tourReviewPending: !toursFilters.toursReviewed,
          };
        } else {
          filters = { ...filters, ...toursFilters };
          delete filters[toursReviewed];
        }
        const response = await sendToursRequest(
          dataResource.getDispatchingDetails(
            organizations[currentOrgIndex].id,
            resource,
            {
              filters: filters,
            }
          )
        );

        setCurrentOrg(response.data);
        const newInvoices = response.data.invoices?.filter(
          (invoice) => invoice.status !== enums.InvoiceStatus.INVOICED
        );
        setInvoices(newInvoices);
        if (
          (!tourId || routingSuccessful) &&
          (currentTourIndex === null ||
            !response.data?.tours?.[currentTourIndex] ||
            currentOrg?.id !== response.data?.id)
        ) {
          setCurrentTourIndex(0);
        }
      }
    } catch (err) {}
  }, [
    currentOrg,
    currentTourIndex,
    currentOrgIndex,
    organizations,
    toursFilters,
    tourId,
    routingSuccessful,
    resource,
  ]);

  const getTour = useCallback(async () => {
    try {
      if (currentTourId) {
        const response = await sendTourRequest(api.tours.getOne(currentTourId));
        if (response?.data) setCurrentTour(response?.data);
        else setCurrentTour(null);
      } else {
        setCurrentTour(null);
      }
    } catch (err) {
      console.log("err", err);
    }
  }, [currentOrg?.id, currentTourId]);

  useEffect(() => {
    getOrganizations();
  }, [toursFilters, orgId, resource]);

  useEffect(() => {
    if (!organizations || organizations.length === 0) return;
    getTours();
  }, [
    currentOrgIndex,
    organizations,
    toursFilters,
    tourId,
    routingSuccessful,
    resource,
  ]);

  useEffect(() => {
    getTour();
  }, [getTour]);

  return {
    toggleServiceProviderIsNull,
    toggleDriverOrTruckIsNull,
    setPickupDate,
    toggleToursCompleted,
    toggleToursReviewed,
    setCurrentTourIndex,
    setCurrentTour,
    getTour,
    setCurrentOrgIndex,
    getOrganizations,
    getTours,
    setInvoices,
    serviceProviderIsNull,
    driverOrTruckIsNull,
    pickupDate,
    toursCompleted,
    toursReviewed,
    organizations,
    serviceProviders,
    currentOrgIndex,
    currentTourIndex,
    currentTour,
    currentOrg,
    invoices,
    isOrganizationsLoading,
    isToursLoading,
    isTourLoading: isTourLoading,
  };
}
