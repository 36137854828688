import translations from "constants/translations/index";
import enums from "enums";
import i18n from "i18n";
import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Name",
        validation: yup.string().required("Required"),
      },
      {
        name: "price",
        type: "number",
        label: "Price",
        validation: yup.string().required("Required"),
      },
      {
        name: "cost",
        type: "number",
        label: "Cost",
        validation: yup.string().required("Required"),
      },
    ],
  },
];
