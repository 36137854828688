import enums from "enums/index";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { useForm } from "react-hook-form";
import RentalDealStatusButtonValidation from "./RentalDealStatusButton.validation";

export default function useRentalDealStatusButton({
  rentalDeal,
  targetStatus,
  updateRentalDeal,
}) {
  const statusList = Object.values(enums.RentalDealStatus);
  const statusIndex =
    statusList.findIndex((value) => value === targetStatus) - 1;
  const resolver = useYupValidationResolver(
    RentalDealStatusButtonValidation(targetStatus, startDate)
  );

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      startDate: null,
      endDate: null,
    },
  });

  const [showModal, setShowModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { handleSubmit, getValues, setValue, reset } = form;

  const currentStatus = useMemo(() => rentalDeal?.status, [rentalDeal]);
  const startDate = useMemo(() => rentalDeal?.startDate, [rentalDeal]);
  const rentalDealId = useMemo(() => rentalDeal?.id, [rentalDeal]);

  useEffect(() => {
    if (showModal) {
      if (rentalDeal?.startDate)
        setValue(
          "startDate",
          targetStatus === enums.RentalDealStatus.ACTIVE
            ? new Date().toISOString().split("T")[0]
            : rentalDeal.startDate
        );
      if (rentalDeal?.endDate) setValue("endDate", rentalDeal.endDate);
    } else {
      reset();
    }
  }, [rentalDealId, showModal]);

  const onSubmit = (values) => async () => {
    setConfirmLoading(true);
    try {
      let rentalDealUpdate = { status: targetStatus };
      if (targetStatus === enums.RentalDealStatus.ACTIVE) {
        rentalDealUpdate = {
          ...rentalDealUpdate,
          activationDate: values.startDate,
        };
      }

      if (targetStatus === enums.RentalDealStatus.CANCELLED) {
        rentalDealUpdate = {
          ...rentalDealUpdate,
          cancellationReason: values.reason,
          cancellationDate: new Date().toISOString().split("T")[0],
        };
      }

      if (targetStatus === enums.RentalDealStatus.TERMINATED) {
        rentalDealUpdate = {
          ...rentalDealUpdate,
          cancellationReason: values.reason,
          cancellationDate: values.endDate,
        };
      }
      await updateRentalDeal(rentalDealUpdate);

      setConfirmLoading(false);

      toast.success("Status changed successfully");
      closeModal();
    } catch (error) {
      setConfirmLoading(false);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const openModal = () => setShowModal(true);

  const closeModal = () => {
    setShowModal(false);
    reset();
  };

  const shouldButtonShow = useMemo(
    () =>
      ([enums.RentalDealStatus.PLANNED, enums.RentalDealStatus.LATE].includes(
        currentStatus
      ) &&
        [
          enums.RentalDealStatus.ACTIVE,
          enums.RentalDealStatus.CANCELLED,
        ].includes(targetStatus)) ||
      (currentStatus === enums.RentalDealStatus.ACTIVE &&
        targetStatus === enums.RentalDealStatus.TERMINATED),
    [currentStatus, targetStatus]
  );

  return {
    form,
    showModal,
    statusList,
    statusIndex,
    confirmLoading,
    shouldButtonShow,
    submitHandler,
    openModal,
    closeModal,
  };
}
