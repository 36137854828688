import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./customRadioStyle";

const useStyles = makeStyles(styles);

export default function CustomRadio(props) {
  const classes = useStyles();

  const {
    value,
    data,
    onChange,
    labelText,
    rootStyle,
    disabled,
    classes: propClasses,
    ...rest
  } = props;

  const valueMemo = React.useMemo(() => {
    return value === "true" ? true : value === "false" ? false : value;
  }, [value]);

  const rootClasses = classNames({
    [classes.root]: true,
    [propClasses?.root]: propClasses?.root,
  });

  const labelClasses = classNames({
    [classes.label]: true,
    [propClasses?.label]: propClasses?.label,
  });

  const radioContainerClasses = classNames({
    [classes.radioContainer]: true,
    [propClasses?.radioButtonsContainer]: propClasses?.radioButtonsContainer,
  });

  const radioButtonLabelClasses = classNames({
    [classes.label]: true,
    [propClasses?.radioLabel]: propClasses?.radioLabel,
  });

  const radioButtons = data?.map((radioButton, index) => {
    const radioButtonValue =
      typeof radioButton === "object" ? radioButton.value : radioButton;

    return (
      <FormControlLabel
        key={index}
        control={
          <Radio
            checked={valueMemo === radioButtonValue}
            onClick={() => onChange(radioButtonValue)}
            value={radioButtonValue}
            icon={<FiberManualRecord className={classes.radioUnchecked} />}
            checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
            classes={{
              checked: classes.radio,
              root: classes.radioRoot,
            }}
          />
        }
        classes={{
          label: radioButtonLabelClasses,
          root: classes.labelRoot,
        }}
        label={typeof radioButton === "object" ? radioButton.name : radioButton}
      />
    );
  });

  return (
    <div style={rootStyle} className={rootClasses}>
      {labelText && <div className={labelClasses}>{labelText}</div>}
      <div className={radioContainerClasses}>{radioButtons}</div>
    </div>
  );
}

CustomRadio.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  labelText: PropTypes.string,
  rootStyle: PropTypes.object,
  disabled: PropTypes.bool,
};
