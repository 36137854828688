import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import React from "react";
import styles from "./MonthlyOrderDetails.styles";
import { useMonthlyOrderDetails } from "./MonthlyOrderDetails.hook";
import MonthlyStatusButton from "./components/MonthlyStatusButton/MonthlyStatusButton";
import enums from "enums/index";
import OrderWorkers from "./components/OrderWorker/OrderWorkers";
import { MonthlyOrderDetailsContext } from "./MonthlyOrderDetails.context";
import OrderInfo from "./components/OrderInfo/OrderInfo";
import MonthlyOrderDetails from "./MonthlyOrderDetails";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function MonthlyOrderFulfillment() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.upperContainer}>
        <Breadcrumbs />
      </div>
      <Card raised>
        <CardBody>
          <MonthlyOrderDetails />
        </CardBody>
      </Card>
    </div>
  );
}
