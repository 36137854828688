import * as yup from "yup";

//-------------------------------------------------------------------------------------

export default () => {
  return yup.object({
    name: yup.string().required("Required"),
    email: yup.string().email("Invalid email address").required("Required"),
    phone: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .matches(/^((\+2|002)?01\d{9})$/, "Invalid phone number")
      .nullable(),
    title: yup.string().nullable(),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .nullable(),
  });
};
