import { grayColor } from "assets/jss/material-dashboard-pro-react.js";
import { blackColor } from "assets/jss/material-dashboard-pro-react";

const styles = (theme) => ({
  header: {
    margin: "2px",
    display: "flex",
    justifyContent: "center",
  },
  headerContainer: {
    borderColor: grayColor[4],
    color: grayColor[3],
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: 60,
  },
  rowHead: {
    borderColor: "#f0f0f0",
    background: "#fafafa",
    padding: "2px",
    width: "10%",
    borderWidth: `0 1px 1px 0`,
    borderStyle: "solid",
  },

  buttonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    height: 60,
  },
  buttonGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 60,
  },
});

export default styles;
