import React, { useEffect, useState, useCallback } from "react";
import qs from "qs";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";

//-------------------------------------------------------------------------------------

export default function useQuotations({ quotationType }) {
  const history = useHistory();

  const [isAssignModalOpen, setIsAssignModalOpen] = React.useState(false);
  const [
    isAddMultipleQuotationsModalOpen,
    setIsAddMultipleQuotationsModalOpen,
  ] = React.useState(false);
  const [
    isImportQuotationsModalOpen,
    setIsImportQuotationsModalOpen,
  ] = React.useState(false);
  const [listData, setListData] = React.useState({ count: 0, rows: [] });
  const [editedQuotation, setEditedQuotation] = React.useState(null);
  const [sendRequest, isLoading] = useHttpClient();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [highlightedText, setHighlightedText] = React.useState("");
  const [tab, setTab] = useState();

  const { deal, isGetDealLoading, setDeal } = React.useContext(DealContext);

  const quotations = React.useMemo(() => {
    return deal?.quotations?.filter(
      (quotation) => quotation.details.type === quotationType
    );
  }, [deal, quotationType]);

  const getData = React.useCallback(
    async (
      page = currentPage,
      rowsPerPage = rowsPerPage,
      search = searchQuery
    ) => {
      page = Math.max(page, 1);
      try {
        const response = await sendRequest(
          api.deals.getDealQuotations(deal.id, page, rowsPerPage, search, {
            dealId: deal.id,
            type: quotationType,
          })
        );
        setListData({ count: response.count, rows: response.data });
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [deal, sendRequest, setListData]
  );

  useEffect(() => {
    let currentTab = tab;
    if (currentTab === undefined) {
      currentTab = new URLSearchParams(window.location.search).get("tab");
      setTab(currentTab);
    }

    (async () => {
      if (!deal?.id) return;
      const rowsPerPage = 10;
      setItemsPerPage(rowsPerPage);

      let { page, search, filters } = qs.parse(
        window.location.search?.split("?")[1]
      );

      setSearchQuery(search || "");
      setHighlightedText(search || "");

      if (page) {
        setCurrentPage(parseInt(page));
        await getData(page, rowsPerPage, search);
      } else {
        page = 1;
        const query = qs.stringify({ tab: currentTab, page });
        history.push({
          pathname: window.location.pathname,
          search: `?${query}`,
        });
        await getData(page, rowsPerPage, search);
      }
    })();
  }, [deal, quotationType]);

  useEffect(() => {
    if (listData.count > 0 && !listData.rows.length) {
      const lastPage = Math.ceil(listData.count / itemsPerPage);
      const query = qs.stringify({
        tab,
        search: searchQuery,
        page: lastPage,
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
      (async () => await getData(lastPage, itemsPerPage, searchQuery))();
    }
  }, [listData]);

  const onSearchChange = (updatedSearch) => {
    setSearchQuery(updatedSearch);
  };

  const handleAssignQuotation = React.useCallback(
    (quotation) => {
      setEditedQuotation(quotation ? { ...quotation } : null);
      setIsAssignModalOpen(true);
    },
    [setIsAssignModalOpen, setEditedQuotation]
  );
  const onAssignModalClose = React.useCallback(() => {
    setIsAssignModalOpen(false);
    setEditedQuotation(null);
  }, [setIsAssignModalOpen, setEditedQuotation]);

  const handleRemoveQuotation = React.useCallback(
    async (removedQuotationId) => {
      const response = await api.quotations.delete(removedQuotationId);
    },
    [deal]
  );

  const onRemoveModalClose = React.useCallback(
    (removedQuotationId) => {
      const newQuotations = deal.quotations.filter(
        (quotation) => quotation.id !== removedQuotationId
      );
      setDeal({ ...deal, quotations: newQuotations });
    },
    [deal]
  );

  const handleChange = async (pagination) => {
    // setPageSettingsProp(`${match.path}.rowsPerPage`, pagination.pageSize);
    setCurrentPage(pagination.current);
    setItemsPerPage(pagination.pageSize);
    const query = qs.stringify({
      tab,
      search: searchQuery,
      page: pagination.current,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    await getData(pagination.current, pagination.pageSize, searchQuery);
  };

  const reloadQuotations = async (afterImport = false) => {
    const newPage = afterImport
      ? currentPage
      : Math.ceil(listData.count / itemsPerPage) +
        (1 - Math.ceil((listData.count % itemsPerPage) / itemsPerPage));

    setCurrentPage(newPage);

    const query = qs.stringify({
      tab,
      search: searchQuery,
      page: newPage,
    });

    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });

    return getData(newPage, itemsPerPage, "");
  };

  const downloadExcel = async () => {
    const response = await api.deals.exportDealQuotations(
      deal.id,
      searchQuery,
      { dealId: deal.id, type: quotationType }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    const contentDisposition = response.headers["content-disposition"];
    const filename = contentDisposition.split("filename=")[1];
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  };

  const handleSubmit = async (search) => {
    setHighlightedText(search);
    const query = qs.stringify({ tab, search, page: 1 });
    history.push({ pathname: window.location.pathname, search: `?${query}` });
    await getData(1, itemsPerPage, search);
    setCurrentPage(1);
  };

  return {
    deal,
    isAssignModalOpen,
    isGetDealLoading,
    editedQuotation,
    quotations,
    isAddMultipleQuotationsModalOpen,
    isImportQuotationsModalOpen,
    listData,
    currentPage,
    itemsPerPage,
    searchQuery,
    highlightedText,
    isLoading,
    downloadExcel,
    onSearchChange,
    handleSubmit,
    handleChange,
    onAssignModalClose,
    setIsAddMultipleQuotationsModalOpen,
    setIsImportQuotationsModalOpen,
    handleAssignQuotation,
    handleRemoveQuotation,
    onRemoveModalClose,
    reloadQuotations,
  };
}
