import { extractTimeFromISO } from "utils/index";

const convertTimeToMinutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

const convertMinutesToHoursAndMinutes = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return [hours, mins];
};

export const diffDays = (d1, d2) => {
  if (!d1 || !d2) return 0;
  let date1 = new Date(d1);
  let date2 = new Date(d2);
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);

  const timeDiff = Math.abs(date1.getTime() - date2.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return diffDays;
};

export const getCurrentTaskDate = (referenceDate, index, task) => {
  let previousDate = new Date(referenceDate);
  if (!task.startTime) return {};
  if (Date.parse(task.startTime)) {
    task.startTime = extractTimeFromISO(task.startTime);
  }
  if (Date.parse(task.endTime)) {
    task.endTime = extractTimeFromISO(task.endTime);
  }

  const startMinutes = convertTimeToMinutes(task.startTime);
  const endMinutes = task.endTime
    ? convertTimeToMinutes(task.endTime)
    : startMinutes + 1;

  if (
    index > 0 &&
    startMinutes <
      convertTimeToMinutes(
        previousDate.toLocaleTimeString("en-GB").substring(0, 5)
      )
  ) {
    previousDate.setDate(previousDate.getDate() + 1);
  }

  const startDate = new Date(
    previousDate.getFullYear(),
    previousDate.getMonth(),
    previousDate.getDate(),
    ...convertMinutesToHoursAndMinutes(startMinutes)
  );

  let endDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
    ...convertMinutesToHoursAndMinutes(endMinutes)
  );

  if (endMinutes < startMinutes) {
    endDate.setDate(endDate.getDate() + 1);
  }

  return { startDate, endDate };
};
