import React from "react";
import styles from "./CancelCourier.styles";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import useCancelCourier from "./CancelCourier.hook";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function CancelCourier() {
  const { onConfirm, isOrderRequestLoading } = useCancelCourier();
  const classes = useStyles();
  return (
    <ConfirmationButton
      confirmButtonText="Remove Courier"
      headerMessage="Remove Courier"
      confirmMessage="Are you sure you want to remove current courier?"
      successHeaderText="Removed"
      successMessage="Courier Removed Successfully."
      onConfirm={onConfirm}
      loading={isOrderRequestLoading}
      confirmButtonColor="primary"
      buttonProps={{
        className: classes.assignButton,
        color: "primary",
        textColor: "white",
      }}
    >
      <CancelIcon /> Cancel Courier
    </ConfirmationButton>
  );
}
