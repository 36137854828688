import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import useServiceProviderDealInfo from "./ServiceProviderDealInfo.hook";
import React from "react";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import { formatEnums } from "utils/index";
import enums from "enums/index";

export default function ServiceProviderDealInfo() {
  const {
    serviceProvider,
    serviceProviderDailyDurationLimit,
    serviceProviderKmLimit,
    baseTruckCost,
    extraHourCost,
    extraKmCost,
  } = useServiceProviderDealInfo();

  return (
    <Card raised>
      <CardBody>
        <CollapseContainer
          titleComponent={<h3 style={{ margin: 0 }}>Service Provider Deal:</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem label="Name" info={serviceProvider?.name} />

            <InfoWithLabelGridItem
              label="Phone"
              info={serviceProvider?.phonePrimary}
            />
            <InfoWithLabelGridItem
              label="National ID"
              info={serviceProvider?.nationalId}
            />
            <InfoWithLabelGridItem
              label="Duration Limit"
              info={serviceProviderDailyDurationLimit}
            />
            <InfoWithLabelGridItem
              label="Km Limit"
              info={serviceProviderKmLimit}
            />
            <InfoWithLabelGridItem
              label="Base Truck Cost"
              info={baseTruckCost}
            />
            <InfoWithLabelGridItem label="Extra Km Cost" info={extraKmCost} />
            <InfoWithLabelGridItem
              label="Extra Hour Cost"
              info={extraHourCost}
            />
          </GridContainer>
        </CollapseContainer>
      </CardBody>
    </Card>
  );
}
