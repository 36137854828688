const styles = (theme) => ({
  root: {
    padding: "40px 20px",
    paddingBottom: 0,
  },
  section: {
    width: "100%",
    marginBottom: 80,
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    marginBottom: -30,
  },
  header: {
    margin: 0,
  },
  changeOrganizationButton: {
    padding: "5px 15px",
    zIndex: 20,
    display: "flex",
    alignItems: "center",
  },
  changeIcon: {
    zIndex: "4",
    color: "white",
    transition: "all 0.3s",
    width: "20px !important",
    height: "20px !important",
    marginBottom: 2,
    marginRight: 5,
  },
});

export default styles;
