import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import OrganizationForm from "./OrganizationForm.validation";

//------------------------------------------------------------------

export const useOrganizationsForm = ({
  edit,
  create,
  typeId,
  refresh,
  handleAddNew,
  handleModalClose,
}) => {
  const [data, setData] = React.useState(null);

  const [locations, setLocations] = React.useState([]);

  const [sendRequest, isLoading] = useHttpClient();

  const [sendLocationsRequest] = useHttpClient();

  const [sendGetRequest, isGetLoading] = useHttpClient();

  const dataResource = React.useMemo(() => api.organizations, []);

  const resolver = useYupValidationResolver(OrganizationForm());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isDirty },
  } = form;

  const formLocations = watch("locations");

  React.useEffect(() => {
    (async () => {
      try {
        let response;
        if (edit) {
          const id = window.location.pathname.split("/").reverse()[1];
          response = await sendLocationsRequest(
            api.organizations.getAvailableLocations(id)
          );
          setLocations(response);
          return;
        }
        response = (await sendLocationsRequest(api.locations.getAll())).data;
        setLocations(response);
      } catch (err) {}
    })();
  }, []);

  React.useEffect(() => {
    if (edit) {
      const id = window.location.pathname.split("/").reverse()[1];
      if (id) {
        (async () => {
          try {
            const response = await sendGetRequest(dataResource.getOne(id));
            setData(response);
            if (response) {
              Object.keys(response).forEach((key) =>
                setValue(key, response[key])
              );
            }
          } catch (err) {
            toast.error("An error has occurred");
          }
        })();
      } else {
        toast.error("An error has occurred");
      }
    }
  }, [edit]);

  const createHandler = (values) => async () => {
    try {
      const response = await sendRequest(dataResource.create(values));
      toast.success(`Organization has been created successfully`);
      if (handleModalClose) handleModalClose();
      if (refresh) refresh();
      if (handleAddNew) handleAddNew(response);
    } catch (err) {
      toast.error(err.response?.data?.message || "An error has occurred");
    }
  };

  const editHandler = (values) => async () => {
    try {
      const response = await sendRequest(dataResource.patch(values));
      toast.success(`Organization has been updated successfully`);
      if (handleModalClose) handleModalClose();
      if (refresh) refresh();
    } catch (err) {
      toast.error(err.response?.data?.message || "An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let values = getValues();

    values.categoryId = values?.category?.id;
    delete values?.category;

    values.contactIds = values?.contacts?.map((contact) => contact.id);
    delete values?.contacts;

    values.industryIds = values?.industries?.map((industry) => industry.id);
    delete values?.industries;

    values.locationIds = values?.locations?.map((location) => location.id);
    delete values?.locations;

    handleSubmit(
      create ? createHandler(values) : editHandler(values),
      onError
    )();
  };

  const handleAddNewLocation = (location) => {
    setLocations([...locations, location]);
    setValue("locations", [...formLocations, location]);
  };

  return {
    isLoading,
    isGetLoading,
    form,
    data,
    watch,
    typeId,
    locations,
    handleAddNewLocation,
    onSubmit,
  };
};
