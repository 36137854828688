import enums from "enums/index";
import { useContext, useMemo } from "react";
import { extractTimeFromISO, formatEnums } from "utils/index";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";
import moment from "moment";

export default function useTourInfo() {
  const { currentOrg, currentTour, isTourLoading } = useContext(
    TourOperationsContext
  );
  const completedTasksCount = useMemo(
    () =>
      currentTour?.tasks.reduce(
        (pre, task) =>
          task.status === enums.TaskStatus.COMPLETED ? pre + 1 : pre,
        0
      ),
    [currentTour]
  );

  const totalDuration = useMemo(() => {
    const validTasks =
      currentTour?.tasks?.filter((task) => task.startTime || task.endTime) ||
      [];
    const firstTime = validTasks[0]?.startTime || validTasks[0]?.endTime;
    const lastTime =
      validTasks[validTasks.length - 1]?.endTime ||
      validTasks[validTasks.length - 1]?.startTime;
    let totalDuration = moment.duration();
    if (firstTime && lastTime) {
      const startTime = moment(firstTime);
      const endTime = moment(lastTime);
      const duration = moment.duration(endTime.diff(startTime));
      totalDuration.add(duration);
    }

    return `${totalDuration.days()} days, ${totalDuration.hours()} hours and ${totalDuration.minutes()} minutes.`;
  }, [currentTour]);

  const { firstStartTime, lastEndTime } = useMemo(() => {
    const validTasks = currentTour?.tasks?.filter((task) => task.endTime);

    const firstStartTime = currentTour?.tasks[0]?.startTime
      ? new Date(currentTour?.tasks[0].startTime).toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
      : null;
    const lastEndTime = validTasks?.length
      ? new Date(
          validTasks?.[validTasks.length - 1].endTime
        ).toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
      : null;
    return { firstStartTime, lastEndTime };
  }, [currentTour]);

  const tasksCount = useMemo(
    () =>
      currentTour?.tasks.reduce(
        (pre, task) =>
          task.status === enums.TaskStatus.CANCELLED ? pre - 1 : pre,
        currentTour.tasks.length
      ),
    [currentTour]
  );

  const { tourTime, tourDate } = useMemo(() => {
    if (!currentTour)
      return {
        tourTime: undefined,
        tourDate: undefined,
      };
    const date = new Date(currentTour.pickupTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const time = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return {
      tourTime: time,
      tourDate: `${year}-${month}-${day}`,
    };
  }, [currentTour]);

  const dealName = useMemo(
    () =>
      currentOrg?.deals?.find((deal) => deal.id === currentTour?.order?.dealId)
        ?.details?.name,
    [currentOrg, currentTour]
  );

  const cancellationReason = useMemo(() => {
    let reason;
    if (currentTour?.cancellationReason)
      reason = formatEnums(currentTour?.cancellationReason);

    if (
      currentTour?.cancellationReason === enums.CancellationReason.OTHER &&
      currentTour?.customCancellationReason
    )
      reason += `: ${currentTour?.customCancellationReason}`;

    return reason;
  }, [currentTour]);
  const serviceProviderCancellationReason = useMemo(
    () => currentTour?.serviceProviderCancellationReason,
    [currentTour]
  );

  const dealImage = useMemo(() => currentTour?.serviceProviderContractUrl, [
    currentTour,
  ]);
  return {
    currentOrg,
    currentTour,
    dealName,
    completedTasksCount,
    tasksCount,
    tourTime,
    tourDate,
    cancellationReason,
    serviceProviderCancellationReason,
    isTourLoading,
    dealImage,
    totalDuration,
    firstStartTime,
    lastEndTime,
  };
}
