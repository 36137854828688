import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import styles from "./OrderWorkers.styles";
import React, { useContext, useState } from "react";
import ServiceProviderCard from "./components/Cards/ServiceProviderCard";
import DriverCard from "./components/Cards/DriverCard";
import TruckCard from "./components/Cards/TruckCard";
import CourierCard from "./components/Cards/CourierCard";
import { MonthlyOrderDetailsContext } from "../../MonthlyOrderDetails.context";
import enums from "enums/index";
import AssignServiceProviderModal from "./components/AssignModals/AssignServiceProvidierModal/AssignServiceProviderModal";
import AssignTruckModal from "./components/AssignModals/AssignTruckModal/AssignTruckModal";
import AssignDriverModal from "./components/AssignModals/AssignDriverModal/AssignDriverModal";
import AssignCourierModal from "./components/AssignModals/AssignCourierModal/AssignCourierModal";
//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);
export default function OrderWorkers({ viewOnly }) {
  const { order } = useContext(MonthlyOrderDetailsContext);
  const [assignModal, setAssignModal] = useState(enums.DispatchModals.NONE);

  const onCloseAssignModal = (onCloseAction) => {
    if (onCloseAction) onCloseAction();
    setAssignModal(enums.DispatchModals.NONE);
  };

  const classes = useStyles();
  return (
    <GridContainer style={{ padding: "15px 12px" }}>
      <GridItem className={classes.assignCardGridItem} xs={12} md={3} sm={6}>
        <ServiceProviderCard
          viewOnly={viewOnly}
          setAssignModal={setAssignModal}
          order={order}
        />
      </GridItem>
      <GridItem className={classes.assignCardGridItem} xs={12} md={3} sm={6}>
        <DriverCard
          viewOnly={viewOnly}
          setAssignModal={setAssignModal}
          order={order}
        />
      </GridItem>
      <GridItem className={classes.assignCardGridItem} xs={12} md={3} sm={6}>
        <TruckCard
          viewOnly={viewOnly}
          setAssignModal={setAssignModal}
          order={order}
        />
      </GridItem>
      <GridItem className={classes.assignCardGridItem} xs={12} md={3} sm={6}>
        <CourierCard
          viewOnly={viewOnly}
          setAssignModal={setAssignModal}
          order={order}
        />
      </GridItem>
      {!viewOnly && (
        <>
          <AssignServiceProviderModal
            isOpen={assignModal === enums.DispatchModals.SUPPLIER}
            onClose={onCloseAssignModal}
            order={order}
          />
          <AssignTruckModal
            isOpen={assignModal === enums.DispatchModals.TRUCK}
            onClose={onCloseAssignModal}
            order={order}
          />
          <AssignDriverModal
            isOpen={assignModal === enums.DispatchModals.DRIVER}
            onClose={onCloseAssignModal}
            order={order}
          />
          <AssignCourierModal
            isOpen={assignModal === enums.DispatchModals.COURIER}
            onClose={onCloseAssignModal}
            order={order}
          />
        </>
      )}
    </GridContainer>
  );
}
