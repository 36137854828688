import { makeStyles } from "@material-ui/core/styles";
import CachedIcon from "@material-ui/icons/Cached";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import enums from "enums/index";
import styles from "./Cards.Styles";
import CancelCourier from "./Components/CancelCourier/CancelCourier";
import React from "react";

const useStyles = makeStyles(styles);

function CourierCard({ viewOnly, setAssignModal, order }) {
  const courier = order?.monthly?.courier;
  const classes = useStyles();
  return (
    <Card raised className={classes.assignCard}>
      <CardBody className={classes.assignCardBody}>
        <div className={classes.headerContainer}>
          <h3 className={classes.header}>Courier</h3>
        </div>
        <GridContainer className={classes.infoContainer}>
          <GridItem>
            <InfoWithLabel label="Name" info={courier?.nameEn} />
          </GridItem>
          <GridItem>
            <InfoWithLabel label="Phone" info={courier?.phonePrimary} />
          </GridItem>
          <GridItem>
            <InfoWithLabel label="National ID" info={courier?.nationalId} />
          </GridItem>
          <GridItem>
            <InfoWithLabel
              label="Service Provider"
              info={order?.monthly?.courierServiceProvider?.name}
            />
          </GridItem>
        </GridContainer>
        {order?.status !== enums.OrderStatus.CANCELLED &&
          !viewOnly &&
          (courier ? (
            <CancelCourier />
          ) : (
            <Button
              onClick={() => {
                setAssignModal(enums.DispatchModals.COURIER);
              }}
              color="primary"
              className={classes.assignButton}
            >
              <CachedIcon className={classes.changeIcon} /> Assign Courier
            </Button>
          ))}
      </CardBody>
    </Card>
  );
}

export default CourierCard;
