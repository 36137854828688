import enums from "enums/index";
import { useContext, useMemo } from "react";
import { MonthlyOrderDetailsContext } from "../../MonthlyOrderDetails.context";
import { formatEnums } from "utils/index";

export default function useServiceProviderDealInfo() {
  const { order } = useContext(MonthlyOrderDetailsContext);

  const serviceProvider = useMemo(() => order?.monthly?.serviceProvider, [
    order,
  ]);
  const serviceProviderDailyDurationLimit = useMemo(
    () =>
      order?.monthly?.serviceProvider &&
      order?.monthly?.serviceProviderDailyDurationLimit,
    [order]
  );
  const serviceProviderKmLimit = useMemo(
    () =>
      order?.monthly?.serviceProvider &&
      order?.monthly?.serviceProviderMonthlyDistanceLimit,
    [order]
  );
  const baseTruckCost = useMemo(
    () => order?.monthly?.serviceProvider && order?.monthly?.baseTruckCost,
    [order]
  );
  const extraHourCost = useMemo(
    () => order?.monthly?.serviceProvider && order?.monthly?.extraHourCost,
    [order]
  );
  const extraKmCost = useMemo(
    () => order?.monthly?.serviceProvider && order?.monthly?.extraKmCost,
    [order]
  );
  return {
    serviceProvider,
    serviceProviderDailyDurationLimit,
    serviceProviderKmLimit,
    baseTruckCost,
    extraHourCost,
    extraKmCost,
  };
}
