import {
  dangerColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const styles = () => ({
  root: {
    borderRadius: 5,
    padding: 10,
    paddingBottom: 0,
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
    marginBottom: 10,
    transition: "all 0.3s ease-in-out",
    "&:focus,&:focus-within": {
      boxShadow: "0px 0px 10px 0px rgba(76,175,79, 0.6)",
    },
  },
  rootError: {
    boxShadow: `0px 0px 10px 0px rgba(${hexToRgb(dangerColor[0])}, .54)`,
  },
  text: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 18,
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
  },
  headerRoot: {
    paddingBottom: 8,
  },
  header: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 17,
    marginTop: 17,
    marginRight: 10,
  },
  tourIndex: {
    fontWeight: 400,
    fontSize: 18,
  },
  headerInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0 10px",
    fontSize: 14,
  },
  info: {
    fontWeight: 500,
  },
  label: {
    fontWeight: 400,
    color: "#748B97",
    marginRight: 10,
  },
  removeButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  removeButton: {
    marginLeft: "auto",
    marginBottom: -55,
    marginRight: 55,
    zIndex: 100,
  },
});

export default styles;
