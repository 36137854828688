import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ServicesTableContext } from "../../ServiceTable.context";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";
import enums from "enums/index";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useTranslation } from "react-i18next";
import translations from "constants/translations";

export default function useServiceRow(index, data) {
  const { form } = useContext(ServicesTableContext);
  const { serviceProviders } = useContext(TourOperationsContext);
  const { resource, currentTour, getTour, invoices, setInvoices } = useContext(
    TourOperationsContext
  );
  const [bills, setBills] = useState();

  const { i18n } = useTranslation();
  const invoiceTypeTranslation = translations[i18n.language]["invoiceType"];
  const serviceTypeTranslation = translations[i18n.language]["serviceType"];

  const [sendRequest, isLoading] = useHttpClient(true);
  const { setValue, watch } = form;

  const invoice = watch(`services.${index}.invoice`);
  const price = watch(`services.${index}.price`);
  const cost = watch(`services.${index}.cost`);
  const type = watch(`services.${index}.type`);
  const serviceProvider = watch(`services.${index}.serviceProvider`);
  const serviceProviderId = serviceProvider?.id;

  useEffect(() => {
    if (serviceProvider?.bills) {
      const newBills = serviceProvider.bills.filter(
        (bill) => bill.status !== enums.BillStatus.BILLED
      );
      setBills(newBills);
    } else {
      setBills([]);
    }
    setValue(`services.${index}.bill`, undefined);
  }, [serviceProviderId]);

  const handleAddNewInvoice = useCallback(
    async (invoice) => {
      setInvoices([...invoices, invoice]);
      setValue(`services.${index}.invoice`, invoice);
    },
    [index]
  );
  const handleAddNewBill = useCallback(
    async (bill) => {
      setBills([...bills, bill]);
      setValue(`services.${index}.bill`, bill);
    },
    [index, bills]
  );

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (key !== "id") setValue(`services.${index}.${key}`, data[key]);
      });
    }
  }, [data]);

  const finOpsOnly = useMemo(() => resource === enums.Resource.FIN_OPS, [
    resource,
  ]);
  const dispatchingOnly = useMemo(
    () => resource === enums.Resource.DISPATCHING,
    [resource]
  );

  const tourDisabled = useMemo(
    () =>
      currentTour?.status === enums.TourStatus.CANCELLED ||
      currentTour?.status === enums.TourStatus.COMPLETED,
    [currentTour]
  );
  // disable if cancelled if required
  const priceDisabled = useMemo(
    () => data?.invoice?.status === enums.InvoiceStatus.INVOICED,
    [data]
  );
  // disable if cancelled if required
  const serviceBilled = useMemo(() => {
    return data?.bill?.status === enums.BillStatus.BILLED;
  }, [data]);
  const costDisabled = useMemo(
    () => serviceBilled || (tourDisabled && dispatchingOnly),
    [serviceBilled, tourDisabled, dispatchingOnly]
  );
  const rowDisabled = useMemo(
    () => priceDisabled || costDisabled,

    [priceDisabled, costDisabled]
  );
  const cancelledDispatchingTour = useMemo(
    () =>
      currentTour?.status === enums.TourStatus.CANCELLED &&
      resource === enums.Resource.DISPATCHING,
    [currentTour, resource]
  );
  const allTasksFinalized = useMemo(
    () =>
      currentTour?.tasks.reduce(
        (pre, task) =>
          [enums.TaskStatus.COMPLETED, enums.TaskStatus.CANCELLED].includes(
            task.status
          ) && pre,
        true
      ),
    [currentTour]
  );
  const canAttachInvoice = useMemo(() => price !== 0 || cost !== 0, [
    price,
    cost,
  ]);
  const isServiceReviewed = useMemo(
    () => data.finOpsStatus === enums.FinOpsStatus.REVIEWED,
    [data]
  );
  const isInvoiceAttached = useMemo(() => !!invoice, [invoice]);
  const serviceName = useMemo(
    () =>
      type === enums.ServiceType.ADDITIONAL
        ? data?.service?.name || "Additional"
        : serviceTypeTranslation[type],
    [type]
  );
  const addedBy = useMemo(() => data?.addedBy?.name || "System", [type]);
  const canEditQuantity = useMemo(() => type === enums.ServiceType.ADDITIONAL, [
    type,
  ]);

  const reviewService = async () => {
    try {
      if (!currentTour?.id) return;
      await sendRequest(
        api.tours.updateTourService(currentTour.id, {
          id: data.id,
          finOpsStatus: enums.FinOpsStatus.REVIEWED,
        })
      );
      await getTour();
    } catch (err) {
      console.log("error", err);
    }
  };
  const revertReviewService = async () => {
    try {
      if (!currentTour?.id) return;
      await sendRequest(
        api.tours.updateTourService(currentTour.id, {
          id: data.id,
          finOpsStatus: enums.FinOpsStatus.PENDING,
        })
      );
      await getTour();
    } catch (err) {
      console.log("error", err);
    }
  };

  return {
    invoiceTypeTranslation,
    serviceName,
    addedBy,
    tourDisabled,
    finOpsOnly,
    isServiceReviewed,
    isInvoiceAttached,
    canAttachInvoice,
    cancelledDispatchingTour,
    rowDisabled,
    resource,
    invoices,
    bills,
    form,
    serviceProviders,
    priceDisabled,
    costDisabled,
    serviceBilled,
    serviceProviderId,
    billDisabled: !serviceProviderId,
    quantityDisabled: !canEditQuantity,
    handleAddNewInvoice,
    handleAddNewBill,
    reviewService,
    revertReviewService,
  };
}
