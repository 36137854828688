import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import Button from "components/CustomButtons/Button.js";
import useAddDiscountModal from "./AddDiscountModal.hook";
import styles from "./AddDiscountModal.styles";
import InvoiceModal from "../../../InvoiceModal/InvoiceModal";
import { useTranslation } from "react-i18next";
import translations from "constants/translations";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AddDiscountModal(props) {
  const { isOpen, onClose, currentTour } = props;

  const {
    form,
    submitHandler,
    invoices,
    handleAddNewInvoice,
  } = useAddDiscountModal({
    currentTour,
    onClose,
  });

  const { i18n } = useTranslation();
  const translationObj = translations[i18n.language]["invoiceType"];

  const classes = useStyles();
  return (
    <CustomModal
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <Card style={{ margin: 0 }}>
        <CardBody>
          <div className={classes.formHeader}>
            <React.Fragment>
              <AddIcon className={classes.icon} />
              Add Discount
            </React.Fragment>
          </div>
          <form
            style={{ padding: 20, paddingBottom: 0 }}
            onSubmit={submitHandler}
            autoComplete="off"
          >
            <FormField
              md={12}
              form={form}
              name="type"
              type="select"
              label="Discount Type"
              options={{
                enum: "discountType",
              }}
            />
            <FormField
              md={12}
              form={form}
              name="invoice"
              type="autocomplete"
              label="Invoice"
              options={{
                getOptionLabel: (option) =>
                  option?.name ||
                  [
                    option?.name,
                    option?.referenceNumber,
                    translationObj[option.type],
                    option?.expectedInvoiceDate?.split("T")[0],
                  ]
                    .filter((item) => !!item)
                    .join(" - "),
                data: invoices,
                blurOnSelect: false,
                addNew: true,
                formComponent: (
                  <InvoiceModal handleAddNew={handleAddNewInvoice} />
                ),
              }}
            />
            <FormField
              md={12}
              form={form}
              name="price"
              type="number"
              label="Discount Price"
            />
            <FormField
              md={12}
              form={form}
              name="notes"
              type="text"
              label="Notes"
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                type="submit"
                // loading={isLoading}
                style={{ margin: "20px 0" }}
                create
                loadFromRight
              >
                Add Discount
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </CustomModal>
  );
}
