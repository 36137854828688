import { makeStyles } from "@material-ui/core/styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import enums from "enums/index";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { getDateString } from "utils/index";
import usePlansListPage from "./PlansListPage.hook";
import styles from "./PlansListPage.styles";
import { getI18n } from "react-i18next";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function PlansListPage(props) {
  const classes = useStyles();

  const { resource } = props;

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
  } = usePlansListPage({
    resource,
    filterInputs,
  });

  const filterInputs = React.useMemo(() => {
    return undefined;
  }, []);

  const columns = React.useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Tax Id",
        dataIndex: "taxId",
      },
      {
        title: "Industries",
        dataIndex: "industries",
        attribute: "name",
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div style={{ display: "flex", width: "fit-content" }}>
              <CheckPermission resource={resource} action={enums.Action.READ}>
                <Link to={`/admin/plans/${record?.id}`}>
                  <Button justIcon next color="white" textColor="primary" />
                </Link>
              </CheckPermission>
            </div>
          );
        },
      },
    ],
    [resource]
  );

  return (
    <div>
      <div className={classes.topContainer} />

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
              inputs: filterInputs,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title="Customers Summary"
            addIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

PlansListPage.propTypes = {
  resource: PropTypes.string,
};
