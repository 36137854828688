import React, { useContext, useEffect } from "react";
import api from "services/api";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";
//------------------------------------------------------------------

export default function useAssignCourierRentalModal({
  currentTour,
  isOpen,
  onClose,
}) {
  const [sendRequest, isLoading] = useHttpClient(true);
  const { getTour, getOrganizations, getTours } = useContext(
    TourOperationsContext
  );

  const [filtersState, setFiltersState] = React.useState({});
  const [searchQuery, setSearchQuery] = React.useState("");
  const [tourToCancel, setTourToCancel] = React.useState();
  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);

  const onFilterChange = (updatedFilters) => {
    setFiltersState(updatedFilters);
  };
  const onSearchChange = (updatedSearch) => {
    setSearchQuery(updatedSearch);
  };

  const toggleCancelModal = (tour) => {
    setTourToCancel(tour);
    setIsCancelModalOpen(!!tour);
  };

  const onCloseModal = () => {
    setFiltersState({});
    setSearchQuery("");
    setRentals({
      count: 0,
      rows: [],
    });
    onClose();
  };

  const [rentals, setRentals] = React.useState({
    count: 0,
    rows: [],
  });
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [highlightedText, setHighlightedText] = React.useState("");

  const onSelect = async (record) => {
    try {
      if (!currentTour) {
        toast.error("no tour is selected");
        return;
      }
      await sendRequest(
        api.orders.assignTourWorker({
          id: currentTour.id,
          courierRentalId: record.id,
        })
      );
      getTour();
      toast.success("Courier Rental Assigned Successfully");
      onCloseModal();
    } catch (error) {
      if (error?.response?.data?.error === "Conflict") {
        await getOrganizations();
        await getTours();
        onCloseModal();
      }
      console.log(error);
    }
  };
  const getData = React.useCallback(
    async (
      page = currentPage,
      rowsPerPage = itemsPerPage,
      search = searchQuery,
      { serviceProvider, courier } = filtersState
    ) => {
      page = Math.max(page, 1);
      try {
        const response = await sendRequest(
          api.courierRentals.getAllForDispatching(currentTour?.pickupTime, {
            page,
            rowsPerPage,
            search,
            filters: {
              ...(serviceProvider && {
                serviceProviderId: serviceProvider.id,
              }),
              ...(courier && { courierId: courier.id }),
              ...(currentTour?.id && { tourId: currentTour.id }),
            },
          })
        );

        setRentals({ count: response.count, rows: response.data });
      } catch (err) {
        console.log(err);
        toast.error("An error has occurred");
      }
    },
    [filtersState, sendRequest, setRentals, currentTour]
  );
  useEffect(() => {
    (async () => {
      if (!isOpen) return;
      const rowsPerPage = 10;
      setItemsPerPage(rowsPerPage);
      await getData(currentPage, rowsPerPage, searchQuery);
    })();
  }, [isOpen]);

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };
  const handleChange = async (pagination) => {
    setCurrentPage(pagination.current);
    setItemsPerPage(pagination.pageSize);
    await getData(pagination.current, pagination.pageSize, searchQuery);
  };

  const handleSubmit = async (search, filters) => {
    setHighlightedText(search);
    await getData(1, itemsPerPage, search, filters);
    setCurrentPage(1);
  };

  return {
    isLoading,
    filtersState,
    searchQuery,
    rentals,
    currentPage,
    itemsPerPage,
    highlightedText,
    tourToCancel,
    isCancelModalOpen,
    onCloseModal,
    onFilterChange,
    onSearchChange,
    onSelect,
    handleSubmit,
    handleChange,
    setTourToCancel,
    toggleCancelModal,
  };
}
