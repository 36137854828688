import AssignmentIcon from "@material-ui/icons/Assignment";
import enums from "enums/index";
import React from "react";
import MonthlyOrderDetails from "views/Plans/MonthlyOrderDetails/MonthlyOrderDetails";
import MonthlyOrderFulfillment from "views/Plans/MonthlyOrderDetails/MonthlyOrderFullfillment";
import MonthlyOrders from "views/Plans/PlanDetails/components/MonthlyOrders/MonthlyOrders";

// appear in breadcrumbs and page headers
const pageName = "Monthly Fulfillment";

const resource = enums.Resource.MONTHLY_FULFILLMENT;

const layout = "/admin";

const path = "monthly";

export default {
  path: `/${path}/`,
  name: pageName,
  sidebarName: pageName,
  icon: AssignmentIcon,
  render: () => <MonthlyOrders resource={resource} />,
  action: "read",
  resource,
  layout: layout,
  children: true,
  views: [
    {
      path: `/${path}/:orgId/:id`,
      render: () => <MonthlyOrderFulfillment />,
      layout: layout,
      action: "read",
      resource,
    },
  ],
};
