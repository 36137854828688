import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import styles from "./AssignCourierModal.styles";
import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import useAssignCourierModal from "./AssignCourierModal.hook";
import Button from "components/CustomButtons/Button.js";
import api from "services/api";
import CouriersForm from "views/Common/Couriers/CouriersForm";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AssignCourierModal(props) {
  const { isOpen, onClose, order } = props;
  const {
    form,
    couriers,
    serviceProviders,
    serviceProviderDisabled,
    serviceProvider,
    isLoading,
    submitHandler,
    resetCourier,
    handleAddNew,
  } = useAssignCourierModal({
    onClose,
    order,
  });
  const classes = useStyles();
  return (
    <CustomModal
      open={isOpen}
      onClose={() => {
        onClose(resetCourier);
      }}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <Card style={{ margin: 0 }}>
        <CardBody>
          <div className={classes.formHeader}>
            <React.Fragment>
              <AddIcon className={classes.icon} />
              Assign Courier
            </React.Fragment>
          </div>
          <form
            style={{ padding: 20, paddingBottom: 0 }}
            onSubmit={submitHandler}
            autoComplete="off"
          >
            <FormField
              md={12}
              form={form}
              name="courier"
              type="autocomplete"
              label="Courier"
              options={{
                data: couriers,
                getOptionLabel: (option) =>
                  [option?.id, option?.name || "", option?.phonePrimary]
                    .filter((item) => !!item)
                    .join(" - "),
                getSubmitValue: (options) =>
                  options?.map((option) => option?.id),
                blurOnSelect: false,
                isTableInput: true,
                addNew: true,
                formComponent: (
                  <CouriersForm
                    serviceProvider={serviceProvider}
                    handleAddNew={handleAddNew}
                    create
                  />
                ),
                dataResource: api.couriers,
              }}
            />
            <FormField
              md={12}
              form={form}
              name="serviceProvider"
              type="autocomplete"
              label="Service Provider"
              options={{
                data: serviceProviders,
                disabled: serviceProviderDisabled,
                disabledTooltipText: "Please select courier first",

                getOptionLabel: (option) =>
                  [option?.id, option?.nameEn, option?.phonePrimary]
                    .filter((item) => !!item)
                    .join(" - "),
                blurOnSelect: false,
              }}
            />
            <FormField
              md={12}
              form={form}
              name="name"
              type="text"
              label="Name"
            />
            <FormField
              md={12}
              form={form}
              name="phoneNumber"
              type="text"
              label="Phone Number"
            />

            <FormField
              md={12}
              form={form}
              name="phoneNumberSecondary"
              type="text"
              label="Secondary Phone Number"
            />
            <FormField
              md={12}
              form={form}
              name="nationalId"
              type="text"
              label="National ID"
            />
            <FormField
              md={12}
              form={form}
              name="courierCost"
              type="number"
              label="Daily Courier Cost"
            />
            <InfoWithLabel
              label="Courier Target Cost"
              info={order?.monthly?.courierTargetCost || 0}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                type="submit"
                loading={isLoading}
                style={{ margin: "20px 0" }}
                create
                loadFromRight
              >
                Assign courier
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </CustomModal>
  );
}
