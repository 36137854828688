import { useState, useCallback, useContext, useEffect } from "react";
import { toastError } from "utils/index";
import { AuthContext } from "../context/auth-context";

export const useHttpClient = (showErrors) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (error?.message && showErrors) {
      toastError(error.message);
    }
  }, [error]);

  const sendRequest = useCallback(async (request) => {
    setIsLoading(true);

    try {
      const response = await request;
      setIsLoading(false);
      return response.data;
    } catch (err) {
      console.log("### http-hook error ###", err);
      console.log("### http-hook error response ###", err.response);
      console.log("err.response.status", err?.response?.status);
      if (err.response?.status === 401) {
        console.log("response", 401);
        console.log("Here we should refresh the token");
        auth.logout();
        return;
      }
      setError({
        message: err.response?.data?.message,
        ...(err.response?.data?.param
          ? { param: err.response?.data?.param }
          : {}),
      });
      setIsLoading(false);
      throw err;
    }
  }, []);

  const clearError = () => {
    setError(null);
  };

  const paramError = (param) =>
    error?.param && error.param === param ? error.message : "";

  return [sendRequest, isLoading, error, paramError, clearError];
};
