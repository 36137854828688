import api from "services/api";
import React from "react";
import enums from "enums/index";
import PlansListPage from "views/Plans/PlansListPage/PlansListPage";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import PlanDetails from "views/Plans/PlanDetails/PlanDetails";
import MonthlyOrderDetails from "views/Plans/MonthlyOrderDetails/MonthlyOrderDetails";

// appear in breadcrumbs and page headers
const listPageName = "Plans";
const createPageName = "Create Plan";
const viewPageName = "View Plan";

const resource = enums.Resource.PLANS;

const layout = "/admin";

const path = "plans";

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: EventAvailableIcon,
  render: () => <PlansListPage resource={resource} />,
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => null,
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:planId/orders/:id`,
      render: () => <MonthlyOrderDetails />,
      layout: layout,
      // randomKey: true,
      action: "read",
      getName: (id) => `Order #${id}`,
      resource,
    },
    {
      path: `/${path}/:id`,
      render: () => <PlanDetails />,
      layout: layout,
      // randomKey: true,
      action: "read",
      getName: async (id) => {
        try {
          const data = (await api.organizations.getOne(id)).data;
          return `${data.name}'s Plans`;
        } catch {
          return viewPageName;
        }
      },
      resource,
    },
  ],
};
