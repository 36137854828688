const styles = (theme) => ({
  tooltip: {
    transform: "translateY(-20px) !important",
  },
  button: {
    width: "30px",
    height: "30px",
    minWidth: "30px",
    borderRadius: "50%",
    padding: "1px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      margin: 0,
    },
  },
});

export default styles;
