import enums from "enums/index";
import React from "react";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//-------------------------------------------------------------------------------------

export const usePlanDetails = () => {
  const [organization, setOrganization] = React.useState(null);
  const [currentTab, setCurrentTab] = React.useState(
    enums.PlanDetailsTabs.NEW_ORDER
  );

  const [sendGetRequest, isGetLoading] = useHttpClient();

  React.useEffect(() => {
    (async () => {
      const id = window.location.pathname.split("/").pop().trim();
      if (id && !isNaN(id)) {
        try {
          const response = await sendGetRequest(api.organizations.getOne(id));
          setOrganization(response);
        } catch (err) {
          toast.error("An error has occurred");
        }
      } else {
        toast.error("An error has occurred");
      }
    })();
  }, []);

  return {
    organization,
    isGetLoading,
    currentTab,
    setCurrentTab,
  };
};
