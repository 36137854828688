import translations from "constants/translations/index";
import * as yup from "yup";
import { getI18n } from "react-i18next";
import api from "services/api";
import contactsForm from "./contactsForm";
import locationsForm from "views/Zoning/locationsForm";

export default [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Name",
        validation: yup.string().required("Required"),
      },
      {
        name: "officialName",
        type: "text",
        label: "Official Name",
        validation: yup.string().required("Required"),
      },
      {
        name: "organizationClassification",
        type: "select",
        label: "Organization Classification",
        options: {
          data: Object.keys(
            translations[getI18n().language].organizationClassification
          ).map((key) => ({
            value: key,
            name:
              translations[getI18n().language].organizationClassification[key],
          })),
        },
        validation: yup.string().required("Required"),
      },
      {
        name: "organizationSize",
        type: "select",
        label: "Organization Size",
        options: {
          data: Object.keys(
            translations[getI18n().language].organizationSize
          ).map((key) => ({
            value: key,
            name: translations[getI18n().language].organizationSize[key],
          })),
        },
        validation: yup.string().required("Required"),
      },
      {
        name: "locationIds",
        fetchedName: "locations",
        type: "autocomplete",
        label: "Locations",
        options: {
          getData: () =>
            api.locations.getAll(
              undefined,
              undefined,
              undefined,
              { isPrivate: false },
              undefined
            ),
          getOptionLabel: (option) => option.name,
          getSubmitValue: (options) => options?.map((option) => option?.id),
          multiple: true,
          addNew: true,
          formStructure: locationsForm,
          freeSolo: true,
          dataResource: api.locations,
          blurOnSelect: false,
        },
        validation: yup.mixed(),
      },
      {
        name: "taxId",
        type: "text",
        label: "Tax Id",
      },
      {
        name: "commercialRegistrationNumber",
        type: "text",
        label: "Commercial Registration Number",
      },
      {
        name: "taxDocumentUrl",
        type: "dropzone",
        label: "Tax Document",
        options: {
          maxFiles: 1,
        },
      },
      {
        name: "commercialRegistrationDocumentUrl",
        type: "dropzone",
        label: "Commercial Registration Document",
        options: {
          maxFiles: 1,
        },
      },
      {
        name: "categoryId",
        fetchedName: "category",
        type: "autocomplete",
        label: "Category",
        options: {
          getData: api.categories.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (option) => option?.id,
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "industryIds",
        fetchedName: "industries",
        type: "autocomplete",
        label: "Industry",
        options: {
          getData: api.industries.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (options) => options?.map((option) => option?.id),
          multiple: true,
          blurOnSelect: false,
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "contactIds",
        fetchedName: "contacts",
        type: "autocomplete",
        label: "Contacts",
        options: {
          getData: api.contacts.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (options) => options?.map((option) => option?.id),
          multiple: true,
          addNew: true,
          formStructure: contactsForm.create,
          freeSolo: true,
          dataResource: api.contacts,
          blurOnSelect: false,
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "status",
        type: "select",
        label: "Status",
        options: {
          data: Object.keys(
            translations[getI18n().language].organizationStatus
          ).map((key) => ({
            value: key,
            name: translations[getI18n().language].organizationStatus[key],
          })),
        },
        validation: yup
          .mixed()
          .required("Required")
          .when(
            [
              "name",
              "officialName",
              "organizationClassification",
              "organizationSize",
              "locationIds",
              "taxId",
              "commercialRegistrationNumber",
              "taxDocumentUrl",
              "commercialRegistrationDocumentUrl",
              "categoryId",
              "industryIds",
              "contactIds",
            ],
            {
              is: (
                name,
                officialName,
                organizationClassification,
                organizationSize,
                locationIds,
                taxId,
                commercialRegistrationNumber,
                taxDocumentUrl,
                commercialRegistrationDocumentUrl,
                categoryId,
                industryIds,
                contactIds
              ) =>
                !name ||
                !officialName ||
                !organizationClassification ||
                !organizationSize ||
                !locationIds ||
                !taxId ||
                !commercialRegistrationNumber ||
                !taxDocumentUrl ||
                !commercialRegistrationDocumentUrl ||
                !categoryId ||
                !industryIds ||
                !contactIds,
              then: yup
                .string()
                .oneOf(
                  ["INACTIVE", "BLACK_LISTED"],
                  "For the organization to be active, all information should be complete and documents are uploaded"
                ), //if not all information are complete
              otherwise: yup
                .string()
                .oneOf(
                  ["ACTIVE", "INACTIVE", "BLACK_LISTED"],
                  "Status can only be active or inactive or blacklisted"
                ), //if all information are complete
            }
          ),
      },
    ],
  },
];
