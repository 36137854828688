import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import React from "react";
import useAssignSupplierModal from "./AssignSupplierModal.hook";
import styles from "./AssignSupplierModal.styles";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AssignSupplierModal(props) {
  const { isOpen, onClose, currentTour } = props;
  const {
    serviceProviders,
    form,
    submitHandler,
    isLoading,
  } = useAssignSupplierModal({
    currentTour,
    onClose,
  });
  const { reset } = form;
  const classes = useStyles();
  return (
    isOpen && (
      <CustomModal
        open
        onClose={() => {
          reset();
          onClose();
        }}
        modalHeaderClassName={classes.modalHeader}
        modalBodyClassName={classes.modalBody}
      >
        <Card style={{ margin: 0 }}>
          <CardBody>
            <div className={classes.formHeader}>
              <React.Fragment>
                <AddIcon className={classes.icon} />
                Assign Service Provider
              </React.Fragment>
            </div>
            <form
              style={{ padding: 20, paddingBottom: 0 }}
              onSubmit={submitHandler}
              autoComplete="off"
            >
              <FormField
                md={12}
                form={form}
                name="serviceProvider"
                type="autocomplete"
                label="Service Provider"
                options={{
                  data: serviceProviders,
                  getOptionLabel: (option) =>
                    [option?.id, option?.nameEn, option?.phonePrimary]
                      .filter((item) => !!item)
                      .join(" - "),
                  blurOnSelect: false,
                  isTableInput: true,
                }}
              />
              <FormField
                md={12}
                form={form}
                name="phoneNumber"
                type="text"
                label="Phone Number"
              />
              <FormField
                md={12}
                form={form}
                name="phoneNumberSecondary"
                type="text"
                label="Secondary Phone Number"
              />

              <FormField
                md={12}
                form={form}
                name="nationalId"
                type="text"
                label="National ID"
              />
              <FormField
                md={12}
                form={form}
                name="cost"
                type="number"
                label="Base Truck Cost"
              />
              <InfoWithLabel
                label="Base Truck Target Cost"
                info={currentTour?.baseTruckTargetCost || 0}
              />
              <FormField
                md={12}
                form={form}
                name="dealImage"
                type="dropzone"
                options={{
                  maxFiles: 1,
                }}
                label="Upload Deal Image"
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="primary"
                  type="submit"
                  loading={isLoading}
                  style={{ margin: "20px 0" }}
                  create
                  loadFromRight
                >
                  Assign Service Provider
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </CustomModal>
    )
  );
}
