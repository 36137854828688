import enums from "enums";
import colors from "enums/colors.enum";
import api from "services/api";
import { formatEnums } from "utils";
import * as yup from "yup";

function formatTruckTypeName(nameEn, nameAr) {
  return nameEn && nameAr ? `${nameEn} - ${nameAr}` : nameEn ? nameEn : nameAr;
}

export default [
  {
    column: 1,
    fields: [
      {
        name: "typeId",
        fetchedName: "type",
        type: "autocomplete",
        label: "Type",
        options: {
          getData: api.truckTypes.getAll,
          getOptionLabel: (option) =>
            formatTruckTypeName(
              option?.details?.nameEn,
              option?.details?.nameAr
            ),
          getSubmitValue: (option) => option?.id,
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "licenseId",
        type: "text",
        label: "License ID",
        validation: yup.string().required("Required"),
      },
      {
        name: "licenseExpirationDate",
        type: "date",
        label: "License Exp. Date",
        validation: yup.string().required("Required"),
      },
      {
        name: "licenseAttachment",
        type: "dropzone",
        label: "License Attachment",
        options: { maxFiles: 1 },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "status",
        type: "select",
        label: "Status",
        options: {
          data: Object.keys(enums.TruckStatus).map((key) => ({
            value: enums.TruckStatus[key],
            name: formatEnums(key),
          })),
        },
        validation: yup.string(),
      },

      {
        name: "color",
        type: "select",
        label: "Color",
        options: {
          data: Object.keys(colors).map((key) => ({
            value: colors[key],
            name: formatEnums(key),
          })),
        },
        validation: yup.string(),
      },
      {
        name: "modelYear",
        type: "text",
        label: "Model Year",
        validation: yup.string().required("Required"),
      },
      {
        name: "serviceProviderIds",
        fetchedName: "serviceProviders",
        type: "autocomplete",
        label: "Service Providers",
        options: {
          getData: api.serviceProviders.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (options) => options?.map((option) => option?.id),
          multiple: true,
          blurOnSelect: false,
        },
        validation: yup.mixed(),
      },
    ],
  },
];
