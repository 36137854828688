import enums from "enums/index";
import * as yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";

//-------------------------------------------------------------------------------------

export default yup.object({
  tasks: yup
    .array()
    .of(
      yup.object().shape({
        status: yup
          .mixed()
          .required("Tasks are required")
          .test(
            "status-error",
            "all tasks must be either completed or cancelled",
            (status) =>
              status === enums.TaskStatus.CANCELLED ||
              status === enums.TaskStatus.COMPLETED
          ),
        location: yup
          .mixed()
          .required("Task Location Required")
          .test("task-location-required", "Task Location Required", function (
            value
          ) {
            if (!value) {
              toast.error("Task location is required.");
              return false;
            }
            return true;
          }),
        startTime: yup
          .mixed()
          .required("Task Start Time Required")
          .test(
            "start-time-before-end-time",
            "Start time must be before end time",
            function (value) {
              const { endTime } = this.parent;
              return moment(value).isBefore(moment(endTime));
            }
          ),
        endTime: yup
          .mixed()
          .required("Task End Time Required")
          .test(
            "end-time-after-start-time",
            "End time must be after start time",
            function (value) {
              const { startTime } = this.parent;
              return moment(value).isAfter(moment(startTime));
            }
          ),
        serviceProvider: yup.mixed().required("Task Service Provider Required"),
        driver: yup.mixed().required("Task Driver Required"),
        truck: yup.mixed().required("Task Truck Required"),
        courier: yup.mixed(),
      })
    )
    .test(
      "tasks-in-sequence",
      "Each task must start after the previous task",
      function (tasks) {
        const filteredTasks = tasks.filter(
          (task) => task.status !== enums.TaskStatus.CANCELLED
        );
        for (let i = 1; i < filteredTasks.length; i++) {
          const prevTask = filteredTasks[i - 1];
          const currentTask = filteredTasks[i];
          if (
            !moment(currentTask.startTime).isAfter(moment(prevTask.endTime))
          ) {
            toast.error(
              `Task Start Time Should Be After End Time of Previous Task, Tasks ${prevTask.taskIndex} and ${currentTask.taskIndex}.`
            );
            return false;
          }
        }
        return true;
      }
    ),
  services: yup.array().of(
    yup.object().shape({
      price: yup.mixed().required("All Tasks Must Have A Price"),
      cost: yup.mixed().required("All Tasks Must Have A Cost"),
    })
  ),
  serviceProvider: yup.mixed().required("Service Provider Required"),
  driver: yup.mixed().required("Driver Required"),
  truck: yup.mixed().required("Truck Required"),
  courier: yup.mixed(),
});
