import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    nameEn: yup.string().required("Required"),
    nameAr: yup.string().required("Required"),
    phonePrimary: yup
      .string()
      .matches(/^((\+2|002)?01\d{9})$/, "Invalid primary phone number")
      .required("Required"),
    phoneSecondary: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .matches(/^((\+2|002)?01\d{9})$/, "Invalid secondary phone number")
      .nullable(),
    nationalId: yup
      .string()
      .required("Required")
      .length(14, "National ID must be 14 characters"),
    email: yup.string().email().nullable(),
    licenseId: yup.string().required("Required"),
    licenseExpirationDate: yup.string().required("Required"),
    licenseAttachment: yup.mixed().required("Required"),
    status: yup.string(),
    serviceProviders: yup.mixed(),
  });
};
