const styles = (theme) => ({
  root: {
    padding: "40px 20px",
    paddingBottom: 0,
  },
  section: {
    width: "100%",
    marginBottom: 80,
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
  },
  buttonGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: 60,
    marginBottom: -60,
  },
  assignButton: {
    padding: "5px 15px",
    zIndex: 20,
    display: "flex",
    alignItems: "center",
  },
  header: {
    margin: 0,
    marginLeft: 10,
  },
  addContactButton: {
    padding: "5px 15px",
    zIndex: 20,
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  addIcon: {
    width: "16px",
    zIndex: "4",
    color: "white",
    transition: "all 0.3s",
    width: "20px !important",
    height: "20px !important",
    marginBottom: 2,
    marginRight: 5,
  },
  actionsContainer: {
    display: "flex",
    width: "fit-content",
  },
  tableContainer: {
    width: "100%",
    marginTop: 25,
  },
  removeButton: {
    margin: "0px 5px",
  },
  removeIcon: {
    width: "30px !important",
    height: "30px !important",
  },
  buttonsGridContainer: {
    margin: "0 0 !important",
    width: "100%",
  },
  assignCardGridItem: {
    padding: "0 3px !important",
  },

  infoGridContainer: {
    width: "100%",
  },
  infoWithLabelRoot: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tourCardCollapseContainer: { padding: 0 },
  toursListRootContainer: { paddingTop: 10 },
  tourContainer: {
    marginTop: 31,
    height: "81vh",
    backgroundColor: "white",
    padding: "15px !important",
    borderRadius: 6,
    border: "1px solid #ddd",
    boxShadow:
      "0 10px 20px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  sidebarContainer: {
    marginTop: 113,
    position: "-webkit-sticky",
    position: "sticky",
    top: 2,
    height: "81vh",
    overflowY: "scroll",
    backgroundColor: "white",
    padding: "15px !important",
    borderRadius: 6,
    border: "1px solid #ddd",
    boxShadow:
      "0 10px 20px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
});

export default styles;
