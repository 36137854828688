import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./TourList.styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import BlockIcon from "@material-ui/icons/Block";
import enums from "enums/index";
import Tooltip from "@material-ui/core/Tooltip";
import TourInfo from "./TourInfo";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";
import Loading from "components/Loading/Loading";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function TourList(props) {
  const classes = useStyles();

  const { onSelect, org, tabs } = props;
  const { currentTourIndex, isToursLoading } = useContext(
    TourOperationsContext
  );

  const listClasses = classNames({
    [classes.list]: true,
    [classes.tabsList]: tabs,
  });

  const listItemClasses = (index) =>
    classNames({
      [classes.listItem]: true,
      [classes.tabListItem]: tabs,
      [classes.listItemSelected]: !tabs && currentTourIndex === index,
      [classes.tabListItemSelected]: tabs && currentTourIndex === index,
    });
  const tours = useMemo(() => org.tours, [org]);

  const getTourLabel = (tour) => {
    const date = new Date(tour.pickupTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    return [
      tour.id,
      `${year}-${month}-${day}`,
      `${hour}:${minute}`,
      tour?.truckType?.details?.nameEn,
      tour?.truck?.licenseId,
    ]
      .filter((item) => !!item)
      .join(" | ");
  };
  return (
    <Loading loading={isToursLoading}>
      <ul className={listClasses}>
        {tours.map((tour, index) => {
          return (
            <li
              key={Math.random()}
              onClick={() => {
                onSelect(index);
              }}
            >
              <Tooltip title={<TourInfo tour={tour} currentOrg={org} />}>
                <a className={listItemClasses(index)}>
                  <div>{getTourLabel(tour)}</div>
                  <div>
                    {tour.status === enums.TourStatus.COMPLETED && (
                      <CheckCircleOutlineIcon
                        style={{ marginBottom: "-5px", padding: "2px" }}
                        fontSize="small"
                      />
                    )}
                    {tour.status === enums.TourStatus.CANCELLED && (
                      <BlockIcon
                        style={{ marginBottom: "-5px", padding: "2px" }}
                        fontSize="small"
                      />
                    )}
                  </div>
                </a>
              </Tooltip>
            </li>
          );
        })}
      </ul>
    </Loading>
  );
}

TourList.propTypes = {
  org: PropTypes.object,
  orgIndex: PropTypes.number,
  tabs: PropTypes.bool,
  onSelect: PropTypes.func,
};
