// import EngineeringIcon from "@material-ui/icons/Engineering";
import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import api from "services/api";
import serviceProvidersForm from "views/Common/serviceProvidersForm";
import React from "react";
import { formatEnums } from "utils/index";

// appear in breadcrumbs and page headers
const listPageName = "Service Providers";
const createPageName = "Create Service Provider";
const editPageName = "Edit Service Provider";

// appears on alerts; example: Entity created successfully
const entityName = "Service Provider";

const resource = enums.Resource.SERVICE_PROVIDERS;

const layout = "/admin";

const path = "serviceProviders";

const formStructure = serviceProvidersForm;

const dataResource = api.serviceProviders;

const columns = [
  {
    title: "Name",
    render: (_, record) => (
      <span>{`${record?.nameEn} - ${record?.nameAr}`}</span>
    ),
  },
  {
    title: "Phone",
    dataIndex: "phonePrimary",
  },
  {
    title: "National ID",
    dataIndex: "nationalId",
  },
  {
    title: "Status",
    dataIndex: "status",
    getData: (value) => (value ? formatEnums(value) : "N/A"),
  }
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "engineering",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      resource,
    },
  ],
};
