import enums from "enums/index";
import * as yup from "yup";

export default (targetStatus) => {
  return yup.object().shape({
    startDate: [
      enums.OrderStatus.ACTIVE,
      enums.OrderStatus.TERMINATED,
    ].includes(targetStatus)
      ? yup.mixed().required("Required")
      : yup.mixed(),
    endDate: [enums.OrderStatus.TERMINATED].includes(targetStatus)
      ? yup
          .mixed()
          .required("Required")
          .when(["startDate"], (startDate) => {
            return yup
              .mixed()
              .required("Required")
              .test(
                "endDate-after-startDate-error",
                "The end date should be after the start date.",
                (date) => {
                  if (startDate) return startDate < date;
                  else return true;
                }
              );
          })
      : yup.mixed(),
    reason: [
      enums.OrderStatus.CANCELLED,
      enums.OrderStatus.TERMINATED,
    ].includes(targetStatus)
      ? yup.mixed().required("Required")
      : yup.mixed(),
  });
};
