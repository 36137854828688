import React, { useCallback, useContext } from "react";
import { useFieldArray } from "react-hook-form";
import { PlanFormContext } from "../../PlanForm.context";
import { TourContext } from "../ToursList/Tour/Tour.context";

export const useTasksList = ({ tourIndex }) => {
  const { form, defaultTask, initialTask } = useContext(PlanFormContext);
  const { tour } = useContext(TourContext);

  const {
    setValue,
    control,
    errors,
    watch,
    formState: { isDirty },
  } = form;

  const { fields: tasks, append, remove } = useFieldArray({
    control,
    name: `tours.${tourIndex}.tasks`,
  });

  const tourType = tour.type;
  const truckTypeId = tour.truckType?.id;
  const distanceLimit = tour.distanceLimit;
  const durationLimit = tour.durationLimit;
  const dealId = watch("deal")?.id;

  React.useEffect(() => {
    if (isDirty) {
      remove();
      append({ ...initialTask });
    }
  }, [tourType, truckTypeId, distanceLimit, durationLimit, dealId]);

  const onAdd = useCallback(() => {
    append({ ...defaultTask });
  }, [defaultTask, append]);

  const onRemove = useCallback(
    (index) => {
      remove(index);
    },
    [remove]
  );

  const handleChange = useCallback(
    (value, key, index) => {
      setValue(`tours.${tourIndex}.tasks.${index}.${key}`, value);
    },
    [setValue]
  );

  return {
    tasks,
    errors,
    handleChange,
    onAdd,
    onRemove,
  };
};
