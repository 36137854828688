import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import CouriersFormValidation from "./CouriersForm.validation";

//------------------------------------------------------------------

export const useCouriersForm = ({
  edit,
  create,
  serviceProvider,
  courier,
  refresh,
  handleAddNew,
  handleModalClose,
}) => {
  const [data, setData] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const [sendGetRequest, isGetLoading] = useHttpClient();

  const dataResource = React.useMemo(() => api.couriers, []);

  const resolver = useYupValidationResolver(CouriersFormValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isDirty },
  } = form;

  React.useEffect(() => {
    if (serviceProvider) {
      setValue("serviceProvider", serviceProvider);
    }
  }, []);

  React.useEffect(() => {
    if (edit) {
      const id = window.location.pathname.split("/").reverse()[1];
      if (id) {
        (async () => {
          try {
            const response = await sendGetRequest(dataResource.getOne(id));
            setData(response);
            if (response) {
              Object.keys(response).forEach((key) =>
                setValue(key, response[key])
              );
            }
          } catch (err) {
            toast.error("An error has occurred");
          }
        })();
      } else {
        toast.error("An error has occurred");
      }
    } else if (courier) {
      setData(courier);
      Object.keys(courier).forEach((key) => setValue(key, courier[key]));
    }
  }, [edit, courier]);

  const createHandler = (values) => async () => {
    try {
      const response = await sendRequest(dataResource.create(values));
      toast.success(`Courier has been created successfully`);
      if (handleModalClose) handleModalClose();
      if (refresh) refresh();
      if (handleAddNew) handleAddNew(response);
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const editHandler = (values) => async () => {
    try {
      const response = await sendRequest(dataResource.patch(values));
      toast.success(`Courier has been updated successfully`);
      if (handleModalClose) handleModalClose();
      if (refresh) refresh();
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let values = getValues();

    if (values.serviceProvider) {
      values.serviceProviderIds = [values.serviceProvider.id];
      delete values?.serviceProvider;
    }

    handleSubmit(
      create ? createHandler(values) : editHandler(values),
      onError
    )();
  };

  return {
    isLoading,
    isGetLoading,
    form,
    data,
    watch,
    serviceProvider,
    onSubmit,
  };
};
