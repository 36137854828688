import {
  blackColor,
  hexToRgb,
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react";

const customSwitchStyle = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingTop: 16,
    marginBottom: 2,
    boxSizing: "content-box",
    width: "100%",
    justifyContent: "space-between",
  },
  labelRoot: {
    marginLeft: "-14px",
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "black",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
    transition: "0.3s ease all",
    letterSpacing: "unset",
  },
  radioRoot: {
    paddingRight: "16px",
    paddingLeft: "16px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  radio: {
    color: primaryColor[0] + "!important",
  },
  radioChecked: {
    width: "16px",
    height: "16px",
    border: "1px solid " + primaryColor[0],
    borderRadius: "50%",
  },
  radioUnchecked: {
    width: "0px",
    height: "0px",
    padding: "7px",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
    borderRadius: "50%",
  },
  radioContainer: {
    display: "flex",
    flexDirection: "column",
  },
});

export default customSwitchStyle;
