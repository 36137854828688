import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { billStatusColors, invoiceStatusColors } from "constants/colors";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { formatEnums, getDateString } from "utils/index";
import styles from "./History.styles";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function History({ id, resource, status, ...props }) {
  const classes = useStyles();

  const [history, setHistory] = useState([]);
  const [sendRequest, isLoading] = useHttpClient();

  useEffect(() => {
    if (!id) return;
    (async () => {
      const historyResponse = await sendRequest(
        api[resource.toLowerCase()].getRevisions(id)
      );

      const result = historyResponse.reduce((acc, curr, index, array) => {
        if (index < array.length - 1) {
          acc.push({
            from: curr.status,
            to: array[index + 1].status,
            updatedBy: array[index + 1].createdBy,
            updatedAt: array[index + 1].timestamp,
          });
        }
        return acc;
      }, []);
      setHistory(result);
    })();
  }, [id, status]);

  const statusColors =
    resource.toLowerCase() === "invoices"
      ? invoiceStatusColors
      : billStatusColors;
  const finOpsStatusHistoryColumns = [
    {
      title: "From",
      dataIndex: "from",
      getData: (status) => {
        return (
          <div style={{ color: statusColors[status], fontWeight: 600 }}>
            {formatEnums(status)}
          </div>
        );
      },
    },
    {
      title: "To",
      dataIndex: "to",
      getData: (status) => {
        return (
          <div style={{ color: statusColors[status], fontWeight: 600 }}>
            {formatEnums(status)}
          </div>
        );
      },
    },
    {
      title: "Updated By",
      dataIndex: ["updatedBy", "name"],
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      getData: (data) => (data ? getDateString(data, "en-US", true) : ""),
    },
  ];

  return history.length > 0 ? (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.tableContainer}>
          <CustomTable
            columns={finOpsStatusHistoryColumns}
            data={history || []}
            addIndexColumn
          />
        </div>
      </GridItem>
    </GridContainer>
  ) : null;
}

History.propTypes = {
  id: PropTypes.number,
  resource: PropTypes.func,
  status: PropTypes.string
};
