import AssignmentIcon from "@material-ui/icons/Assignment";
import enums from "enums/index";
import React from "react";
import TourOperationsView from "views/TourOperations/TourOperationsView";

// appear in breadcrumbs and page headers
const listPageName = "Dispatching";

const resource = enums.Resource.DISPATCHING; //TODO what resources we need here

const layout = "/admin";

const path = "dispatch";

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: AssignmentIcon,
  render: () => <TourOperationsView resource={resource} path={path} />,
  action: "read",
  resource,
  layout: layout,
};
