import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import GridContainer from "components/Grid/GridContainer.js";
import Loading from "components/Loading/Loading";
import styles from "./CourierRentalForm.styles";
import React from "react";
import api from "services/api";
import { useCourierRentalForm } from "./CourierRentalForm.hook";
//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function CourierRentalForm(props) {
  const { create, edit, setIsEditing, path } = props;
  const classes = useStyles();
  const {
    isGetRentalLoading,
    isLoading,
    couriers,
    form,
    onSubmit,
  } = useCourierRentalForm({
    edit,
    setIsEditing,
    path,
  });
  return (
    <form className={classes.form} onSubmit={onSubmit} autoComplete="off">
      <Loading loading={isGetRentalLoading} style={{ height: "60vh" }}>
        <div className={classes.section}>
          <div className={classes.headerContainer}>
            {edit && (
              <Button
                simple
                className={classes.closeButton}
                key="close"
                aria-label="Close"
                onClick={() => setIsEditing(false)}
                color="danger"
              >
                <Close className={classes.closeIcon} />
              </Button>
            )}
          </div>
          <GridContainer>
            <FormFieldGridItem
              type="autocomplete"
              form={form}
              name="serviceProvider"
              label="Service Provider"
              options={{
                getData: api.serviceProviders.getAll,
                getOptionLabel: (option) => option?.name,
                blurOnSelect: false,
              }}
            />
            <FormFieldGridItem
              type="autocomplete"
              form={form}
              name="courier"
              label="Courier"
              options={{
                data: couriers,
                getOptionLabel: (option) => option?.name,
                disabled: !couriers,
                disabledTooltipText: "Please select a service provider first",
                blurOnSelect: false,
              }}
            />
            <FormFieldGridItem
              name="startDate"
              type="date"
              label="Start Date"
              form={form}
              options={{}}
            />
            <FormFieldGridItem
              name="endDate"
              type="date"
              label="End Date"
              form={form}
              options={{}}
            />
            <FormFieldGridItem
              type="autocomplete"
              form={form}
              name="offDays"
              label="Off Days"
              options={{
                getOptionLabel: (option) => option.name,
                enum: "weekDay",
                multiple: true,
                blurOnSelect: false,
              }}
            />
            <FormFieldGridItem
              type="number"
              form={form}
              name="workingDays"
              label="Number Of Working Days"
            />
            <FormFieldGridItem
              type="number"
              form={form}
              name="monthlyCourierCost"
              label="Monthly Courier Cost"
            />
          </GridContainer>
        </div>
        <div className={classes.buttonsContainer}>
          {edit && (
            <Button
              color="danger"
              style={{ marginTop: 20 }}
              onClick={() => setIsEditing(false)}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          )}
          <Button
            color="primary"
            type="submit"
            loading={isLoading}
            style={{ marginTop: 20 }}
            create={create}
            edit={edit}
            loadFromRight
          />
        </div>
      </Loading>
    </form>
  );
}
