import { grayColor } from "assets/jss/material-dashboard-pro-react.js";
import { blackColor } from "assets/jss/material-dashboard-pro-react";

const styles = (theme) => ({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  rowCell: {
    borderColor: "#f0f0f0",
    padding: "10px 10px",
    width: "10%",
    borderWidth: `0 1px 1px 0`,
    borderStyle: "solid",
  },
  tooltip: {
    transform: "translateY(-20px) !important",
  },
  button: {
    width: "30px",
    height: "30px",
    minWidth: "30px",
    borderRadius: "50%",
    padding: "1px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      margin: 0,
    },
  },
});

export default styles;
