import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomForm from "components/Forms/CustomForm/CustomForm";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading/Loading";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { createValidationSchema } from "utils";
import styles from "./FormContainer.styles";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import CustomModal from "components/CustomModal/CustomModal";
import enums from "enums";
import { toastError } from "utils/index";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function FormContainer(props) {
  const {
    create,
    edit,
    entityName,
    path,
    dataResource,
    formStructure,
    getName,
    type,
    isOpen,
    onClose,
    editItemId,
    defaultValues,
    preprocessOnSubmit,
    preprocessOnGet,
  } = props;

  const validationSchema = createValidationSchema(formStructure);

  const classes = useStyles();

  const [data, setData] = React.useState(null);

  const [sendRequest, isLoading, sendRequestError] = useHttpClient();

  const [sendGetRequest, isGetLoading] = useHttpClient();

  const history = useHistory();

  const { t } = useTranslation();

  React.useEffect(() => {
    if (create && !!defaultValues) {
      setData(defaultValues);
    }
  }, [create, defaultValues]);

  React.useEffect(() => {
    if (sendRequestError?.message) {
      toastError(sendRequestError.message);
    }
  }, [sendRequestError]);

  React.useEffect(() => {
    if (
      edit &&
      ((type === enums.FormContainerType.MODAL && isOpen && editItemId) ||
        type === enums.FormContainerType.PAGE)
    ) {
      const id = editItemId
        ? editItemId
        : window.location.pathname.split("/").reverse()[1];

      if (id) {
        (async () => {
          try {
            const response = await sendGetRequest(dataResource.getOne(id));

            const processedData = preprocessOnGet
              ? preprocessOnGet(response)
              : response;

            setData(processedData);
          } catch (err) {
            toast.error("An error has occurred");
            if (type === enums.FormContainerType.MODAL) {
              onClose();
            } else {
              history.push({
                pathname: `/admin/${path}`,
              });
            }
          }
        })();
      } else {
        toast.error("An error has occurred");
        if (type === enums.FormContainerType.MODAL) {
          onClose();
        } else {
          history.push({
            pathname: `/admin/${path}`,
          });
        }
      }
    }
  }, [edit, type, isOpen, editItemId, preprocessOnGet]);

  const createHandler = (values) => async () => {
    try {
      values = preprocessOnSubmit ? preprocessOnSubmit(values) : values;
      const response = await sendRequest(dataResource.create(values));
      toast.success(`${entityName} has been created successfully`);
      if (type === enums.FormContainerType.MODAL) {
        onClose(response);
      } else {
        history.push({
          pathname: `/admin/${path}`,
        });
      }
    } catch (err) {
      // toast.error("An error has occurred");
    }
  };

  const editHandler = (values) => async () => {
    try {
      values = preprocessOnSubmit ? preprocessOnSubmit(values) : values;
      const response = await sendRequest(dataResource.patch(values));
      toast.success(`${t(entityName)} has been edited successfully`);
      if (type === enums.FormContainerType.MODAL) {
        onClose(response);
      } else {
        history.push({
          pathname: `/admin/${path}`,
        });
      }
    } catch (err) {
      // toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const formBody = (
    <GridContainer>
      <GridItem
        xs={12}
        sm={12}
        md={
          formStructure?.length === 1 && type === enums.FormContainerType.PAGE
            ? 6
            : 12
        }
      >
        <Card
          style={type === enums.FormContainerType.MODAL ? { margin: 0 } : {}}
          className={classes.cardRoot}
        >
          <CardBody>
            <div className={classes.formHeader}>
              {create ? (
                <AddIcon className={classes.icon} />
              ) : (
                <EditIcon className={classes.icon} />
              )}
              {edit ? (
                isGetLoading ? (
                  <Skeleton variant="rect" height={25} width={250} />
                ) : (
                  `Edit ${getName(data) || t(entityName)}`
                )
              ) : (
                `Create ${t(entityName)}`
              )}
            </div>
            <Loading
              loading={isGetLoading}
              style={{
                minHeight:
                  type === enums.FormContainerType.MODAL ? "220px" : "60vh",
              }}
            >
              <CustomForm
                type={type}
                formStructure={formStructure}
                submitHandler={create ? createHandler : editHandler}
                onError={onError}
                loading={isLoading}
                validationSchema={validationSchema}
                data={data}
                create={create}
                edit={edit}
              />
            </Loading>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  return type === enums.FormContainerType.MODAL ? (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      {formBody}
    </CustomModal>
  ) : (
    <div>
      <Breadcrumbs />
      {formBody}
    </div>
  );
}

FormContainer.defaultProps = {
  getName: (data) => {
    const name = data ? data.name : "";
    return name;
  },
  type: enums.FormContainerType.PAGE,
  isOpen: false,
};

FormContainer.propTypes = {
  getName: PropTypes.func,
  entityName: PropTypes.string,
  dataResource: PropTypes.object,
  path: PropTypes.string,
  edit: PropTypes.bool,
  create: PropTypes.bool,
  formStructure: PropTypes.object,
  type: PropTypes.oneOf([
    enums.FormContainerType.PAGE,
    enums.FormContainerType.MODAL,
  ]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  editItemId: PropTypes.any,
  preprocessOnSubmit: PropTypes.func,
  preprocessOnGet: PropTypes.func,
};
