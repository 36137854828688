import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { useContext, useState } from "react";
import api from "services/api";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

export default function useCancelCourierRental({
  currentTour,
  tourToCancel,
  toggleCancelModal,
  onClose,
}) {
  const { getTour, getOrganizations, getTours } = useContext(
    TourOperationsContext
  );
  const [sendTourRequest, isTourRequestLoading] = useHttpClient(true);
  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit } = form;

  const onSubmit = () => async () => {
    try {
      if (!tourToCancel || !currentTour) {
        toast.error("no tour is selected");
        return;
      }
      //TODO: need to implement
      await sendTourRequest(api.tours.removeCourier(tourToCancel.id));
      await sendTourRequest(
        api.orders.assignTourWorker({
          id: currentTour.id,
          courierRentalId: tourToCancel.courierRentalId,
        })
      );
      getTour();
      toast.success("Courier Rental Cancelled Successfully");
      toggleCancelModal();
      onClose();
    } catch (error) {
      if (error?.response?.data?.error === "Conflict") {
        await getOrganizations();
        await getTours();
        toggleCancelModal();
      } else {
        console.log(error);
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    handleSubmit(onSubmit(), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    submitHandler,
    isTourRequestLoading,
  };
}
