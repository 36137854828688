import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import styles from "./Buttons.styles";
import React from "react";

const useStyles = makeStyles(styles);

export default function CancelButton({ onCancel, index }) {
  const classes = useStyles();

  return (
    <ConfirmationButton
      onConfirm={onCancel}
      confirmMessage={`Are you sure you want to cancel task number ${
        index + 1
      }`}
      successHeaderText="Task Cancelled"
      successMessage="Task cancelled successfully"
      headerMessage="Cancel Task"
      confirmButtonText="Confirm"
      confirmButtonColor="success"
      buttonProps={{
        textColor: "textColor",
        className: classes.button,
      }}
    >
      <Tooltip
        title={"Cancel"}
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
        arrow
      >
        <CancelOutlinedIcon style={{ cursor: "pointer" }} />
      </Tooltip>
    </ConfirmationButton>
  );
}
