import React from "react";
import api from "services/api";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";

//-------------------------------------------------------------------------------------

export default function useServices() {
  const [isAssignModalOpen, setIsAssignModalOpen] = React.useState(false);
  const [editedService, setEditedService] = React.useState(null);

  const { deal, setDeal, isGetDealLoading } = React.useContext(DealContext);

  const services = React.useMemo(
    () => (deal.services?.length ? deal.services : []),
    [deal]
  );

  const handleAssignService = React.useCallback(
    (service) => {
      setEditedService(service);
      setIsAssignModalOpen(true);
    },
    [setIsAssignModalOpen, setEditedService]
  );
  const onAssignModalClose = React.useCallback(() => {
    setIsAssignModalOpen(false);
    setEditedService(null);
  }, [setIsAssignModalOpen, setEditedService]);

  const handleRemoveService = React.useCallback(
    async (record) => {
      const response = await api.deals.deleteService(
        deal.id,
        record.service.id
      );
    },
    [deal]
  );

  const onRemoveModalClose = React.useCallback(
    (record) => {
      const newServices = deal.services.filter(
        (dealService) => dealService.service.id !== record.service.id
      );
      setDeal({ ...deal, services: newServices });
    },
    [deal]
  );

  return {
    deal,
    isAssignModalOpen,
    isGetDealLoading,
    editedService,
    services,
    onAssignModalClose,
    handleAssignService,
    handleRemoveService,
    onRemoveModalClose,
  };
}
