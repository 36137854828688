import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import styles from "./AssignTruckModal.styles";
import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import useAssignTruckModal from "./AssignTruckModal.hook";
import Button from "components/CustomButtons/Button.js";
import api from "services/api";
import TrucksForm from "views/Common/Trucks/TrucksForm";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AssignTruckModal(props) {
  const { isOpen, onClose, currentTour } = props;
  const {
    form,
    trucks,
    truckType,
    serviceProvider,
    isLoading,
    resetTruck,
    submitHandler,
    handleAddNew,
  } = useAssignTruckModal({
    onClose,
    currentTour,
  });
  const classes = useStyles();
  return (
    <CustomModal
      open={isOpen}
      onClose={() => {
        onClose(resetTruck);
      }}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <Card style={{ margin: 0 }}>
        <CardBody>
          <div className={classes.formHeader}>
            <React.Fragment>
              <AddIcon className={classes.icon} />
              Assign Truck
            </React.Fragment>
          </div>
          <form
            style={{ padding: 20, paddingBottom: 0 }}
            onSubmit={submitHandler}
            autoComplete="off"
          >
            <FormField
              md={12}
              form={form}
              name="truck"
              type="autocomplete"
              label="Truck"
              options={{
                data: trucks,
                getOptionLabel: (option) => option.name,
                blurOnSelect: false,
                isTableInput: true,
                addNew: true,
                formComponent: (
                  <TrucksForm
                    type={truckType}
                    serviceProvider={serviceProvider}
                    handleAddNew={handleAddNew}
                    create
                  />
                ),
                dataResource: api.trucks,
              }}
            />
            <FormField
              md={12}
              form={form}
              name="licenseImage"
              type="dropzone"
              options={{
                maxFiles: 1,
              }}
              label="Upload Latest License"
            />

            <FormField
              md={12}
              form={form}
              name="license"
              type="text"
              label="License"
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                type="submit"
                loading={isLoading}
                style={{ margin: "20px 0" }}
                create
                loadFromRight
              >
                Assign Truck
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </CustomModal>
  );
}
