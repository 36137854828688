import enums from "enums/index";
import React, { useCallback, useContext, useMemo } from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

export default function useDiscountRow(form, index, data) {
  const { setValue } = form;

  const { currentTour, getTour, invoices, setInvoices } = useContext(
    TourOperationsContext
  );
  const [sendRequest, isLoading] = useHttpClient(true);

  const tourDisabled = useMemo(
    () =>
      currentTour?.status === enums.TourStatus.CANCELLED ||
      currentTour?.status === enums.TourStatus.COMPLETED,
    [currentTour]
  );
  const rowDisabled = useMemo(
    () => data.invoice.status === enums.InvoiceStatus.INVOICED,
    [data]
  );
  const reviewDiscount = async () => {
    try {
      if (!currentTour?.id) return;

      await sendRequest(
        api.discounts.patch({
          id: data.id,
          finOpsStatus: enums.FinOpsStatus.REVIEWED,
        })
      );
      await getTour();
    } catch (err) {
      console.log("error", err);
    }
  };
  const handleAddNew = useCallback(
    async (invoice) => {
      setInvoices([...invoices, invoice]);
      setValue(`discounts.${index}.invoice`, invoice);
    },
    [index]
  );

  React.useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (key !== "id") setValue(`discounts.${index}.${key}`, data[key]);
      });
    }
  }, [data]);
  return { invoices, handleAddNew, reviewDiscount, tourDisabled, rowDisabled };
}
