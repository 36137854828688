import enums from "enums/index";
import { useContext, useMemo } from "react";
import { MonthlyOrderDetailsContext } from "../../MonthlyOrderDetails.context";
import { formatEnums } from "utils/index";

export default function useOrderInfo() {
  const { order } = useContext(MonthlyOrderDetailsContext);
  const org = useMemo(() => order?.deal?.organization, [order]);
  const completedTours = useMemo(
    () =>
      order?.tours.reduce(
        (pre, tour) =>
          tour.status === enums.TourStatus.COMPLETED ? pre + 1 : pre,
        0
      ),
    [order]
  );

  const toursCount = useMemo(
    () =>
      order?.tours.reduce(
        (pre, tour) =>
          tour.status === enums.TourStatus.CANCELLED ? pre - 1 : pre,
        order.tours.length
      ),
    [order]
  );

  const dealName = useMemo(() => order?.deal?.details?.name, [order]);

  const endReason = useMemo(() => order?.endReason, [order]);
  const serviceProviderCancellationReason = useMemo(
    () => order?.monthly?.serviceProviderCancellationReason,
    [order]
  );

  const startTime = useMemo(() => {
    if (order?.monthly?.pickupTime) {
      const date = new Date(order?.monthly?.pickupTime);
      const time = date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return time;
    }
  }, [order]);

  return {
    org,
    order,
    dealName,
    completedTours,
    toursCount,
    endReason,
    serviceProviderCancellationReason,
    startTime,
  };
}
