import enums from "enums/index";
import api from "services/api";
import * as yup from "yup";

export default function contractsForm(organizationId) {
  return [
    {
      column: 1,
      fields: [
        {
          name: "dealId",
          type: "hidden",
        },
        {
          name: "name",
          type: "text",
          label: "Name",
          validation: yup.string().required("Required"),
        },
        {
          name: "type",
          type: "select",
          label: "Type",
          options: {
            data: Object.keys(enums.ContractType).map((key) => ({
              value: enums.ContractType[key],
              name: key,
            })),
          },
          validation: yup.string().required("Required"),
        },
        {
          name: "startingDate",
          type: "date",
          label: "Starting Date",
          validation: yup.string().required("Required"),
        },
        {
          name: "expiryDate",
          type: "date",
          label: "Expiry Date",
          validation: yup.string().required("Required"),
        },
        {
          name: "documentUrl",
          type: "dropzone",
          label: "Document",
          options: {
            maxFiles: 1,
          },
          validation: yup.mixed().required("Required"),
        },
        {
          name: "signatureContactId",
          fetchedName: "signatureContact",
          type: "autocomplete",
          label: "Signature Contact",
          options: {
            getData: api.contacts.getAll,
            filters: { organizationId },
            getOptionLabel: (option) => option.name,
            getSubmitValue: (option) => option?.id,
          },
          validation: yup.mixed().required("Required"),
        },
      ],
    },
  ];
}
