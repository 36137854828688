import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import CustomForm from "components/Forms/CustomForm/CustomForm";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import enums from "enums";
import React from "react";
import styles from "./LoseDealModal.styles";
import { useLoseDealModal } from "./LoseDealModal.hook";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function LoseDealModal(props) {
  const {
    isOpen,
    onClose,
    isLoseDealLoading,
    formStructure,
    validationSchema,
    submitHandler,
    onError,
  } = useLoseDealModal(props);

  const classes = useStyles();

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>Lose Deal</div>
              <p className={classes.title}>Please state below the reason:</p>
              <CustomForm
                type={enums.FormContainerType.MODAL}
                formStructure={formStructure}
                submitHandler={submitHandler}
                onError={onError}
                loading={isLoseDealLoading}
                validationSchema={validationSchema}
                submitButtonText={"Confirm"}
                submitButtonColor={"black"}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}
