import { createContext } from "react";

export const TasksTableContext = createContext({
  tasks: {},
  tasksWithTime: [],
  form: {},
  currentTask: null,
  defaultTask: {},
  tourPickupLocationId: null,
  tourPickupLocation: null,
  quotationLocations: [],
  quotationCities: [],
  quotationDistricts: [],
  tourType: null,
  isServiceProcessed: false,
  allCities: [],
  allDistricts: [],
  setTourPickupLocation: () => {},
  getTourLocations: () => {},
  setCurrentTask: () => {},
  onSubmit: () => {},
  updateTasks: () => {},
});
