import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const styles = (theme) => ({
  infoItem: { display: "flex" },
  infoItemLabel: { fontWeight: "bold", marginRight: 5 },
  infoCardLoading: {
    display: "flex",
    alignItems: "center",
  },
  card: { height: 230 },
  cardBody: { overflowY: "scroll" },
  cardContainer: { display: "flex", flexDirection: "column", width: "100%" },
  loading: { marginTop: 0 },
  editIcon: {
    marginLeft: 15,
    fontSize: 20,
    color: primaryColor[0],
    cursor: "pointer",
  },
  saveIcon: {
    marginLeft: 15,
    fontSize: 20,
    color: "#00008B",
    cursor: "pointer",
  },
  dateInputContainer: { width: "40%", marginTop: -4 },
  dateFormControl: { margin: 0, paddingTop: 0 },
  dateInput: {
    width: "100%",
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export default styles;
