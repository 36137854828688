import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router";
import LocationsForm from "./LocationsForm";
import styles from "./LocationsForm.styles";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function LocationsFormPage(props) {
  const { path } = props;

  const history = useHistory();

  const refresh = () => history.push({ pathname: `/admin/${path}` });

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <LocationsForm {...props} refresh={refresh} />
      </GridItem>
    </GridContainer>
  );
}

LocationsForm.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};
