import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import styles from "./AddServiceModal.styles";
import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import Button from "components/CustomButtons/Button.js";
import api from "services/api";
import useAddServiceModal from "./AddServiceModal.hook";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AddServiceModal(props) {
  const { isOpen, onClose, currentTour } = props;

  const {
    serviceProviders,
    form,
    reset,
    services,
    isServiceLoading,
    submitHandler,
  } = useAddServiceModal({
    currentTour,
    onClose,
  });

  const classes = useStyles();
  return (
    <CustomModal
      open={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <Card style={{ margin: 0 }}>
        <CardBody>
          <div className={classes.formHeader}>
            <React.Fragment>
              <AddIcon className={classes.icon} />
              Add Additional Service
            </React.Fragment>
          </div>
          <form
            style={{ padding: 20, paddingBottom: 0 }}
            onSubmit={submitHandler}
            autoComplete="off"
          >
            <FormField
              md={12}
              form={form}
              name="serviceProvider"
              type="autocomplete"
              label="Service Provider"
              options={{
                data: serviceProviders,
                getOptionLabel: (option) => option.nameEn,
                blurOnSelect: false,
              }}
            />
            <FormField
              md={12}
              form={form}
              name="service"
              type="autocomplete"
              label="Service"
              options={{
                data: services,
                getOptionLabel: (option) => option.name,
                blurOnSelect: false,
              }}
            />
            <FormField
              md={12}
              form={form}
              name="targetCost"
              options={{
                disabled: true,
              }}
              type="number"
              label="Target Cost"
            />
            <FormField
              md={12}
              form={form}
              name="cost"
              type="number"
              label="Cost"
            />

            <FormField
              md={12}
              form={form}
              name="quantity"
              type="number"
              label="Quantity"
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                type="submit"
                loading={isServiceLoading}
                style={{ margin: "20px 0" }}
                create
                loadFromRight
              >
                Add Service
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </CustomModal>
  );
}
