import { makeStyles } from "@material-ui/core/styles";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import enums from "enums/index";
import React from "react";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import styles from "./OrganizationDetailsSection.styles";
import { useTranslation } from "react-i18next";
import translations from "constants/translations";
import CachedIcon from "@material-ui/icons/Cached";
import AssignOrganizationModal from "../../components/AssignOrganizationModal/AssignOrganizationModal";
import CheckPermission from "components/CheckPermission/CheckPermission";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrganizationDetailsSection() {
  const classes = useStyles();

  const [isOpen, setIsOpen] = React.useState(false);

  const { i18n } = useTranslation();

  const { deal } = React.useContext(DealContext);

  const handleAssignOrganization = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const organization = React.useMemo(() => {
    return deal?.organization;
  }, [deal]);

  return (
    <div className={classes.section}>
      <AssignOrganizationModal isOpen={isOpen} onClose={onClose} />
      {organization ? (
        <React.Fragment>
          <div
            style={{ marginBottom: -43 }}
            className={classes.headerContainer}
          >
            <h3 style={{ marginLeft: 20 }} className={classes.header}>
              Organization details
            </h3>
            <CheckPermission
              resource={enums.Resource.DEAL_ORGANIZATIONS}
              action={enums.Action.UPDATE}
            >
              <Button
                onClick={handleAssignOrganization}
                color="primary"
                className={classes.changeOrganizationButton}
                style={{ marginRight: 60 }}
              >
                <CachedIcon className={classes.changeIcon} /> Change
                Organization
              </Button>
            </CheckPermission>
          </div>
          <CollapseContainer
            name="organizationDetails"
            titleComponent={<div />}
            startCollapsed={false}
          >
            <GridContainer>
              <InfoWithLabelGridItem label="Name" info={organization?.name} />
              <InfoWithLabelGridItem
                label="Official Name"
                info={organization?.officialName}
              />
              <InfoWithLabelGridItem
                label="Status"
                info={
                  translations[i18n.language].organizationStatus[
                    organization?.status
                  ]
                }
              />
              <InfoWithLabelGridItem
                label="Tax ID"
                info={organization?.taxId}
              />
              <InfoWithLabelGridItem
                label="Commercial Registration Number"
                info={organization?.commercialRegistrationNumber}
              />
              <InfoWithLabelGridItem
                label="Organization Category"
                info={organization?.category?.name}
              />
              <InfoWithLabelGridItem
                label="Industry"
                info={organization?.industry?.name}
              />
              <InfoWithLabelGridItem
                label="Tax Document"
                info={organization?.taxDocumentUrl}
                type={enums.InfoType.FILE}
              />
              <InfoWithLabelGridItem
                label="Commercial Registration Document"
                info={organization?.commercialRegistrationDocumentUrl}
                type={enums.InfoType.FILE}
              />
            </GridContainer>
          </CollapseContainer>
        </React.Fragment>
      ) : (
        <div className={classes.headerContainer}>
          <h3 className={classes.header}>
            No organization assigned for this deal yet
          </h3>
          <CheckPermission
            resource={enums.Resource.DEAL_ORGANIZATIONS}
            action={enums.Action.UPDATE}
          >
            <Button
              onClick={handleAssignOrganization}
              color="success"
              className={classes.changeOrganizationButton}
              create
            >
              Assign Organization
            </Button>
          </CheckPermission>
        </div>
      )}
    </div>
  );
}
