import AssignmentIcon from "@material-ui/icons/Assignment";
import DataList from "components/DataList/DataList";
import api from "services/api";
import DealView from "views/Deals/pages/DealView/DealView";
import React from "react";
import CreateDealPage from "views/Deals/pages/CreateDealPage/CreateDealPage";
import translations from "constants/translations/index";
import i18n from "i18n";
import { dealStatusColors } from "constants/colors";
import { getDateString } from "utils/index";
import { getI18n } from "react-i18next";
import enums from "enums/index";

// appear in breadcrumbs and page headers
const listPageName = "Deals";
const createPageName = "Create Deal";
const viewPageName = "View Deal";

// appears on alerts; example: Entity created successfully
const entityName = "Deal";

const resource = enums.Resource.DEALS;

const layout = "/admin";

const path = "deals";

const dataResource = api.deals;

const columns = [
  {
    title: "Name",
    dataIndex: ["details", "name"],
  },
  {
    title: "CRM Identifier",
    dataIndex: ["details", "crmIdentifier"],
  },
  {
    title: "Organization",
    dataIndex: ["organization", "name"],
  },
  {
    title: "Expected Close Date",
    dataIndex: ["details", "expectedCloseDate"],
    getData: (date) => {
      return date ? getDateString(date) : "";
    },
  },
  {
    title: "Status",
    dataIndex: ["details", "status"],
    getData: (status) => {
      return (
        <div
          style={{
            color: dealStatusColors[status],
            fontWeight: 600,
          }}
        >
          {translations[i18n.language].dealStatus[status]}
        </div>
      );
    },
  },
];

const filterInputs = [
  {
    name: "details.status",
    label: "Status",
    type: "select",
    options: {
      data: Object.keys(translations[getI18n().language].dealStatus).map(
        (key) => ({
          value: key,
          name: translations[getI18n().language].dealStatus[key],
        })
      ),
      addAllChoice: true,
    },
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: AssignmentIcon,
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: false, delete: false, view: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      filterInputs={filterInputs}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => <CreateDealPage path={path} />,
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:id`,
      render: () => <DealView path={path} />,
      layout: layout,
      // randomKey: true,
      action: "read",
      getName: async (id) => {
        try {
          const data = (await dataResource.getOne(id)).data;
          return data.details.name;
        } catch {
          return viewPageName;
        }
      },
      resource,
    },
  ],
};
