import React from "react";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import api from "services/api";
import { getDateString } from "utils/index";
import trucksForm from "views/Common/trucksForm";

// appear in breadcrumbs and page headers
const listPageName = "Trucks";
const createPageName = "Create Truck";
const editPageName = "Edit Truck";

// appears on alerts; example: Entity created successfully
const entityName = "Truck";

const resource = enums.Resource.TRUCKS;

const layout = "/admin";

const path = "trucks";

const formStructure = trucksForm;

const dataResource = api.trucks;

const columns = [
  {
    title: "Type",
    dataIndex: ["type", "details", "name"],
  },
  {
    title: "License ID",
    dataIndex: "licenseId",
  },
  {
    title: "License Exp. Date",
    dataIndex: "licenseExpirationDate",
    getData: (value) => (value ? getDateString(value) : "N/A"),
  },
  {
    title: "Model Year",
    dataIndex: "modelYear",
  },
];

const filterInputs = [
  {
    name: "serviceProvider",
    label: "Service Provider",
    type: "autocomplete",
    options: {
      getData: api.serviceProviders.getAll,
      getOptionLabel: (option) => option?.name,
      addAllChoice: true,
    },
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: LocalShippingIcon,
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      filterInputs={filterInputs}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      resource,
    },
  ],
};
