import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loading from "components/Loading/Loading";
import Tabs from "components/Tabs/Tabs";
import enums from "enums/index";
import React from "react";
import PlanForm from "./components/PlanForm/PlanForm";
import styles from "./PlanDetails.styles";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { usePlanDetails } from "./PlanDetails.hook";
import { PlanDetailsContext } from "./PlanDetails.context";
import MonthlyOrders from "./components/MonthlyOrders/MonthlyOrders";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function PlanDetails() {
  const classes = useStyles();

  const {
    organization,
    isGetLoading,
    currentTab,
    setCurrentTab,
  } = usePlanDetails();

  return (
    <PlanDetailsContext.Provider
      value={{
        organization,
        currentTab,
        setCurrentTab,
      }}
    >
      <div className={classes.root}>
        <div className={classes.upperContainer}>
          <Breadcrumbs />
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card raised>
              <CardBody>
                <Loading loading={isGetLoading} style={{ height: "70vh" }}>
                  {organization ? (
                    <React.Fragment>
                      <div className={classes.pageHeader}>
                        <div className={classes.infoContainer}>
                          <div className={classes.infoWithIcon}>
                            <EventAvailableIcon className={classes.planIcon} />
                            <div className={classes.headerInfoContainer}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {`${organization?.name}`} &nbsp;
                              </div>
                              {/* <div className={classes.subtitle}>
                                {`${organization?.deal?.details.name}`}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Tabs
                        buttonsContainerClasses={classes.tabsContainer}
                        tabs={[
                          {
                            name: "New On-Demand Order",
                            component: <PlanForm isNewOrder />,
                            resource: enums.Resource.PLANS,
                          },
                          {
                            name: "Daily Plan",
                            component: <PlanForm />,
                            resource: enums.Resource.PLANS,
                          },
                          {
                            name: "Monthly Orders",
                            component: <MonthlyOrders />,
                            resource: enums.Resource.PLANS,
                          },
                        ]}
                        value={currentTab}
                        onChange={setCurrentTab}
                      />
                    </React.Fragment>
                  ) : (
                    <div>No available data</div>
                  )}
                </Loading>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </PlanDetailsContext.Provider>
  );
}
