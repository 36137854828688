const styles = (theme) => ({
  modalHeader: {
    width: "100%",
    position: "absolute",
  },
  modalBody: {
    padding: 4,
    maxHeight: 750,
    width: "70vw",
  },

  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    marginBottom: -10,
  },
  header: {
    margin: 0,
    marginLeft: 10,
  },
  tableContainer: {
    width: "100%",
    maxHeight: 600,
    overflowY: "scroll",
    marginTop: 0,
  },
  assignButton: {
    padding: "5px 15px",
    zIndex: 20,
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
});

export default styles;
