import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import translations from "constants/translations/index";
import enums from "enums/index";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import PerTripQuotationForm from "./PerTripQuotationForm/PerTripQuotationForm";
import styles from "./QuotationModal.styles";
import RentalQuotationForm from "./RentalQuotationForm/RentalQuotationForm";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function QuotationModal(props) {
  const { isOpen, onClose, quotation: quotationProp, type } = props;

  const classes = useStyles();

  const { i18n } = useTranslation();

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                {quotationProp ? (
                  <React.Fragment>
                    <EditIcon className={classes.icon} /> Edit Quotation
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <AddIcon className={classes.icon} />
                    {`Add ${
                      translations[i18n.language].quotationType[type]
                    } Quotation`}
                  </React.Fragment>
                )}
              </div>
              {quotationProp ? (
                <div className={classes.infoContainer}>
                  <InfoWithLabel
                    label="Quotation Type"
                    info={translations[i18n.language].quotationType[type]}
                  />
                </div>
              ) : null}
              {type === enums.QuotationType.PER_TRIP ? (
                <PerTripQuotationForm
                  quotation={quotationProp}
                  onClose={onClose}
                />
              ) : (
                <RentalQuotationForm
                  quotation={quotationProp}
                  onClose={onClose}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

QuotationModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

QuotationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
