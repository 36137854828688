import React from "react";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import enums from "enums/index";
import moment from "moment";
import { Link } from "react-router-dom";
import api from "services/api";
import { formatEnums, getDateString, isEmpty } from "utils/index";
import FinOpsEdit from "views/FinOps/FinOpsEdit/FinOpsEdit";
import FinOpsList from "views/FinOps/FinOpsList/FinOpsList";
import * as yup from "yup";

// appear in breadcrumbs and page headers
const listPageName = "Invoices";

const editPageName = "Edit Invoice";

// appears on alerts; example: Entity created successfully
const entityName = "Invoice";

const resource = enums.Resource.INVOICES;

const layout = "/admin";

const path = "invoices";

const dataResource = api.invoices;

const getColumns = (handleEditModalOpen) => [
  {
    title: "Invoice ID",
    dataIndex: "referenceNumber",
  },
  {
    title: "Invoice Name",
    dataIndex: "name",
  },
  {
    title: "Type",
    dataIndex: "type",
    getData: (value) => (value ? formatEnums(value) : "N/A"),
  },
  {
    title: "Total Price",
    dataIndex: "totalPrice",
  },
  {
    title: "No. of Tours",
    dataIndex: "numberOfTours",
  },
  {
    title: "Total Discount",
    dataIndex: "totalDiscount",
  },
  {
    title: "Net Price",
    dataIndex: "netPrice",
  },
  //   {
  //     title: "Last Update",
  //     dataIndex: "lastUpdateId",
  //   },
  {
    title: "Expected Invoice Date",
    dataIndex: "expectedInvoiceDate",
    getData: (value) => (value ? getDateString(value) : "N/A"),
  },
  {
    title: "",
    render: (_, record) => {
      return (
        <div style={{ display: "flex", width: "fit-content" }}>
          <CheckPermission resource={resource} action={enums.Action.READ}>
            <Link to={`/admin/invoices/${record?.id}/edit`}>
              <Button justIcon next color="white" textColor="primary" />
            </Link>
          </CheckPermission>
          <CheckPermission resource={resource} action={enums.Action.UPDATE}>
            <Button
              justIcon
              edit
              color="white"
              textColor="success"
              onClick={() => {
                handleEditModalOpen(record?.id);
              }}
            />
          </CheckPermission>
        </div>
      );
    },
  },
];

const getTotalDuration = (tasks) => {
  const validTasks =
    tasks?.filter((task) => task.startTime || task.endTime) || [];

  const firstTime = validTasks[0]?.startTime || validTasks[0]?.endTime;
  const lastTime =
    validTasks[validTasks.length - 1]?.endTime ||
    validTasks[validTasks.length - 1]?.startTime;
  let totalDuration = moment.duration();
  if (firstTime && lastTime) {
    const startTime = moment(firstTime);
    const endTime = moment(lastTime);
    const duration = moment.duration(endTime.diff(startTime));
    totalDuration.add(duration);
  }

  const days = totalDuration.days();
  const hours = totalDuration.hours();
  const minutes = totalDuration.minutes();

  return days || hours || minutes
    ? `${totalDuration.days()} d, ${totalDuration.hours()} h, ${totalDuration.minutes()} m`
    : "-";
};

const getFirstStartTime = (tasks) => {
  const firstStartTime = tasks[0]?.startTime
    ? new Date(tasks[0].startTime).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : null;
  return firstStartTime || "-";
};

const getLastEndTime = (tasks) => {
  const validTasks = tasks?.filter((task) => task.endTime);

  const lastEndTime = validTasks?.length
    ? new Date(validTasks?.[validTasks.length - 1].endTime).toLocaleTimeString(
        "en-US",
        {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      )
    : null;
  return lastEndTime || "-";
};

const editFormStructure = [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Invoice Name",
        validation: yup.mixed().required("Invoice name is required"),
      },
      {
        name: "type",
        type: "select",
        label: "Invoice Type",
        options: {
          enum: "invoiceType",
        },
        validation: yup.mixed().required("Invoice type is required"),
      },
      {
        name: "expectedInvoiceDate",
        type: "date",
        label: "Expected Invoice Date",
      },
      {
        name: "status",
        type: "select",
        label: "Invoice Status",
        options: {
          enum: "invoiceStatus",
        },
        validation: yup.mixed().required("Invoice status is required"),
      },
    ],
  },
];

const editColumns = [
  {
    title: "Tour ID",
    render: (_, record) => {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link
            to={`/admin/finops?organizationId=${record?.organizationId}&tourId=${record?.id}`}
            target="_blank"
          >
            <a style={{ color: "blue" }}>{record?.id}</a>
          </Link>
        </div>
      );
    },
  },
  {
    title: "Pickup Date",
    dataIndex: "pickupTime",
    getData: (value) => (value ? getDateString(value) : "N/A"),
  },
  {
    title: "Trip Type",
    dataIndex: "type",
    getData: (value) => (value ? formatEnums(value) : "N/A"),
  },
  {
    title: "Truck Type",
    dataIndex: ["truckType", "details", "name"],
  },
  {
    title: "Pickup Location",
    dataIndex: ["pickupLocation", "name"],
    canBeHighlighted: true,
  },
  {
    title: "Drop Off Location",
    dataIndex: ["dropOffLocation", "name"],
    canBeHighlighted: true,
  },
  {
    title: "Service Provider",
    dataIndex: ["serviceProvider", "name"],
  },
  {
    title: "Driver",
    dataIndex: ["driver", "name"],
    canBeHighlighted: true,
  },
  {
    title: "Truck",
    dataIndex: ["truck", "name"],
    canBeHighlighted: true,
  },

  {
    title: "Tour Aggregates",
    dataIndex: "tourAggregates",
    getData: (value) => (!isEmpty(value) ? value.toFixed(2) : "N/A"),
  },

  {
    title: "Services Price",
    dataIndex: "servicesPrice",
    getData: (value) => (!isEmpty(value) ? value.toFixed(2) : "N/A"),
  },
  {
    title: "Discounts Price",
    dataIndex: "discountsPrice",
    getData: (value) => (!isEmpty(value) ? value.toFixed(2) : "N/A"),
  },
  {
    title: "Net Price",
    dataIndex: "netPrice",
    getData: (value) => value.toFixed(2),
  },
  {
    title: "Total Duration",
    dataIndex: "tasks",
    getData: (value) => {
      return getTotalDuration(value);
    },
  },
  {
    title: "First Task Start Time",
    dataIndex: "tasks",
    getData: (value) => {
      return getFirstStartTime(value);
    },
  },
  {
    title: "Last Task End Time",
    dataIndex: "tasks",
    getData: (value) => {
      return getLastEndTime(value);
    },
  },
];

const infoColumns = [
  {
    title: "Expected Invoice Date",
    dataIndex: "expectedInvoiceDate",
    getData: (value) => (value ? getDateString(value) : "N/A"),
    editable: true,
    type: "date",
  },
  {
    title: "Client",
    dataIndex: "organization",
    getData: (value) => value?.name,
  },
  { title: "Invoice ID", dataIndex: "referenceNumber" },
  { title: "No.of Tours", dataIndex: "numberOfTours" },
  {
    title: "Status",
    dataIndex: "status",
    getData: (value) => (value ? formatEnums(value) : "N/A"),
  },
  {
    title: "Total Amount",
    dataIndex: "total",
    getData: (value) => (!isEmpty(value) ? value.toFixed(2) : "N/A"),
  },
  { title: "Discount", dataIndex: "deductions" },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "monetization_on",
  render: () => (
    <FinOpsList
      resource={resource}
      filters={Object.values(enums.InvoiceStatus).map((filter) => ({
        name: formatEnums(filter),
        value: filter,
      }))}
      apiRequest={api.organizations.getOrganizationsWithInvoicesCount}
      selectorKey="organizationId"
      getColumns={getColumns}
      entityName={entityName}
      dataResource={dataResource}
      editFormStructure={editFormStructure}
      path={path}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FinOpsEdit
          resource={resource}
          request={api.invoices.getInvoiceTours}
          updatesDataResource={api.invoiceUpdates}
          referenceKey="invoiceId"
          columns={editColumns}
          infoColumns={infoColumns}
        />
      ),
      layout: layout,
      // randomKey: true,
      action: "read",
      resource,
    },
  ],
};
