import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import styles from "./TourInfo.styles";
import useTourInfo from "./TourInfo.hook";
import React from "react";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import { formatEnums } from "utils/index";
import enums from "enums/index";

const useStyles = makeStyles(styles);

export default function TourInfo() {
  const {
    dealImage,
    currentOrg,
    currentTour,
    dealName,
    completedTasksCount,
    tasksCount,
    tourTime,
    tourDate,
    cancellationReason,
    serviceProviderCancellationReason,
    totalDuration,
    firstStartTime,
    lastEndTime,
  } = useTourInfo();

  const classes = useStyles();
  return (
    <Card raised>
      <CardBody>
        <CollapseContainer
          titleComponent={<h3 style={{ margin: 0 }}>Tour Info:</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem
              label="Client Name"
              info={currentOrg?.name}
            />
            <InfoWithLabelGridItem
              label="Tour Type"
              info={formatEnums(currentTour?.type)}
            />
            <InfoWithLabelGridItem
              label="Ordered Truck Type"
              info={currentTour?.truckType?.details?.name}
            />
          </GridContainer>

          <GridContainer className={classes.infoGridContainer}>
            <InfoWithLabelGridItem
              label="Completed Tasks"
              info={currentTour && `${completedTasksCount}/${tasksCount}`}
            />
            <InfoWithLabelGridItem
              label="Tour Status"
              info={formatEnums(currentTour?.status)}
            />

            <InfoWithLabelGridItem label="Tour ID" info={currentTour?.id} />
          </GridContainer>
          <GridContainer>
            <InfoWithLabelGridItem label="Tour Date" info={tourDate} />
            <InfoWithLabelGridItem label="Tour Time" info={tourTime} />
            <InfoWithLabelGridItem label="Deal" info={dealName} />
          </GridContainer>
          <GridContainer className={classes.infoGridContainer}>
            <InfoWithLabelGridItem
              label="Tour Cancellation Reason"
              info={cancellationReason}
            />
            <InfoWithLabelGridItem
              label="Service Provider Cancellation Reason"
              info={serviceProviderCancellationReason}
            />
            <InfoWithLabelGridItem
              label="Deal Image"
              info={dealImage}
              type={enums.InfoType.FILE}
            />
          </GridContainer>
          <GridContainer className={classes.infoGridContainer}>
            <InfoWithLabelGridItem label="Notes" info={currentTour?.notes} />
            <InfoWithLabelGridItem
              label="Total Duration"
              info={totalDuration}
            />
            <InfoWithLabelGridItem
              label="First Task Start Time"
              info={firstStartTime}
            />
          </GridContainer>
          <GridContainer className={classes.infoGridContainer}>
            <InfoWithLabelGridItem label="Last End Time" info={lastEndTime} />
          </GridContainer>
        </CollapseContainer>
      </CardBody>
    </Card>
  );
}
