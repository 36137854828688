import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import GridContainer from "components/Grid/GridContainer.js";
import Loading from "components/Loading/Loading";
import { useTruckTypeForm } from "./TruckTypeForm.hooks";
import styles from "./TruckTypeForm.styles";
import React from "react";
import enums from "enums";
import CustomModal from "components/CustomModal/CustomModal";
import { Breadcrumbs } from "@material-ui/core";
import Card from "components/Card/Card";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function TruckTypeForm(props) {
  const { edit, create, path, formType, isOpen, onClose, dealTruck } = props;

  const classes = useStyles();

  const { isLoading, isGetLoading, form, onSubmit, data } = useTruckTypeForm({
    edit,
    create,
    path,
    dealTruck,
    isOpen,
    onClose,
    formType,
  });

  const formBody = (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card
          style={
            formType === enums.FormContainerType.MODAL ? { margin: 0 } : {}
          }
          raised={formType === enums.FormContainerType.Page}
          className={classes.cardRoot}
        >
          <CardBody>
            <div className={classes.formHeader}>
              {create ? (
                <AddIcon className={classes.icon} />
              ) : (
                <EditIcon className={classes.icon} />
              )}
              {edit ? (
                isGetLoading ? (
                  <Skeleton variant="rect" height={25} width={250} />
                ) : (
                  `Edit ${data?.details?.name}`
                )
              ) : (
                `Create Truck Type`
              )}
            </div>
            <Loading loading={isGetLoading} style={{ height: "60vh" }}>
              <form
                style={{ padding: 40, paddingBottom: 0 }}
                onSubmit={onSubmit}
                autoComplete="off"
              >
                <div className={classes.section}>
                  <div className={classes.section}>
                    <h3>Truck Specifications</h3>
                    <GridContainer>
                      <FormFieldGridItem
                        name="details.nameEn"
                        type="text"
                        label="Name (English)"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="details.nameAr"
                        type="text"
                        label="Name (Arabic)"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="specs.weightAllowanceTons"
                        type="number"
                        label="Weight Allowance (Tons)"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="specs.boxHeight"
                        type="number"
                        label="Box Height"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="specs.boxWidth"
                        type="number"
                        label="Box Width"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="specs.boxLength"
                        type="number"
                        label="Box Length"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="specs.volumeCapacityRatio"
                        type="number"
                        label="Volume Capacity Ratio"
                        form={form}
                      />
                      {data ? (
                        <InfoWithLabelGridItem
                          label="Volume (Height * Width * Length * Volume Capacity Ratio)"
                          info={
                            data.specs?.volume
                              ? parseFloat(data.specs.volume.toFixed(2))
                              : 0
                          }
                        />
                      ) : null}
                    </GridContainer>
                  </div>
                  <div className={classes.section}>
                    <h3>Truck Pricing Model Attributes</h3>
                    <GridContainer>
                      <FormFieldGridItem
                        name="pricingInfo.truckAvailability"
                        label="Truck Availability"
                        form={form}
                        type="select"
                        options={{
                          enum: "truckAvailability",
                        }}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.truckAssetPrice"
                        type="number"
                        label="Truck Asset Price"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.boxAssetPrice"
                        type="number"
                        label="Box Asset Price"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.refUnitPrice"
                        type="number"
                        label="Ref Unit Price"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.yearlyLicensesFees"
                        type="number"
                        label="Yearly Licenses Fees"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.truckTireCount"
                        type="number"
                        label="Truck Tire Count"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.tireKmLimit"
                        type="number"
                        label="Tire Km Limit"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.truckOilConsumptionPerKm"
                        type="number"
                        label="Truck Oil Consumption Per Km"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.truckGasConsumptionPerKm"
                        type="number"
                        label="Truck Gas Consumption Per Km"
                        form={form}
                      />
                      <FormFieldGridItem
                        type="select"
                        form={form}
                        name="details.gasType"
                        label="Gas Type"
                        options={{
                          enum: "gasType",
                        }}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.truckMaintenanceCostPerMonth"
                        type="number"
                        label="Truck Maintenance Cost Per Month"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.tireCost"
                        type="number"
                        label="Tire Cost"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.driverDailySalary"
                        type="number"
                        label="Driver Daily Salary"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.truckOwnerProfitPerDay"
                        type="number"
                        label="Truck Owner Profit Per Day"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.contractorProfitPerDay"
                        type="number"
                        label="Contractor Profit Per Day"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.averageMonthlyDistance"
                        type="number"
                        label="Average Monthly Distance"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.marketAssetDepreciationInYears"
                        type="number"
                        label="Market Asset Depreciation In Years"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="pricingInfo.travelFees"
                        type="number"
                        label="Travel Fees"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="rental.details.baseMonthlyFactor"
                        type="number"
                        label="Base Monthly Factor"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="perTrip.details.intracityReturnDistance"
                        type="number"
                        label="Intracity Return Distance"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="details.targetCostMargin"
                        type="number"
                        label="Target Cost Margin (0-1)"
                        form={form}
                      />
                      <FormFieldGridItem
                        name="details.targetPriceMargin"
                        type="number"
                        label="Target Price Margin (0-1)"
                        form={form}
                      />
                    </GridContainer>
                  </div>
                  <h3>Truck General Attributes</h3>
                  <GridContainer>
                    <FormFieldGridItem
                      name="details.onDemandCancellationNoticePeriod"
                      type="number"
                      label="On-demand Cancellation Notice Period (hours)"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="details.cancellationPriceAfterNoticePeriod"
                      type="number"
                      label="Cancellation Price After Notice Period"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="details.extraKmPrice"
                      type="number"
                      label="Extra Km Price"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="details.extraKmTargetCost"
                      type="number"
                      label="Extra Km Target Cost"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="details.extraHourPrice"
                      type="number"
                      label="Extra Hour Price"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="details.extraHourTargetCost"
                      type="number"
                      label="Extra Hour Target Cost"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="details.offDayFixedPrice"
                      type="number"
                      label="Off-day Fixed Price"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="details.offDayFixedTargetCost"
                      type="number"
                      label="Off-day Fixed Target Cost"
                      form={form}
                    />
                  </GridContainer>
                </div>
                <div className={classes.section}>
                  <h3>Truck Per Trip Attributes</h3>
                  <GridContainer>
                    <FormFieldGridItem
                      name="perTrip.details.tripKmAllowancePct"
                      type="number"
                      label="Trip Km Allowance (0 ➔ 1)"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="perTrip.details.tripDurationAllowancePct"
                      type="number"
                      label="Trip Duration Allowance (hrs)"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="perTrip.details.extraTaskKmAllowance"
                      type="number"
                      label="Extra Task Km Allowance (km)"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="perTrip.details.extraTaskTripDurationAllowance"
                      type="number"
                      label="Extra Task Trip Duration Allowance (hrs)"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="perTrip.details.taskLimit"
                      type="number"
                      label="Task Limit"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="perTrip.details.extraTaskPrice"
                      type="number"
                      label="Extra Task Price"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="perTrip.details.extraTaskTargetCost"
                      type="number"
                      label="Extra Task Target Cost"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="perTrip.details.returnTaskPrice"
                      type="number"
                      label="Return Task Price (0 ➔ 1)"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="perTrip.details.returnTaskTargetCost"
                      type="number"
                      label="Return Task Target Cost (0 ➔ 1)"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="perTrip.details.layoverTaskPrice"
                      type="number"
                      label="Layover Task Price"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="perTrip.details.layoverTaskTargetCost"
                      type="number"
                      label="Layover Task Target Cost"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="perTrip.details.travelOvernightPrice"
                      type="number"
                      label="Travel Overnight Price"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="perTrip.details.travelOvernightTargetCost"
                      type="number"
                      label="Travel Overnight Target Cost"
                      form={form}
                    />
                  </GridContainer>
                </div>
                <div className={classes.section}>
                  <h3>Rental Attributes</h3>
                  <GridContainer>
                    <FormFieldGridItem
                      name="rental.details.idleTripLimit"
                      type="number"
                      label="Idle Trip Limit"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="rental.details.idleDiscountPricePerDay"
                      type="number"
                      label="Idle Discount Price Per Day (0 → 1)"
                      form={form}
                    />
                    <FormFieldGridItem
                      name="rental.details.idleDiscountTargetCostPerDay"
                      type="number"
                      label="Idle Discount Target Cost Per Day (0 → 1)"
                      form={form}
                    />
                  </GridContainer>
                </div>
                {data && (
                  <div className={classes.section}>
                    <h3>Computed Attributes</h3>
                    <GridContainer>
                      <InfoWithLabelGridItem
                        label="Base Opening Price"
                        info={data.computed?.baseOpeningPrice}
                      />
                      <InfoWithLabelGridItem
                        label="Average Deprecation Per Km"
                        info={data.computed?.averageDeprecationPerKm}
                      />
                      <InfoWithLabelGridItem
                        label="Base Price Per Km"
                        info={data.computed?.basePricePerKm}
                      />
                      <InfoWithLabelGridItem
                        label="Gas Cost Per Km"
                        info={data.computed?.gasCostPerKm}
                      />
                      <InfoWithLabelGridItem
                        label="Oil Cost Per Km"
                        info={data.computed?.oilCostPerKm}
                      />
                      <InfoWithLabelGridItem
                        label="Tire Cost Per Km"
                        info={data.computed?.tireCostPerKm}
                      />
                      <InfoWithLabelGridItem
                        label="Maintenance Cost Per Km"
                        info={data.computed?.maintenanceCostPerKm}
                      />
                      <InfoWithLabelGridItem
                        label="Truck Licenses Daily Fees"
                        info={data.computed?.truckLicensesDailyFees}
                      />
                      <InfoWithLabelGridItem
                        label="Truck Total Asset Price"
                        info={data.computed?.truckTotalAssetPrice}
                      />
                    </GridContainer>
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    style={{ margin: "20px 0" }}
                    create={create}
                    edit={edit}
                    loadFromRight
                  />
                </div>
              </form>
            </Loading>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  return formType === enums.FormContainerType.MODAL ? (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      {formBody}
    </CustomModal>
  ) : (
    <div>
      <Breadcrumbs />
      {formBody}
    </div>
  );
}

TruckTypeForm.defaultProps = {
  formType: enums.FormContainerType.PAGE,
  isOpen: false,
};

TruckTypeForm.propTypes = {
  path: PropTypes.string,
  edit: PropTypes.bool,
  create: PropTypes.bool,
  formType: PropTypes.oneOf([
    enums.FormContainerType.PAGE,
    enums.FormContainerType.MODAL,
  ]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  dealTruck: PropTypes.object,
};
