import { makeStyles } from "@material-ui/core/styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import enums from "enums/index";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter, zeroPadding } from "utils/index";

import CustomInput from "components/CustomInput/CustomInput";
import SearchIcon from "@material-ui/icons/Search";

import useFinOpsList from "./FinOpsList.hook";
import FilterSelector from "views/FinOps/FinOpsList/components/FilterSelector/FilterSelector";
import styles from "./FinOpsList.styles";
import { getI18n } from "react-i18next";
import FormContainer from "components/Forms/FormContainer/FormContainer";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function FinOpsList(props) {
  const classes = useStyles();

  const {
    resource,
    getColumns,
    filters,
    apiRequest: selectionListRequest,
    selectorKey,
    entityName,
    editFormStructure,
    dataResource,
    path,
  } = props;

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    selectionList,
    selectorSearch,
    editItemId,
    isEditModalOpen,
    handleSelector,
    handleStatusSelect,
    handleSelectorSearch,
    getSelectionData,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    handleEditModalClose,
    handleEditModalOpen,
  } = useFinOpsList({
    resource,
    filterInputs,
    selectorKey,
    selectionListRequest,
  });

  const filterInputs = React.useMemo(() => {
    return undefined;
  }, []);

  return (
    <div>
      <div className={classes.topContainer} />
      <FilterSelector
        options={filters}
        onSelect={handleStatusSelect}
        horizontal
        tabs
      />

      <GridContainer>
        <GridItem xs={10} sm={10} md={10}>
          <CustomTable
            columns={getColumns(handleEditModalOpen)}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
              inputs: filterInputs,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title={`${capitalizeFirstLetter(resource.toLowerCase())} Summary`}
            addIndexColumn
          />
        </GridItem>
        <GridItem xs={2} sm={2} md={2}>
          <div className={classes.searchContainer}>
            <CustomInput
              formControlProps={{ className: classes.search }}
              inputProps={{
                placeholder: "Search",
                inputProps: { "aria-label": "Search" },
                value: selectorSearch,
                onChange: handleSelectorSearch,
              }}
              variant="none"
            />
            <SearchIcon
              onClick={getSelectionData}
              className={classes.searchIcon}
            />
          </div>
          <FilterSelector
            options={selectionList.map((item) => ({
              ...(resource
                ? {
                    id: `${
                      resource === enums.Resource.INVOICES
                        ? "ORG"
                        : resource === enums.Resource.BILLS
                        ? "SP"
                        : ""
                    }#${zeroPadding(item.id)}`,
                  }
                : {}),
              name: item.name,
              value: item.id,
            }))}
            onSelect={handleSelector}
            tabs
            hasSearch
          />
        </GridItem>
      </GridContainer>
      <FormContainer
        entityName={entityName}
        dataResource={dataResource}
        formStructure={editFormStructure}
        path={path}
        type={enums.FormContainerType.MODAL}
        isOpen={isEditModalOpen}
        edit
        onClose={handleEditModalClose}
        editItemId={editItemId}
      />
    </div>
  );
}

FinOpsList.propTypes = {
  resource: PropTypes.string,
};
