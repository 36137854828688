import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { getDaysLimit, toastError } from "utils/index";
import CourierRentalFormValidation from "./CourierRentalForm.validation";
import enums from "enums/index";
import { CourierRentalContext } from "views/CourierRentals/pages/CourierRentalView/CourierRentalView.context";
import { getI18n } from "react-i18next";
import translations from "constants/translations";

//-------------------------------------------------------------------------------------

export const useCourierRentalForm = ({
  edit,
  setIsEditing,
  path: pathProp,
}) => {
  const { path: pathContext, setCourierRental: setContext } = React.useContext(
    CourierRentalContext
  );

  const [courierRental, setCourierRental] = React.useState(null);

  const path = pathProp || pathContext;

  const [sendGetRequest, isGetRentalLoading] = useHttpClient();
  const [sendRequest, isLoading, sendRequestError] = useHttpClient();

  const history = useHistory();

  const dataResource = React.useMemo(() => api.courierRentals, []);

  React.useEffect(() => {
    (async () => {
      if (edit) {
        const id = window.location.pathname.split("/").pop().trim();
        if (id && !isNaN(id)) {
          try {
            const response = await sendGetRequest(dataResource.getOne(id));
            const processedResponse = {
              ...response,
              offDays: response?.offDays?.map((day) => ({
                id: `${day}`,
                name: translations[getI18n().language].weekDay[day],
              })),
            };
            setCourierRental(processedResponse);
          } catch (err) {
            toast.error("An error has occurred");
            setIsEditing(false);
          }
        } else {
          toast.error("An error has occurred");
          setIsEditing(false);
        }
      } else {
        setValue("status", enums.RentalDealStatus.PLANNED);
      }
    })();
  }, [edit, setIsEditing, dataResource]);

  React.useEffect(() => {
    if (sendRequestError?.message) {
      toastError(sendRequestError.message);
    }
  }, [sendRequestError]);

  React.useEffect(() => {
    (async () => {
      const tempRental = { ...courierRental };
      if (edit && courierRental) {
        Object.keys(tempRental).forEach((key) => {
          if (key !== "deletedAt" && key !== "updatedAt" && key !== "createdAt")
            setValue(key, tempRental[key]);
        });
      }
    })();
  }, [edit, courierRental]);

  const resolver = useYupValidationResolver(CourierRentalFormValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      status: enums.RentalDealStatus.PLANNED,
    },
  });

  const { handleSubmit, setValue, getValues, watch } = form;
  const couriers = watch("serviceProvider")?.couriers;
  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const offDays = watch("offDays");

  useEffect(() => {
    if (!startDate || !endDate || !offDays) return;
    const offDaysArray = offDays?.map((offDay) => parseInt(offDay.id));
    const count = getDaysLimit(
      startDate.split("T")[0],
      endDate.split("T")[0],
      offDaysArray
    );
    setValue("workingDays", count);
  }, [startDate, endDate, offDays]);

  const createHandler = (requestData) => async () => {
    try {
      const response = await sendRequest(
        dataResource.create({
          ...requestData,
        })
      );
      toast.success("Courier Rental created successfully");
      history.push({
        pathname: `/admin/${path}/${response.id}`,
      });
    } catch (err) {
      history.push({
        pathname: `/admin/${path}`,
      });
    }
  };

  const editHandler = (requestData) => async () => {
    try {
      const response = await sendRequest(
        dataResource.patch({
          ...requestData,
        })
      );
      setContext({ ...response });
      toast.success("Courier rental edited successfully");
      setIsEditing(false);
    } catch (err) {
      setIsEditing(false);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors above and submit again.");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let values = { ...getValues() };

    if (values?.serviceProvider?.id) {
      values.serviceProviderId = values.serviceProvider.id;
      delete values.serviceProvider;
    }
    if (values?.courier?.id) {
      values.courierId = values.courier.id;
      delete values.courier;
    }

    if (values?.offDays) {
      values.offDays = values.offDays.map((day) => parseInt(day.id));
    }
    if (!values?.offDays) {
      values.offDays = [];
    }

    handleSubmit(edit ? editHandler(values) : createHandler(values), onError)();
  };
  return {
    isLoading,
    isGetRentalLoading,
    onSubmit,
    form,
    courierRental,
    couriers,
  };
};
