import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const styles = (theme) => ({
  list: { listStyleType: "none", padding: 0 },
  tabsList: {},
  listItem: {
    margin: "4px 0px 8px 0px",
    font: "inherit",
    color: "#007FFF",
    display: "block",
    "-webkit-text-decoration": "none",
    textDecoration: "none",
    fontWeight: 700,
    "-webkit-align-items": "center",
    "-webkit-box-align": "center",
    "-ms-flex-align": "center",
    color: "#0072E5",
    fontSize: "0.8125rem",
    padding: "0px 8px 0px 8px",
    borderLeft: "1px solid transparent",
    boxSizing: "border-box",
    fontWeight: 500,
    color: "#1C2025",
    "&:hover": {
      borderLeftColor: "#B0B8C4",
      color: "#6B7A90",
    },
  },
  tabListItem: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
    height: 80,
    "&:hover": {
      borderLeftColor: "transparent",
      color: "#6B7A90",
      backgroundColor: "#D3D3D3",
      borderRadius: 5,
    },
  },
  tabListItemSelected: {
    backgroundColor: primaryColor[0],
    color: "#FFF",
    height: 80,
    borderRadius: 5,
    "&:hover": {
      backgroundColor: primaryColor[0],
      color: "#FFF",
    },
  },
  listItemSelected: {
    margin: "4px 0px 8px",
    borderLeft: "1px solid rgb(153, 204, 243)",
    color: "rgb(0, 114, 229)",
    "&:hover": {
      borderLeftColor: "rgb(0, 114, 229)",
      color: "rgb(0, 114, 229)",
    },
  },
});

export default styles;
