import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import GridContainer from "components/Grid/GridContainer.js";
import Loading from "components/Loading/Loading";
import enums from "enums";
import colors from "enums/colors.enum";
import PropTypes from "prop-types";
import { formatEnums } from "utils";
import { useTrucksForm } from "./TrucksForm.hooks";
import api from "services/api";
import styles from "./TrucksForm.styles";

//------------------------------------------------------------------------------

function formatTruckTypeName(nameEn, nameAr) {
  return nameEn && nameAr ? `${nameEn} - ${nameAr}` : nameEn ? nameEn : nameAr;
}

const useStyles = makeStyles(styles);

export default function TrucksForm(props) {
  const { edit, create, deal } = props;

  const {
    isLoading,
    isGetLoading,
    form,
    data,
    type,
    serviceProvider,
    onSubmit,
  } = useTrucksForm(props);

  const classes = useStyles();

  return (
    <Card className={classes.cardRoot}>
      <CardBody>
        <div className={classes.formHeader}>
          {create ? (
            <AddIcon className={classes.icon} />
          ) : (
            <EditIcon className={classes.icon} />
          )}
          {edit ? (
            isGetLoading ? (
              <Skeleton variant="rect" height={25} width={250} />
            ) : (
              `Edit ${data?.name}`
            )
          ) : (
            `Create Truck`
          )}
        </div>

        <Loading loading={isGetLoading} style={{ height: "60vh" }}>
          <form
            style={{ padding: 40, paddingBottom: 0 }}
            onSubmit={onSubmit}
            autoComplete="off"
          >
            <div className={classes.section}>
              <GridContainer>
                <FormFieldGridItem
                  name="type"
                  type="autocomplete"
                  label="Type"
                  options={{
                    getData: api.truckTypes.getAll,
                    getOptionLabel: (option) =>
                      formatTruckTypeName(
                        option?.details?.nameEn,
                        option?.details?.nameAr
                      ),
                    getSubmitValue: (option) => option?.id,
                    disabled: !!type,
                  }}
                  form={form}
                />
                <FormFieldGridItem
                  type="select"
                  name="color"
                  label="Color"
                  options={{
                    data: Object.keys(colors).map((key) => ({
                      value: colors[key],
                      name: formatEnums(key),
                    })),
                  }}
                  form={form}
                />
                <FormFieldGridItem
                  name="modelYear"
                  type="text"
                  label="Model Year"
                  form={form}
                />
                <FormFieldGridItem
                  type="select"
                  name="status"
                  label="Status"
                  options={{
                    data: Object.keys(enums.TruckStatus).map((key) => ({
                      value: enums.TruckStatus[key],
                      name: formatEnums(key),
                    })),
                  }}
                  form={form}
                />
                <FormFieldGridItem
                  name="licenseId"
                  type="text"
                  label="License ID"
                  form={form}
                />
                <FormFieldGridItem
                  name="licenseExpirationDate"
                  type="date"
                  label="License Exp. Date"
                  form={form}
                />
                <FormFieldGridItem
                  name="licenseAttachment"
                  type="dropzone"
                  label="License Attachment"
                  options={{ maxFiles: 1 }}
                  form={form}
                />

                <FormFieldGridItem
                  name="serviceProviders"
                  type="autocomplete"
                  label="Service Providers"
                  options={{
                    getData: api.serviceProviders.getAll,
                    getOptionLabel: (option) => option.name,
                    getSubmitValue: (options) =>
                      options?.map((option) => option?.id),
                    multiple: true,
                    blurOnSelect: false,
                    disabled: !!serviceProvider,
                  }}
                  form={form}
                />
              </GridContainer>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                type="submit"
                loading={isLoading}
                style={{ margin: "20px 0" }}
                create={create}
                edit={edit}
                loadFromRight
              />
            </div>
          </form>
        </Loading>
      </CardBody>
    </Card>
  );
}

TrucksForm.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};
