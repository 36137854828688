import { useCallback, useContext, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { PlanFormContext } from "../../PlanForm.context";

export const useToursList = () => {
  const { form, defaultTour, isNewOrder } = useContext(PlanFormContext);

  const [collapseAll, setCollapseAll] = useState(!isNewOrder);

  const { setValue, control } = form;

  const { fields: tours, append, remove } = useFieldArray({
    control,
    name: "tours",
  });

  const onAdd = useCallback(() => {
    append({ ...defaultTour });
  }, [defaultTour, append]);

  const onRemove = useCallback(
    (index) => {
      remove(index);
    },
    [remove]
  );

  const handleChange = useCallback(
    (value, key, index) => {
      setValue(`tours.${index}.${key}`, value);
    },
    [setValue]
  );

  return {
    tours,
    isNewOrder,
    collapseAll,
    onAdd,
    onRemove,
    handleChange,
    setCollapseAll,
  };
};
