const styles = (theme) => ({
  button: {
    height: "fit-content",
    padding: "7px 40px",
    borderRadius: 5,
    margin: "0 0 20px 0",
    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      width: "60%",
      padding: "7px 10px",
      order: 0,
    },
  },
  buttonContainer: {
    marginLeft: "3px",
  },
  modalHeader: { width: "100%", position: "absolute" },
  modalBody: { padding: 0, maxWidth: 500 },
  formHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 400,
    padding: 20,
    paddingBottom: 20,
    borderBottom: "1px solid #ddd",
    // margin: "0px -20px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  form: { padding: "20px 20px 0 20px" },
  proceedButton: { margin: "0 0 20px" },
  confirmButton: { margin: "20px 0" },
  cancelButton: { margin: "20px 5px" },
  modalButtonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  tooltip: {
    transform: "translateY(-20px) !important",
  },
});

export default styles;
