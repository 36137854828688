import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import GridContainer from "components/Grid/GridContainer.js";
import Loading from "components/Loading/Loading";
import translations from "constants/translations/index";
import PropTypes from "prop-types";
import { getI18n } from "react-i18next";
import api from "services/api";
import contactsForm from "views/Organizations/contactsForm";
import locationsForm from "views/Zoning/locationsForm";
import { useOrganizationsForm } from "./OrganizationForm.hooks";
import styles from "./OrganizationForm.styles";
import LocationsForm from "views/Zoning/Locations/LocationsForm";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrganizationsForm(props) {
  const { edit, create, deal } = props;

  const {
    isLoading,
    isGetLoading,
    form,
    data,
    locations,
    typeId,
    watch,
    handleAddNewLocation,
    onSubmit,
  } = useOrganizationsForm(props);

  const classes = useStyles();

  return (
    <Card className={classes.cardRoot}>
      <CardBody>
        <div className={classes.formHeader}>
          {create ? (
            <AddIcon className={classes.icon} />
          ) : (
            <EditIcon className={classes.icon} />
          )}
          {edit ? (
            isGetLoading ? (
              <Skeleton variant="rect" height={25} width={250} />
            ) : (
              `Edit ${data?.name}`
            )
          ) : (
            `Create Organization`
          )}
        </div>

        <Loading loading={isGetLoading} style={{ height: "60vh" }}>
          <form
            style={{ padding: 40, paddingBottom: 0 }}
            onSubmit={onSubmit}
            autoComplete="off"
          >
            <div className={classes.section}>
              <GridContainer>
                <FormFieldGridItem
                  name="name"
                  type="text"
                  label="Name"
                  form={form}
                />
                <FormFieldGridItem
                  name="officialName"
                  type="text"
                  label="Official Name"
                  form={form}
                />
                <FormFieldGridItem
                  type="select"
                  name="organizationClassification"
                  label="Organization Classification"
                  options={{
                    data: Object.keys(
                      translations[getI18n().language]
                        .organizationClassification
                    ).map((key) => ({
                      value: key,
                      name:
                        translations[getI18n().language]
                          .organizationClassification[key],
                    })),
                  }}
                  form={form}
                />
                <FormFieldGridItem
                  type="select"
                  name="organizationSize"
                  label="Organization Size"
                  options={{
                    data: Object.keys(
                      translations[getI18n().language].organizationSize
                    ).map((key) => ({
                      value: key,
                      name:
                        translations[getI18n().language].organizationSize[key],
                    })),
                  }}
                  form={form}
                />
                <FormFieldGridItem
                  name="locations"
                  type="autocomplete"
                  label="Locations"
                  options={{
                    data: locations,
                    getOptionLabel: (option) => option.name,
                    getSubmitValue: (options) =>
                      options?.map((option) => option?.id),
                    multiple: true,
                    addNew: true,
                    formComponent: (
                      <LocationsForm
                        handleAddNew={handleAddNewLocation}
                        create
                      />
                    ),
                    freeSolo: true,
                    dataResource: api.locations,
                    blurOnSelect: false,
                  }}
                  form={form}
                />
                <FormFieldGridItem
                  name="taxId"
                  type="text"
                  label="Tax ID"
                  form={form}
                />
                <FormFieldGridItem
                  name="commercialRegistrationNumber"
                  type="text"
                  label="Commercial Registration Number"
                  form={form}
                />
                <FormFieldGridItem
                  name="category"
                  type="autocomplete"
                  label="Category"
                  options={{
                    getData: api.categories.getAll,
                    getOptionLabel: (option) => option.name,
                    getSubmitValue: (option) => option?.id,
                  }}
                  form={form}
                />
                <FormFieldGridItem
                  name="industries"
                  type="autocomplete"
                  label="Industry"
                  options={{
                    getData: api.industries.getAll,
                    getOptionLabel: (option) => option.name,
                    getSubmitValue: (options) =>
                      options?.map((option) => option?.id),
                    multiple: true,
                    blurOnSelect: false,
                  }}
                  form={form}
                />
                <FormFieldGridItem
                  name="contacts"
                  type="autocomplete"
                  label="Contacts"
                  options={{
                    getData: api.contacts.getAll,
                    getOptionLabel: (option) => option.name,
                    getSubmitValue: (options) =>
                      options?.map((option) => option?.id),
                    multiple: true,
                    addNew: true,
                    formStructure: contactsForm.create,
                    freeSolo: true,
                    dataResource: api.contacts,
                    blurOnSelect: false,
                  }}
                  form={form}
                />
                <FormFieldGridItem
                  type="select"
                  name="status"
                  label="Status"
                  options={{
                    data: Object.keys(
                      translations[getI18n().language].organizationStatus
                    ).map((key) => ({
                      value: key,
                      name:
                        translations[getI18n().language].organizationStatus[
                          key
                        ],
                    })),
                  }}
                  form={form}
                />
                <FormFieldGridItem
                  name="taxDocumentUrl"
                  type="dropzone"
                  label="Tax Document"
                  options={{ maxFiles: 1 }}
                  form={form}
                />
                <FormFieldGridItem
                  name="commercialRegistrationDocumentUrl"
                  type="dropzone"
                  label="Commercial Registration Document"
                  options={{ maxFiles: 1 }}
                  form={form}
                />
              </GridContainer>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                type="submit"
                loading={isLoading}
                style={{ margin: "20px 0" }}
                create={create}
                edit={edit}
                loadFromRight
              />
            </div>
          </form>
        </Loading>
      </CardBody>
    </Card>
  );
}

OrganizationsForm.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};
