import api from "services/api";
import * as yup from "yup";
import districtsForm from "./districtsForm";

export default [
  {
    column: 1,
    fields: [
      {
        name: "nameEn",
        type: "text",
        label: "Name (English)",
        validation: yup.string().required("Required"),
      },
      {
        name: "nameAr",
        type: "text",
        label: "Name (Arabic)",
        validation: yup.string().required("Required"),
      },
      {
        name: "lat",
        type: "text",
        label: "latitude",
        validation: yup
          .string()
          .required("Required")
          .test(
            "is-valid-latitude",
            "Latitude must be a number between -90 and 90",
            (value) => {
              const number = parseFloat(value);
              return !isNaN(number) && Math.abs(number) <= 90;
            }
          ),
      },
      {
        name: "lng",
        type: "text",
        label: "longitude",
        validation: yup
          .string()
          .required("Required")
          .test(
            "is-valid-longitude",
            "Longitude must be a number between -180 and 180",
            (value) => {
              const number = parseFloat(value);
              return !isNaN(number) && Math.abs(number) <= 180;
            }
          ),
      },
      {
        name: "districtIds",
        fetchedName: "districts",
        type: "autocomplete",
        label: "District",
        options: {
          getData: api.districts.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (districts) =>
            districts?.map((district) => district?.id),
          addNew: true,
          formStructure: districtsForm,
          freeSolo: true,
          dataResource: api.districts,
          multiple: true,
          blurOnSelect: false,
        },
        validation: yup.mixed().required("Required"),
      },
    ],
  },
];
