import { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import AddDiscountModalValidation from "./AddDiscountModal.validation";
import enums from "enums/index";
import { FinOpsContext } from "views/TourOperations/Components/FinOps/FinOps.context";

export default function useAddDiscountModal({ currentTour, onClose }) {
  const resolver = useYupValidationResolver(AddDiscountModalValidation);
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });
  const [sendRequest, isLoading] = useHttpClient(true);

  const { getTour, invoices, setInvoices } = useContext(TourOperationsContext);
  const { getTourUpdate } = useContext(FinOpsContext);

  const { handleSubmit, getValues, reset, setValue } = form;

  const onSubmit = (values) => async () => {
    try {
      if (!currentTour) {
        toast.error("no current tour");
        return;
      }
      const newDiscount = {
        tourId: currentTour.id,
        invoiceId: values.invoice.id,
        ...values,
      };
      delete newDiscount["invoice"];
      await sendRequest(api.discounts.create(newDiscount));
      toast.success("Discount Added Successfully");
      reset();
      onClose();
      getTourUpdate();
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const handleAddNewInvoice = async (invoice) => {
    setInvoices([...invoices, invoice]);
    setValue(`invoice`, invoice);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    form,
    submitHandler,
    handleAddNewInvoice,
    invoices,
  };
}
