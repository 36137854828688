import React from "react";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./DiscountsTable.styles";
import DiscountRow from "./Components/DiscountRow/DiscountRow";
import AddDiscountModal from "./Components/AddDiscountModal/AddDiscountModal";
import useDiscountsTable from "./DiscountsTable.hook";

const useStyles = makeStyles(styles);

export default function DiscountsTable({
  currentTour,
  form,
  tourReviewed,
  tourReviewable,
}) {
  const discountsElements = [];
  const classes = useStyles();

  const {
    onRemove,
    discounts,
    isModalOpen,
    onModalClose,
    onModalOpen,
  } = useDiscountsTable(form, currentTour);

  if (discounts?.length) {
    discounts.forEach((discount, index) => {
      discountsElements.push(
        <DiscountRow
          tourReviewed={tourReviewed}
          tourReviewable={tourReviewable}
          onRemove={onRemove}
          key={discount.id}
          index={index}
          data={discount}
          form={form}
        />
      );
    });
  }
  return (
    <div>
      <div className={classes.buttonGroup}>
        <Button
          onClick={() => {
            onModalOpen();
          }}
          className={classes.addContactButton}
          disabled={tourReviewed}
          color="primary"
        >
          Add Discount
        </Button>
      </div>
      <Card raised style={{ margin: 0 }}>
        <CardBody noPadding>
          {discountsElements.length > 0 && (
            <table>
              <tr>
                <th
                  style={{ borderTopLeftRadius: "10px" }}
                  className={classes.rowHead}
                >
                  Type
                </th>
                <th className={classes.rowHead}>Invoice</th>
                <th className={classes.rowHead}>Discount Price</th>
                <th className={classes.rowHead}>Notes</th>
                <th className={classes.rowHead}>Added By</th>
                {
                  <th
                    className={classes.rowHead}
                    style={{
                      borderWidth: `0 0 1px 0`,
                      borderTopRightRadius: "10px",
                    }}
                  >
                    Actions
                  </th>
                }
              </tr>
              {discountsElements}
            </table>
          )}
          {discountsElements.length === 0 && (
            <h3 className={classes.headerContainer}>No Discounts To Show</h3>
          )}
        </CardBody>
      </Card>
      <AddDiscountModal
        currentTour={currentTour}
        isOpen={isModalOpen}
        onClose={onModalClose}
      />
    </div>
  );
}
