import * as yup from "yup";

//-------------------------------------------------------------------------------------

export default yup.object({
  penalties: yup.array().of(
    yup.object({
      // finOpsStatus: yup
      //   .mixed()
      //   .required("Status is required")
      //   .test(
      //     "status-error",
      //     "all penalties must be reviewed",
      //     (finOpsStatus) => finOpsStatus === enums.FinOpsStatus.REVIEWED
      //   ),
    })
  ),
  discounts: yup.array().of(
    yup.object({
      // finOpsStatus: yup
      //   .mixed()
      //   .required("Status is required")
      //   .test(
      //     "status-error",
      //     "all discounts must be reviewed",
      //     (finOpsStatus) => finOpsStatus === enums.FinOpsStatus.REVIEWED
      //   ),
    })
  ),
  services: yup
    .array()
    .of(
      yup.object({
        finOpsStatus: yup.mixed().required("Status is required"),
      })
    )
    .test(
      "services-are-reviewable",
      "All services with price or cost not equal to 0 should be attached to an invoice.",
      (services) => {
        let isValid = true;
        services.map((service, index) => {
          const canAttachInvoice = !(service.price === 0 && service.cost === 0);
          const isInvoiceAttached = !!service.invoice;
          const serviceIsReviewable =
            (isInvoiceAttached && canAttachInvoice) ||
            (!isInvoiceAttached && !canAttachInvoice);
          if (!serviceIsReviewable) isValid = false;
        });
        return isValid;
      }
    ),
});
