import useDropzoneModal from "./DropzoneModal.hook";
import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import styles from "./DropzoneModal.styles";
import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import Button from "components/CustomButtons/Button.js";
import AttachmentNameField from "./AttachmentNameField";

const useStyles = makeStyles(styles);

export default function DropzoneModal(props) {
  const { isOpen, onClose, setDocuments, formRef } = props;
  const {
    form,
    submitHandler,
    documentNames,
    parsedDocumentNames,
    onRemoveAttachment,
    onAddAttachments,
  } = useDropzoneModal({
    onClose,
    setDocuments,
    formRef,
  });
  const classes = useStyles();
  const documentAttachmentNames = documentNames?.map((document, index) => {
    return (
      <AttachmentNameField
        key={document.id}
        index={index}
        data={document}
        form={form}
      />
    );
  });
  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <Card style={{ margin: 0 }}>
        <CardBody>
          <div className={classes.formHeader}>
            <React.Fragment>
              <AddIcon className={classes.icon} />
              Attach Document
            </React.Fragment>
          </div>
          <form
            style={{ padding: 20, paddingBottom: 0 }}
            onSubmit={submitHandler}
            autoComplete="off"
          >
            {documentAttachmentNames}
            <FormField
              md={12}
              form={form}
              name="documentUrls"
              type="dropzone"
              label="Upload Document"
              options={{
                maxFiles: 5,
                onRemoveAttachment,
                onAddAttachments,
                names: parsedDocumentNames,
              }}
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                type="submit"
                style={{ margin: "20px 0" }}
                create
                loadFromRight
              >
                Attach Document
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </CustomModal>
  );
}
