export default {
  FormContainerType: {
    PAGE: "PAGE",
    MODAL: "MODAL",
  },
  GoodsType: {
    PACKAGED_FOODS: "PACKAGED_FOODS",
    FRUITS_AND_VEGETABLES: "FRUITS_AND_VEGETABLES",
    MEAT_AND_POULTRY: "MEAT_AND_POULTRY",
    CLOTHING_AND_TEXTILE: "CLOTHING_AND_TEXTILE",
    CONSTRUCTION_MATERIALS: "CONSTRUCTION_MATERIALS",
    FURNITURE_AND_APPLIANCES: "FURNITURE_AND_APPLIANCES",
    ELECTRONICS: "ELECTRONICS",
  },
  DealStatus: {
    DRAFT: "DRAFT",
    PENDING_PRICING: "PENDING_PRICING",
    PRICED: "PRICED",
    REJECTED: "REJECTED",
    ACCEPTED: "ACCEPTED",
    ACTIVE: "ACTIVE",
    LOST: "LOST",
  },
  InvoiceRecurrence: {
    DAILY: "DAILY",
    WEEKLY: "WEEKLY",
    BIWEEKLY: "BIWEEKLY",
    MONTHLY: "MONTHLY",
  },
  DealSource: {
    YELLOW_PAGES: "YELLOW_PAGES",
    FACEBOOK: "FACEBOOK",
    WEBSITE: "WEBSITE",
    SEARCH_ENGINES: "SEARCH_ENGINES",
    KNOCKING_DOOR: "KNOCKING_DOOR",
    LINKEDIN: "LINKEDIN",
    REFERRED_BY_CUSTOMER: "REFERRED_BY_CUSTOMER",
    PERSONAL_CONTACT: "PERSONAL_CONTACT",
    TELESALES: "TELESALES",
    REFERRED_BY_OPERATIONS: "REFERRED_BY_OPERATIONS",
    WORD_OF_MOUTH: "WORD_OF_MOUTH ",
    FACEBOOK_CAMPAIGN: "FACEBOOK_CAMPAIGN",
    UPSELLING: "UPSELLING",
  },
  Attractiveness: {
    HOT: "HOT",
    COLD: "COLD",
    WARM: "WARM",
  },
  OrganizationStatus: {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    BLACK_LISTED: "BLACK_LISTED",
  },
  InfoType: {
    TEXT: "TEXT",
    DATE: "DATE",
    BOOLEAN: "BOOLEAN",
    LIST: "LIST",
    LINK: "LINK",
    EXTERNAL_LINK: "EXTERNAL_LINK",
    FILE: "FILE",
    TAGS: "TAGS",
  },
  ContactFormType: {
    EXISTING: "EXISTING",
    NEW: "NEW",
  },
  ContactType: {
    ADMIN: "ADMIN",
    FINANCIAL: "FINANCIAL",
    OPERATIONAL: "OPERATIONAL",
  },
  ContractType: {
    QUOTATION: "QUOTATION",
    CONTRACT: "CONTRACT",
  },
  QuotationType: {
    RENTAL: "RENTAL",
    PER_TRIP: "PER_TRIP",
  },
  DealType: {
    RENTAL: "RENTAL",
    PER_TRIP: "PER_TRIP",
    BOTH: "BOTH",
  },
  Resource: {
    USERS: "USERS",
    PERMISSIONS: "PERMISSIONS",
    DEPARTMENTS: "DEPARTMENTS",
    ROLES: "ROLES",
    SERVICES: "SERVICES",
    TRUCK_TYPES: "TRUCK_TYPES",
    ORGANIZATIONS: "ORGANIZATIONS",
    ZONING: "ZONING",
    DEALS: "DEALS",
    // DEAL_DETAILS: "DEAL_DETAILS",
    DEAL_ORGANIZATIONS: "DEAL_ORGANIZATIONS",
    DEAL_TRUCKS: "DEAL_TRUCKS",
    DEAL_QUOTATIONS: "DEAL_QUOTATIONS",
    DEAL_SERVICES: "DEAL_SERVICES",
    GLOBALS: "GLOBALS",
    PLANS: "PLANS",
    DISPATCHING: "DISPATCHING",
    RENTAL: "RENTAL",
    FIN_OPS: "FIN_OPS",
    ORDERS: "ORDERS",
    SERVICE_PROVIDERS: "SERVICE_PROVIDERS",
    TRUCKS: "TRUCKS",
    DRIVERS: "DRIVERS",
    COURIERS: "COURIERS",
    INVOICES: "INVOICES",
    BILLS: "BILLS",
    MONTHLY_FULFILLMENT: "MONTHLY_FULFILLMENT",
    SERVICE_PROVIDER_RENTALS: "SERVICE_PROVIDER_RENTALS",
    COURIER_RENTALS: "COURIER_RENTALS",
  },
  Action: {
    CREATE: "create",
    READ: "read",
    UPDATE: "update",
    DELETE: "delete",
  },
  TruckAvailability: {
    LOW: "LOW",
    MEDIUM: "MEDIUM",
    HIGH: "HIGH",
  },
  RouteTypes: {
    TRAVEL: "TRAVEL",
    INTERNAL: "INTERNAL",
  },
  OrganizationClassification: {
    INTERNATIONAL_PRINCIPLE: "INTERNATIONAL_PRINCIPLE",
    LOCAL_PRINCIPLE: "LOCAL_PRINCIPLE",
    TRADITIONAL_DISTRIBUTOR: "TRADITIONAL_DISTRIBUTOR",
    E_COMMERCE: "E-COMMERCE",
    OTHER: "OTHER",
  },
  OrganizationSize: {
    EXTRA_LARGE_ENTERPRISE: "EXTRA_LARGE_ENTERPRISE",
    LARGE_ENTERPRISE: "LARGE_ENTERPRISE",
    MEDIUM_BUSINESS: "MEDIUM_BUSINESS",
    SMALL_BUSINESS: "SMALL_BUSINESS",
    STARTUP: "STARTUP",
  },
  DealExpectedPickupTime: {
    FROM_12AM_TO_3AM: "FROM_12AM_TO_3AM",
    FROM_3AM_TO_6AM: "FROM_3AM_TO_6AM",
    FROM_6AM_TO_9AM: "FROM_6AM_TO_9AM",
    FROM_9AM_TO_12PM: "FROM_9AM_TO_12PM",
    FROM_12PM_TO_3PM: "FROM_12PM_TO_3PM",
    FROM_3PM_TO_6PM: "FROM_3PM_TO_6PM",
    FROM_6PM_TO_9PM: "FROM_6PM_TO_9PM",
    FROM_9PM_TO_12AM: "FROM_9PM_TO_12AM",
  },
  TemperatureControl: {
    FROZEN_GOODS: "FROZEN_GOODS",
    CHILLED_GOODS: "CHILLED_GOODS",
    AMBIENT_GOODS: "AMBIENT_GOODS",
  },
  GasType: {
    GAS_95: "GAS_95",
    GAS_92: "GAS_92",
    GAS_80: "GAS_80",
    SOLAR: "SOLAR",
    ELECTRIC: "ELECTRIC",
  },
  DealLossReason: {
    TOO_EXPENSIVE: "TOO_EXPENSIVE",
    HEAVY_TRUCKS: "HEAVY_TRUCKS",
    SERVICE_QUALITY: "SERVICE_QUALITY",
    USES_OWNED_TRUCKS: "USES_OWNED_TRUCKS",
    LATE_RESPONSE: "LATE_RESPONSE",
    SPECIAL_REQUIREMENTS: "SPECIAL_REQUIREMENTS",
    WRONG_NUMBER: "WRONG_NUMBER",
    SERVICE_NOT_NEEDED: "SERVICE_NOT_NEEDED",
    SERVICE_PROVIDER: "SERVICE_PROVIDER",
    LOCATION: "LOCATION",
    UNABLE_TO_REACH: "UNABLE_TO_REACH",
    PORTS_TRUCKING: "PORTS_TRUCKING",
    DUPLICATE_OR_HANDED_OVER: "DUPLICATE_OR_HANDED_OVER",
    CIGARETTE_OR_ALCOHOL: "CIGARETTE_OR_ALCOHOL",
    LOW_DEMAND: "LOW_DEMAND",
    BUSINESS_CLOSED: "BUSINESS_CLOSED",
    MISSED_TENDER_DATE: "MISSED_TENDER_DATE",
  },
  LocationTaskType: {
    PICKUPS_AND_RETURNS_ONLY: "PICKUPS_AND_RETURNS_ONLY",
    DROPOFFS_ONLY: "DROPOFFS_ONLY",
    ALL: "ALL",
  },
  LocationStatus: {
    DELETED: "DELETED",
    ACTIVE: "ACTIVE",
    ARCHIVED: "ARCHIVED",
  },
  TourType: {
    PER_TRIP: "PER_TRIP",
    DAILY: "DAILY",
    MONTHLY: "MONTHLY",
  },
  TaskType: {
    PICKUP: "PICKUP",
    DROP_OFF: "DROP_OFF",
    RETURN: "RETURN",
    LAYOVER: "LAYOVER",
  },
  ServiceType: {
    BASE_TRUCK: "BASE_TRUCK",
    WAITING_TIME: "WAITING_TIME",
    EXTRA_DROP_OFFS: "EXTRA_DROP_OFFS",
    EXTRA_HOURS: "EXTRA_HOURS",
    EXTRA_KILOMETERS: "EXTRA_KILOMETERS",
    COURIER: "COURIER",
    TOUR_CANCELLED: "TOUR_CANCELLED",
    ADDITIONAL: "ADDITIONAL",
    RETURN: "RETURN",
  },
  TaskStatus: {
    UPCOMING: "UPCOMING",
    ONGOING: "ONGOING",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
  },
  TourStatus: {
    UPCOMING: "UPCOMING",
    ONGOING: "ONGOING",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
  },
  OrderChannel: {
    EMAIL: "EMAIL",
    WHATSAPP: "WHATSAPP",
    HOTLINE: "HOTLINE",
    PLATFORM: "PLATFORM",
  },
  OrderStatus: {
    PLANNED: "PLANNED",
    ACTIVE: "ACTIVE",
    CANCELLED: "CANCELLED",
    TERMINATED: "TERMINATED",
    LATE: "LATE",
    EXPIRED: "EXPIRED",
  },
  FulfillmentStatus: {
    FULFILLED: "FULFILLED",
    UNFULFILLED: "UNFULFILLED",
  },
  PlanDetailsTabs: {
    NEW_ORDER: 0,
    DAILY_PLANS: 1,
    MONTHLY_PLANS: 2,
  },
  WeekDay: {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
  },
  DispatchModals: {
    SUPPLIER: "SUPPLIER",
    SERVICE_PROVIDER_RENTAL: "SERVICE_PROVIDER_RENTAL",
    DRIVER: "DRIVER",
    TRUCK: "TRUCK",
    COURIER: "COURIER",
    COURIER_RENTAL: "COURIER_RENTAL",
    CANCEL: "CANCEL",
    UPDATES: "UPDATES",
    NONE: "NONE",
  },
  ProviderStatus: {
    ACTIVE: "ACTIVE",
    BANNED: "BANNED",
  },
  TruckStatus: {
    ACTIVE: "ACTIVE",
    BANNED: "BANNED",
  },
  DriverStatus: {
    ACTIVE: "ACTIVE",
    BANNED: "BANNED",
  },
  CourierStatus: {
    ACTIVE: "ACTIVE",
    BANNED: "BANNED",
  },
  DiscountType: {
    MISSING_GOODS: "MISSING_GOODS",
    DAMAGED_GOODS: "DAMAGED_GOODS",
    LATE_TRUCK: "LATE_TRUCK",
    OTHER: "OTHER",
  },
  PenaltyType: {
    LATE_TRUCK: "LATE_TRUCK",
    DROPPED_TRUCK: "DROPPED_TRUCK",
    MISSING_GOODS: "MISSING_GOODS",
    DAMAGED_GOODS: "DAMAGED_GOODS",
    DRIVER_BEHAVIOR: "DRIVER_BEHAVIOR",
    COURIER_BEHAVIOR: "COURIER_BEHAVIOR",
    OTHER: "OTHER",
  },
  InvoiceType: {
    FIXED: "FIXED",
    ON_DEMAND: "ON_DEMAND",
    MIXED: "MIXED",
  },
  InvoiceStatus: {
    PLANNED: "PLANNED",
    COMPLETED: "COMPLETED",
    REVIEWED: "REVIEWED",
    INVOICED: "INVOICED",
  },
  BillStatus: {
    PLANNED: "PLANNED",
    COMPLETED: "COMPLETED",
    REVIEWED: "REVIEWED",
    BILLED: "BILLED",
  },
  FinOpsStatus: {
    REVIEWED: "REVIEWED",
    PENDING: "PENDING",
  },
  CancellationReason: {
    UNABLE_TO_MATCH: "UNABLE_TO_MATCH",
    LATE_DRIVER: "LATE_DRIVER",
    SERVICE_PROVIDER_CANCELLED: "SERVICE_PROVIDER_CANCELLED",
    UNABLE_TO_REACH_SERVICE_PROVIDER_OR_DRIVER:
      "UNABLE_TO_REACH_SERVICE_PROVIDER_OR_DRIVER",
    TRUCK_MALFUNCTION: "TRUCK_MALFUNCTION",
    POSTPONED_BY_CUSTOMER: "POSTPONED_BY_CUSTOMER",
    CUSTOMER_DOES_NOT_NEED_THE_TRUCK_ANYMORE:
      "CUSTOMER_DOES_NOT_NEED_THE_TRUCK_ANYMORE",
    UNABLE_TO_REACH_CUSTOMER: "UNABLE_TO_REACH_CUSTOMER",
    CLIENT_VIOLATION: "CLIENT_VIOLATION",
    ORDERED_BY_MISTAKE: "ORDERED_BY_MISTAKE",
    OTHER: "OTHER",
  },
  RentalDealStatus: {
    PLANNED: "PLANNED",
    ACTIVE: "ACTIVE",
    CANCELLED: "CANCELLED",
    TERMINATED: "TERMINATED",
  },
};
