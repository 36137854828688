import { useCallback, useEffect, useMemo, useState } from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { toast } from "react-toastify";
import enums from "enums/index";

export const useMonthlyOrderDetails = (viewOnly, planOrder) => {
  const [order, setOrder] = useState(null);
  const [sendGetRequest, isGetLoading] = useHttpClient();
  const [sendUpdateRequest, isUpdateLoading] = useHttpClient();

  const pathOrderId = viewOnly
    ? planOrder?.realId
    : window.location.pathname.split("/")[4]?.trim();
  const orderId = order?.id;

  const getOrder = useCallback(async () => {
    if (pathOrderId && !isNaN(pathOrderId)) {
      try {
        const response = await sendGetRequest(api.orders.getOne(pathOrderId));
        setOrder(response);
      } catch (err) {
        toast.error("An error has occurred");
      }
    }
  }, [pathOrderId]);

  const updateOrder = useCallback(
    async (data) => {
      if (orderId && !isNaN(orderId)) {
        if (data.status === enums.OrderStatus.ACTIVE)
          await sendUpdateRequest(
            api.orders.startMonthly(orderId, { id: orderId, ...data })
          );
        else if (data.status === enums.OrderStatus.TERMINATED)
          await sendUpdateRequest(
            api.orders.terminateMonthly(orderId, { id: orderId, ...data })
          );
        else
          await sendUpdateRequest(api.orders.patch({ id: orderId, ...data }));

        setOrder((prevOrder) => ({ ...prevOrder, ...data }));
      }
    },
    [orderId]
  );

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  return {
    order: order,
    setOrder,
    updateOrder,
  };
};
