const styles = (theme) => ({
  button: {
    height: "fit-content",
    padding: "7px 40px",
    borderRadius: 5,
    margin: "0 0 20px 0",
    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      width: "60%",
      padding: "7px 10px",
      order: 0,
    },
  },
  buttonContainer: {
    marginLeft: "3px",
  },
  modalHeader: { width: "100%", position: "absolute" },
  modalBody: { padding: 25, maxWidth: 850, height: "fit-content" },
  //   button: { margin: "0 0 20px" },
});

export default styles;
