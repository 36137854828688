import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import styles from "./AssignDriverModal.styles";
import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import useAssignDriverModal from "./AssignDriverModal.hook";
import Button from "components/CustomButtons/Button.js";
import api from "services/api";
import DriversForm from "views/Common/Drivers/DriversForm";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AssignDriverModal(props) {
  const { isOpen, onClose, currentTour } = props;
  const {
    form,
    drivers,
    serviceProvider,
    isLoading,
    submitHandler,
    resetDriver,
    handleAddNew,
  } = useAssignDriverModal({
    onClose,
    currentTour,
  });
  const classes = useStyles();
  return (
    <CustomModal
      open={isOpen}
      onClose={() => {
        onClose(resetDriver);
      }}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <Card style={{ margin: 0 }}>
        <CardBody>
          <div className={classes.formHeader}>
            <React.Fragment>
              <AddIcon className={classes.icon} />
              Assign Driver
            </React.Fragment>
          </div>
          <form
            style={{ padding: 20, paddingBottom: 0 }}
            onSubmit={submitHandler}
            autoComplete="off"
          >
            <FormField
              md={12}
              form={form}
              name="driver"
              type="autocomplete"
              label="Driver"
              options={{
                data: drivers,
                getOptionLabel: (option) =>
                  [option?.id, option?.name, option?.phonePrimary]
                    .filter((item) => !!item)
                    .join(" - "),
                getSubmitValue: (options) =>
                  options?.map((option) => option?.id),
                blurOnSelect: true,
                isTableInput: true,
                addNew: true,
                dataResource: api.drivers,
                formComponent: (
                  <DriversForm
                    serviceProvider={serviceProvider}
                    handleAddNew={handleAddNew}
                    create
                  />
                ),
              }}
            />
            <FormField
              md={12}
              form={form}
              name="phoneNumber"
              type="text"
              label="Phone Number"
            />
            <FormField
              md={12}
              form={form}
              name="phoneNumberSecondary"
              type="text"
              label="Secondary Phone Number"
            />

            <FormField
              md={12}
              form={form}
              name="licenseAttachment"
              type="dropzone"
              label="Upload Latest License"
              options={{
                maxFiles: 1,
              }}
            />

            <FormField
              md={12}
              form={form}
              name="licenseId"
              type="text"
              label="License"
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                type="submit"
                loading={isLoading}
                style={{ margin: "20px 0" }}
                create
                loadFromRight
              >
                Assign Driver
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </CustomModal>
  );
}
