const styles = () => ({
  root: {
    borderRadius: 5,
    padding: 10,
    boxShadow: "0px 0px 5px 0px rgba(241,179,26,0.8)",
    marginBottom: 10,
  },
  text: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 18,
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
  },
  typeSelectContainer: {
    width: "100%",
    marginTop: -2,
  },
  index: {
    marginBottom: 19,
    marginTop: 30,
    marginRight: 10,
    fontWeight: 300,
    fontSize: 24,
  },
  removeButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  removeButton: {
    marginTop: "20px !important",
  },
  taskTypeRoot: {
    marginBottom: 17,
    marginTop: 8,
  },
  taskTypeLabel: {
    marginBottom: 5,
  },
  hidden: {
    display: "none",
  },
});

export default styles;
