import * as yup from "yup";
import enums from "enums/index";

export default () => {
  return yup.object().shape({
    cancellationReason: yup.string().required("Required"),
    customCancellationReason: yup
      .mixed()
      .when("cancellationReason", (cancellationReason) => {
        if (cancellationReason == enums.CancellationReason.OTHER)
          return yup.string().required("Required");
      }),
  });
};
