import { makeStyles } from "@material-ui/core/styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import enums from "enums/index";
import React from "react";
import DealTruckForm from "./components/DealTruckForm/DealTruckForm";
import useTrucks from "./Trucks.hook";
import styles from "./Trucks.styles";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function Trucks() {
  const classes = useStyles();

  const {
    isEditTruckModalOpen,
    isGetTrucksLoading,
    dealTruck,
    truckTypes,
    onEditModalClose,
    handleEditTruck,
  } = useTrucks();

  const columns = [
    {
      title: "Name",
      dataIndex: ["truckType", "details", "name"],
    },
    {
      title: "Extra Km Price",
      dataIndex: ["details", "extraKmPrice"],
    },
    {
      title: "Extra Hour Price",
      dataIndex: ["details", "extraHourPrice"],
    },
    {
      title: "Off Day Fixed Price",
      dataIndex: ["details", "offDayFixedPrice"],
    },
    {
      title: "Off Day Fixed Target Cost",
      dataIndex: ["details", "offDayFixedTargetCost"],
    },
    {
      title: "",
      render: (_, record) => {
        return (
          <div className={classes.actionsContainer}>
            <CheckPermission
              resource={enums.Resource.DEAL_TRUCKS}
              action={enums.Action.UPDATE}
            >
              <Button
                justIcon
                edit
                color="white"
                textColor="success"
                onClick={() => handleEditTruck(record)}
              />
            </CheckPermission>
          </div>
        );
      },
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        {isEditTruckModalOpen && (
          <DealTruckForm
            isOpen={isEditTruckModalOpen}
            onClose={onEditModalClose}
            dealTruck={dealTruck}
          />
        )}
        <React.Fragment>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>Trucks Summary</h3>
          </div>
          <div className={classes.tableContainer}>
            <CustomTable
              columns={columns}
              data={truckTypes}
              loading={isGetTrucksLoading}
              addIndexColumn
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}
