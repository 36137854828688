import { useContext, useEffect, useMemo } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { TasksTableContext } from "../../TasksTable.context";
import { parseUseFieldsArrayString } from "./Dropzone.util";

export default function useDropzoneModal({ onClose, setDocuments, formRef }) {
  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      documentUrls: [],
      documentNames: [],
    },
  });

  const { form: taskTableForm, currentTask } = useContext(TasksTableContext);
  const { handleSubmit, getValues, watch, setValue, reset, control } = form;
  const { getValues: getTaskTableValues } = taskTableForm;
  const documentUrls = watch("documentUrls");

  const { fields: documentNames, insert, append, remove } = useFieldArray({
    control,
    name: "documentNames",
  });

  const parsedDocumentNames = useMemo(
    () => documentNames.map((name) => parseUseFieldsArrayString(name)),
    [documentNames]
  );
  //TODO get attachment from current task and set the value here in the form
  const onSubmit = (values) => () => {
    try {
      const documents = values.documentUrls?.map((url, index) => ({
        url,
        name: values.documentNames[index],
      }));
      setDocuments(documents);
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentTask !== null) {
      const documents = getTaskTableValues(`tasks.${currentTask}.documents`);
      const documentURLs = documents?.map((doc) => doc.url);
      const documentNames = documents?.map((doc) => doc.name);
      reset({ documentUrls: documentURLs, documentNames });
    }
  }, [currentTask, reset, getTaskTableValues]);
  const onRemoveAttachment = (index) => {
    remove(index);
  };
  const onAddAttachments = (attachments) => {
    remove();
    const newNames = attachments.map(
      (url, index) => url.split("/").reverse()[0].split("?")[0]
    );
    append(newNames);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    submitHandler,
    documentNames,
    parsedDocumentNames,
    form,
    onRemoveAttachment,
    onAddAttachments,
  };
}
