import React, { useCallback } from "react";
import qs from "qs";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//-------------------------------------------------------------------------------------

export const useCourierRentalView = ({ path }) => {
  const [sendRequest, isGetRentalLoading] = useHttpClient();
  const [sendUpdateRequest, isUpdateLoading] = useHttpClient();

  const [courierRental, setCourierRental] = React.useState(null);

  const history = useHistory();

  const dataResource = React.useMemo(() => api.courierRentals, []);
  const rentalDealId = window.location.pathname.split("/").pop().trim();

  const getLatestCourierRental = React.useCallback(async () => {
    if (rentalDealId && !isNaN(rentalDealId)) {
      try {
        const response = await sendRequest(dataResource.getOne(rentalDealId));
        setCourierRental({ ...response });
      } catch (err) {
        toast.error("An error has occurred");
        history.push({
          pathname: `/admin/${path}`,
        });
      }
    } else {
      toast.error("An error has occurred");
      history.push({
        pathname: `/admin/${path}`,
      });
    }
  }, [dataResource]);

  const updateRentalDeal = useCallback(
    async (data) => {
      await sendUpdateRequest(
        api.courierRentals.patch({ id: rentalDealId, ...data })
      );
      setCourierRental((prevRental) => ({ ...prevRental, ...data }));
    },
    [rentalDealId]
  );

  React.useEffect(() => {
    getLatestCourierRental();
  }, [getLatestCourierRental]);

  return {
    courierRental,
    isGetRentalLoading,
    setCourierRental,
    getLatestCourierRental,
    updateRentalDeal,
  };
};
