import usersRoutes from "./usersRoutes";
import adminIcon from "@material-ui/icons/Lock";
import permissionsRoutes from "./permissionsRoutes";
import departmentsRoutes from "./departmentsRoutes";
import rolesRoutes from "./rolesRoutes";
import enums from "enums/index";

export default {
  name: "Access Management",
  icon: adminIcon,
  layout: "/admin",
  collapse: true,
  state: "usersCollapse",
  resource: enums.Resource.USERS,
  views: [{ ...usersRoutes }, {...permissionsRoutes}, {...departmentsRoutes}, {...rolesRoutes}],
};
