import { useForm } from "react-hook-form";
import CancelServiceProviderModalValidation from "./CancelServiceProvider.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { useContext, useState } from "react";
import api from "services/api";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

export default function useCancelServiceProvider({ currentTour, onClose }) {
  const { getTour, getOrganizations, getTours } = useContext(
    TourOperationsContext
  );
  const [sendTourRequest, isTourRequestLoading] = useHttpClient(true);
  const [modalOpen, setModalOpen] = useState(false);
  const resolver = useYupValidationResolver(
    CancelServiceProviderModalValidation()
  );
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, watch, reset } = form;

  const toggleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const onSubmit = (values) => async () => {
    try {
      if (!currentTour) {
        toast.error("no tour is selected");
        return;
      }

      await sendTourRequest(
        api.tours.removeServiceProvider(currentTour.id, {
          serviceProviderCancellationReason:
            values.serviceProviderCancellationReason,
        })
      );
      getTour();
      toast.success("Service Provider Cancelled Successfully");
      toggleModalOpen();
    } catch (error) {
      if (error?.response?.data?.error === "Conflict") {
        await getOrganizations();
        await getTours();
        toggleModalOpen();
      } else {
        console.log(error);
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    form,
    reset,
    submitHandler,
    isTourRequestLoading,
    toggleModalOpen,
    modalOpen,
  };
}
