import api from "services/api";
import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "nameEn",
        type: "text",
        label: "Name (English)",
        validation: yup.string().required("Required"),
      },
      {
        name: "nameAr",
        type: "text",
        label: "Name (Arabic)",
        validation: yup.string().required("Required"),
      },
      {
        name: "cityId",
        fetchedName: "city",
        type: "autocomplete",
        label: "City",
        options: {
          getData: api.cities.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (option) => option?.id,
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "zoneId",
        fetchedName: "zone",
        type: "autocomplete",
        label: "Zone",
        options: {
          getData: api.zones.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (zones) => zones?.id,
        },
      },
    ],
  },
];
