import translations from "constants/translations/index";
import enums from "enums/index";
import React from "react";
import { getI18n } from "react-i18next";
import { PlanFormContext } from "../../../PlanForm.context";

// ------------------------------------------------------------------------------------

export const useTour = ({ index, data }) => {
  const [tour, setTour] = React.useState({});
  const [selectedTourType, setSelectedTourType] = React.useState(null);
  const [selectedTruckType, setSelectedTruckType] = React.useState(null);
  const [selectedDistanceLimit, setSelectedDistanceLimit] = React.useState(
    null
  );
  const [selectedDurationLimit, setSelectedDurationLimit] = React.useState(
    null
  );
  const [tourTypes, setTourTypes] = React.useState([]);
  const [truckTypes, setTruckTypes] = React.useState([]);
  const [distanceLimitOptions, setDistanceLimitOptions] = React.useState([]);
  const [durationLimitOptions, setDurationLimitOptions] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [tourPickupLocation, setTourPickupLocation] = React.useState(null);

  const { form, isNewOrder, plan, quotationGroups } = React.useContext(
    PlanFormContext
  );

  const {
    watch,
    errors,
    formState: { isDirty },
  } = form;

  const tourType = tour.type;
  const truckTypeId = tour.truckType?.id;
  const distanceLimit = tour.distanceLimit;
  const durationLimit = tour.durationLimit;
  const dealId = watch("deal")?.id;

  const tourError = errors
    ? Object.keys(errors)?.some((key) => key.startsWith(`tours[${index}]`))
    : false;

  const isTourTypeDisabled = React.useMemo(() => isNewOrder && !dealId, [
    dealId,
    isNewOrder,
  ]);
  const isTruckTypeDisabled = React.useMemo(
    () => !tourType || isTourTypeDisabled,
    [tourType, isTourTypeDisabled]
  );
  const isDistanceLimitDisabled = React.useMemo(() => !truckTypeId, [
    truckTypeId,
  ]);
  const isDurationLimitDisabled = React.useMemo(() => distanceLimit == null, [
    distanceLimit,
  ]);

  React.useEffect(() => {
    if (data) {
      setTour(data);
    }
  }, [data]);

  React.useEffect(() => {
    if (quotationGroups?.length) {
      const tempTourTypes = quotationGroups
        ?.map((group) => ({
          value: group.tourType,
          name: translations[getI18n().language].tourType[group.tourType],
        }))
        .filter((tourType) =>
          isNewOrder || tour?.type !== enums.TourType.MONTHLY
            ? tourType.value !== enums.TourType.MONTHLY
            : tourType.value === enums.TourType.MONTHLY
        );
      setTourTypes(tempTourTypes);
    }
  }, [quotationGroups, isNewOrder, tour]);

  React.useEffect(() => {
    const selectedTourType = quotationGroups?.find(
      (group) => group.tourType === tourType
    );
    setSelectedTourType(selectedTourType);
    if (selectedTourType?.truckTypes?.length) {
      const tempTruckTypes = selectedTourType?.truckTypes?.map((truckType) => ({
        id: truckType?.id,
        name: truckType?.name,
      }));
      setTruckTypes(tempTruckTypes);
    }
  }, [tourType, quotationGroups]);

  React.useEffect(() => {
    const selectedTruckType = selectedTourType?.truckTypes?.find(
      (truckType) => truckType?.id === truckTypeId
    );
    setSelectedTruckType(selectedTruckType);
    if (
      tourType === enums.TourType.MONTHLY ||
      tourType === enums.TourType.DAILY
    ) {
      if (selectedTruckType?.distanceLimits?.length) {
        const tempDistanceLimitOptions = selectedTruckType?.distanceLimits?.map(
          (distanceLimit) => distanceLimit.value
        );
        setDistanceLimitOptions(tempDistanceLimitOptions);
      }
    }
  }, [tourType, truckTypeId, quotationGroups, selectedTourType]);

  React.useEffect(() => {
    if (
      tourType === enums.TourType.MONTHLY ||
      tourType === enums.TourType.DAILY
    ) {
      const selectedDistanceLimit = selectedTruckType?.distanceLimits?.find(
        (el) => el.value === distanceLimit
      );
      setSelectedDistanceLimit(selectedDistanceLimit);
      if (selectedDistanceLimit?.durationLimits?.length) {
        const tempDurationLimitOptions = selectedDistanceLimit?.durationLimits?.map(
          (durationLimit) => durationLimit.value
        );
        setDurationLimitOptions(tempDurationLimitOptions);
      }
    }
  }, [
    tourType,
    truckTypeId,
    distanceLimit,
    quotationGroups,
    selectedDistanceLimit,
    selectedTruckType,
  ]);

  React.useEffect(() => {
    if (tourType === enums.TourType.PER_TRIP && truckTypeId) {
      setCities(selectedTruckType?.pickupCities);
      setDistricts(selectedTruckType?.pickupDistricts);
      setLocations(selectedTruckType?.pickupLocations);
    } else if (
      (tourType === enums.TourType.MONTHLY ||
        tourType === enums.TourType.DAILY) &&
      truckTypeId &&
      distanceLimit != null &&
      durationLimit
    ) {
      const selectedDurationLimit = selectedDistanceLimit?.durationLimits?.find(
        (el) => el.value === durationLimit
      );
      setSelectedDurationLimit(selectedDurationLimit);
      setCities(selectedDurationLimit?.cities);
      setDistricts(selectedDurationLimit?.districts);
      setLocations(selectedDurationLimit?.locations);
    }
  }, [
    tourType,
    truckTypeId,
    distanceLimit,
    durationLimit,
    quotationGroups,
    selectedTruckType,
    selectedDistanceLimit,
  ]);

  React.useEffect(() => {
    if (isDirty) {
      form.setValue(`tours.${index}.type`, null);
      form.setValue(`tours.${index}.truckType`, null);
      form.setValue(`tours.${index}.distanceLimit`, null);
      form.setValue(`tours.${index}.durationLimit`, null);
    }
  }, [dealId]);

  React.useEffect(() => {
    if (isDirty) {
      form.setValue(`tours.${index}.truckType`, null);
      form.setValue(`tours.${index}.distanceLimit`, null);
      form.setValue(`tours.${index}.durationLimit`, null);
    }
  }, [tourType]);

  React.useEffect(() => {
    if (isDirty) {
      form.setValue(`tours.${index}.distanceLimit`, null);
      form.setValue(`tours.${index}.durationLimit`, null);
    }
  }, [truckTypeId]);

  React.useEffect(() => {
    if (isDirty) {
      form.setValue(`tours.${index}.durationLimit`, null);
    }
  }, [distanceLimit]);

  const handleChange = (prop) => (newValue) => {
    setTour((prev) => ({ ...prev, [prop]: newValue }));
  };

  return {
    tourTypes,
    truckTypes,
    distanceLimitOptions,
    durationLimitOptions,
    form,
    isNewOrder,
    plan,
    tour,
    tourType,
    tourError,
    isTourTypeDisabled,
    isTruckTypeDisabled,
    isDistanceLimitDisabled,
    isDurationLimitDisabled,
    cities,
    districts,
    locations,
    tourPickupLocation,
    selectedTourType,
    selectedTruckType,
    selectedDistanceLimit,
    selectedDurationLimit,
    handleChange,
    setLocations,
    setTourPickupLocation,
  };
};
