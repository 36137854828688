const styles = (theme) => ({
  topInnerContainer: {
    display: "flex",
    alignItems: "center",
    width: "97.5%",
    marginLeft: 15,
    justifyContent: "space-between",
  },
  infoContainer: { width: "25%" },
  updatesContainer: { width: "70%" },
});

export default styles;
