import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import styles from "./Buttons.styles";
import React from "react";

const useStyles = makeStyles(styles);

export default function CompleteButton({
  statusChangeDisabled,
  errorMessage,
  onComplete,
  index,
}) {
  const classes = useStyles();

  return (
    <Tooltip
      title={
        statusChangeDisabled
          ? "You should assign service provider, driver and truck to change task status"
          : "Complete"
      }
      placement="bottom"
      classes={{ tooltip: classes.tooltip }}
      arrow
    >
      <div style={{ width: "fit-content" }}>
        <ConfirmationButton
          onConfirm={onComplete}
          confirmMessage={`Are you sure you want to complete task number ${
            index + 1
          }`}
          successHeaderText="Task Completed"
          successMessage="Task completed successfully"
          headerMessage="Complete Task"
          confirmButtonText="Confirm"
          confirmButtonColor="success"
          errorMessage={errorMessage}
          buttonProps={{
            textColor: "textColor",
            className: classes.button,
            disabled: statusChangeDisabled,
          }}
        >
          <DoneIcon style={{ cursor: "pointer" }} />
        </ConfirmationButton>
      </div>
    </Tooltip>
  );
}
