import { createContext } from "react";

export const PlanFormContext = createContext({
  plan: null,
  form: {},
  defaultTour: {},
  defaultTask: {},
  initialTask: {},
  isNewOrder: false,
  quotationGroups: [],
  allCities: [],
  allDistricts: [],
});
