import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "idleAllowance",
        type: "number",
        label: "Idle Allowance (days)",
        // validation: yup.mixed().required("Required"),
      },
      {
        name: "extraDropOffAllowance",
        type: "number",
        label: "Extra Drop Off Allowance",
      },
      {
        name: "onDemandCancellationNoticePeriod",
        type: "number",
        label: "On Demand Cancellation Notice Period",
      },
      {
        name: "cancellationPriceNoticePeriodPct",
        type: "number",
        label: "Cancellation Price Notice Period Pct",
      },
      {
        name: "cancellationPriceAfterNoticePeriodPct",
        type: "number",
        label: "Cancellation Price After Notice Period  (0 ➔ 1)",
      },
      {
        name: "extraKmAllowancePct",
        type: "number",
        label: "Extra Km Allowance (0 ➔ 1)",
      },
      {
        name: "extraHourAllowancePct",
        type: "number",
        label: "Extra Hour Allowance (0 ➔ 1)",
      },
      {
        name: "offDayFixedPricePct",
        type: "number",
        label: "Off Day Fixed Price (0 ➔ 1)",
      },
      {
        name: "offDayFixedTargetCostPct",
        type: "number",
        label: "Off Day Target Cost (0 ➔ 1)",
      },
      {
        name: "tripKmAllowancePct",
        type: "number",
        label: "Trip Km Allowance (0 ➔ 1)",
      },
      {
        name: "extraDropOffKmAllowance",
        type: "number",
        label: "Extra Drop Off Km Allowance",
      },
      {
        name: "extraDropOffTripDurationAllowance",
        type: "number",
        label: "Extra Drop Off Trip Duration Allowance",
      },
      {
        name: "monthlyBasePriceDiscount",
        type: "number",
        label: "Monthly Base Price Discount",
      },
      {
        name: "workingDays",
        type: "number",
        label: "Working Days",
      },
      {
        name: "workingHours",
        type: "number",
        label: "Working Hours",
      },
      {
        name: "offDays",
        type: "number",
        label: "Off Days",
      },
      {
        name: "oilCostPerLiter",
        type: "number",
        label: "Oil Cost Per Liter",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "gasCostPerLiter95",
        type: "number",
        label: "95 Gas Cost Per Liter",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "gasCostPerLiter92",
        type: "number",
        label: "92 Gas Cost Per Liter",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "gasCostPerLiter80",
        type: "number",
        label: "80 Gas Cost Per Liter",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "gasCostPerLiterSolar",
        type: "number",
        label: "Solar Cost Per Liter",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "gasCostPerLiterElectric",
        type: "number",
        label: "Electric Cost Per Liter",
        validation: yup.mixed().required("Required"),
      },
    ],
  },
];
