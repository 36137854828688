import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckPermission from "components/CheckPermission/CheckPermission";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import translations from "constants/translations/index";
import enums from "enums";
import React from "react";
import { useTranslation } from "react-i18next";
import api from "services/api";
import contactsForm from "views/Organizations/contactsForm";
import AddContactModal from "../../components/AddContactModal/AddContactModal";
import useContacts from "./ContactsSection.hook";
import styles from "./ContactsSection.styles";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ContactsSection() {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    deal,
    contacts,
    isAddContactOpen,
    isEditContactOpen,
    isGetDealLoading,
    editItemId,
    handleAddContact,
    onAddModalClose,
    handleEditContact,
    onEditModalClose,
    handleRemoveContact,
    onRemoveModalClose,
  } = useContacts();

  const columns = React.useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Position",
        dataIndex: "position",
      },
      {
        title: "Contact Type",
        dataIndex: "type",
        getData: (data) => {
          if (data) return translations[i18n.language].contactType[data];
        },
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              <CheckPermission
                resource={enums.Resource.DEAL_ORGANIZATIONS}
                action={enums.Action.UPDATE}
              >
                <Button
                  justIcon
                  edit
                  color="white"
                  textColor="success"
                  onClick={() => handleEditContact(record?.id)}
                />
              </CheckPermission>
              <CheckPermission
                resource={enums.Resource.DEAL_ORGANIZATIONS}
                action={enums.Action.UPDATE}
              >
                <ConfirmationButton
                  confirmMessage="Are you sure you want to remove this contact?"
                  successHeaderText="Contact Removed"
                  successMessage="Contact removed successfully"
                  headerMessage="Remove Contact"
                  confirmButtonText="Remove"
                  onConfirm={() => handleRemoveContact(record?.id)}
                  buttonProps={{
                    justIcon: true,
                  }}
                >
                  <RemoveIcon className={classes.removeIcon} />
                </ConfirmationButton>
              </CheckPermission>
            </div>
          );
        },
      },
    ],
    [deal]
  );

  return (
    <div className={classes.section}>
      {isAddContactOpen && (
        <AddContactModal isOpen={isAddContactOpen} onClose={onAddModalClose} />
      )}
      {isEditContactOpen && (
        <FormContainer
          entityName="Contact"
          dataResource={api.contacts}
          formStructure={contactsForm.editDealContact}
          type={enums.FormContainerType.MODAL}
          edit
          isOpen={isEditContactOpen}
          onClose={onEditModalClose}
          editItemId={editItemId}
        />
      )}
      <React.Fragment>
        <div className={classes.headerContainer}>
          <h3 className={classes.header}>Contacts</h3>
          <CheckPermission
            resource={enums.Resource.DEAL_ORGANIZATIONS}
            action={enums.Action.UPDATE}
          >
            <Button
              onClick={handleAddContact}
              className={classes.addContactButton}
              color="success"
            >
              <AddIcon className={classes.addIcon} />
              Add Contact
            </Button>
          </CheckPermission>
        </div>
        <CollapseContainer
          name="contacts"
          titleComponent={<div />}
          startCollapsed={false}
        >
          <div className={classes.tableContainer}>
            <CustomTable
              columns={columns}
              data={contacts}
              loading={isGetDealLoading}
              addIndexColumn
            />
          </div>
        </CollapseContainer>
      </React.Fragment>
    </div>
  );
}
