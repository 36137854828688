import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import enums from "enums/index";
import AssignCourierModal from "./Components/AssignCourierModal/AssignCourierModal";
import AssignDriverModal from "./Components/AssignDriverModal/AssignDriverModal";
import AssignSupplierModal from "./Components/AssignSupplierModal/AssignSupplierModal";
import AssignTruckModal from "./Components/AssignTruckModal/AssignTruckModal";
import CourierCard from "./Components/Cards/CourierCard";
import DriverCard from "./Components/Cards/DriverCard";
import SupplierCard from "./Components/Cards/SupplierCard";
import TruckCard from "./Components/Cards/TruckCard";
import TasksTable from "./Components/TasksTable/TasksTable";
import styles from "./Common.styles";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import ServicesTable from "./Components/ServicesTable/ServiceTable";
import FiltersList from "./Components/FiltersList/FiltersList";
import CancelTourModal from "./Components/CancelTourModal/CancelTourModal";
import useCommon from "./Common.hook";
import TourInfo from "./Components/TourInfo/TourInfo";
import TourList from "./Components/TourList/TourList";
import TourLoading from "./Components/TourLoading/TourLoading";
import UpdatesModal from "./Components/UpdatesModal/UpdatesModal";
import HistoryButton from "./Components/HistoryButton/HistoryButton";
import { TourStatusColors } from "constants/colors";
import { formatEnums, getDateString } from "utils/index";
import Loading from "components/Loading/Loading";
import AssignServiceProviderRentalModal from "./Components/AssignServiceProviderRentalModal/AssignServiceProviderRentalModal";
import AssignCourierRentalModal from "./Components/AssignCourierRentalModal/AssignCourierRentalModal";

const useStyles = makeStyles(styles);

export default function Common() {
  const {
    resource,
    serviceProviderIsNull,
    driverOrTruckIsNull,
    pickupDate,
    toursCompleted,
    toursReviewed,
    toggleServiceProviderIsNull,
    toggleDriverOrTruckIsNull,
    setPickupDate,
    toggleToursCompleted,
    toggleToursReviewed,
    organizations,
    currentOrgIndex,
    currentOrg,
    currentTour,
    tourReviewable,
    tourReviewed,
    assignModal,
    completeTour,
    revertCompleteTour,
    tourDisabled,
    tourStarted,
    setAssignModal,
    onOrgClick,
    onTourClick,
    onCloseAssignModal,
    isOrganizationsLoading,
    isTourLoading,
    sendTasksRequest,
    isTasksLoading,
    canRevertTour,
  } = useCommon();

  const historyColumns = useMemo(() => {
    return [
      {
        title: "Status",
        dataIndex: "status",
        getData: (status) => {
          return (
            <div style={{ color: TourStatusColors[status], fontWeight: 600 }}>
              {formatEnums(status)}
            </div>
          );
        },
      },
      {
        title: "Truck",
        dataIndex: ["truck", "name"],
        getData: (data) => (data ? data : "-"),
      },
      {
        title: "Driver",
        dataIndex: ["driver", "name"],
        getData: (data) => (data ? data : "-"),
      },
      {
        title: "Courier",
        dataIndex: ["courier", "name"],
        getData: (data) => (data ? data : "-"),
      },
      {
        title: "Service Provider",
        dataIndex: ["serviceProvider", "name"],
        getData: (data) => (data ? data : "-"),
      },
      {
        title: "Updated At",
        dataIndex: "timestamp",
        getData: (data) => (data ? getDateString(data, "en-US", true) : "-"),
      },
      {
        title: "Updated By",
        dataIndex: ["createdBy", "name"],
        getData: (data) => (data ? data : "-"),
      },
    ];
  }, []);
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} md={10} sm={10}>
        <GridContainer className={classes.buttonsGridContainer}>
          <GridItem xs={12} md={12} sm={12}>
            <FiltersList
              serviceProviderIsNull={serviceProviderIsNull}
              driverOrTruckIsNull={driverOrTruckIsNull}
              pickupDate={pickupDate}
              toursCompleted={toursCompleted}
              toursReviewed={toursReviewed}
              setPickupDate={setPickupDate}
              toggleServiceProviderIsNull={toggleServiceProviderIsNull}
              toggleDriverOrTruckIsNull={toggleDriverOrTruckIsNull}
              toggleToursCompleted={toggleToursCompleted}
              toggleToursReviewed={toggleToursReviewed}
              resource={resource}
            />
          </GridItem>
        </GridContainer>
        <TourLoading
          isLoading={isTourLoading}
          message={"No tours were found."}
          notFound={!!currentOrg?.tours?.length}
          className={classes.tourContainer}
        >
          <GridContainer>
            <GridItem xs={12} md={12} sm={12}>
              <TourInfo />
            </GridItem>
          </GridContainer>
          <Card raised>
            <CardBody>
              <CollapseContainer
                titleComponent={
                  <div className={classes.headerContainer}>
                    <h3 className={classes.header}>Details</h3>
                  </div>
                }
                startCollapsed={false}
              >
                <div
                  className={classes.buttonGroup}
                  style={{ marginBottom: "-10px" }}
                >
                  <Button
                    onClick={() => {
                      setAssignModal(enums.DispatchModals.UPDATES);
                    }}
                    className={classes.addContactButton}
                    color="primary"
                  >
                    Tour Updates
                  </Button>
                  <HistoryButton
                    id={currentTour?.id}
                    pathName={"tours"}
                    title={"Tour History"}
                    columns={historyColumns}
                  />
                </div>

                <GridContainer style={{ padding: "15px 12px" }}>
                  <GridItem
                    className={classes.assignCardGridItem}
                    xs={12}
                    md={3}
                    sm={6}
                  >
                    <SupplierCard
                      currentTour={currentTour}
                      setAssignModal={setAssignModal}
                    />
                  </GridItem>
                  <GridItem
                    className={classes.assignCardGridItem}
                    xs={12}
                    md={3}
                    sm={6}
                  >
                    <DriverCard
                      currentTour={currentTour}
                      setAssignModal={setAssignModal}
                    />
                  </GridItem>
                  <GridItem
                    className={classes.assignCardGridItem}
                    xs={12}
                    md={3}
                    sm={6}
                  >
                    <TruckCard
                      currentTour={currentTour}
                      setAssignModal={setAssignModal}
                    />
                  </GridItem>
                  <GridItem
                    className={classes.assignCardGridItem}
                    xs={12}
                    md={3}
                    sm={6}
                  >
                    <CourierCard
                      currentTour={currentTour}
                      setAssignModal={setAssignModal}
                    />
                  </GridItem>
                </GridContainer>
              </CollapseContainer>
              <Loading loading={isTasksLoading}>
                <CollapseContainer
                  startCollapsed={false}
                  titleComponent={
                    <div className={classes.headerContainer}>
                      <h3 className={classes.header}>Tasks</h3>
                    </div>
                  }
                >
                  {!tourDisabled && (
                    <div className={classes.buttonGroup}>
                      <Button
                        onClick={() => {
                          setAssignModal(enums.DispatchModals.CANCEL);
                        }}
                        disabled={tourDisabled}
                        className={classes.addContactButton}
                        color="primary"
                      >
                        Cancel Tour
                      </Button>
                      {tourStarted && (
                        <Button
                          onClick={completeTour}
                          className={classes.addContactButton}
                          disabled={tourDisabled}
                          color="primary"
                        >
                          Complete Tour
                        </Button>
                      )}
                    </div>
                  )}
                  {canRevertTour && (
                    <div className={classes.buttonGroup}>
                      <Button
                        onClick={revertCompleteTour}
                        className={classes.addContactButton}
                        color="primary"
                      >
                        Return Tour To Dispatching
                      </Button>
                    </div>
                  )}
                  <TasksTable sendTasksRequest={sendTasksRequest} />
                </CollapseContainer>
                <CollapseContainer
                  titleComponent={
                    <div className={classes.headerContainer}>
                      <h3 className={classes.header}>Services</h3>
                    </div>
                  }
                  startCollapsed={false}
                >
                  <ServicesTable
                    currentTour={currentTour}
                    tourReviewed={tourReviewed}
                    tourReviewable={tourReviewable}
                  />
                </CollapseContainer>
              </Loading>
            </CardBody>
          </Card>
        </TourLoading>
      </GridItem>

      <GridItem md={2} sm={2} className={classes.sidebarContainer}>
        <TourLoading
          isLoading={isOrganizationsLoading}
          message={"No organizations were found."}
          notFound={!!organizations?.length}
        >
          {organizations?.map((org, orgIndex) => {
            return (
              <CollapseContainer
                titleComponent={
                  <div>
                    <h3 style={{ margin: 0 }}>
                      {org.name} ({org.tourCount})
                    </h3>
                    <p
                      style={{
                        fontWeight: 500,
                        color: "#999",
                        marginBottom: 8,
                      }}
                    ></p>
                  </div>
                }
                startCollapsed={currentOrgIndex !== orgIndex}
                onExpand={() => {
                  onOrgClick(orgIndex);
                }}
                name={org.name}
                key={org.id}
                ignoreLocalStorage
                classes={{
                  root: classes.toursListRootContainer,
                  collapseContainer: classes.tourCardCollapseContainer,
                }}
              >
                {currentOrg?.id === org.id && (
                  <TourList
                    org={currentOrg}
                    orgIndex={orgIndex}
                    onSelect={onTourClick}
                    tabs
                  />
                )}
              </CollapseContainer>
            );
          })}
        </TourLoading>
      </GridItem>

      <AssignSupplierModal
        isOpen={assignModal === enums.DispatchModals.SUPPLIER}
        onClose={onCloseAssignModal}
        currentTour={currentTour}
      />
      <AssignServiceProviderRentalModal
        isOpen={assignModal === enums.DispatchModals.SERVICE_PROVIDER_RENTAL}
        onClose={onCloseAssignModal}
        currentTour={currentTour}
      />
      <AssignCourierRentalModal
        isOpen={assignModal === enums.DispatchModals.COURIER_RENTAL}
        onClose={onCloseAssignModal}
        currentTour={currentTour}
      />
      <AssignCourierModal
        isOpen={assignModal === enums.DispatchModals.COURIER}
        onClose={onCloseAssignModal}
        currentTour={currentTour}
      />
      <AssignDriverModal
        isOpen={assignModal === enums.DispatchModals.DRIVER}
        onClose={onCloseAssignModal}
        currentTour={currentTour}
      />

      <AssignTruckModal
        isOpen={assignModal === enums.DispatchModals.TRUCK}
        onClose={onCloseAssignModal}
        currentTour={currentTour}
      />
      <CancelTourModal
        isOpen={assignModal === enums.DispatchModals.CANCEL}
        onClose={onCloseAssignModal}
        currentTour={currentTour}
      />
      <UpdatesModal
        isOpen={assignModal === enums.DispatchModals.UPDATES}
        onClose={onCloseAssignModal}
        currentTour={currentTour}
      />
    </GridContainer>
  );
}
