import { makeStyles } from "@material-ui/core/styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import translations from "constants/translations/index";
import enums from "enums/index";
import React from "react";
import { getI18n, useTranslation } from "react-i18next";
import api from "services/api";
import { ServiceProviderRentalContext } from "views/ServiceProviderRentals/pages/ServiceProviderRentalView/ServiceProviderRentalView.context";
import ServiceProviderRentalForm from "../ServiceProviderRentalFrom/ServiceProviderRentalForm";
import styles from "./ServiceProviderRentalDetails.styles";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ServiceProviderRentalDetails() {
  const classes = useStyles();

  const [isEditing, setIsEditing] = React.useState(false);

  const { i18n } = useTranslation();

  const { serviceProviderRental } = React.useContext(
    ServiceProviderRentalContext
  );

  const handleEdit = () => {
    setIsEditing(true);
  };

  return isEditing ? (
    <ServiceProviderRentalForm edit={true} setIsEditing={setIsEditing} />
  ) : (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <h3 className={classes.header}>Service Provider Rental details</h3>
        <CheckPermission
          resource={enums.Resource.SERVICE_PROVIDER_RENTALS}
          action={enums.Action.UPDATE}
        >
          <Button onClick={handleEdit} color="primary" edit={true} />
        </CheckPermission>
      </div>
      <div className={classes.section}>
        <CollapseContainer
          info="basicInfo"
          titleComponent={<h3 style={{ margin: 0 }}>Basic Info</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={serviceProviderRental?.serviceProvider?.name}
              label="Service Provider"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={serviceProviderRental?.truckType?.details?.name}
              label="Truck Type"
            />
            <InfoWithLabelGridItem
              info={serviceProviderRental?.startDate}
              type={enums.InfoType.DATE}
              label="Start Date"
            />
            <InfoWithLabelGridItem
              info={serviceProviderRental?.endDate}
              type={enums.InfoType.DATE}
              label="End Date"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.LIST}
              info={serviceProviderRental?.offDays?.map(
                (day) => translations[getI18n().language].weekDay[day]
              )}
              label="Off Days"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={serviceProviderRental?.workingDays}
              label="Number Of Working Days"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={serviceProviderRental?.truck?.name}
              label="Truck"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={serviceProviderRental?.driver?.name}
              label="driver"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={serviceProviderRental?.kmAllowance}
              label="Km Allowance"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={serviceProviderRental?.durationAllowance}
              label="Duration Allowance"
            />

            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={serviceProviderRental?.extraKmCost}
              label="Extra Km Cost"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={serviceProviderRental?.monthlyTruckCost}
              label="Monthly Truck Cost"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={serviceProviderRental?.extraHourCost}
              label="Extra Hour Cost"
            />
            {serviceProviderRental?.cancellationReason && (
              <InfoWithLabelGridItem
                type={enums.InfoType.TEXT}
                info={serviceProviderRental?.cancellationReason}
                label="Cancellation Reason"
              />
            )}
            <InfoWithLabelGridItem
              md={12}
              info={serviceProviderRental?.contractUrl}
              type={enums.InfoType.FILE}
              label="Contract Image"
            />
          </GridContainer>
        </CollapseContainer>
      </div>
    </div>
  );
}
