import enums from "enums";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { AuthContext } from "shared/context/auth-context";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { createValidationSchema, isEmpty } from "utils/index";
import TruckTypeFormValidation from "./TruckTypeForm.validation";

//------------------------------------------------------------------

export const useTruckTypeForm = ({
  edit,
  create,
  path,
  dealTruck,
  isOpen,
  onClose,
  formType,
}) => {
  const [data, setData] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const [sendGetRequest, isGetLoading] = useHttpClient();

  const dataResource = React.useMemo(() => api.truckTypes, []);

  const resolver = useYupValidationResolver(TruckTypeFormValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue } = form;

  const auth = React.useContext(AuthContext);

  const history = useHistory();

  React.useEffect(() => {
    if (edit && formType === enums.FormContainerType.PAGE) {
      const id = window.location.pathname.split("/").reverse()[1];
      if (id) {
        (async () => {
          try {
            const response = await sendGetRequest(dataResource.getOne(id));
            setData(response);
            if (response) {
              Object.keys(response).forEach((key) =>
                setValue(key, response[key])
              );
            }
          } catch (err) {
            toast.error("An error has occurred");
            if (formType === enums.FormContainerType.MODAL) {
              onClose();
            } else {
              history.push({
                pathname: `/admin/${path}`,
              });
            }
          }
        })();
      } else {
        toast.error("An error has occurred");
        if (formType === enums.FormContainerType.MODAL) {
          onClose();
        } else {
          history.push({
            pathname: `/admin/${path}`,
          });
        }
      }
    } else if (
      formType === enums.FormContainerType.MODAL &&
      isOpen &&
      dealTruck
    ) {
      setData(dealTruck);
      Object.keys(dealTruck).forEach((key) => setValue(key, dealTruck[key]));
    }
  }, [edit, formType, isOpen, dealTruck]);

  const createHandler = (values) => async () => {
    try {
      const response = await sendRequest(api.truckTypes.create(values));
      toast.success(`Truck type has been created successfully`);
      history.push({
        pathname: `/admin/${path}`,
      });
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const editHandler = (values) => async () => {
    try {
      const response = await sendRequest(dataResource.patch(values));
      toast.success(`Truck type has been updated successfully`);
      if (formType === enums.FormContainerType.MODAL) {
        onClose();
      } else {
        history.push({
          pathname: `/admin/${path}`,
        });
      }
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let values = getValues();

    let isPerTripEmpty = true;
    if (values.perTrip?.details)
      Object.keys(values.perTrip.details).forEach((key) => {
        if (values.perTrip.details[key] === NaN) {
          values.perTrip.details[key] = null;
          isPerTripEmpty = false;
        } else if (!isEmpty(values.perTrip.details[key])) {
          isPerTripEmpty = false;
        }
      });

    if (isPerTripEmpty) {
      delete values.perTrip;
    }

    let isRentalEmpty = true;
    if (values.rental?.details)
      Object.keys(values.rental.details).forEach((key) => {
        if (values.rental.details[key] === NaN) {
          values.rental.details[key] = null;
          isRentalEmpty = false;
        } else if (!isEmpty(values.rental.details[key])) {
          isRentalEmpty = false;
        }
      });

    if (isRentalEmpty) {
      delete values.rental;
    }

    handleSubmit(
      create ? createHandler(values) : editHandler(values),
      onError
    )();
  };

  return {
    isLoading,
    isGetLoading,
    form,
    onSubmit,
    data,
  };
};
