import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import Button from "components/CustomButtons/Button.js";
import useAddPenaltyModal from "./AddPenaltyModal.hook";
import translations from "constants/translations";
import { getI18n } from "react-i18next";
import styles from "./AddPenaltyModal.styles";
import api from "services/api";
import BillModal from "../../../BillModal/BillModal";
import enums from "enums/index";
import { formatEnums } from "utils/index";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AddPenaltyModal(props) {
  const { isOpen, onClose, currentTour } = props;

  const {
    serviceProviders,
    form,
    submitHandler,
    serviceProvider,
    handleAddNewBill,
    bills,
    billsDisabled,
  } = useAddPenaltyModal({ currentTour, onClose });
  const classes = useStyles();
  return (
    <CustomModal
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <Card style={{ margin: 0 }}>
        <CardBody>
          <div className={classes.formHeader}>
            <React.Fragment>
              <AddIcon className={classes.icon} />
              Add Penalty
            </React.Fragment>
          </div>
          <form
            style={{ padding: 20, paddingBottom: 0 }}
            onSubmit={submitHandler}
            autoComplete="off"
          >
            <FormField
              md={12}
              form={form}
              name="type"
              type="select"
              label="Penalty Type"
              options={{
                data: Object.keys(translations[getI18n().language].penaltyType)
                  .map((key) => ({
                    value: key,
                    name: translations[getI18n().language].penaltyType[key],
                  }))
                  .filter(({ value, name }) =>
                    [
                      enums.PenaltyType.COURIER_BEHAVIOR,
                      enums.PenaltyType.MISSING_GOODS,
                      enums.PenaltyType.DRIVER_BEHAVIOR,
                      enums.PenaltyType.DAMAGED_GOODS,
                    ].includes(value)
                  ),
              }}
            />
            <FormField
              md={12}
              form={form}
              name="serviceProvider"
              type="autocomplete"
              label="Service Provider"
              options={{
                data: serviceProviders,
                getOptionLabel: (option) => option.nameEn,
                blurOnSelect: false,
              }}
            />
            <FormField
              md={12}
              form={form}
              name="bill"
              type="autocomplete"
              label="Bill"
              options={{
                getOptionLabel: (option) =>
                  option?.name ||
                  [
                    option?.referenceNumber,
                    formatEnums(option?.status),
                    option?.expectedBillDate?.split("T")[0],
                  ]
                    .filter((item) => !!item)
                    .join(" - "),
                data: bills,
                disabled: billsDisabled,
                disabledTooltipText: "Please select service provider first",
                blurOnSelect: false,
                addNew: true,
                formComponent: (
                  <BillModal
                    serviceProviderId={serviceProvider?.id}
                    handleAddNew={handleAddNewBill}
                  />
                ),
              }}
            />
            <FormField
              md={12}
              form={form}
              name="price"
              type="number"
              label="Penalty Price"
            />
            <FormField
              md={12}
              form={form}
              name="notes"
              type="text"
              label="Notes"
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="primary"
                type="submit"
                // loading={isLoading}
                style={{ margin: "20px 0" }}
                create
                loadFromRight
              >
                Add Penalty
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </CustomModal>
  );
}
