import { useMemo, useState } from "react";

export default function useMonthlyOrderDetailsModal(order) {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);

  const closeModal = () => {
    setShowModal(false);
  };

  const isButtonDisabled = useMemo(() => !order?.monthly, [order]);

  return {
    showModal,
    isButtonDisabled,
    openModal,
    closeModal,
  };
}
