import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import MonthlyOrderModalValidation from "./MonthlyOrderModal.validation";
import enums from "enums/index";
import { useHttpClient } from "shared/hooks/http-hook";
import translations from "constants/translations";
import { PlanDetailsContext } from "views/Plans/PlanDetails/PlanDetails.context";
import api from "services/api";
import {
  convertTimeToISO,
  deepen,
  extractTimeFromISO,
  isEmpty,
  toastError,
} from "utils/index";
import PropTypes from "prop-types";
import { MonthlyOrderContext } from "../../MonthlyOrders.context";
//-------------------------------------------------------------------------------------
export default function useMonthlyOrderModal({
  onClose,
  isEditingOrder,
  currentOrder,
}) {
  const { i18n } = useTranslation();
  const [quotationTypes, setQuotationTypes] = React.useState({});
  const [selectedTruckType, setSelectedTruckType] = React.useState(null);
  const [selectedDistanceLimit, setSelectedDistanceLimit] = React.useState(
    null
  );

  const [truckTypes, setTruckTypes] = React.useState([]);
  const [monthlyDistanceOptions, setMonthlyDistanceOptions] = React.useState(
    []
  );
  const [durationLimitOptions, setDurationLimitOptions] = React.useState([]);

  const [cities, setCities] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [filteredDistricts, setFilteredDistricts] = React.useState([]);
  const [filteredLocations, setFilteredLocations] = React.useState([]);

  const [sendRequest, isLoading] = useHttpClient(true);
  const [sendGetQuotationsRequest] = useHttpClient(true);
  const [sendQuotationLocationsRequest] = useHttpClient(true);

  const { organization } = React.useContext(PlanDetailsContext);
  const { getLatestOrders } = React.useContext(MonthlyOrderContext);
  const organizationId = organization?.id;

  const resolver = useYupValidationResolver(MonthlyOrderModalValidation());
  const form = useForm({
    defaultValues: { ...(currentOrder && { ...currentOrder }) },
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });
  const {
    handleSubmit,
    getValues,
    watch,
    formState: { isDirty },
  } = form;

  const deal = watch("deal");
  const location = watch("location");
  const district = watch("district");
  const dealId = deal?.id;
  const truckTypeId = watch("truckType")?.id;
  const cityId = watch("city")?.id;
  const districtId = district?.id;
  const monthlyDistanceQuota = watch("monthlyDistanceQuota");
  const dailyDurationLimit = watch("dailyDurationLimit");
  const locationId = location?.id;

  const isEditingAfterActivation = React.useMemo(
    () => currentOrder?.status !== enums.OrderStatus.PLANNED && isEditingOrder,
    [currentOrder, isEditingOrder]
  );

  const isTruckTypeDisabled = React.useMemo(() => !dealId, [dealId]);
  const isLocationDisabled = React.useMemo(
    () =>
      !dealId ||
      !truckTypeId ||
      !quotationTypes ||
      monthlyDistanceQuota == null ||
      !dailyDurationLimit,
    [dealId, truckTypeId, monthlyDistanceQuota, dailyDurationLimit]
  );
  const isDistanceQuotaDisabled = React.useMemo(() => !truckTypeId, [
    truckTypeId,
  ]);
  const isDurationLimitDisabled = React.useMemo(
    () => monthlyDistanceQuota == null,
    [monthlyDistanceQuota]
  );

  useEffect(() => {
    if (quotationTypes?.truckTypes?.length) {
      const tempTruckTypes = quotationTypes?.truckTypes?.map((truckType) => ({
        id: truckType?.id,
        name: truckType?.name,
      }));
      setTruckTypes(tempTruckTypes);
    }
  }, [quotationTypes]);

  useEffect(() => {
    const selectedTruckType = quotationTypes.truckTypes?.find(
      (truckType) => truckType?.id === truckTypeId
    );
    setSelectedTruckType(selectedTruckType);

    if (selectedTruckType?.distanceLimits?.length) {
      const tempDistanceLimitOptions = selectedTruckType?.distanceLimits?.map(
        (distanceLimit) => distanceLimit.value.toString()
      );
      setMonthlyDistanceOptions(tempDistanceLimitOptions);
    }
  }, [truckTypeId, quotationTypes]);

  useEffect(() => {
    const selectedDistanceLimit = selectedTruckType?.distanceLimits?.find(
      (el) => el.value === parseInt(monthlyDistanceQuota)
    );
    setSelectedDistanceLimit(selectedDistanceLimit);
    if (selectedDistanceLimit?.durationLimits?.length) {
      const tempDurationLimitOptions = selectedDistanceLimit?.durationLimits?.map(
        (durationLimit) => durationLimit.value.toString()
      );
      setDurationLimitOptions(tempDurationLimitOptions);
    }
  }, [
    truckTypeId,
    monthlyDistanceQuota,
    quotationTypes,
    selectedDistanceLimit,
    selectedTruckType,
  ]);

  useEffect(() => {
    if (truckTypeId && monthlyDistanceQuota != null && dailyDurationLimit) {
      const selectedDurationLimit = selectedDistanceLimit?.durationLimits?.find(
        (el) => el.value === parseInt(dailyDurationLimit)
      );
      setCities(selectedDurationLimit?.cities);
      setDistricts(selectedDurationLimit?.districts);
      setLocations(selectedDurationLimit?.locations);
    }
  }, [
    truckTypeId,
    monthlyDistanceQuota,
    dailyDurationLimit,
    quotationTypes,
    selectedTruckType,
    selectedDistanceLimit,
  ]);

  // reset fields on change

  useEffect(() => {
    if (isDirty) {
      form.setValue(`truckType`, null);
      form.setValue(`monthlyDistanceQuota`, null);
      form.setValue(`dailyDurationLimit`, null);
    }
  }, [dealId]);

  useEffect(() => {
    if (isDirty) {
      form.setValue(`monthlyDistanceQuota`, null);
      form.setValue(`dailyDurationLimit`, null);
    }
  }, [truckTypeId]);

  useEffect(() => {
    if (isDirty) {
      form.setValue(`dailyDurationLimit`, null);
    }
  }, [monthlyDistanceQuota]);

  useEffect(() => {
    if (dealId) {
      (async () => {
        try {
          const response = await sendGetQuotationsRequest(
            api.deals.getQuotationTypes(dealId, enums.TourType.MONTHLY)
          );
          if (response[0]) {
            setQuotationTypes(response[0]);
          }
        } catch (err) {
          // toast.error("An error has occurred");
        }
      })();
    }
  }, [dealId]);

  useEffect(() => {
    (async () => {
      if (
        isEmpty(dealId) ||
        isEmpty(truckTypeId) ||
        isEmpty(monthlyDistanceQuota) ||
        isEmpty(dailyDurationLimit)
      ) {
        return;
      }

      const response = await sendQuotationLocationsRequest(
        api.locations.getQuotationLocations(undefined, undefined, undefined, {
          dealId,
          truckTypeId,
          kmLimit: monthlyDistanceQuota,
          daysHoursLimit: dailyDurationLimit,
          tourType: enums.TourType.MONTHLY,
          taskType: enums.TaskType.PICKUP,
        })
      );

      setCities(response?.cities);
      setDistricts(response?.districts);
      setLocations(response?.locations);

      setFilteredDistricts(response?.districts);
      setFilteredLocations(response?.locations);
    })();
  }, [dealId, truckTypeId, monthlyDistanceQuota, dailyDurationLimit]);

  useEffect(() => {
    if (districtId !== undefined && districtId !== null) {
      const district = districts?.find((district) => {
        return district?.id === districtId;
      });
      const city = cities?.find((city) => {
        return city?.id === district?.cityId;
      });
      if (city) form.setValue(`city`, city);
    }
    handleDistrictChange();
  }, [districtId, cities]);

  useEffect(() => handleCityChange(), [cityId]);

  useEffect(() => {
    const location = locations?.find((location) => {
      return location?.id === locationId;
    });
    const district = districts?.find(
      (district) => district?.id === location?.districtId
    );
    if (district) form.setValue(`district`, district);
    const city = cities?.find((city) => city?.id === district?.cityId);
    if (city) form.setValue(`city`, city);
  }, [locationId, locations, districts]);

  useEffect(() => {
    if (currentOrder?.monthly) {
      form.setValue(
        "pickupTime",
        extractTimeFromISO(currentOrder?.monthly?.pickupTime)
      );
    }
  }, [currentOrder, isEditingOrder]);

  const handleCityChange = () => {
    setFilteredDistricts(
      districts?.filter((district) => district.cityId == cityId)
    );
    if (district?.cityId != cityId) form.setValue(`district`, null);
  };

  const handleDistrictChange = () => {
    setFilteredLocations(
      locations?.filter((location) => location.districtId == districtId)
    );
    if (location?.districtId != districtId) form.setValue(`location`, null);
  };

  const createHandler = (values) => async () => {
    try {
      await sendRequest(api.orders.create(deepen(values)));
      toast.success(`Monthly Order has been created successfully`);
      getLatestOrders();
      onClose();
    } catch {}
  };

  const editHandler = (values) => async () => {
    try {
      if (isEditingOrder && currentOrder.status === enums.OrderStatus.ACTIVE) {
        await sendRequest(
          api.orders.patch({
            id: currentOrder.id,
            monthly: { pickupTime: values.pickupTime },
          })
        );
      } else {
        await sendRequest(api.orders.create(deepen(values)));
      }
      toast.success(`Monthly Order has been updated successfully`);
      getLatestOrders();
      onClose();
    } catch (err) {
      console.log("err", err);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let values = getValues();
    if (values?.truckType?.id) {
      values.truckTypeId = values.truckType.id;
      delete values.truckType;
    }
    if (values?.monthlyDistanceQuota != undefined) {
      values.monthlyDistanceLimit = parseInt(values.monthlyDistanceQuota);
    }
    if (values?.dailyDurationLimit) {
      values.dailyDurationLimit = parseInt(values.dailyDurationLimit);
    }
    if (values?.offDays) {
      values.offDays = values.offDays.map((day) => parseInt(day.id));
    }
    if (!values?.offDays) {
      values.offDays = [];
    }
    if (values?.deal?.id) {
      values.dealId = values.deal.id;
      delete values.deal;
    }

    if (values?.location?.id) {
      values.pickupLocationId = values.location.id;
      delete values.location;
    }

    if (values?.channel) {
      values.orderChannel = values.channel;
      delete values.channel;
    }
    if (values?.pickupTime && values?.startDate) {
      values.pickupTime = convertTimeToISO(
        values.startDate,
        values?.pickupTime
      );
    }

    values.type = enums.TourType.MONTHLY;
    values.organizationId = organizationId;

    delete values.district;
    delete values.city;
    values.quantity = 1;
    handleSubmit(
      isEditingOrder ? editHandler(values) : createHandler(values),
      onError
    )();
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const getLocationTaskTypes = () => {
    const translationObj = translations[i18n.language]["locationTaskType"];
    const locationTaskTypeMap = Object.keys(translationObj).reduce(
      (pre, key) => {
        pre[key] = {
          value: key,
          name: translationObj[key],
        };
        return pre;
      },
      {}
    );
    let taskTypes = [
      locationTaskTypeMap[enums.LocationTaskType.ALL],
      locationTaskTypeMap[enums.LocationTaskType.PICKUPS_AND_RETURNS_ONLY],
    ];
    return taskTypes;
  };

  const handleAddNew = (location) => {
    form.setValue("location", location);
    setLocations((prevLocations) => [...prevLocations, location]);
  };

  return {
    isEditingAfterActivation,
    isLoading,
    truckTypes,
    monthlyDistanceOptions,
    durationLimitOptions,
    cities,
    districts: filteredDistricts,
    locations: filteredLocations,
    organizationId,
    isTruckTypeDisabled,
    isLocationDisabled,
    isDistanceQuotaDisabled,
    isDurationLimitDisabled,
    i18n,
    form,
    deal,
    submitHandler,
    getLocationTaskTypes,
    handleAddNew,
  };
}
useMonthlyOrderModal.propTypes = {
  onClose: PropTypes.func,
};
