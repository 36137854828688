import React, { useContext } from "react";
import styles from "./Cards.Styles";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import enums from "enums/index";
import CachedIcon from "@material-ui/icons/Cached";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";
import CancelServiceProvider from "./components/CancelServiceProvider";

const useStyles = makeStyles(styles);

function SupplierCard({ setAssignModal, currentTour }) {
  const { resource } = useContext(TourOperationsContext);
  const serviceProvider = currentTour?.serviceProvider;
  const classes = useStyles();
  return (
    <Card raised className={classes.assignCard}>
      <CardBody className={classes.assignCardBody}>
        <div className={classes.headerContainer}>
          <h3 className={classes.header}>Service Provider</h3>
        </div>
        <GridContainer className={classes.infoContainer}>
          <GridItem>
            <InfoWithLabel label="Name" info={serviceProvider?.nameEn} />
          </GridItem>
          <GridItem>
            <InfoWithLabel label="Phone" info={serviceProvider?.phonePrimary} />
          </GridItem>
          <GridItem>
            <InfoWithLabel
              label="National ID"
              info={serviceProvider?.nationalId}
            />
          </GridItem>
        </GridContainer>
        {![enums.TourStatus.CANCELLED, enums.TourStatus.COMPLETED].includes(
          currentTour?.status
        ) &&
          (serviceProvider ? (
            <div className={classes.buttonsContainer}>
              <CancelServiceProvider currentTour={currentTour} />
            </div>
          ) : (
            <div className={classes.buttonsContainer}>
              <Button
                onClick={() => {
                  setAssignModal(enums.DispatchModals.SUPPLIER);
                }}
                color="primary"
                className={classes.assignButton}
              >
                <CachedIcon className={classes.changeIcon} /> Assign Service
                Provider
              </Button>
              <Button
                onClick={() => {
                  setAssignModal(enums.DispatchModals.SERVICE_PROVIDER_RENTAL);
                }}
                color="primary"
                className={classes.assignButton}
              >
                Assign Service Provider Rental
              </Button>
            </div>
          ))}
      </CardBody>
    </Card>
  );
}

export default SupplierCard;
