import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import CustomForm from "components/Forms/CustomForm/CustomForm";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  daysHoursLimit,
  daysLimit,
  monthlyKmLimit,
  oneDayKmLimit,
} from "constants/arrays";
import translations from "constants/translations/index";
import enums from "enums/index";
import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { createValidationSchema, getDealType, toastError } from "utils/index";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import { QuotationsContext } from "views/Deals/components/Tabs/Quotations/Quotations.context";
import * as yup from "yup";
import styles from "./AddQuotationGroupModal.styles";
import { useTranslation } from "react-i18next";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AddQuotationGroupModal(props) {
  const { isOpen, onClose, type: quotationTypeProp, } = props;

  const classes = useStyles();

  const [data, setData] = React.useState(null);

  const [sendRequest, isLoading, sendRequestError] = useHttpClient();

  const { deal, getLatestDeal } = React.useContext(DealContext);
  const { reloadQuotations } = React.useContext(QuotationsContext);

  const { i18n } = useTranslation();

  React.useEffect(() => {
    if (sendRequestError?.message) {
      toastError(sendRequestError.message);
    }
  }, [sendRequestError]);

  const submitHandler = (values) => async () => {
    try {
      const response = await sendRequest(
        api.quotations.createMany({
          dealId: deal.id,
          type: quotationTypeProp,
          ...values,
          kmLimit: values.kmLimit ? parseInt(values.kmLimit) : null,
          daysLimit: values.daysLimit ? parseInt(values.daysLimit) : null,
          daysHoursLimit: values.daysHoursLimit
            ? parseInt(values.daysHoursLimit)
            : null,
        })
      );
      const dealType = getDealType(response.allQuotations);

      reloadQuotations();
      toast.success(`Quotations added successfully`);
      onClose();
    } catch (err) {
      // toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const formStructure = React.useMemo(
    () => [
      {
        column: 1,
        fields: [
          {
            name: "truckTypeIds",
            type: "autocomplete",
            label: "Truck Types",
            options: {
              getData: api.truckTypes.getAll,
              getOptionLabel: (option) => option.details.name,
              getSubmitValue: (options) => options?.map((el) => el.id),
              multiple: true,
              blurOnSelect: false,
            },
            validation: yup.mixed().required("Required"),
          },
          {
            name: "pickupZoneIds",
            type: "autocomplete",
            label: "Pickup Zones",
            options: {
              getData: api.zones.getAll,
              getOptionLabel: (option) => option.name,
              getSubmitValue: (options) => options?.map((el) => el.id),
              multiple: true,
              blurOnSelect: false,
            },
            validation: yup.mixed().required("Required"),
          },
          ...(quotationTypeProp === enums.QuotationType.PER_TRIP
            ? [
                {
                  name: "dropOffZoneIds",
                  type: "autocomplete",
                  label: "Drop-off Zones",
                  options: {
                    getData: api.zones.getAll,
                    getOptionLabel: (option) => option.name,
                    getSubmitValue: (options) => options?.map((el) => el.id),
                    multiple: true,
                    blurOnSelect: false,
                  },
                  validation: yup.mixed().required("Required"),
                },
              ]
            : []),
          ...(quotationTypeProp === enums.QuotationType.RENTAL
            ? [
                {
                  name: "kmLimit",
                  type: "autocomplete",
                  label: "Km Limit",
                  options: {
                    data: [...oneDayKmLimit, ...monthlyKmLimit],
                    blurOnSelect: false,
                  },
                },
                {
                  name: "daysHoursLimit",
                  type: "autocomplete",
                  label: "Duration Allowance (hours)",
                  options: {
                    data: daysHoursLimit,
                    blurOnSelect: false,
                  },
                },
                {
                  name: "daysLimit",
                  type: "autocomplete",
                  label: "Days limit",
                  options: {
                    data: daysLimit,
                    blurOnSelect: false,
                  },
                  validation: yup.mixed().required("Required"),
                },
              ]
            : []),
        ],
      },
    ],
    [quotationTypeProp]
  );

  const validationSchema = React.useMemo(
    () => createValidationSchema(formStructure),
    [formStructure, createValidationSchema]
  );

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <AddIcon className={classes.icon} />
                  {`Add ${
                    translations[i18n.language].quotationType[quotationTypeProp]
                  } Quotation`}
                </React.Fragment>
              </div>
              <CustomForm
                type={enums.FormContainerType.MODAL}
                formStructure={formStructure}
                submitHandler={submitHandler}
                onError={onError}
                loading={isLoading}
                validationSchema={validationSchema}
                data={data}
                create
                submitButtonText="Add"
                submitButtonColor="success"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

AddQuotationGroupModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

AddQuotationGroupModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  create: PropTypes.bool,
  edit: PropTypes.bool,
};
