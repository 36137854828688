import React, { useContext } from "react";
import styles from "./Cards.Styles";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import enums from "enums/index";
import CachedIcon from "@material-ui/icons/Cached";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

const useStyles = makeStyles(styles);

function CourierCard({ setAssignModal, currentTour }) {
  const { resource } = useContext(TourOperationsContext);
  const courier = currentTour?.courier;
  const classes = useStyles();
  return (
    <Card raised className={classes.assignCard}>
      <CardBody className={classes.assignCardBody}>
        <div className={classes.headerContainer}>
          <h3 className={classes.header}>Courier</h3>
        </div>
        <GridContainer className={classes.infoContainer}>
          <GridItem>
            <InfoWithLabel label="Name" info={courier?.nameEn} />
          </GridItem>
          <GridItem>
            <InfoWithLabel label="Phone" info={courier?.phonePrimary} />
          </GridItem>
          <GridItem>
            <InfoWithLabel label="National ID" info={courier?.nationalId} />
          </GridItem>
          <GridItem>
            <InfoWithLabel
              label="Service Provider"
              info={currentTour?.courierServiceProvider?.name}
            />
          </GridItem>
        </GridContainer>

        {![enums.TourStatus.CANCELLED, enums.TourStatus.COMPLETED].includes(
          currentTour?.status
        ) && (
          <div className={classes.buttonsContainer}>
            <Button
              onClick={() => {
                setAssignModal(enums.DispatchModals.COURIER);
              }}
              color="primary"
              className={classes.assignButton}
            >
              <CachedIcon className={classes.changeIcon} /> Assign Courier
            </Button>
            <Button
              onClick={() => {
                setAssignModal(enums.DispatchModals.COURIER_RENTAL);
              }}
              color="primary"
              className={classes.assignButton}
            >
              Assign Courier Rental
            </Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default CourierCard;
