import {
  defaultFont,
  successColor,
} from "assets/jss/material-dashboard-pro-react.js";

//-------------------------------------------------------------------------------------

const styles = (theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButton: {
    border: "1px solid #ddd",
    borderRadius: "50%",
    fontWeight: "700",
    marginLeft: "auto",
    padding: 5,
  },
  closeIcon: {
    width: "25px !important",
    height: "25px !important",
    margin: "0px !important",
  },
  form: {
    padding: 40,
    paddingBottom: 0,
  },
  section: {
    width: "100%",
    marginBottom: 80,
  },
  buttonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    height: 60,
  },
  cancelButton: {
    marginRight: 20,
    marginTop: 20,
    height: 41.2,
  },
});

export default styles;
