import * as yup from "yup";
import enums from "enums/index";

//----------------------------------------------------------

const validateActiveStatus = (status) => {
  if (status == enums.OrganizationStatus.ACTIVE)
    return yup.mixed().required("Required");
};

export default () => {
  return yup.object().shape({
    name: yup.string().required("Required"),
    officialName: yup.string().required("Required"),
    organizationClassification: yup.string().required("Required"),
    organizationSize: yup.string().required("Required"),
    locations: yup.mixed(),
    commercialRegistrationNumber: yup
      .mixed()
      .when("status", validateActiveStatus),
    taxDocumentUrl: yup.mixed().when("status", validateActiveStatus),
    commercialRegistrationDocumentUrl: yup
      .mixed()
      .when("status", validateActiveStatus),
    taxId: yup.mixed().when("status", validateActiveStatus),
    category: yup.mixed().required("Required"),
    industries: yup.mixed().required("Required"),
    contacts: yup.mixed().required("Required"),
    status: yup
      .mixed()
      .required("Required")
      .when(
        [
          "name",
          "officialName",
          "organizationClassification",
          "organizationSize",
          "category",
          "industries",
          "contacts",
        ],
        {
          _is: (
            name,
            officialName,
            organizationClassification,
            organizationSize,
            category,
            industries,
            contacts
          ) => {
            console.log({
              name,
              officialName,
              organizationClassification,
              organizationSize,
              category,
              industries,
              contacts,
            });
            return (
              !name ||
              !officialName ||
              !organizationClassification ||
              !organizationSize ||
              !category ||
              !industries ||
              !contacts
            );
          },
          get is() {
            return this._is;
          },
          set is(value) {
            this._is = value;
          },
          then: yup
            .string()
            .oneOf(
              ["INACTIVE", "BLACK_LISTED"],
              "For the organization to be active, all information should be complete and documents are uploaded"
            ), //if not all information are complete
          otherwise: yup
            .string()
            .oneOf(
              ["ACTIVE", "INACTIVE", "BLACK_LISTED"],
              "Status can only be active or inactive or blacklisted"
            ), //if all information are complete
        }
      ),
  });
};
