import { useForm } from "react-hook-form";
import AssignTruckModalValidation from "./AssignTruckModal.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { useContext, useEffect, useMemo, useState } from "react";
import api from "services/api";
import { MonthlyOrderDetailsContext } from "views/Plans/MonthlyOrderDetails/MonthlyOrderDetails.context";

export default function useAssignTruckModal({ order, onClose }) {
  const [sendRequest, isLoading] = useHttpClient(true);
  const resolver = useYupValidationResolver(AssignTruckModalValidation());
  const { setOrder } = useContext(MonthlyOrderDetailsContext);
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });
  const [trucks, setTrucks] = useState([]);

  const { handleSubmit, setValue, getValues, watch } = form;

  const truck = watch("truck");

  const resetTruck = () => {
    if (order?.monthly?.serviceProvider) {
      setValue("truck", order.monthly.truck);

      const fetchTrucks = async () => {
        const response = (
          await api.quotations.getAvailableTrucks(order.monthly.quotationId, {
            serviceProviderId: order?.monthly?.serviceProvider?.id,
          })
        )?.data;
        setTrucks(response);
      };
      fetchTrucks();
    }
  };

  useEffect(() => {
    resetTruck();
  }, [order]);

  const truckId = useMemo(() => truck?.id, [truck]);

  useEffect(() => {
    setValue("licenseImage", truck?.licenseAttachment || "");
    setValue("license", truck?.licenseId || "");
  }, [truckId]);
  const onSubmit = (values) => async () => {
    try {
      const newTruck = {
        ...values.truck,
        licenseId: values.license,
        licenseAttachment: values.licenseImage,
      };
      await sendRequest(
        api.orders.patch({
          id: order.id,
          monthly: {
            truck: newTruck,
          },
        })
      );
      setOrder({
        ...order,
        monthly: { ...order.monthly, truck: newTruck },
      });
      toast.success("Truck assigned successfully");
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const handleAddNew = (truck) => {
    setTrucks((prevTrucks) => [...prevTrucks, truck]);
    setValue("truck", truck);
  };

  return {
    form,
    trucks,
    truckType: order?.monthly?.truckType,
    serviceProvider: order?.monthly?.serviceProvider,
    isLoading,
    resetTruck,
    submitHandler,
    handleAddNew,
  };
}
