import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import React, { useMemo } from "react";
import useServiceProviderRentalTours from "./ServiceProviderRentalTours.hook";
import styles from "./ServiceProviderRentalTours.styles";
import {
  formatEnums,
  generateMonths,
  generateYears,
  getDateString,
  getDurationString,
} from "utils/index";
import { Link } from "react-router-dom";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ServiceProviderRentalTours({ serviceProviderRental }) {
  const classes = useStyles();

  const {
    listData,
    currentPage,
    itemsPerPage,
    searchQuery,
    highlightedText,
    isLoading,
    filtersState,
    onFilterChange,
    onSearchChange,
    handleSubmit,
    handleChange,
  } = useServiceProviderRentalTours({ serviceProviderRental });

  const columns = React.useMemo(
    () => [
      {
        title: "Tour ID",
        render: (_, record) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link to={`/admin/finops?tourId=${record?.id}`} target="_blank">
                <a style={{ color: "blue" }}>{record?.id}</a>
              </Link>
            </div>
          );
        },
      },
      {
        title: "Deal",
        dataIndex: ["deal", "name"],
        canBeHighlighted: true,
      },
      {
        title: "Truck Type",
        dataIndex: ["truckType", "details", "name"],
        canBeHighlighted: true,
      },
      {
        title: "Type",
        dataIndex: ["type"],
        getData: (type) => formatEnums(type),
      },
      {
        title: "Date",
        dataIndex: ["pickupTime"],
        getData: (pickupTime) => getDateString(pickupTime),
      },
      {
        title: "Distance (km)",
        dataIndex: ["distanceKm"],
      },
      {
        title: "Trip duration",
        dataIndex: ["tripDuration"],
        getData: (duration) => getDurationString(duration),
      },
    ],
    []
  );

  const filterInputs = useMemo(() => {
    return [
      {
        name: "month",
        label: "Month",
        type: "autocomplete",
        options: {
          data: generateMonths(filtersState?.year).map((month, index) => ({
            name: month,
            value: index,
          })),
        },
      },
      {
        name: "year",
        label: "Year",
        type: "autocomplete",
        options: { data: generateYears(2021) },
      },
    ];
  }, [filtersState?.year]);

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <React.Fragment>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>Tours</h3>
          </div>
          <div className={classes.tableContainer}>
            <CustomTable
              data={listData.rows}
              onChange={handleChange}
              pagination={{
                total: listData.count,
                current: currentPage,
                pageSize: itemsPerPage,
                position: ["topRight"],
                pageSizeOptions: [10, 20, 50],
              }}
              loading={isLoading}
              highlightedText={highlightedText}
              searchProps={{
                onChange: onSearchChange,
                value: searchQuery,
                placeholder: "Search",
              }}
              filterProps={{
                value: filtersState,
                onChange: onFilterChange,
                inputs: filterInputs,
                updateOnChange: true,
              }}
              handleSubmit={handleSubmit}
              columns={columns}
              addIndexColumn
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}
