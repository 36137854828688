import React from "react";
import qs from "qs";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { getDealType } from "utils/index";

//-------------------------------------------------------------------------------------

export const useDealView = ({ path }) => {
  const [sendRequest, isGetDealLoading] = useHttpClient();
  const [
    submitForPricingRequest,
    isSubmitDealForPricingLoading,
  ] = useHttpClient();
  const [finalizePricingRequest, isFinalizePricingLoading] = useHttpClient();
  const [loseDealRequest, isLoseDealLoading] = useHttpClient();
  const [activateDealRequest, isActivateDealLoading] = useHttpClient();
  const [acceptDealRequest, isAcceptDealLoading] = useHttpClient();
  const [rejectDealRequest, isRejectDealLoading] = useHttpClient();
  const [resubmitPricingRequest, isResubmitPricingLoading] = useHttpClient();

  const [deal, setDeal] = React.useState(null);

  const [currentTab, setCurrentTab] = React.useState(0);

  const [isOpen, setIsOpen] = React.useState(false);

  const history = useHistory();

  const dataResource = React.useMemo(() => api.deals, []);

  const handleLoseDeal = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const getLatestDeal = React.useCallback(async () => {
    const id = window.location.pathname.split("/").pop().trim();
    if (id && !isNaN(id)) {
      try {
        const response = await sendRequest(dataResource.getOne(id));
        const dealType = getDealType(response.quotations);
        setDeal({ quotations: [], ...response, dealType });
      } catch (err) {
        toast.error("An error has occurred");
        history.push({
          pathname: `/admin/${path}`,
        });
      }
    } else {
      toast.error("An error has occurred");
      history.push({
        pathname: `/admin/${path}`,
      });
    }
  }, [dataResource]);

  React.useEffect(() => {
    getLatestDeal();
  }, [getLatestDeal]);

  const submitDealForPricing = React.useCallback(async () => {
    const response = await submitForPricingRequest(
      api.deals.submitForPricing(deal.id)
    );
    setDeal({
      ...deal,
      details: { ...deal.details, status: response.details.status },
      statusHistory: response.statusHistory,
    });
    toast.success("Deal submitted for pricing successfully");
  }, [deal]);

  const finalizePricing = React.useCallback(async () => {
    const response = await finalizePricingRequest(
      api.deals.finalizePricing(deal.id)
    );
    setDeal({
      ...deal,
      details: { ...deal.details, status: response.details.status },
      statusHistory: response.statusHistory,
    });
    toast.success("Pricing finalized successfully");
  }, [deal]);

  const activateDeal = React.useCallback(async () => {
    const response = await activateDealRequest(api.deals.activate(deal.id));
    setDeal({
      ...deal,
      details: { ...deal.details, status: response.details.status },
      statusHistory: response.statusHistory,
    });
    toast.success("Deal activated successfully");
  }, [deal]);

  const acceptDeal = React.useCallback(async () => {
    const response = await acceptDealRequest(api.deals.accept(deal.id));
    setDeal({
      ...deal,
      details: { ...deal.details, status: response.details.status },
      statusHistory: response.statusHistory,
    });
    toast.success("Deal accepted successfully");
  }, [deal]);

  const rejectDeal = React.useCallback(async () => {
    const response = await rejectDealRequest(api.deals.reject(deal.id));
    setDeal({
      ...deal,
      details: { ...deal.details, status: response.details.status },
      statusHistory: response.statusHistory,
    });
    toast.success("Deal rejected successfully");
  }, [deal]);

  const resubmitPricing = React.useCallback(async () => {
    const response = await resubmitPricingRequest(
      api.deals.resubmitPricing(deal.id)
    );
    setDeal({
      ...deal,
      details: { ...deal.details, status: response.details.status },
      statusHistory: response.statusHistory,
    });
    toast.success("Deal resubmitted for pricing successfully");
  }, [deal]);

  const handleTabChange = (newTab) => {
    setCurrentTab(newTab);

    const { tab } = qs.parse(window.location.search?.split("?")[1]);

    if (newTab != tab) {
      history.replace({
        pathname: window.location.pathname,
        search: `?tab=${newTab}`,
      });
    }
  };

  return {
    isGetDealLoading,
    deal,
    currentTab,
    isSubmitDealForPricingLoading,
    isFinalizePricingLoading,
    isLoseDealLoading,
    isActivateDealLoading,
    isAcceptDealLoading,
    isRejectDealLoading,
    isResubmitPricingLoading,
    isOpen,
    handleLoseDeal,
    setDeal,
    getLatestDeal,
    submitDealForPricing,
    finalizePricing,
    activateDeal,
    acceptDeal,
    rejectDeal,
    resubmitPricing,
    onClose,
    handleTabChange,
  };
};
