import React from "react";
import useTourOperationsView from "./TourOperations.hook";
import enums from "enums/index";
import { TourOperationsContext } from "./TourOperations.context";
import Common from "./Components/Common/Common";
import FinOps from "./Components/FinOps/FinOps";

export default function TourOperationsView({ path, resource }) {
  const {
    setCurrentTour,
    getTour,
    setCurrentTourIndex,
    setCurrentOrgIndex,
    getOrganizations,
    getTours,
    toggleDriverOrTruckIsNull,
    setPickupDate,
    toggleServiceProviderIsNull,
    toggleToursCompleted,
    toggleToursReviewed,
    setInvoices,
    currentOrg,
    currentOrgIndex,
    currentTourIndex,
    currentTour,
    organizations,
    serviceProviders,
    toursCompleted,
    pickupDate,
    serviceProviderIsNull,
    driverOrTruckIsNull,
    toursReviewed,
    invoices,
    isOrganizationsLoading,
    isToursLoading,
    isTourLoading,
  } = useTourOperationsView(resource);
  return (
    <TourOperationsContext.Provider
      value={{
        setCurrentTour,
        setCurrentTourIndex,
        setCurrentOrgIndex,
        getOrganizations,
        getTours,
        getTour,
        toggleDriverOrTruckIsNull,
        setPickupDate,
        toggleServiceProviderIsNull,
        toggleToursCompleted,
        toggleToursReviewed,
        setInvoices,
        resource,
        currentOrg,
        currentOrgIndex,
        currentTourIndex,
        currentTour,
        organizations,
        serviceProviders,
        toursCompleted,
        pickupDate,
        serviceProviderIsNull,
        driverOrTruckIsNull,
        toursReviewed,
        invoices,
        isOrganizationsLoading,
        isToursLoading,
        isTourLoading,
      }}
    >
      <Common />
      {!isTourLoading &&
        currentTour &&
        ((resource !== enums.Resource.FIN_OPS &&
          currentOrg?.tours?.length !== 0) ||
          resource === enums.Resource.FIN_OPS) && (
          <FinOps inFinOps={resource === enums.Resource.FIN_OPS} />
        )}
    </TourOperationsContext.Provider>
  );
}
