import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import styles from "./CancelCourierRental.styles";
import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import useCancelCourierRental from "./CancelCourierRental.hook";
import Button from "components/CustomButtons/Button.js";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function CancelCourierRental(props) {
  const { tourToCancel, isCancelModalOpen, toggleCancelModal } = props;
  const { submitHandler, isTourRequestLoading } = useCancelCourierRental(props);
  const classes = useStyles();
  return (
    isCancelModalOpen && (
      <CustomModal
        open={isCancelModalOpen}
        onClose={() => {
          toggleCancelModal();
        }}
        modalHeaderClassName={classes.modalHeader}
        modalBodyClassName={classes.modalBody}
      >
        <Card style={{ margin: 0 }}>
          <CardBody>
            <div className={classes.formHeader}>
              <React.Fragment>
                Cancel Courier Rental From {tourToCancel?.id}
              </React.Fragment>
            </div>
            <form
              style={{ padding: 20, paddingBottom: 0 }}
              onSubmit={submitHandler}
              autoComplete="off"
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="primary"
                  type="submit"
                  loading={isTourRequestLoading}
                  style={{ margin: "20px 0" }}
                  loadFromRight
                >
                  Cancel Courier Rental
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </CustomModal>
    )
  );
}
