import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import CustomForm from "components/Forms/CustomForm/CustomForm";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import enums from "enums";
import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { createValidationSchema } from "utils";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import contractsForm from "views/Organizations/contractsForm";
import styles from "./UploadContractModal.styles";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function UploadContractModal(props) {
  const { isOpen, onClose } = props;

  const classes = useStyles();

  const [data, setData] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const { deal, setDeal } = React.useContext(DealContext);

  React.useEffect(() => {
    if (deal) {
      setData({ dealId: deal?.id });
    }
  }, [deal]);

  const submitHandler = (values) => async () => {
    try {
      const contract = await sendRequest(api.contracts.create(values));
      setDeal({ ...deal, contracts: [...(deal?.contracts || []), contract] });
      toast.success(`Contract uploaded successfully`);
      onClose();
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const validationSchema = createValidationSchema(
    contractsForm(deal?.organization?.id)
  );

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <i className={"fas fa-upload " + classes.icon} />
                Upload Contract
              </div>
              <CustomForm
                type={enums.FormContainerType.MODAL}
                formStructure={contractsForm(deal?.organization?.id)}
                submitHandler={submitHandler}
                onError={onError}
                loading={isLoading}
                validationSchema={validationSchema}
                data={data}
                create={true}
                submitButtonText="Upload"
                submitButtonColor="success"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

UploadContractModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

UploadContractModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
