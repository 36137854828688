import * as yup from "yup";

export default () => {
  return yup.object().shape({
    courier: yup.mixed().required("Must Choose a courier"),
    name: yup.mixed().required("Required"),
    phoneNumber: yup
      .string()
      .matches(/^((\+2|002)?01\d{9})$/, "Invalid primary phone number")
      .required("Required"),
    phoneNumberSecondary: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .matches(/^((\+2|002)?01\d{9})$/, "Invalid secondary phone number")
      .nullable(),
    nationalId: yup
      .mixed()
      .required()
      .test(
        "invalid-national-id-length",
        "National ID should be 14 numbers long",
        (nationalId) => {
          return nationalId?.length === 14;
        }
      ),
  });
};
