const styles = (theme) => ({
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    marginBottom: -10,
  },
  assignButton: {
    padding: "5px 15px",
    marginTop: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    margin: 0,
    marginLeft: 10,
  },

  assignCard: {
    height: "100%",
    margin: "0px",
    display: "flex",
    alignItems: "center",
  },
  noPadding: {
    padding: "0",
  },
  assignCardBody: {
    width: "100%",
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
  },
  infoContainer: {
    marginBottom: "5px",
    padding: 10,
  },
  buttonsContainer: {
    padding: 5,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: 60,
  },
});

export default styles;
