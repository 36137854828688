import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import GridContainer from "components/Grid/GridContainer.js";
import Loading from "components/Loading/Loading";
import { useDealDetailsForm } from "./DealDetailsForm.hook";
import styles from "./DealDetailsForm.styles";
import React from "react";
import api from "services/api";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function DealDetailsForm(props) {
  const { create, edit, setIsEditing, path } = props;
  const classes = useStyles();
  const {
    isLoading,
    onSubmit,
    form,
    isGetDealLoading,
    deal,
  } = useDealDetailsForm({
    edit,
    setIsEditing,
    path,
  });

  return (
    <form className={classes.form} onSubmit={onSubmit} autoComplete="off">
      <Loading loading={isGetDealLoading} style={{ height: "60vh" }}>
        <div className={classes.section}>
          <div className={classes.headerContainer}>
            <h3>Basic Info</h3>
            {edit && (
              <Button
                simple
                className={classes.closeButton}
                key="close"
                aria-label="Close"
                onClick={() => setIsEditing(false)}
                color="danger"
              >
                <Close className={classes.closeIcon} />
              </Button>
            )}
          </div>
          <GridContainer>
            <FormFieldGridItem
              type="text"
              form={form}
              name="details.name"
              label="Deal Name"
            />
            {edit && (
              <FormFieldGridItem
                type="autocomplete"
                form={form}
                name="owner"
                label="Deal Owner"
                options={{
                  getData: api.users.getAll,
                  getOptionLabel: (option) => option.name,
                }}
              />
            )}
            <FormFieldGridItem
              type="text"
              form={form}
              name="details.crmIdentifier"
              label="CRM Identifier"
            />
            <FormFieldGridItem
              type="autocomplete"
              form={form}
              name="details.goodsTypes"
              label="Goods Types"
              options={{
                getOptionLabel: (option) => option.name,
                enum: "goodsType",
                multiple: true,
                blurOnSelect: false,
              }}
            />
            <FormFieldGridItem
              type="autocomplete"
              form={form}
              name="details.temperatureControl"
              label="Temperature Control"
              options={{
                getOptionLabel: (option) => option.name,
                enum: "temperatureControl",
                multiple: true,
                blurOnSelect: false,
              }}
            />
            <FormFieldGridItem
              type="select"
              form={form}
              name="details.recurringInvoicePeriod"
              label="Recurring Invoice Period"
              options={{
                enum: "invoiceRecurrence",
              }}
            />
            <FormFieldGridItem
              type="select"
              form={form}
              name="details.source"
              label="Deal Source"
              options={{
                enum: "dealSource",
              }}
            />
            <FormFieldGridItem
              type="autocomplete"
              form={form}
              name="locationTypes"
              label="Location Types"
              options={{
                getOptionLabel: (option) => option.name,
                getData: api.locationTypes.getAll,
                freeSolo: true,
                dataResource: api.locationTypes,
                multiple: true,
                blurOnSelect: false,
              }}
            />
          </GridContainer>
        </div>
        <div className={classes.section}>
          <h3>Expected Volumes and Attractiveness</h3>
          <GridContainer>
            <FormFieldGridItem
              type="select"
              form={form}
              name="details.attractiveness"
              label="Attractiveness"
              options={{
                enum: "attractiveness",
              }}
            />
            <FormFieldGridItem
              type="number"
              form={form}
              name="details.expectedToursPerMonth"
              label="Expected Tours Per Month"
            />
            <FormFieldGridItem
              type="number"
              form={form}
              name="details.totalNoOfOutsourcedToursPerMonth"
              label="Total Number Of Outsourced Tours Per Month"
            />
            <FormFieldGridItem
              type="date"
              form={form}
              name="details.expectedCloseDate"
              label="Deal Expected Close Date"
            />
            {/* 
            
             */}
          </GridContainer>
        </div>
        <div className={classes.section}>
          <h3>Ops Description</h3>
          <GridContainer>
            <FormFieldGridItem
              type="text"
              form={form}
              name="details.description"
              label="Description"
              options={{
                multiline: true,
                rows: 5,
              }}
            />
            <FormFieldGridItem
              type="number"
              form={form}
              name="details.onDemandFulfillmentDuration"
              label="On Demand Fulfillment Duration (days)"
            />
            <FormFieldGridItem
              type="number"
              form={form}
              name="details.rentalFulfillmentDuration"
              label="Rental Fulfillment Duration (days)"
            />
            <FormFieldGridItem
              type="autocomplete"
              form={form}
              name="details.expectedPickupTime"
              label="Expected Pickup Time"
              options={{
                getOptionLabel: (option) => option.name,
                enum: "dealExpectedPickupTime",
                multiple: true,
                blurOnSelect: false,
              }}
            />
            <FormFieldGridItem
              type="number"
              form={form}
              name="details.expectedQueuingDuration"
              label="Expected Queuing Duration (hours)"
            />
            <FormFieldGridItem
              type="number"
              form={form}
              name="details.expectedLoadingDuration"
              label="Expected Loading Duration (hours)"
            />
            <FormFieldGridItem
              type="number"
              form={form}
              name="details.expectedDropOffDuration"
              label="Expected Drop Off Duration (hours)"
            />
            {/* <FormFieldGridItem
              type="autocomplete"
              form={form}
              name="tags"
              label="Tags"
              options={{
                getOptionLabel: (option) => option.name,
                getData: api.tags.getAll,
                freeSolo: true,
                formStructure: tagsForm,
                dataResource: api.tags,
                defaultValues: { dealId: deal?.id },
                multiple: true,
                blurOnSelect: false,
              }}
            /> */}
          </GridContainer>
        </div>
        <div className={classes.buttonsContainer}>
          {edit && (
            <Button
              color="danger"
              onClick={() => setIsEditing(false)}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          )}
          <Button
            color="primary"
            type="submit"
            loading={isLoading}
            style={{ marginTop: 20 }}
            create={create}
            edit={edit}
            loadFromRight
          />
        </div>
      </Loading>
    </form>
  );
}
