import * as yup from "yup";

//-------------------------------------------------------------------------------------

export default () => {
  return yup.object({
    details: yup.object({
      name: yup.string().required("This field is required"),
      crmIdentifier: yup.string().required("Required"),
    }),
  });
};
