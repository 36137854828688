import { makeStyles } from "@material-ui/core/styles";
import React, { useRef, useEffect, useState } from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { toast } from "react-toastify";
import FormField from "components/Forms/FormField/FormField";
import Loading from "components/Loading/Loading";
import classNames from "classnames";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import styles from "./InfoCard.styles";
import { useForm } from "react-hook-form";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import * as yup from "yup";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

const InfoItem = ({
  info,
  options,
  form,
  name,
  type,
  onSave,
  editable,
  infoItemClasses,
  infoItemLabelClasses,
  editIconClasses,
  saveIconClasses,
  inputContainerClasses,
}) => {
  const [editing, setEditing] = useState(false);
  const handleSave = async () => {
    await onSave();
    setEditing(false);
  };

  const item = (
    <div className={infoItemClasses}>
      <p className={infoItemLabelClasses}>{info.label}:</p>
      {!form ? (
        <p>{info.text}</p>
      ) : editing ? (
        <>
          <form className={inputContainerClasses}>
            <FormField name={name} type={type} form={form} options={options} />
          </form>
          <SaveIcon onClick={handleSave} className={saveIconClasses} />
        </>
      ) : (
        <>
          <p>{info.text}</p>
          <EditIcon
            onClick={() => setEditing(true)}
            className={editIconClasses}
          />
        </>
      )}
    </div>
  );

  return editable ? (
    <ClickAwayListener onClickAway={() => setEditing(false)}>
      {item}
    </ClickAwayListener>
  ) : (
    item
  );
};

export default function InfoCard({
  columns,
  info,
  updateInfo,
  loading,
  ...props
}) {
  const dismissRef = useRef(null);
  const classes = useStyles();

  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.infoCardLoading]: loading,
  });

  const resolver = useYupValidationResolver(
    yup.object().shape({
      date: yup.string().required("Required"),
    })
  );

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: { date: null },
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    if (!info) return;
    const key = info.expectedInvoiceDate
      ? "expectedInvoiceDate"
      : "expectedBillDate";
    setValue("date", info[key]);
  }, [info]);

  const onSubmit = async (e) => {
    // e.preventDefault();

    let values = getValues();

    const key = info.expectedInvoiceDate
      ? "expectedInvoiceDate"
      : "expectedBillDate";

    try {
      await updateInfo({ [key]: values.date });
      toast.success("Date updated successfully");
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  return (
    <GridContainer ref={dismissRef}>
      <Card className={classes.card}>
        <CardBody className={cardBodyClasses}>
          <div className={classes.cardContainer}>
            <Loading
              loading={loading}
              circularProgressCustomClasses={classes.loading}
            >
              {info
                ? columns.map((column) => {
                    let text = info[column.dataIndex];
                    if (column.getData) text = column.getData(text);
                    const infoItemProps =
                      column.editable && column.type === "date"
                        ? {
                            form,
                            type: column.type,
                            name: "date",
                            options: {
                              formControlProps: {
                                className: classes.dateFormControl,
                              },
                              inputCustomClasses: classes.dateInput,
                              inputProps: { placeholder: "dd/mm/yyyy" },
                            },
                            editable: column.editable,
                          }
                        : {};

                    return (
                      <InfoItem
                        info={{ label: column.title, text }}
                        infoItemClasses={classes.infoItem}
                        infoItemLabelClasses={classes.infoItemLabel}
                        editIconClasses={classes.editIcon}
                        saveIconClasses={classes.saveIcon}
                        onSave={onSubmit}
                        inputContainerClasses={classes.dateInputContainer}
                        {...infoItemProps}
                      />
                    );
                  })
                : null}
            </Loading>
          </div>
        </CardBody>
      </Card>
    </GridContainer>
  );
}

InfoCard.propTypes = {
  info: PropTypes.object,
  updateInfo: PropTypes.func,
  loading: PropTypes.bool,
  columns: PropTypes.array,
};
