import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import styles from "./AssignServiceProviderModal.styles";
import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import useAssignServiceProviderModal from "./AssignServiceProviderModal.hook";
import Button from "components/CustomButtons/Button.js";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AssignServiceProviderModal(props) {
  const { isOpen, onClose, order } = props;
  const {
    serviceProviders,
    form,
    isLoading,
    submitHandler,
    fetchServiceProviders,
  } = useAssignServiceProviderModal({
    order,
    onClose,
  });
  const { reset } = form;
  const classes = useStyles();
  return (
    isOpen && (
      <CustomModal
        open
        onClose={() => {
          reset();
          onClose(fetchServiceProviders);
        }}
        modalHeaderClassName={classes.modalHeader}
        modalBodyClassName={classes.modalBody}
      >
        <Card style={{ margin: 0 }}>
          <CardBody>
            <div className={classes.formHeader}>
              <React.Fragment>
                <AddIcon className={classes.icon} />
                Assign Service Provider
              </React.Fragment>
            </div>
            <form
              style={{ padding: 20, paddingBottom: 0 }}
              onSubmit={submitHandler}
              autoComplete="off"
            >
              <FormField
                md={12}
                form={form}
                name="serviceProvider"
                type="autocomplete"
                label="Service Provider"
                options={{
                  data: serviceProviders,
                  getOptionLabel: (option) =>
                    [option?.id, option?.nameEn, option?.phonePrimary]
                      .filter((item) => !!item)
                      .join(" - "),
                  blurOnSelect: false,
                  isTableInput: true,
                }}
              />
              <FormField
                md={12}
                form={form}
                name="phoneNumber"
                type="text"
                label="Phone Number"
              />
              <FormField
                md={12}
                form={form}
                name="phoneNumberSecondary"
                type="text"
                label="Secondary Phone Number"
              />

              <FormField
                md={12}
                form={form}
                name="nationalId"
                type="text"
                label="National ID"
              />
              <FormField
                md={12}
                form={form}
                name="baseTruckCost"
                type="number"
                label="Monthly Truck Cost"
              />
              <InfoWithLabel
                label="Monthly Target Cost"
                info={order?.monthly?.baseTruckTargetCost || 0}
              />
              <FormField
                md={12}
                form={form}
                name="monthlyDistanceLimit"
                type="number"
                label="Kms Limit"
              />
              <FormField
                md={12}
                form={form}
                name="dailyDurationLimit"
                type="number"
                label="Duration Limit"
              />
              <FormField
                md={12}
                form={form}
                name="extraKmCost"
                type="number"
                label="Extra Km Cost"
              />
              <InfoWithLabel
                label="Extra Km Target Cost"
                info={order?.monthly?.targetExtraKmCost || 0}
              />
              <FormField
                md={12}
                form={form}
                name="extraHourCost"
                type="number"
                label="Extra Hour Cost"
              />
              <InfoWithLabel
                label="Extra Hour Target Cost"
                info={order?.monthly?.targetExtraHourCost || 0}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="primary"
                  type="submit"
                  loading={isLoading}
                  style={{ margin: "20px 0" }}
                  create
                  loadFromRight
                >
                  Assign Service Provider
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </CustomModal>
    )
  );
}
