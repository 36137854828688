import React from "react";
import api from "services/api";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import { toast } from "react-toastify";

//-------------------------------------------------------------------------------------

export default function useContacts() {
  const [isAddContactOpen, setIsAddContactOpen] = React.useState(false);
  const [isEditContactOpen, setIsEditContactOpen] = React.useState(false);
  const [editItemId, setEditItemId] = React.useState(null);

  const { deal, setDeal, isGetDealLoading } = React.useContext(DealContext);

  const handleAddContact = React.useCallback(() => {
    setIsAddContactOpen(true);
  }, [setIsAddContactOpen]);

  const onAddModalClose = React.useCallback(() => {
    setIsAddContactOpen(false);
  }, [setIsAddContactOpen]);

  const handleEditContact = React.useCallback(
    (contactId) => {
      setEditItemId(contactId);
      setIsEditContactOpen(true);
    },
    [setIsEditContactOpen]
  );

  const onEditModalClose = React.useCallback(
    (updatedContact) => {
      const updatedContacts = deal.contacts.map((contact) => {
        if (contact.id === updatedContact.id) {
          return updatedContact;
        }
        return contact;
      });
      setDeal({ ...deal, contacts: updatedContacts });
      setIsEditContactOpen(false);
    },
    [setIsEditContactOpen, deal]
  );

  const handleRemoveContact = React.useCallback(
    async (contactId) => {
      let dealContactIds = deal?.contacts.map((contact) => contact.id);
      dealContactIds = dealContactIds.filter((id) => id !== contactId);
      const response = await api.deals.patch({
        id: deal.id,
        contactIds: dealContactIds,
      });
      setDeal(response.data);
      toast.success("Contact removed successfully");
    },
    [deal]
  );

  const contacts = React.useMemo(() => {
    return deal?.contacts || [];
  }, [deal]);

  return {
    deal,
    contacts,
    isAddContactOpen,
    isEditContactOpen,
    isGetDealLoading,
    editItemId,
    handleAddContact,
    onAddModalClose,
    handleEditContact,
    onEditModalClose,
    handleRemoveContact,
  };
}
