import {
  primaryColor,
  infoColor,
  successColor,
} from "assets/jss/material-dashboard-pro-react";
import enums from "enums/index";

export const dealStatusColors = {
  [enums.DealStatus.DRAFT]: "#F7DC6F",
  [enums.DealStatus.PENDING_PRICING]: "#FFA500",
  [enums.DealStatus.PRICED]: "#BB9F88",
  [enums.DealStatus.REJECTED]: "#f44336",
  [enums.DealStatus.ACCEPTED]: "#2A6A9E",
  [enums.DealStatus.ACTIVE]: "#32CD32",
  [enums.DealStatus.LOST]: "#AAA",
};

export const invoiceStatusColors = {
  [enums.InvoiceStatus.PLANNED]: "#AAA",
  [enums.InvoiceStatus.COMPLETED]: primaryColor[0],
  [enums.InvoiceStatus.REVIEWED]: infoColor[0],
  [enums.InvoiceStatus.INVOICED]: successColor[0],
};

export const billStatusColors = {
  [enums.BillStatus.PLANNED]: "#AAA",
  [enums.BillStatus.COMPLETED]: primaryColor[0],
  [enums.BillStatus.REVIEWED]: infoColor[0],
  [enums.BillStatus.BILLED]: successColor[0],
};

export const TourStatusColors = {
  [enums.TourStatus.UPCOMING]: "#AAA",
  [enums.TourStatus.ONGOING]: primaryColor[0],
  [enums.TourStatus.CANCELLED]: "#f44336",
  [enums.TourStatus.COMPLETED]: successColor[0],
};

export const RentalDealStatusColors = {
  [enums.RentalDealStatus.PLANNED]: "#F7DC6F",
  [enums.RentalDealStatus.CANCELLED]: "#f44336",
  [enums.RentalDealStatus.ACTIVE]: "#32CD32",
  [enums.RentalDealStatus.TERMINATED]: "#f44336",
};
