import enums from "enums/index";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import ServiceTableValidation from "./ServiceTable.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

// ------------------------------------------------------------------------------------------

export default function useServicesTable() {
  const resolver = useYupValidationResolver(ServiceTableValidation);

  const dataResource = useMemo(() => api.tours, []);
  const [sendRequest, isLoading] = useHttpClient(true);
  const [sendGetRequest, isGetLoading] = useHttpClient(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    setCurrentTour,
    resource,
    currentTour,
    serviceProviders,
  } = useContext(TourOperationsContext);

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      services: [],
    },
  });

  const { handleSubmit, setValue, getValues, reset, control, watch } = form;
  const { fields: services, remove } = useFieldArray({
    control,
    name: "services",
  });
  const onRemove = useCallback(
    (index) => {
      remove(index);
    },
    [remove]
  );
  const getServices = useCallback(async () => {
    const { data: tourServices } = await sendGetRequest(
      dataResource.getTourServices(currentTour.id)
    );

    setCurrentTour({ ...currentTour, services: tourServices });
  }, [setCurrentTour, currentTour, dataResource]);
  const firstServiceCost = watch("services");

  const tourDisabled = useMemo(
    () =>
      currentTour?.status === enums.TourStatus.CANCELLED ||
      currentTour?.status === enums.TourStatus.COMPLETED,
    [currentTour]
  );

  useEffect(() => {
    const currentServices = currentTour?.services || [];
    reset({ services: currentServices });
  }, [currentTour, reset]);

  const onModalOpen = () => {
    setIsModalOpen(true);
  };
  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const submitHandler = (requestData) => async () => {
    try {
      const values = requestData.services.map((service) => {
        let newService = {
          id: service.id,
          notes: service.notes,
          quantity: service.quantity,
          totalCost: service.cost * service.quantity,
          cost: service.cost,
          ...(service.price && {
            price: service.price,
            totalPrice: service.price * service.quantity,
          }),
          ...(service.invoice && { invoiceId: service.invoice.id }),
          ...(service.bill && { billId: service.bill.id }),
          ...(service.serviceProvider && {
            serviceProviderId: service.serviceProvider.id,
          }),
        };
        return newService;
      });
      const tour = await sendRequest(
        dataResource.updateTourServices(currentTour.id, {
          services: values,
        })
      );
      setCurrentTour({ ...currentTour, services: tour.services });
      toast.success(`Services has been updated successfully`);
    } catch (err) {
      console.log("error", err);
    }
  };
  const onError = () => {
    toast.error("Please fix the errors above and submit again.");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let requestData = { ...getValues() };
    handleSubmit(submitHandler(requestData), onError)();
  };

  return {
    currentTour,
    resource,
    onRemove,
    form,
    onSubmit,
    services,
    serviceProviders,
    isLoading,
    onModalOpen,
    onModalClose,
    isModalOpen,
    tourDisabled,
    getServices,
    serviceLoading: isGetLoading || isLoading,
  };
}
