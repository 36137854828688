import couriersRoutes from "./couriersRoutes";
import driversRoutes from "./driversRoutes";
import globalsRoutes from "./globalsRoutes";
import serviceProvidersRoutes from "./serviceProvidersRoutes";
import servicesRoutes from "./servicesRoutes";
import truckTypesRoutes from "./truckTypesRoutes";
import trucksRoutes from "./trucksRoutes";

export default [
  servicesRoutes,
  truckTypesRoutes,
  globalsRoutes,
  trucksRoutes,
  serviceProvidersRoutes,
  couriersRoutes,
  driversRoutes,
];
