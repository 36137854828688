import React from "react";
import styles from "./Cards.Styles";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import enums from "enums/index";
import CachedIcon from "@material-ui/icons/Cached";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CancelServiceProvider from "./Components/CancelServiceProvider/CancelServiceProvider";

const useStyles = makeStyles(styles);

function ServiceProviderCard({ viewOnly, setAssignModal, order }) {
  const serviceProvider = order?.monthly?.serviceProvider;
  const classes = useStyles();
  return (
    <Card raised className={classes.assignCard}>
      <CardBody className={classes.assignCardBody}>
        <div className={classes.headerContainer}>
          <h3 className={classes.header}>Service Provider</h3>
        </div>
        <GridContainer className={classes.infoContainer}>
          <GridItem>
            <InfoWithLabel label="Name" info={serviceProvider?.nameEn} />
          </GridItem>
          <GridItem>
            <InfoWithLabel label="Phone" info={serviceProvider?.phonePrimary} />
          </GridItem>
          <GridItem>
            <InfoWithLabel
              label="National ID"
              info={serviceProvider?.nationalId}
            />
          </GridItem>
        </GridContainer>
        {order?.status !== enums.OrderStatus.CANCELLED &&
          !viewOnly &&
          (serviceProvider ? (
            <CancelServiceProvider />
          ) : (
            <Button
              onClick={() => {
                setAssignModal(enums.DispatchModals.SUPPLIER);
              }}
              color="primary"
              className={classes.assignButton}
            >
              <CachedIcon className={classes.changeIcon} /> Assign Service
              Provider
            </Button>
          ))}
      </CardBody>
    </Card>
  );
}

export default ServiceProviderCard;
