import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import DiscountsTable from "./Components/DiscountsTable/DiscountsTable";
import styles from "./FinOps.styles";
import useFinOps from "./FinOps.hook";
import PenaltiesTable from "./Components/PenaltiesTable/PenaltiesTable";
import Button from "components/CustomButtons/Button.js";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import Loading from "components/Loading/Loading";
import { FinOpsContext } from "./FinOps.context";

const useStyles = makeStyles(styles);

function FinOps() {
  const {
    currentTour,
    tourReviewable,
    tourReviewed,
    form,
    isLoading,
    inFinOps,
    tourDisabled,
    onSubmit,
    reviewTour,
    revertReviewDisabled,
    isGetLoading,
    revertTourReview,
    getTourUpdate,
  } = useFinOps();
  const classes = useStyles();
  return (
    <FinOpsContext.Provider value={{ getTourUpdate }}>
      <GridContainer>
        <GridItem xs={12} md={10} sm={10}>
          <Card raised>
            <CardBody>
              <Loading loading={isGetLoading}>
                <form onSubmit={onSubmit}>
                  {inFinOps && (
                    <CollapseContainer
                      startCollapsed={false}
                      titleComponent={
                        <div className={classes.headerContainer}>
                          <h3 className={classes.header}>Discounts</h3>
                        </div>
                      }
                    >
                      <DiscountsTable
                        form={form}
                        currentTour={currentTour}
                        tourReviewed={tourReviewed}
                        tourReviewable={tourReviewable}
                      />
                    </CollapseContainer>
                  )}

                  <CollapseContainer
                    startCollapsed={false}
                    titleComponent={
                      <div className={classes.headerContainer}>
                        <h3 className={classes.header}>Penalties</h3>
                      </div>
                    }
                  >
                    <PenaltiesTable
                      inFinOps={inFinOps}
                      tourDisabled={tourDisabled}
                      form={form}
                      currentTour={currentTour}
                      tourReviewed={tourReviewed}
                      tourReviewable={tourReviewable}
                    />
                  </CollapseContainer>

                  {
                    <div className={classes.buttonsContainer}>
                      {((!tourReviewed && inFinOps) ||
                        (!inFinOps && !tourDisabled)) && (
                        <Button
                          color="primary"
                          type="submit"
                          loading={isLoading}
                          style={{ marginTop: 20 }}
                          edit
                          loadFromRight
                        >
                          Submit
                        </Button>
                      )}
                      {!tourReviewed && tourReviewable && inFinOps && (
                        <Button
                          color="primary"
                          loading={isLoading}
                          style={{ marginTop: 20, marginBottom: 0 }}
                          onClick={reviewTour}
                          view
                          loadFromRight
                        >
                          Finish Review
                        </Button>
                      )}
                      {!revertReviewDisabled && tourReviewed && inFinOps && (
                        <Button
                          color="primary"
                          loading={isLoading}
                          style={{ marginTop: 20 }}
                          onClick={revertTourReview}
                          cancelButton
                          loadFromRight
                        >
                          Revert Review
                        </Button>
                      )}
                    </div>
                  }
                </form>
              </Loading>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </FinOpsContext.Provider>
  );
}

export default FinOps;
