import { useForm } from "react-hook-form";
import AssignTruckModalValidation from "./AssignTruckModal.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { useContext, useEffect, useMemo, useState } from "react";
import api from "services/api";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

export default function useAssignTruckModal({ currentTour, onClose }) {
  const [sendRequest, isLoading] = useHttpClient(true);
  const { getTour, getOrganizations, getTours } = useContext(
    TourOperationsContext
  );
  const resolver = useYupValidationResolver(AssignTruckModalValidation());
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });
  const [trucks, setTrucks] = useState([]);

  const { handleSubmit, setValue, getValues, watch } = form;

  const truck = watch("truck");

  const resetTruck = () => {
    if (currentTour?.serviceProvider) {
      setValue("truck", currentTour.truck);

      const fetchTrucks = async () => {
        const response = (
          await api.quotations.getAvailableTrucks(currentTour.quotationId, {
            serviceProviderId: currentTour?.serviceProvider?.id,
          })
        )?.data;
        setTrucks(response);
      };
      fetchTrucks();
    }
  };

  useEffect(() => {
    resetTruck();
  }, [currentTour]);

  const truckId = useMemo(() => truck?.id, [truck]);

  useEffect(() => {
    setValue("licenseImage", truck?.licenseAttachment || "");
    setValue("license", truck?.licenseId || "");
  }, [truckId]);
  const onSubmit = (values) => async () => {
    try {
      if (!currentTour) {
        toast.error("no tour is selected");
        return;
      }
      //TODO: truck is giving a server error
      delete values.truck.typeId;
      delete values.truck.type;
      await sendRequest(
        api.orders.assignTourWorker({
          id: currentTour.id,
          truck: {
            ...values.truck,
            licenseId: values.license,
            licenseAttachment: values.licenseImage,
          },
        })
      );
      getTour();
      toast.success("Truck assigned successfully");
      onClose();
    } catch (error) {
      if (error?.response?.data?.error === "Conflict") {
        await getOrganizations();
        await getTours();
        onClose();
      }
      console.log(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const handleAddNew = (truck) => {
    setTrucks((prevTrucks) => [...prevTrucks, truck]);
    setValue("truck", truck);
  };

  return {
    form,
    trucks,
    truckType: currentTour?.truckType,
    serviceProvider: currentTour?.serviceProvider,
    isLoading,
    resetTruck,
    submitHandler,
    handleAddNew,
  };
}
