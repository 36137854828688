import React from "react";
import CustomModal from "components/CustomModal/CustomModal";
import styles from "./HistoryButton.styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import useHistoryButton from "./HistoryButton.hook";
import CustomTable from "components/CustomTable/CustomTable";

const useStyles = makeStyles(styles);
function HistoryButton({
  title,
  pathName,
  id,
  columns,
  buttonProps,
  tableProps,
  modalProps,
}) {
  const { toggleModalOpen, modalOpen, data, isLoading } = useHistoryButton({
    pathName,
    id,
  });
  const classes = useStyles();
  return (
    <>
      <Button
        className={classes.assignButton}
        view
        color="primary"
        textColor="white"
        onClick={() => {
          toggleModalOpen();
        }}
        {...(buttonProps || {})}
      >
        Tour History
      </Button>
      <CustomModal
        open={modalOpen}
        onClose={() => {
          toggleModalOpen();
        }}
        modalHeaderClassName={classes.modalHeader}
        modalBodyClassName={classes.modalBody}
        {...(modalProps || {})}
      >
        <React.Fragment>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>{title}</h3>
          </div>

          <div className={classes.tableContainer}>
            <CustomTable
              columns={columns}
              data={data}
              loading={isLoading}
              addIndexColumn
              raised={false}
              {...(tableProps || {})}
            />
          </div>
        </React.Fragment>
      </CustomModal>
    </>
  );
}

export default HistoryButton;
