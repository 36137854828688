import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import styles from "./CancelServiceProvider.styles";
import { makeStyles } from "@material-ui/core/styles";
import FormField from "components/Forms/FormField/FormField";
import useCancelServiceProvider from "./CancelServiceProvider.hook";
import Button from "components/CustomButtons/Button.js";
import enums from "enums/index";
import { formatEnums } from "utils/index";
import CancelIcon from "@material-ui/icons/Cancel";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function CancelServiceProvider() {
  const {
    order,
    form,
    reset,
    submitHandler,
    isTourRequestLoading,
    toggleModalOpen,
    modalOpen,
  } = useCancelServiceProvider();
  const classes = useStyles();
  return (
    <>
      <Button
        onClick={toggleModalOpen}
        color="primary"
        className={classes.assignButton}
      >
        <CancelIcon /> Cancel Service Provider
      </Button>
      <CustomModal
        open={modalOpen}
        onClose={() => {
          reset();
          toggleModalOpen();
        }}
        modalHeaderClassName={classes.modalHeader}
        modalBodyClassName={classes.modalBody}
      >
        <Card style={{ margin: 0 }}>
          <CardBody>
            <div className={classes.formHeader}>
              <React.Fragment>
                {`Cancel Service Provider ${order?.monthly?.serviceProvider?.nameEn}.`}
              </React.Fragment>
            </div>
            <form
              style={{ padding: 20, paddingBottom: 0 }}
              onSubmit={submitHandler}
              autoComplete="off"
            >
              <FormField
                type="text"
                name="serviceProviderCancellationReason"
                label="Cancellation Reason"
                form={form}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="primary"
                  type="submit"
                  loading={isTourRequestLoading}
                  style={{ margin: "20px 0" }}
                  loadFromRight
                >
                  Cancel Service Provider
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </CustomModal>
    </>
  );
}
