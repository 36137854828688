import FormField from "components/Forms/FormField/FormField";
import React from "react";
import { parseUseFieldsArrayString } from "./Dropzone.util";
export default function AttachmentNameField(props) {
  const { index, data, form } = props;
  const { setValue } = form;

  React.useEffect(() => {
    if (data) {
      const value = parseUseFieldsArrayString(data);
      setValue(`documentNames.${index}`, value);
    }
  }, []);

  return (
    <FormField
      md={12}
      form={form}
      name={`documentNames.${index}`}
      type="text"
      label={`Document ${index + 1} Name`}
    />
  );
}
