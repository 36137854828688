import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import translations from "constants/translations/index";
import PropTypes from "prop-types";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { toastError } from "utils/index";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import * as yup from "yup";
import styles from "./ImportQuotationsModal.styles";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ImportQuotationsModal(props) {
  const { isOpen, onClose, type: quotationTypeProp } = props;

  const resolver = useYupValidationResolver(
    yup.object().shape({
      quotationsFile: yup.mixed().required("Required"),
    })
  );

  const form = useForm({
    defaultValues: { quotationsFile: null },
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues } = form;

  const classes = useStyles();

  const [sendRequest, isLoading, sendRequestError] = useHttpClient();

  const { deal } = React.useContext(DealContext);

  const { i18n } = useTranslation();

  React.useEffect(() => {
    if (sendRequestError?.message) {
      toastError(sendRequestError.message);
    }
  }, [sendRequestError]);

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };

  const onSubmit = (values) => async () => {
    try {
      console.log("values", values);
      const response = await sendRequest(
        api.deals.importDealQuotations(deal.id, values.quotationsFile)
      );

      toast.success(`Quotations updated successfully`);
      onClose();
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <AddIcon className={classes.icon} />
                  {`Import ${
                    translations[i18n.language].quotationType[quotationTypeProp]
                  } Quotations`}
                </React.Fragment>
              </div>

              <form
                style={{ padding: 20, paddingBottom: 0 }}
                onSubmit={submitHandler}
                autoComplete="off"
              >
                <FormField
                  md={12}
                  form={form}
                  name="quotationsFile"
                  type="dropzone"
                  options={{ maxFiles: 1 }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    style={{ margin: "20px 0" }}
                    loadFromRight
                  >
                    Import
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

ImportQuotationsModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

ImportQuotationsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string,
};
