import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import PropTypes from "prop-types";
import React from "react";
import api from "services/api";
import useMonthlyOrderModal from "./MonthlyOrderModal.hook";
import LocationsForm from "views/Zoning/Locations/LocationsForm";
import styles from "./MonthlyOrderModal.styles";
import enums from "enums/index";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export function MonthlyOrderModal(props) {
  const {
    isOpen,
    onClose,
    isEditingOrder,
    currentOrder,
    setCurrentOrder,
    setIsEditingOrder,
  } = props;
  const classes = useStyles();

  const {
    isEditingAfterActivation,
    isLoading,
    truckTypes,
    monthlyDistanceOptions,
    durationLimitOptions,
    cities,
    districts,
    locations,
    isLocationDisabled,
    isTruckTypeDisabled,
    isDistanceQuotaDisabled,
    isDurationLimitDisabled,
    organizationId,
    deal,
    form,
    submitHandler,
    getLocationTaskTypes,
    handleAddNew,
  } = useMonthlyOrderModal({
    onClose,
    isEditingOrder,
    currentOrder,
    setCurrentOrder,
    setIsEditingOrder,
  });

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <Card style={{ margin: 0 }}>
          <CardBody>
            <div className={classes.formHeader}>
              <React.Fragment>
                <AddIcon className={classes.icon} />
                {isEditingOrder ? "Edit" : "Add"} Monthly Order
              </React.Fragment>
            </div>
            <form
              style={{ padding: 20, paddingBottom: 0 }}
              onSubmit={submitHandler}
              autoComplete="off"
            >
              <FormField
                md={3}
                type="text"
                label="Customer Ref"
                name="customerReference"
                form={form}
                options={{ disabled: isEditingAfterActivation }}
              />
              <FormField
                md={3}
                type="select"
                label="Order Channel"
                name="channel"
                form={form}
                options={{
                  enum: "orderChannel",
                  disabled: isEditingAfterActivation,
                }}
              />
              <FormField
                md={12}
                name="deal"
                type="autocomplete"
                label="Deal"
                form={form}
                options={{
                  getData: () =>
                    api.deals.getAll(
                      undefined,
                      undefined,
                      undefined,
                      {
                        "details.status": enums.DealStatus.ACTIVE,
                        organizationId,
                      },
                      undefined
                    ),
                  getOptionLabel: (option) => option.details.name,
                  disabled: isEditingAfterActivation,
                }}
              />

              <FormField
                md={12}
                name="truckType"
                type="autocomplete"
                label="Truck Type"
                form={form}
                options={{
                  data: truckTypes,
                  getOptionLabel: (option) => option.name,
                  disabled: isTruckTypeDisabled || isEditingAfterActivation,
                  disabledTooltipText: "Please select deal first",
                }}
              />

              <FormField
                md={12}
                name="monthlyDistanceQuota"
                type="autocomplete"
                label="Km Limit"
                form={form}
                options={{
                  data: monthlyDistanceOptions,
                  blurOnSelect: false,
                  disabled: isDistanceQuotaDisabled || isEditingAfterActivation,
                  disabledTooltipText: "Please select a truck type first",
                }}
              />
              <FormField
                md={12}
                name="dailyDurationLimit"
                type="autocomplete"
                label="Duration Allowance (hours)"
                form={form}
                options={{
                  data: durationLimitOptions,
                  blurOnSelect: false,
                  disabled: isDurationLimitDisabled || isEditingAfterActivation,
                  disabledTooltipText: "Please select a distance limit first",
                }}
              />
              <FormField
                md={12}
                name="city"
                type="autocomplete"
                label="City"
                form={form}
                options={{
                  data: cities,
                  disabled: isLocationDisabled || isEditingAfterActivation,
                  disabledTooltipText: "Please select a duration limit first",
                  getOptionLabel: (option) => option.name,
                }}
              />
              <FormField
                md={12}
                name="district"
                type="autocomplete"
                label="District"
                form={form}
                options={{
                  data: districts,
                  disabled: isLocationDisabled || isEditingAfterActivation,
                  disabledTooltipText: "Please select a duration limit first",
                  getOptionLabel: (option) => option.name,
                }}
              />
              <FormField
                md={12}
                name="location"
                type="autocomplete"
                label="Location"
                form={form}
                options={{
                  data: locations,
                  disabled: isLocationDisabled || isEditingAfterActivation,
                  disabledTooltipText: "Please select a duration limit first",
                  getOptionLabel: (option) => option.name,
                  addNew: true,
                  formComponent: (
                    <LocationsForm
                      getLocationTaskTypes={getLocationTaskTypes}
                      deal={deal}
                      cities={cities}
                      districts={districts}
                      handleAddNew={handleAddNew}
                      create
                    />
                  ),
                  dataResource: api.locations,
                }}
              />

              <FormField
                type="autocomplete"
                form={form}
                name="offDays"
                label="Off Days"
                options={{
                  getOptionLabel: (option) => option.name,
                  enum: "weekDay",
                  multiple: true,
                  blurOnSelect: false,
                  disabled: isEditingAfterActivation,
                }}
              />
              <FormField
                md={12}
                name="pickupTime"
                type="time"
                label="Pickup Time"
                form={form}
              />
              <FormField
                md={12}
                name="startDate"
                type="date"
                label="Start Date"
                form={form}
                options={{
                  disabled: isEditingAfterActivation,
                }}
              />
              <FormField
                md={12}
                name="endDate"
                type="date"
                label="End Date"
                form={form}
                options={{
                  disabled: isEditingAfterActivation,
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="primary"
                  type="submit"
                  loading={isLoading}
                  style={{ margin: "20px 0" }}
                  {...(isEditingOrder ? { edit: true } : { create: true })}
                  loadFromRight
                />
              </div>
            </form>
          </CardBody>
        </Card>
      </GridContainer>
    </CustomModal>
  );
}

MonthlyOrderModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

MonthlyOrderModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
