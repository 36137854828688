import enums from "enums/index";
import React, { useContext, useMemo } from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

export default function usePenaltyRow(form, index, data) {
  const { setValue } = form;
  const { currentTour, getTour, serviceProviders, resource } = useContext(
    TourOperationsContext
  );
  const [sendRequest, isLoading] = useHttpClient(true);

  const inFinOps = useMemo(() => resource === enums.Resource.FIN_OPS, [
    resource,
  ]);
  const tourDisabled = useMemo(
    () =>
      currentTour?.status === enums.TourStatus.CANCELLED ||
      currentTour?.status === enums.TourStatus.COMPLETED,
    [currentTour]
  );
  const rowDisabled = useMemo(
    () => data.bill.status === enums.BillStatus.BILLED,
    [data]
  );

  const reviewPenalty = async () => {
    try {
      if (!currentTour?.id) return;
      await sendRequest(
        api.penalties.patch({
          id: data.id,
          finOpsStatus: enums.FinOpsStatus.REVIEWED,
        })
      );
      await getTour();
    } catch (err) {
      console.log("error", err);
    }
  };

  React.useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (key !== "id") setValue(`penalties.${index}.${key}`, data[key]);
        if (key === "bill")
          setValue(
            `penalties.${index}.bill.serviceProvider`,
            data[key].serviceProvider
          );
      });
    }
  }, [data]);

  return {
    serviceProviders,
    inFinOps,
    tourDisabled,
    rowDisabled,
    reviewPenalty,
  };
}
