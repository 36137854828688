import { createContext } from "react";

export const TourOperationsContext = createContext({
  resource: undefined,
  organizations: [],
  serviceProviders: [],
  getOrganizations: () => {},
  getTours: () => {},
  getTour: () => {},
  currentOrg: {},
  currentOrgIndex: undefined,
  currentTourIndex: null,
  currentTour: {},
  setCurrentTourIndex: undefined,
  pickupDate: undefined,
  serviceProviderIsNull: false,
  driverOrTruckIsNull: false,
  toursCompleted: false,
  toursReviewed: false,
  setCurrentTour: () => {},
  setCurrentOrgIndex: () => {},
  toggleDriverOrTruckIsNull: () => {},
  setPickupDate: () => {},
  toggleServiceProviderIsNull: () => {},
  toggleToursCompleted: () => {},
  toggleToursReviewed: () => {},
  invoices: [],
  setInvoices: () => {},
  isOrganizationsLoading: false,
  isToursLoading: true,
  isTourLoading: true,
});
