import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button";
import styles from "./Buttons.styles";
import React from "react";

const useStyles = makeStyles(styles);

export default function AddButton({ onAdd }) {
  const classes = useStyles();

  return (
    <Button
      color="white"
      textColor="textColor"
      className={classes.button}
      onClick={onAdd}
    >
      <Tooltip
        title={"Add"}
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
        arrow
      >
        <AddIcon style={{ cursor: "pointer" }} />
      </Tooltip>
    </Button>
  );
}
