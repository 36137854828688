import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import cx from "classnames";
import Footer from "components/Footer/Footer.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import Sidebar from "components/Sidebar/Sidebar.js";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes";
import { AuthContext } from "shared/context/auth-context";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image] = React.useState(null);
  const [color] = React.useState("primary");
  const [bgColor] = React.useState("white");
  const [logo] = React.useState(require("assets/img/traxl-logo-slogan.png"));

  const auth = React.useContext(AuthContext);

  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

  const mainPanel = React.createRef();

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "traxl";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          // if (routes[i].children) {
          //   let childRoute = getActiveRoute(routes[i].views);
          //   if (childRoute !== activeRoute) {
          //     return childRoute;
          //   }
          // }
          return routes[i];
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      let childrenRoutes = [];
      if (prop.children) {
        childrenRoutes = getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return [
          ...childrenRoutes,
          <PrivateRoute
            path={prop.layout + prop.path}
            action={prop.action}
            resource={prop.resource}
            render={prop.render}
            component={prop.component}
            key={prop.randomKey ? Math.random() : "route - " + prop.path}
          />,
        ];
      } else {
        return null;
      }
    });
  };

  // const filterRoutes = (routes) => {
  //   const filteredRoutes = [];
  //   routes.forEach((route) => {
  //     if (
  //       route.layout === "/admin" &&
  //       auth.userData.resources?.indexOf(route.resource) !== -1 &&
  //       (route.action === "list" ||
  //         auth.userData.permissions[route.resource][route.action])
  //     ) {
  //       filteredRoutes.push(route);
  //     }
  //   });
  //   return filteredRoutes;
  // };

  const filterRoutes = (routes) => {
    return routes.flatMap((route) => {
      let childrenRoutes = [];
      if (route.collapse) {
        childrenRoutes = filterRoutes(route.views);
      }
      if (route.children) {
        childrenRoutes = filterRoutes(route.views);
      }
      if (
        route.layout === "/admin" &&
        auth.userData.resources?.indexOf(route.resource) !== -1 &&
        (route.action === undefined ||
          auth.userData.permissions[route.resource][route.action])
      ) {
        return (route.children || route.collapse) && childrenRoutes.length
          ? [{ ...route, views: childrenRoutes }]
          : [route];
      } else if (route.resource === undefined) {
        return (route.children || route.collapse) && childrenRoutes.length
          ? [{ ...route, views: childrenRoutes }]
          : [route];
      } else {
        return [];
      }
    });
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={filterRoutes(routes)}
        image={image}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        raised
        {...rest}
      />
      <div id="main-panel" className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          activeRoute={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/admin" to={`/admin/profile`} />
            </Switch>
          </div>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
}
