import BusinessIcon from "@material-ui/icons/Business";
import enums from "enums/index";
import categoriesRoutes from "./categoriesRoutes";
import contactsRoutes from "./contactsRoutes";
import industriesRoutes from "./industriesRoutes";
import organizationsRoutes from "./organizationsRoutes";

export default {
  name: "Organizations",
  icon: BusinessIcon,
  layout: "/admin",
  collapse: true,
  state: "organizationsCollapse",
  resource: enums.Resource.ORGANIZATIONS,
  views: [
    { ...organizationsRoutes },
    { ...contactsRoutes },
    { ...industriesRoutes },
    { ...categoriesRoutes },
  ],
};
