import * as yup from "yup";

//-------------------------------------------------------------------------------------

export default () => {
  return yup.object({
    courier: yup.mixed().required("Required"),
    serviceProvider: yup.mixed().required("Required"),
    startDate: yup.mixed().required("Required"),
    endDate: yup
      .mixed()
      .required("Required")
      .when(["startDate"], (startDate) => {
        return yup
          .mixed()
          .required("Required")
          .test(
            "endDate-after-startDate-error",
            "The end date should be after the start date.",
            (date) => {
              if (startDate) return startDate < date;
              else return true;
            }
          );
      }),
    offDays: yup.mixed().required("Required"),
    monthlyCourierCost: yup.mixed().required("Required"),
    workingDays: yup
      .mixed()
      .required("Required")
      .test(
        "working-days-error",
        "working days should be a positive value",
        (workingDays) => workingDays >= 0
      ),
  });
};
