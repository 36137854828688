import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import React from "react";
import styles from "./MonthlyOrderDetails.styles";
import { useMonthlyOrderDetails } from "./MonthlyOrderDetails.hook";
import MonthlyStatusButton from "./components/MonthlyStatusButton/MonthlyStatusButton";
import enums from "enums/index";
import OrderWorkers from "./components/OrderWorker/OrderWorkers";
import { MonthlyOrderDetailsContext } from "./MonthlyOrderDetails.context";
import OrderInfo from "./components/OrderInfo/OrderInfo";
import ServiceProviderDealInfo from "./components/ServiceProviderDealInfo/ServiceProviderDealInfo";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function MonthlyOrderDetails({ viewOnly, planOrder }) {
  const { order, setOrder, updateOrder } = useMonthlyOrderDetails(
    viewOnly,
    planOrder
  );
  const classes = useStyles();
  return (
    <MonthlyOrderDetailsContext.Provider value={{ order, setOrder }}>
      <div className={classes.section}>
        <div className={classes.headerContainer}>
          <h3 className={classes.header}>Monthly Order Details</h3>
          {viewOnly && (
            <div className={classes.buttonsContainer}>
              <MonthlyStatusButton
                order={order}
                targetStatus={enums.OrderStatus.ACTIVE}
                updateOrder={updateOrder}
              />
              <MonthlyStatusButton
                order={order}
                targetStatus={enums.OrderStatus.CANCELLED}
                updateOrder={updateOrder}
              />
              <MonthlyStatusButton
                order={order}
                targetStatus={enums.OrderStatus.TERMINATED}
                updateOrder={updateOrder}
              />
            </div>
          )}
        </div>
        <OrderInfo />
        <OrderWorkers viewOnly={viewOnly} />
        <ServiceProviderDealInfo />
      </div>
    </MonthlyOrderDetailsContext.Provider>
  );
}
