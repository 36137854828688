import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import styles from "./OrderInfo.styles";
import useOrderInfo from "./OrderInfo.hook";
import React from "react";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import { formatEnums } from "utils/index";
import enums from "enums/index";

const useStyles = makeStyles(styles);

export default function OrderInfo() {
  const {
    org,
    order,
    dealName,
    completedTours,
    toursCount,
    endReason,
    serviceProviderCancellationReason,
    startTime,
  } = useOrderInfo();
  const classes = useStyles();
  return (
    <Card raised>
      <CardBody>
        <CollapseContainer
          titleComponent={<h3 style={{ margin: 0 }}>Order Info:</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem label="Client Name" info={org?.name} />
            <InfoWithLabelGridItem
              label="Order Type"
              info={formatEnums(order?.type)}
            />
            <InfoWithLabelGridItem
              label="Ordered Truck Type"
              info={order?.monthly?.truckType?.details?.name}
            />
          </GridContainer>

          <GridContainer className={classes.infoGridContainer}>
            <InfoWithLabelGridItem
              label="Completed Tours"
              info={order && `${completedTours}/${toursCount}`}
            />
            <InfoWithLabelGridItem
              label="Order Status"
              info={formatEnums(order?.status)}
            />
            <InfoWithLabelGridItem label="Order ID" info={order?.id} />
          </GridContainer>
          <GridContainer>
            <InfoWithLabelGridItem label="Start Date" info={order?.startDate} />
            <InfoWithLabelGridItem label="End Date" info={order?.endDate} />
            <InfoWithLabelGridItem label="Start Time" info={startTime} />
          </GridContainer>
          <GridContainer className={classes.infoGridContainer}>
            <InfoWithLabelGridItem label="Deal" info={dealName} />
            {order?.status === enums.OrderStatus.CANCELLED ||
              (order?.status === enums.OrderStatus.TERMINATED && (
                <InfoWithLabelGridItem
                  label={`Order ${
                    order?.status === enums.OrderStatus.CANCELLED
                      ? "Cancellation"
                      : "Termination"
                  } Reason`}
                  info={endReason}
                />
              ))}
            {serviceProviderCancellationReason && (
              <InfoWithLabelGridItem
                label="Service Provider Cancellation Reason"
                info={serviceProviderCancellationReason}
              />
            )}
          </GridContainer>
        </CollapseContainer>
      </CardBody>
    </Card>
  );
}
