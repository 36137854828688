import enums from "enums/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { deepen, flattenObject, toastError } from "utils/index";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import { QuotationsContext } from "views/Deals/components/Tabs/Quotations/Quotations.context";
import PerTripQuotationFormValidation from "./PerTripQuotationForm.validation";
import { useForm } from "react-hook-form";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";

//-------------------------------------------------------------------------------------

export default function usePerTripQuotationForm({ quotationProp, onClose }) {
  const [sendRequest, isLoading, sendRequestError] = useHttpClient();
  const [sendZoneMappingsRequest, isZoneMappingsLoading] = useHttpClient(true);
  const [tripDuration, setTripDuration] = React.useState(0);
  const [tripDistance, setTripDistance] = React.useState(0);
  const { deal, getLatestDeal } = React.useContext(DealContext);
  const { reloadQuotations } = React.useContext(QuotationsContext);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(PerTripQuotationFormValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue, watch } = form;

  const pickupZoneId = watch("perTrip.pickupZone")?.id;
  const dropOffZoneId = watch("perTrip.dropOffZone")?.id;

  React.useEffect(() => {
    if (quotationProp) {
      const truckType = quotationProp.truckType;
      delete quotationProp.truckType;

      let pickupZone = {};
      let dropOffZone = {};
      dropOffZone = quotationProp.perTrip.dropOffZone;
      pickupZone = quotationProp.perTrip.pickupZone;

      const formData = {
        ...flattenObject(quotationProp),
        truckType,
        dealId: deal.id,
        "perTrip.pickupZone": pickupZone,
        "perTrip.dropOffZone": dropOffZone,
      };

      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    } else {
      setValue("dealId", deal.id);
    }
  }, [quotationProp, deal.id]);

  React.useEffect(() => {
    async function getZoneMappingByOriginAndDestination() {
      try {
        const response = await sendZoneMappingsRequest(
          api.zoneMappings.getZoneMappingByOriginAndDestination(
            pickupZoneId,
            dropOffZoneId
          )
        );
        setTripDistance(parseFloat(response?.distanceKm || 0).toFixed(2));
        setTripDuration(parseFloat(response?.tripDuration || 0).toFixed(2));
      } catch (err) {
        console.log(err);
        setTripDistance(undefined);
        setTripDuration(undefined);
      }
    }

    if (quotationProp && pickupZoneId && dropOffZoneId) {
      if (
        quotationProp.perTrip.pickupZone?.id === pickupZoneId &&
        quotationProp.perTrip.dropOffZone?.id === dropOffZoneId
      ) {
        setTripDistance(quotationProp.perTrip.zoneMapping.distanceKm);
        setTripDuration(quotationProp.perTrip.zoneMapping.tripDuration);
      } else {
        getZoneMappingByOriginAndDestination();
      }
    }
  }, [pickupZoneId, dropOffZoneId, quotationProp]);

  React.useEffect(() => {
    if (sendRequestError?.message) {
      toastError(sendRequestError.message);
    }
  }, [sendRequestError]);
  const createHandler = (values) => async () => {
    try {
      await sendRequest(api.quotations.create(deepen(values)));
      toast.success(`Quotation has been created successfully`);

      reloadQuotations();
      onClose();
    } catch {}
  };

  const editHandler = (values) => async () => {
    try {
      await sendRequest(api.quotations.patch(deepen(values)));
      toast.success(`Quotation has been updated successfully`);

      getLatestDeal();
      onClose();
    } catch {}
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let values = getValues();

    if (values?.truckType?.id) {
      values.truckTypeId = values.truckType.id;
      delete values.truckType;
    }
    if (values?.perTrip?.pickupZone) {
      values.perTrip.pickupZoneId = values.perTrip.pickupZone.id;
      delete values.perTrip.pickupZone;
    }
    if (values?.perTrip?.dropOffZone) {
      values.perTrip.dropOffZoneId = values.perTrip.dropOffZone.id;
      delete values.perTrip.dropOffZone;
    }
    values.details.type = enums.QuotationType.PER_TRIP;

    handleSubmit(
      !!quotationProp ? editHandler(values) : createHandler(values),
      onError
    )();
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    tripDuration,
    tripDistance,
    submitHandler,
    onError,
  };
}
