import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import CustomForm from "components/Forms/CustomForm/CustomForm";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import enums from "enums";
import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { createValidationSchema, capitalizeFirstLetter } from "utils";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import * as yup from "yup";
import styles from "./ServiceModal.styles";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ServiceModal(props) {
  const { isOpen, onClose, service: serviceProp } = props;

  const classes = useStyles();

  const [data, setData] = React.useState(null);

  const [sendRequest, isLoading, error] = useHttpClient();

  const { deal, setDeal } = React.useContext(DealContext);

  React.useEffect(() => {
    if (isOpen && serviceProp) {
      setData(serviceProp);
    }
  }, [isOpen, serviceProp]);

  React.useEffect(() => {
    if (error?.message) {
      toast.error(capitalizeFirstLetter(error.message));
    }
  }, [error]);

  const submitHandler = (values) => async () => {
    try {
      if (serviceProp) {
        const requestObj = {
          price: values.price,
          cost: values.cost,
        };
        const response = await sendRequest(
          api.deals.updateService(deal.id, serviceProp.service.id, requestObj)
        );
        const updatedDealServices = deal.services.map((dealService) =>
          dealService.service.id === serviceProp.service.id
            ? response
            : dealService
        );

        setDeal({ ...deal, services: updatedDealServices });
        toast.success(`Service has been updated successfully`);
      } else {
        const requestObj = {
          serviceId: values.service.id,
          price: values.price,
          cost: values.cost,
        };
        const response = await sendRequest(
          api.deals.addService(deal.id, requestObj)
        );
        setDeal({
          ...deal,
          services: [...deal.services, response],
        });
        toast.success(`Service has been created successfully`);
      }
      onClose();
    } catch (err) {}
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const formStructure = React.useMemo(
    () => [
      {
        column: 1,
        fields: [
          {
            name: "dealId",
            type: "hidden",
          },
          ...(serviceProp
            ? [
                {
                  name: "price",
                  type: "number",
                  label: "Price",
                  validation: yup
                    .number()
                    .required("Required")
                    .min(1, "Please enter a number greater or equal 1"),
                },
                {
                  name: "cost",
                  type: "number",
                  label: "Cost",
                  validation: yup
                    .number()
                    .required("Required")
                    .min(1, "Please enter a number greater or equal 1"),
                },
              ]
            : [
                {
                  name: "service",
                  type: "autocomplete",
                  label: "Service",
                  options: {
                    getData: api.services.getAll,
                    getOptionLabel: (option) => option.name,
                  },
                  validation: yup.mixed().required("Required"),
                },
                {
                  name: "price",
                  type: "number",
                  label: "Price",
                  validation: yup
                    .number()
                    .required("Required")
                    .min(1, "Please enter a number greater or equal 1"),
                },
                {
                  name: "cost",
                  type: "number",
                  label: "Cost",
                  validation: yup
                    .number()
                    .required("Required")
                    .min(1, "Please enter a number greater or equal 1"),
                },
              ]),
        ],
      },
    ],
    [serviceProp]
  );

  const validationSchema = React.useMemo(
    () => createValidationSchema(formStructure),
    [formStructure, createValidationSchema]
  );

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                {serviceProp ? (
                  <React.Fragment>
                    <EditIcon className={classes.icon} /> Edit Service
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <AddIcon className={classes.icon} />
                    Add Service
                  </React.Fragment>
                )}
              </div>
              {serviceProp ? (
                <div className={classes.infoContainer}>
                  <InfoWithLabel
                    label="Service"
                    info={serviceProp.service.name}
                  />
                </div>
              ) : (
                []
              )}
              <CustomForm
                type={enums.FormContainerType.MODAL}
                formStructure={formStructure}
                submitHandler={submitHandler}
                onError={onError}
                loading={isLoading}
                validationSchema={validationSchema}
                data={data}
                edit={!!serviceProp}
                create={!serviceProp}
                submitButtonText={serviceProp ? "Edit" : "Add"}
                submitButtonColor={serviceProp ? "primary" : "success"}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

ServiceModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

ServiceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
