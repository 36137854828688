import React, { useEffect } from "react";
import api from "services/api";
import qs from "qs";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
//------------------------------------------------------------------

export const useLocationsInput = ({
  form,
  name,
  cities,
  districts: districtsArr,
  locations: locationsArr,
  fetchFromApi,
  getLocationTaskTypes,
  tourId,
  taskType,
  filters,
}) => {
  const [filtersState, setFiltersState] = React.useState({});
  const [searchQuery, setSearchQuery] = React.useState("");

  const onFilterChange = (updatedFilters) => {
    setFiltersState(updatedFilters);
  };
  const onSearchChange = (updatedSearch) => {
    setSearchQuery(updatedSearch);
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);

  const [districts, setDistricts] = React.useState([]);
  const [locations, setLocations] = React.useState([]);

  const [sendRequest, isLoading] = useHttpClient();
  const [locationsList, setLocationsList] = React.useState({
    count: 0,
    rows: [],
  });
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [highlightedText, setHighlightedText] = React.useState("");

  const toggleModal = () => {
    setFiltersState({});
    setSearchQuery("");
    setIsModalOpen(!isModalOpen);
  };
  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
    getData(currentPage, itemsPerPage, "");
  };

  const handleCreateModalOpen = () => {
    setIsCreateModalOpen(true);
  };

  const { district, city } = React.useMemo(() => filtersState, [filtersState]);
  const locationTaskTypes = React.useMemo(
    () => getLocationTaskTypes && getLocationTaskTypes(city?.id, district?.id),
    [city, district, getLocationTaskTypes]
  );
  const value = form.watch(name)?.name;

  React.useEffect(() => {
    if (district) {
      const currCity = cities.find((city) => city.id === district?.cityId);
      setFiltersState({ ...filtersState, city: currCity });
    }
  }, [district]);

  React.useEffect(() => {
    (async () => {
      if (districtsArr) {
        const newDistricts =
          city !== undefined && city !== null
            ? districtsArr.filter((district) => district.cityId === city?.id)
            : districtsArr;
        setDistricts(newDistricts);
        return;
      }
      if (fetchFromApi) {
        const response = await api.districts.getAll(
          undefined,
          undefined,
          undefined,
          { cityId: city?.id },
          undefined
        );
        setDistricts(response.data.data);
      }
    })();
  }, [city, districtsArr, fetchFromApi]);

  React.useEffect(() => {
    (async () => {
      if (locationsArr) {
        let newLocations =
          district !== undefined && district !== null
            ? locationsArr.filter(
                (location) => location.districtId === district?.id
              )
            : locationsArr;

        if (searchQuery.trim() !== "") {
          const newSearchQuery = searchQuery.trim().toLocaleLowerCase();
          newLocations = newLocations.filter((item) =>
            item.name?.toLowerCase()?.includes(newSearchQuery)
          );
        }
        setLocations(newLocations);
        return;
      }
      if (fetchFromApi) {
        const response = await api.locations.getAll(
          undefined,
          undefined,
          searchQuery,
          { districtId: district?.id },
          undefined
        );
        setLocations(response.data.data);
      }
    })();
  }, [district, locationsArr, searchQuery, fetchFromApi]);

  const onSelect = (record) => {
    form.setValue(name, record);
    toggleModal();
  };

  const getData = React.useCallback(
    async (
      page = currentPage,
      rowsPerPage = itemsPerPage,
      search = searchQuery,
      { city, district } = filtersState
    ) => {
      page = Math.max(page, 1);
      try {
        const response = await sendRequest(
          api.locations.getTaskLocations(page, rowsPerPage, search, {
            ...filters,
            ...(city && { cityId: city.id }),
            ...(district && { districtId: district.id }),
          })
        );

        setLocationsList({ count: response.count, rows: response.data });
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [filtersState, sendRequest, setLocationsList]
  );

  useEffect(() => {
    (async () => {
      if (!isModalOpen) return;
      const rowsPerPage = 10;
      setItemsPerPage(rowsPerPage);
      await getData(currentPage, rowsPerPage, searchQuery);
    })();
  }, [isModalOpen]);

  const handleChange = async (pagination) => {
    setCurrentPage(pagination.current);
    setItemsPerPage(pagination.pageSize);
    await getData(pagination.current, pagination.pageSize, searchQuery);
  };

  const handleSubmit = async (search, filters) => {
    setHighlightedText(search);
    await getData(1, itemsPerPage, search, filters);
    setCurrentPage(1);
  };

  return {
    locationTaskTypes,
    isLoading,
    cities,
    districts,
    isModalOpen,
    filtersState,
    searchQuery,
    locations,
    value,
    isCreateModalOpen,
    locationsList,
    currentPage,
    itemsPerPage,
    highlightedText,
    handleCreateModalClose,
    handleCreateModalOpen,
    toggleModal,
    onFilterChange,
    onSearchChange,
    onSelect,
    handleSubmit,
    handleChange,
  };
};
