import { dangerColor } from "assets/jss/material-dashboard-pro-react";

const styles = () => ({
  root: {
    width: "100%",
    marginBottom: 80,
    marginTop: 20,
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  error: {
    color: dangerColor[0],
    marginTop: 10,
    paddingLeft: 5,
  },
});

export default styles;
