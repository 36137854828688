import { createContext } from "react";

export const DealContext = createContext({
  deal: [],
  isGetDealLoading: false,
  path: "",
  setDeal: () => {},
  getLatestDeal: () => {},
  updateQuotationGroups: () => {},
});
