import React from "react";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import * as yup from "yup";
import { createValidationSchema } from "utils";
import { useHistory } from "react-router";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import { getDealType } from "utils/index";

//-------------------------------------------------------------------------------------

export const useLoseDealModal = (props) => {
  const { isOpen, onClose } = props;

  const [loseDealRequest, isLoseDealLoading] = useHttpClient();

  const { deal, setDeal } = React.useContext(DealContext);

  const formStructure = React.useMemo(
    () => [
      {
        column: 1,
        fields: [
          {
            name: "dealLossReasons",
            type: "autocomplete",
            label: "Deal Loss Reasons",
            options: {
              getOptionLabel: (option) => option.name,
              enum: "dealLossReason",
              multiple: true,
              blurOnSelect: false,
            },
            validation: yup.mixed().required("Required"),
          },
        ],
      },
    ],
    []
  );

  const validationSchema = React.useMemo(
    () => createValidationSchema(formStructure),
    [formStructure, createValidationSchema]
  );

  const submitHandler = (values) => async () => {
    const dealLossReasons = values.dealLossReasons.map(
      (dealLossReason) => dealLossReason.id
    );
    try {
      const response = await loseDealRequest(
        api.deals.lose(deal.id, dealLossReasons)
      );
      setDeal({
        ...deal,
        details: {
          ...deal.details,
          status: response.details.status,
          dealLossReasons: response.details.dealLossReasons,
        },
        statusHistory: response.statusHistory,
      });
      toast.success(`Deal lost successfully`);
      onClose();
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isOpen,
    onClose,
    isLoseDealLoading,
    formStructure,
    validationSchema,
    submitHandler,
    onError,
  };
};
