import enums from "enums/index";
import * as yup from "yup";

//-------------------------------------------------------------------------------------

export default (isNewOrder) => {
  return yup.object({
    deal: isNewOrder
      ? yup.mixed().required("Required")
      : yup.mixed().nullable(),
    date: yup.string().trim().required("Required"),
    tours: yup.array().of(
      yup.object().shape({
        type: yup.mixed().required("Required"),
        truckType: yup.mixed().required("Required").nullable(),
        pickupTime: yup.mixed().required("Required"),
        distanceLimit: yup.mixed().when("type", (type) => {
          return yup
            .mixed()
            .test("distanceLimit validation", "Required", (distanceLimit) => {
              if (type !== enums.TourType.PER_TRIP) {
                return !!distanceLimit || distanceLimit === 0;
              }
              return true;
            });
        }),
        durationLimit: yup.mixed().when("type", (type) => {
          return yup
            .mixed()
            .test("durationLimit validation", "Required", (durationLimit) => {
              if (type !== enums.TourType.PER_TRIP) {
                return !!durationLimit;
              }
              return true;
            });
        }),
        tasks: yup.array().when("type", (type) => {
          return yup
            .array()
            .of(
              yup.object().shape({
                type: yup.mixed().required("Required"),
                location: yup.mixed().required("Required").nullable(),
              })
            )
            .test(
              "per trip tasks validation",
              "Please add at least one task with type 'Drop Off'",
              (tasks) => {
                if (type === enums.TourType.PER_TRIP) {
                  return tasks.some(
                    (task) => task.type === enums.TaskType.DROP_OFF
                  );
                }
                return true;
              }
            );
        }),
      })
    ),
  });
};
