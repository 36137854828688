import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useToursList } from "./ToursList.hook";
import styles from "./ToursList.styles";
import Button from "components/CustomButtons/Button";
import Tour from "./Tour/Tour";

// ------------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function ToursList() {
  const toursElements = [];
  const classes = useStyles();

  const {
    tours,
    isNewOrder,
    collapseAll,
    onAdd,
    onRemove,
    handleChange,
    setCollapseAll,
  } = useToursList();

  if (tours?.length)
    tours.forEach((tour, index) => {
      toursElements.push(
        <Tour
          collapseAll={collapseAll}
          key={tour.id}
          index={index}
          data={tour}
          onChange={handleChange}
          onRemove={onRemove}
        />
      );
    });

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <h3>Tours:</h3>
        <Button
          className={classes.expandButton}
          color="primary"
          onClick={() => setCollapseAll(!collapseAll)}
        >
          {collapseAll ? "Expand All" : "Collapse All"}
        </Button>
      </div>
      <div className={classes.body}>
        {toursElements}
        {isNewOrder && (
          <div className={classes.buttonContainer}>
            <Button
              color="success"
              onClick={onAdd}
              create
              className={classes.addButton}
            >
              Add Tour
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
