import LoginPage from "views/LoginPage/LoginPage";
import AccessManagement from "./entities/AccessManagement";
import Common from "./entities/Common";
import Zoning from "./entities/Zoning";
import Deal from "./entities/Deal";
import Organizations from "./entities/Organizations/index";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Profile from "views/Profile/Profile";
import Plans from "./entities/Plans/index";
import Monthly from "./entities/Monthly/index";
import Dispatching from "./entities/Dispatching/index";
import FinancialOperations from "./entities/FinancialOperations/index";
import FinOps from "./entities/FinOps/index";
import Rental from "./entities/Rental/index";

var routes = [
  {
    hidden: true,
    path: "/login",
    name: "Login Page",
    rtlName: "هعذاتسجيل الدخول",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: `/profile`,
    name: "Profile",
    sidebarName: "Profile",
    icon: AccountCircleIcon,
    component: Profile,
    action: "read",
    layout: "/admin",
  },
  { ...AccessManagement },
  ...Common,
  { ...Organizations },
  { ...Zoning },
  { ...Deal },
  { ...Plans },
  { ...Monthly },
  { ...Dispatching },
  { ...FinancialOperations },
  ...FinOps,
  ...Rental,
];

export default routes;
