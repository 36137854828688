import { useForm } from "react-hook-form";
import AssignDriverModalValidation from "./AssignDriverModal.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { useContext, useEffect, useMemo, useState } from "react";
import api from "services/api";
import { MonthlyOrderDetailsContext } from "views/Plans/MonthlyOrderDetails/MonthlyOrderDetails.context";

export default function useAssignDriverModal({ onClose, order }) {
  const [sendRequest, isLoading, sendRequestError] = useHttpClient(true);
  const [sendDriversRequest] = useHttpClient(true);
  const resolver = useYupValidationResolver(AssignDriverModalValidation());
  const { setOrder } = useContext(MonthlyOrderDetailsContext);
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const [drivers, setDrivers] = useState([]);

  const { handleSubmit, getValues, watch, setValue } = form;

  const driver = watch("driver");
  const resetDriver = () => {
    if (!order?.monthly?.serviceProvider) return;

    setValue("driver", order.monthly.driver);
    const fetchDrivers = async () => {
      const response = (
        await sendDriversRequest(
          api.drivers.getAll(undefined, undefined, undefined, {
            serviceProviderId: order?.monthly?.serviceProvider?.id,
          })
        )
      )?.data;
      setDrivers(response);
    };
    fetchDrivers();
  };

  useEffect(() => {
    resetDriver();
  }, [order]);

  const driverId = useMemo(() => driver?.id, [driver]);

  useEffect(() => {
    setValue("phoneNumber", driver?.phonePrimary || "");
    setValue("phoneNumberSecondary", driver?.phoneSecondary || "");
    setValue("licenseId", driver?.licenseId || "");
    setValue("licenseAttachment", driver?.licenseAttachment || "");
  }, [driverId]);

  const onSubmit = (values) => async () => {
    try {
      const driver = {
        ...values.driver,
        licenseAttachment: values.licenseAttachment,
        licenseId: values.licenseId,
        phonePrimary: values.phoneNumber,
        phoneSecondary: values.phoneNumberSecondary || null,
      };
      await sendRequest(
        api.orders.patch({
          id: order.id,
          monthly: { driver },
        })
      );
      setOrder({
        ...order,
        monthly: { ...order.monthly, driver },
      });
      toast.success("Driver assigned successfully");
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const handleAddNew = (driver) => {
    setDrivers((prevDrivers) => [...prevDrivers, driver]);
    setValue("driver", driver);
  };

  return {
    form,
    isLoading,
    drivers,
    serviceProvider: order?.monthly?.serviceProvider,
    submitHandler,
    handleAddNew,
    resetDriver,
  };
}
