import enums from "enums/index";

export default {
  dealStatus: {
    [enums.DealStatus.DRAFT]: "Draft",
    [enums.DealStatus.PENDING_PRICING]: "Pending Pricing",
    [enums.DealStatus.PRICED]: "Priced",
    [enums.DealStatus.REJECTED]: "Rejected",
    [enums.DealStatus.ACCEPTED]: "Accepted",
    [enums.DealStatus.ACTIVE]: "Active",
    [enums.DealStatus.LOST]: "Lost",
  },
  invoiceRecurrence: {
    [enums.InvoiceRecurrence.DAILY]: "Daily",
    [enums.InvoiceRecurrence.WEEKLY]: "Weekly",
    [enums.InvoiceRecurrence.BIWEEKLY]: "Biweekly",
    [enums.InvoiceRecurrence.MONTHLY]: "Monthly",
  },
  dealSource: {
    [enums.DealSource.YELLOW_PAGES]: "Yellow Pages",
    [enums.DealSource.FACEBOOK]: "Facebook",
    [enums.DealSource.WEBSITE]: "Website",
    [enums.DealSource.SEARCH_ENGINES]: "Search Engines",
    [enums.DealSource.KNOCKING_DOOR]: "Knocking Door",
    [enums.DealSource.LINKEDIN]: "LinkedIn",
    [enums.DealSource.REFERRED_BY_CUSTOMER]: "Referred by Customer",
    [enums.DealSource.PERSONAL_CONTACT]: "Personal Contact",
    [enums.DealSource.TELESALES]: "Telesales",
    [enums.DealSource.REFERRED_BY_OPERATIONS]: "Referred by Operations",
    [enums.DealSource.WORD_OF_MOUTH]: "Word of Mouth",
    [enums.DealSource.FACEBOOK_CAMPAIGN]: "Facebook Campaign",
    [enums.DealSource.UPSELLING]: "Upselling",
  },
  attractiveness: {
    [enums.Attractiveness.HOT]: "Hot",
    [enums.Attractiveness.COLD]: "Cold",
    [enums.Attractiveness.WARM]: "Warm",
  },
  goodsType: {
    [enums.GoodsType.PACKAGED_FOODS]: "Packaged Foods",
    [enums.GoodsType.FRUITS_AND_VEGETABLES]: "Fruits and Vegetables",
    [enums.GoodsType.MEAT_AND_POULTRY]: "Meat and Poultry",
    [enums.GoodsType.CLOTHING_AND_TEXTILE]: "Clothing and Textile",
    [enums.GoodsType.CONSTRUCTION_MATERIALS]: "Construction Materials",
    [enums.GoodsType.FURNITURE_AND_APPLIANCES]: "Furniture and appliances",
    [enums.GoodsType.ELECTRONICS]: "Electronics",
  },
  organizationStatus: {
    [enums.OrganizationStatus.ACTIVE]: "Active",
    [enums.OrganizationStatus.INACTIVE]: "Inactive",
    [enums.OrganizationStatus.BLACK_LISTED]: "Black Listed",
  },
  quotationType: {
    [enums.QuotationType.RENTAL]: "Rental",
    [enums.QuotationType.PER_TRIP]: "Per Trip",
  },
  contactType: {
    [enums.ContactType.ADMIN]: "Admin",
    [enums.ContactType.FINANCIAL]: "Financial",
    [enums.ContactType.OPERATIONAL]: "Operational",
  },
  contractType: {
    [enums.ContractType.QUOTATION]: "Quotation",
    [enums.ContractType.CONTRACT]: "Contract",
  },
  resource: {
    [enums.Resource.USERS]: "Users",
    [enums.Resource.PERMISSIONS]: "Permissions",
    [enums.Resource.DEPARTMENTS]: "Departments",
    [enums.Resource.ROLES]: "Roles",
    [enums.Resource.SERVICES]: "Services",
    [enums.Resource.TRUCK_TYPES]: "Truck types",
    [enums.Resource.ORGANIZATIONS]: "Organizations",
    [enums.Resource.ZONING]: "Zoning",
    [enums.Resource.DEALS]: "Deals",
    // [enums.Resource.DEAL_DETAILS]: "Deal details",
    [enums.Resource.DEAL_ORGANIZATIONS]: "Deal organizations",
    [enums.Resource.DEAL_TRUCKS]: "Deal trucks",
    [enums.Resource.DEAL_QUOTATIONS]: "Deal quotations",
    [enums.Resource.DEAL_SERVICES]: "Deal services",
    [enums.Resource.GLOBALS]: "Globals",
    [enums.Resource.PLANS]: "Plans",
    [enums.Resource.DISPATCHING]: "Dispatching",
    // [enums.Resource.RENTAL]: "Rental",
    [enums.Resource.FIN_OPS]: "Financial Operations",
    [enums.Resource.SERVICE_PROVIDERS]: "Service Providers",
    [enums.Resource.TRUCKS]: "Trucks",
    [enums.Resource.DRIVERS]: "Drivers",
    [enums.Resource.COURIERS]: "Couriers",
    [enums.Resource.INVOICES]: "Invoices",
    [enums.Resource.BILLS]: "Bills",
    [enums.Resource.MONTHLY_FULFILLMENT]: "Monthly Fulfillment",
    [enums.Resource.SERVICE_PROVIDER_RENTALS]: "Service Provider Rentals",
  },
  truckAvailability: {
    [enums.TruckAvailability.LOW]: "Low",
    [enums.TruckAvailability.MEDIUM]: "Medium",
    [enums.TruckAvailability.HIGH]: "High",
  },
  routeTypes: {
    [enums.RouteTypes.INTERNAL]: "Internal",
    [enums.RouteTypes.TRAVEL]: "Travel",
  },
  organizationClassification: {
    [enums.OrganizationClassification.INTERNATIONAL_PRINCIPLE]:
      "International Principle",
    [enums.OrganizationClassification.LOCAL_PRINCIPLE]: "Local Principle",
    [enums.OrganizationClassification.TRADITIONAL_DISTRIBUTOR]:
      "Traditional Distributor",
    [enums.OrganizationClassification.E_COMMERCE]: "E-Commerce",
    [enums.OrganizationClassification.OTHER]: "Other",
  },
  organizationSize: {
    [enums.OrganizationSize.EXTRA_LARGE_ENTERPRISE]: "Extra Large Enterprise",
    [enums.OrganizationSize.LARGE_ENTERPRISE]: "Large Enterprise",
    [enums.OrganizationSize.MEDIUM_BUSINESS]: "Medium Business",
    [enums.OrganizationSize.SMALL_BUSINESS]: "Small Business",
    [enums.OrganizationSize.STARTUP]: "Startup",
  },
  dealExpectedPickupTime: {
    [enums.DealExpectedPickupTime.FROM_12AM_TO_3AM]: "12:00am - 3:00am",
    [enums.DealExpectedPickupTime.FROM_3AM_TO_6AM]: "3:00am - 6:00am",
    [enums.DealExpectedPickupTime.FROM_6AM_TO_9AM]: "6:00am - 9:00am",
    [enums.DealExpectedPickupTime.FROM_9AM_TO_12PM]: "9:00am - 12:00pm",
    [enums.DealExpectedPickupTime.FROM_12PM_TO_3PM]: "12:00pm - 3:00pm",
    [enums.DealExpectedPickupTime.FROM_3PM_TO_6PM]: "3:00pm - 6:00pm",
    [enums.DealExpectedPickupTime.FROM_6PM_TO_9PM]: "6:00pm - 9:00pm",
    [enums.DealExpectedPickupTime.FROM_9PM_TO_12AM]: "9:00pm - 12:00am",
  },
  temperatureControl: {
    [enums.TemperatureControl.FROZEN_GOODS]: "Frozen Goods",
    [enums.TemperatureControl.CHILLED_GOODS]: "Chilled Goods",
    [enums.TemperatureControl.AMBIENT_GOODS]: "Ambient Goods",
  },
  gasType: {
    [enums.GasType.GAS_95]: "95",
    [enums.GasType.GAS_92]: "92",
    [enums.GasType.GAS_80]: "80",
    [enums.GasType.SOLAR]: "Solar",
    [enums.GasType.ELECTRIC]: "Electric",
  },
  dealLossReason: {
    [enums.DealLossReason.TOO_EXPENSIVE]: "Too Expensive",
    [enums.DealLossReason.HEAVY_TRUCKS]: "Needs Heavy Trucks",
    [enums.DealLossReason.SERVICE_QUALITY]: "Lost to Service Quality",
    [enums.DealLossReason.USES_OWNED_TRUCKS]: "Customer uses owned trucks",
    [enums.DealLossReason.LATE_RESPONSE]: "Lost to late response from traxl",
    [enums.DealLossReason.SPECIAL_REQUIREMENTS]:
      "Customer needs special requirements",
    [enums.DealLossReason.WRONG_NUMBER]: "Wrong Number",
    [enums.DealLossReason.SERVICE_NOT_NEEDED]: "Service not needed",
    [enums.DealLossReason.SERVICE_PROVIDER]:
      "Satisfied with current service provider",
    [enums.DealLossReason.LOCATION]: "Location",
    [enums.DealLossReason.UNABLE_TO_REACH]:
      "Unable to reach for more than a quarter",
    [enums.DealLossReason.PORTS_TRUCKING]: "Needs Ports Trucking",
    [enums.DealLossReason.DUPLICATE_OR_HANDED_OVER]: "Duplicate or Handed over",
    [enums.DealLossReason.CIGARETTE_OR_ALCOHOL]: "Cigarette or Alcohol",
    [enums.DealLossReason.LOW_DEMAND]: "Customer Has Low Demand",
    [enums.DealLossReason.BUSINESS_CLOSED]: "Business Closed Down",
    [enums.DealLossReason.MISSED_TENDER_DATE]: "Missed Tender Date",
  },
  locationTaskType: {
    [enums.LocationTaskType.PICKUPS_AND_RETURNS_ONLY]:
      "Pickups and returns Only",
    [enums.LocationTaskType.DROPOFFS_ONLY]: "DropOffs Only",
    [enums.LocationTaskType.ALL]: "All",
  },
  locationStatus: {
    [enums.LocationStatus.DELETED]: "Deleted",
    [enums.LocationStatus.ACTIVE]: "Active",
    [enums.LocationStatus.ARCHIVED]: "Archived",
  },
  tourType: {
    [enums.TourType.DAILY]: "Daily Rental",
    [enums.TourType.MONTHLY]: "Monthly Rental",
    [enums.TourType.PER_TRIP]: "Per Trip",
  },
  taskType: {
    [enums.TaskType.PICKUP]: "Pickup",
    [enums.TaskType.DROP_OFF]: "Drop Off",
    [enums.TaskType.RETURN]: "Return",
    [enums.TaskType.LAYOVER]: "Layover",
  },
  serviceType: {
    [enums.ServiceType.BASE_TRUCK]: "Base Truck",
    [enums.ServiceType.COURIER]: "Courier",
    [enums.ServiceType.EXTRA_DROP_OFFS]: "Extra Drop Offs",
    [enums.ServiceType.EXTRA_HOURS]: "Extra Hours",
    [enums.ServiceType.EXTRA_KILOMETERS]: "Extra Kilometers",
    [enums.ServiceType.ADDITIONAL]: "Additional",
    [enums.ServiceType.RETURN]: "Return",
    [enums.ServiceType.WAITING_TIME]: "Waiting Time",
    [enums.ServiceType.TOUR_CANCELLED]: "Tour Cancelled",
  },
  taskStatus: {
    [enums.TaskStatus.UPCOMING]: "Upcoming",
    [enums.TaskStatus.ONGOING]: "Ongoing",
    [enums.TaskStatus.COMPLETED]: "Completed",
    [enums.TaskStatus.CANCELLED]: "Cancelled",
  },
  tourStatus: {
    [enums.TourStatus.UPCOMING]: "Upcoming",
    [enums.TourStatus.ONGOING]: "Ongoing",
    [enums.TourStatus.COMPLETED]: "Completed",
    [enums.TourStatus.CANCELLED]: "Cancelled",
  },
  orderChannel: {
    [enums.OrderChannel.EMAIL]: "Email",
    [enums.OrderChannel.WHATSAPP]: "Whatsapp",
    [enums.OrderChannel.HOTLINE]: "Hotline",
    [enums.OrderChannel.PLATFORM]: "Platform",
  },
  orderStatus: {
    [enums.OrderStatus.ACTIVE]: "Active",
    [enums.OrderStatus.CANCELLED]: "Cancelled",
    [enums.OrderStatus.PLANNED]: "Planned",
    [enums.OrderStatus.TERMINATED]: "Terminated",
    [enums.OrderStatus.LATE]: "Late",
    [enums.OrderStatus.EXPIRED]: "Expired",
  },
  fulfillmentStatus: {
    [enums.FulfillmentStatus.FULFILLED]: "Fulfilled",
    [enums.FulfillmentStatus.UNFULFILLED]: "Unfulfilled",
  },
  weekDay: {
    [enums.WeekDay.SUNDAY]: "Sun",
    [enums.WeekDay.MONDAY]: "Mon",
    [enums.WeekDay.TUESDAY]: "Tue",
    [enums.WeekDay.WEDNESDAY]: "Wed",
    [enums.WeekDay.THURSDAY]: "Thu",
    [enums.WeekDay.FRIDAY]: "Fri",
    [enums.WeekDay.SATURDAY]: "Sat",
  },
  dispatchModals: {
    [enums.DispatchModals.COURIER]: "Courier",
    [enums.DispatchModals.DRIVER]: "Driver",
    [enums.DispatchModals.SUPPLIER]: "Supplier",
    [enums.DispatchModals.TRUCK]: "Truck",
    [enums.DispatchModals.NONE]: "None",
  },
  discountType: {
    [enums.DiscountType.MISSING_GOODS]: "Missing Goods",
    [enums.DiscountType.DAMAGED_GOODS]: "Damaged Goods",
    [enums.DiscountType.LATE_TRUCK]: "Late Truck",
    [enums.DiscountType.OTHER]: "Other",
  },
  penaltyType: {
    [enums.PenaltyType.LATE_TRUCK]: "Late Truck",
    [enums.PenaltyType.DROPPED_TRUCK]: "Dropped Truck",
    [enums.PenaltyType.MISSING_GOODS]: "Missing Goods",
    [enums.PenaltyType.DAMAGED_GOODS]: "Damaged Goods",
    [enums.PenaltyType.DRIVER_BEHAVIOR]: "Driver Behavior",
    [enums.PenaltyType.COURIER_BEHAVIOR]: "Courier Behavior",
    [enums.PenaltyType.OTHER]: "Other",
  },
  invoiceType: {
    [enums.InvoiceType.FIXED]: "Fixed",
    [enums.InvoiceType.MIXED]: "Mixed",
    [enums.InvoiceType.ON_DEMAND]: "On Demand",
  },
  invoiceStatus: {
    [enums.InvoiceStatus.COMPLETED]: "Completed",
    [enums.InvoiceStatus.INVOICED]: "Invoiced",
    [enums.InvoiceStatus.PLANNED]: "Planned",
    [enums.InvoiceStatus.REVIEWED]: "Reviewed",
  },
  billStatus: {
    [enums.BillStatus.COMPLETED]: "Completed",
    [enums.BillStatus.BILLED]: "Billed",
    [enums.BillStatus.PLANNED]: "Planned",
    [enums.BillStatus.REVIEWED]: "Reviewed",
  },
  rentalDealStatus: {
    [enums.RentalDealStatus.PLANNED]: "Planned",
    [enums.RentalDealStatus.CANCELLED]: "Cancelled",
    [enums.RentalDealStatus.ACTIVE]: "Active",
    [enums.RentalDealStatus.TERMINATED]: "Terminated",
  },
  anErrorHasOccurred: "An error has occurred",
};
