const styles = (theme) => ({
  tooltip: {
    transform: "translateY(-20px) !important",
  },
  actionButton: {
    margin: "0 0 20px 3px",
  },
  modalHeader: {
    width: "100%",
    position: "absolute",
  },
  modalBody: {
    padding: 0,
    margin: 0,
    width: 800,
  },
  tableContainer: {
    width: "100%",
    marginTop: 25,
  },
  buttonGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: 60,
    marginBottom: 5,
  },
  formHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 400,
    padding: "10px 30px",
    paddingBottom: 20,
    borderBottom: "1px solid #ddd",
    margin: "0px -20px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  changeLocationButton: {
    margin: "0 4px",
    width: "30px",
    height: "30px",
    minWidth: "30px",
    borderRadius: "50%",
    padding: "1px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      margin: 0,
    },
  },
  valueContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  editIcon: {
    margin: "0 4px",
    width: "30px",
    height: "30px",
    minWidth: "30px",
    borderRadius: "50%",
    padding: "1px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      margin: 0,
    },
  },
  cardRoot: {
    margin: 0,
    width: "100%",
    height: "100%",
  },
  icon: {
    marginRight: 15,
    fontSize: 26,
    color: "#6f7f88",
  },
});

export default styles;
