import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import PropTypes from "prop-types";
import Task from "./Task/Task";
import { useTasksList } from "./TasksList.hook";
import styles from "./TasksList.styles";
import React from "react";

// ------------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function TasksList({ tourIndex, disabled }) {
  const tasksElements = [];
  const classes = useStyles();

  const { tasks, errors, onAdd, onRemove, handleChange } = useTasksList({
    tourIndex,
  });

  if (tasks?.length)
    tasks.forEach((task, index) => {
      tasksElements.push(
        <Task
          key={task.id}
          index={index}
          tourIndex={tourIndex}
          data={task}
          onChange={handleChange}
          onRemove={onRemove}
          disabled={disabled}
        />
      );
    });

  return (
    <div className={classes.root}>
      {tasksElements}
      <div className={classes.error}>
        {errors?.[`tours[${tourIndex}].tasks`]?.message}
      </div>
      {!disabled ? (
        <div className={classes.buttonContainer}>
          <Button
            color="success"
            onClick={onAdd}
            create
            className={classes.addButton}
          >
            Add Task
          </Button>
        </div>
      ) : null}
    </div>
  );
}

TasksList.propTypes = {
  tourIndex: PropTypes.number,
  disabled: PropTypes.bool,
};
