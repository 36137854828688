import enums from "enums/index";
import qs from "qs";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import {
  flattenObject,
  injectFilterOperators,
  setPageSettingsProp,
} from "utils";

//------------------------------------------------------------------------------

export default function useFinOpsList(props) {
  const { resource, filterInputs, selectorKey, selectionListRequest } = props;

  const history = useHistory();
  const match = useRouteMatch();

  const [selectionList, setSelectionList] = useState([]);
  const [data, setData] = useState([]);
  const [selectorSearch, setSelectorSearch] = React.useState("");
  const [currentStatus, setCurrentStatus] = useState();
  const [currentSelector, setCurrentSelector] = useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [listData, setListData] = React.useState({ count: 0, rows: [] });
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filtersState, setFiltersState] = React.useState({});
  const [deleteItemId, setDeleteItemId] = React.useState(null);
  const [activeScreen, setActiveScreen] = React.useState("question");
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [highlightedText, setHighlightedText] = React.useState("");
  const [editItemId, setEditItemId] = React.useState(null);

  const [sendGetRequest, isGetLoading] = useHttpClient();
  const [sendDeleteRequest, isDeleteLoading] = useHttpClient();
  const [sendSelectionListRequest, isSelectionListLoading] = useHttpClient();
  const [sendGetDataRequest, isDataLoading] = useHttpClient();
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);

  const handleEditModalOpen = (id) => {
    setIsEditModalOpen(true);
    setEditItemId(id);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    getData(currentPage, itemsPerPage, searchQuery, filtersState);
  };

  const getData = React.useCallback(
    async (
      page = currentPage,
      rowsPerPage = rowsPerPage,
      search = searchQuery,
      filters = filtersState
    ) => {
      if (!currentSelector) return;
      page = Math.max(page, 1);
      try {
        const response = await sendGetRequest(
          api[resource.toLowerCase()].getAll(page, rowsPerPage, search, {
            status: currentStatus,
            ...(selectorKey ? { [selectorKey]: currentSelector } : {}),
          })
        );
        setListData({
          count: response.count,
          rows: response.data,
        });
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [filterInputs, sendGetRequest, currentSelector, currentStatus, setListData]
  );

  const getSelectionData = useCallback(async () => {
    try {
      const q = processSelectorSearch(selectorSearch);

      const sendSelectionListData = (
        await sendSelectionListRequest(selectionListRequest(currentStatus, q))
      ).map((data) => ({
        ...data,
        name: `${data.name} (${data.count} ${resource.toLowerCase()})`,
      }));
      setSelectionList(sendSelectionListData);
    } catch (err) {
      toast.error("An error has occurred");
    }
  }, [currentStatus, selectorSearch]);

  React.useEffect(() => {
    (async () => {
      try {
        const rowsPerPage = 10;

        setItemsPerPage(rowsPerPage);

        let { page, search, filters } = qs.parse(
          window.location.search?.split("?")[1]
        );

        setSearchQuery(search ? search : "");
        const tempFilters = {};
        if (filterInputs) {
          filters = filters ? flattenObject(filters) : {};
          filterInputs.forEach(
            (input, index) =>
              (tempFilters[input.name] = filters[input.name]
                ? filters[input.name]
                : filterInputs[index].defaultValue !== undefined
                ? filterInputs[index].defaultValue
                : null)
          );
          setFiltersState(tempFilters);
        }

        setHighlightedText(search ? search : "");

        if (page) {
          setCurrentPage(parseInt(page));
          await getData(page, rowsPerPage, search, tempFilters);
        } else {
          page = 1;
          history.push({
            pathname: window.location.pathname,
            search: `?page=${page}`,
          });
          await getData(page, rowsPerPage, search, tempFilters);
        }
      } catch (err) {
        toast.error("An error has occurred");
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (!currentStatus) return;
      await getSelectionData();
    })();
  }, [currentStatus]);

  useEffect(() => {
    if (!currentSelector) {
      setListData({ count: 0, rows: [] });
      return;
    }
    const lastPage = Math.ceil(listData.count / itemsPerPage);
    (async () =>
      await getData(lastPage, itemsPerPage, searchQuery, filtersState))();
  }, [currentSelector, currentStatus]);

  React.useEffect(() => {
    // if page is larger than maximum page in database
    if (listData.count > 0 && !listData.rows.length) {
      const lastPage = Math.ceil(listData.count / itemsPerPage);
      const query = qs.stringify({
        search: searchQuery,
        filters: filtersState,
        page: lastPage,
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
      (async () =>
        await getData(lastPage, itemsPerPage, searchQuery, filtersState))();
    }
  }, [listData]);

  const onSearchChange = (updatedSearch) => {
    setSearchQuery(updatedSearch);
  };

  const onFilterChange = (updatedFilters) => {
    setFiltersState(updatedFilters);
  };

  const handleChange = async (pagination) => {
    setPageSettingsProp(`${match.path}.rowsPerPage`, pagination.pageSize);
    setCurrentPage(pagination.current);
    setItemsPerPage(pagination.pageSize);
    const query = qs.stringify({
      search: searchQuery,
      filters: filtersState,
      page: pagination.current,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    await getData(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      filtersState
    );
  };

  const handleSubmit = async (search, filters) => {
    setHighlightedText(search);
    const query = qs.stringify({
      search,
      filters,
      page: 1,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    await getData(1, itemsPerPage, search, filters);
    setCurrentPage(1);
  };

  const handleStatusSelect = (status) => setCurrentStatus(status);

  const handleSelector = (selector) => setCurrentSelector(selector);

  const handleSelectorSearch = (event) => setSelectorSearch(event.target.value);

  const processSelectorSearch = useCallback((search) => {
    const idPrefix = resource === enums.Resource.INVOICES ? "ORG" : "SP";
    const regex = new RegExp(`^${idPrefix}#0*(\\d+)`, "i");
    const match = search.match(regex);

    if (match) {
      const id = parseInt(match[1], 10);
      search = id.toString();
    }

    const number = Number(search);
    if (search !== "" && !isNaN(number)) {
      const trimmedNumber = String(number);
      search = trimmedNumber;
    }

    return search;
  }, []);

  return {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    deleteItemId,
    activeScreen,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    isDeleteLoading,
    selectionList,
    selectorSearch,
    editItemId,
    isEditModalOpen,
    handleSelector,
    handleSelectorSearch,
    handleStatusSelect,
    getSelectionData,
    setListData,
    setDeleteItemId,
    setActiveScreen,
    handleChange,
    handleSubmit,
    onSearchChange,
    onFilterChange,
    getData,
    handleEditModalOpen,
    handleEditModalClose,
  };
}
