import enums from "enums/index";
import * as yup from "yup";

//-------------------------------------------------------------------------------------

export default yup.object({
  tasks: yup.array().of(
    yup.object().shape({
      cost: yup.mixed().required("Required"),
    })
  ),
});
