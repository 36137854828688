import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    truckType: yup.mixed().required("Required"),
    rental: yup.object().shape({
      details: yup.object().shape({
        kmLimit: yup.mixed().required("Required"),
        daysLimit: yup.mixed().required("Required"),
        daysHoursLimit: yup.mixed().required("Required"),
      }),
      pickupZones: yup.mixed().required("Required"),
    }),
  });
};
