import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import CustomRadio from "components/CustomRadio/CustomRadio";
import CustomForm from "components/Forms/CustomForm/CustomForm";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import enums from "enums";
import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { createValidationSchema } from "utils";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import contactsForm from "views/Organizations/contactsForm";
import * as yup from "yup";
import styles from "./AddContactModal.styles";
import { toastError } from "utils/index";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AddContactModal(props) {
  const { isOpen, onClose } = props;

  const classes = useStyles();

  const [data, setData] = React.useState(null);
  const [contactFormType, setContactFormType] = React.useState(
    deal?.organization
      ? enums.ContactFormType.EXISTING
      : enums.ContactFormType.NEW
  );

  const [sendRequest, isLoading, sendRequestError] = useHttpClient();

  const { deal, setDeal } = React.useContext(DealContext);

  React.useEffect(() => {
    if (deal) {
      setData({ organizationId: deal.organization?.id });
    }
  }, [deal]);

  React.useEffect(() => {
    if (sendRequestError?.message) {
      toastError(sendRequestError.message);
    }
  }, [sendRequestError]);

  const submitHandler = (values) => async () => {
    try {
      let contact = { id: values.contactId };
      if (contactFormType === enums.ContactFormType.NEW) {
        contact = await sendRequest(api.contacts.create(values));
      }

      let dealContacts = deal?.contacts?.length
        ? deal.contacts.map((contact) => contact.id)
        : [];
      dealContacts.push(contact.id);

      const response = await sendRequest(
        api.deals.patch({ id: deal.id, contactIds: dealContacts })
      );
      setDeal(response);
      toast.success(`Contact added successfully`);
      onClose();
    } catch {}
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const newContactFormStructure = React.useMemo(() => contactsForm.create, []);

  const editDealFormStructure = React.useMemo(
    () => [
      {
        column: 1,
        fields: [
          {
            name: "contactId",
            type: "autocomplete",
            label: "Contact",
            options: {
              getData: api.contacts.getAll,
              filters: { organizationId: deal?.organization?.id },
              getOptionLabel: (option) => option.name,
              getSubmitValue: (option) => option?.id,
            },
            validation: yup.mixed().required("Required"),
          },
        ],
      },
    ],
    []
  );

  const showEditDealContactForm = React.useMemo(
    () =>
      deal?.organization && contactFormType === enums.ContactFormType.EXISTING,
    [deal, deal.organization, contactFormType]
  );

  const validationSchema = React.useMemo(
    () =>
      createValidationSchema(
        showEditDealContactForm
          ? editDealFormStructure
          : newContactFormStructure
      ),
    [
      editDealFormStructure,
      newContactFormStructure,
      createValidationSchema,
      showEditDealContactForm,
    ]
  );

  const onContactFormTypeChange = (value) => {
    setContactFormType(value);
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <AddIcon className={classes.icon} />
                Add Contact
              </div>
              {deal?.organization && (
                <CustomRadio
                  classes={{
                    root: classes.radioRoot,
                    label: classes.radioLabel,
                    radioLabel: classes.radioButtonLabel,
                    radioButtonsContainer: classes.radioButtonsContainer,
                    radioButton: classes.radioButton,
                  }}
                  value={contactFormType}
                  data={[
                    { name: "Existing", value: "EXISTING" },
                    { name: "New", value: "NEW" },
                  ]}
                  onChange={onContactFormTypeChange}
                  labelText={"Select the type of contact:"}
                />
              )}
              <CustomForm
                type={enums.FormContainerType.MODAL}
                formStructure={
                  showEditDealContactForm
                    ? editDealFormStructure
                    : newContactFormStructure
                }
                submitHandler={submitHandler}
                onError={onError}
                loading={isLoading}
                validationSchema={validationSchema}
                data={data}
                create={true}
                submitButtonText="Add"
                submitButtonColor="success"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

AddContactModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

AddContactModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
