import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { useDealTruckForm } from "./DealTruckForm.hook";
import styles from "./DealTruckForm.styles";
import React from "react";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function DealTruckForm(props) {
  const { path, isOpen, onClose, dealTruck } = props;

  const classes = useStyles();

  const { isLoading, form, onSubmit } = useDealTruckForm({
    path,
    dealTruck,
    isOpen,
    onClose,
  });

  const formBody = (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card style={{ margin: 0 }} className={classes.cardRoot}>
          <CardBody>
            <div className={classes.formHeader}>
              <EditIcon className={classes.icon} />
              {`Edit ${dealTruck?.truckType?.details?.name}`}
            </div>
            <form
              style={{ padding: 40, paddingBottom: 0 }}
              onSubmit={onSubmit}
              autoComplete="off"
            >
              <div className={classes.section}>
                <h3>General Details</h3>
                <GridContainer>
                  <FormFieldGridItem
                    name="details.onDemandCancellationNoticePeriod"
                    type="number"
                    label="On-demand Cancellation Notice Period (hours)"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="details.cancellationPriceAfterNoticePeriod"
                    type="number"
                    label="Cancellation Price After Notice Period"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="details.extraKmPrice"
                    type="text"
                    label="Extra Km Price"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="details.extraKmCost"
                    type="text"
                    label="Extra Km Cost"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="details.extraHourPrice"
                    type="number"
                    label="Extra Hour Price"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="details.extraHourCost"
                    type="number"
                    label="Extra Hour Cost"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="details.offDayFixedPrice"
                    type="number"
                    label="Off-day Fixed Price"
                    form={form}
                  />
                  {dealTruck && (
                    <React.Fragment>
                      <InfoWithLabelGridItem
                        md={5}
                        sm={5}
                        label="Extra Km Generated Price"
                        info={dealTruck.details?.extraKmGeneratedPrice}
                      />
                      <InfoWithLabelGridItem
                        md={5}
                        sm={5}
                        label="Extra Hour Generated Price"
                        info={dealTruck.details?.extraHourGeneratedPrice}
                      />
                      <InfoWithLabelGridItem
                        md={5}
                        sm={5}
                        label="Off-day Fixed Generated Price"
                        info={dealTruck.details?.offDayFixedGeneratedPrice}
                      />
                    </React.Fragment>
                  )}
                </GridContainer>
              </div>
              <div className={classes.section}>
                <h3>Per Trip</h3>
                <GridContainer>
                  <FormFieldGridItem
                    name="perTrip.details.tripKmAllowancePct"
                    type="number"
                    label="Trip Km Allowance (0 ➔ 1)"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="perTrip.details.tripDurationAllowancePct"
                    type="number"
                    label="Trip Duration Allowance (hrs)"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="perTrip.details.extraTaskKmAllowance"
                    type="number"
                    label="Extra Task Km Allowance (km)"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="perTrip.details.extraTaskTripDurationAllowance"
                    type="number"
                    label="Extra Task Trip Duration Allowance (hrs)"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="perTrip.details.taskLimit"
                    type="number"
                    label="Task Limit"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="perTrip.details.extraTaskPrice"
                    type="number"
                    label="Extra Task Price"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="perTrip.details.extraTaskTargetCost"
                    type="number"
                    label="Extra Task Target Cost"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="perTrip.details.returnTaskPrice"
                    type="number"
                    label="Return Task Price (0 ➔ 1)"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="perTrip.details.returnTaskTargetCost"
                    type="number"
                    label="Return Task Target Cost (0 ➔ 1)"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="perTrip.details.layoverTaskPrice"
                    type="number"
                    label="Layover Task Price"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="perTrip.details.layoverTaskTargetCost"
                    type="number"
                    label="Layover Task Target Cost"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="perTrip.details.travelOvernightPrice"
                    type="number"
                    label="Travel Overnight Price"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="perTrip.details.travelOvernightTargetCost"
                    type="number"
                    label="Travel Overnight Target Cost"
                    form={form}
                  />
                </GridContainer>
              </div>
              <div className={classes.section}>
                <h3>Rental</h3>
                <GridContainer>
                  <FormFieldGridItem
                    name="rental.details.idleTripLimit"
                    type="number"
                    label="Idle Trip Limit"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="rental.details.idleDiscountPricePerDay"
                    type="number"
                    label="Idle Discount Price Per Day (0 ➔ 1)"
                    form={form}
                  />
                  <FormFieldGridItem
                    name="rental.details.idleDiscountTargetCostPerDay"
                    type="number"
                    label="Idle Discount Target Cost Per Day (0 ➔ 1)"
                    form={form}
                  />
                </GridContainer>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  color="primary"
                  type="submit"
                  loading={isLoading}
                  style={{ margin: "20px 0" }}
                  edit={true}
                  loadFromRight
                />
              </div>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      {formBody}
    </CustomModal>
  );
}

DealTruckForm.defaultProps = {
  isOpen: false,
};

DealTruckForm.propTypes = {
  path: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  dealTruck: PropTypes.object,
};
