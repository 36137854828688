import { useForm } from "react-hook-form";
import AssignDriverModalValidation from "./AssignDriverModal.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { useContext, useEffect, useMemo, useState } from "react";
import api from "services/api";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

export default function useAssignDriverModal({ onClose, currentTour }) {
  const [sendRequest, isLoading, sendRequestError] = useHttpClient(true);
  const { getTour, getOrganizations, getTours } = useContext(
    TourOperationsContext
  );
  const [sendDriversRequest] = useHttpClient(true);
  const resolver = useYupValidationResolver(AssignDriverModalValidation());
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const [drivers, setDrivers] = useState([]);

  const { handleSubmit, getValues, watch, setValue } = form;

  const driver = watch("driver");
  const resetDriver = () => {
    if (!currentTour?.serviceProvider) return;

    setValue("driver", currentTour.driver);
    const fetchDrivers = async () => {
      const response = (
        await sendDriversRequest(
          api.drivers.getAll(undefined, undefined, undefined, {
            serviceProviderId: currentTour?.serviceProvider?.id,
          })
        )
      )?.data;
      setDrivers(response);
    };
    fetchDrivers();
  };

  useEffect(() => {
    resetDriver();
  }, [currentTour]);

  const driverId = useMemo(() => driver?.id, [driver]);

  useEffect(() => {
    setValue("phoneNumber", driver?.phonePrimary || "");
    setValue("phoneNumberSecondary", driver?.phoneSecondary || "");
    setValue("licenseId", driver?.licenseId || "");
    setValue("licenseAttachment", driver?.licenseAttachment || "");
  }, [driverId]);

  const onSubmit = (values) => async () => {
    try {
      if (!currentTour) {
        toast.error("no tour is selected");
        return;
      }
      const driver = {
        ...values.driver,
        licenseAttachment: values.licenseAttachment,
        licenseId: values.licenseId,
        phonePrimary: values.phoneNumber,
        phoneSecondary: values.phoneNumberSecondary || null,
      };
      await sendRequest(
        api.orders.assignTourWorker({
          id: currentTour.id,
          driver,
        })
      );
      getTour();
      toast.success("Driver assigned successfully");
      onClose();
    } catch (error) {
      if (error?.response?.data?.error === "Conflict") {
        await getOrganizations();
        await getTours();
        onClose();
      }
      console.log(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const handleAddNew = (driver) => {
    setDrivers((prevDrivers) => [...prevDrivers, driver]);
    setValue("driver", driver);
  };

  return {
    form,
    isLoading,
    drivers,
    serviceProvider: currentTour?.serviceProvider,
    submitHandler,
    handleAddNew,
    resetDriver,
  };
}
