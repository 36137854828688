import enums from "enums";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { isEmpty } from "utils/index";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";

//------------------------------------------------------------------

export const useDealTruckForm = ({ path, isOpen, onClose, dealTruck }) => {
  const [sendRequest, isLoading] = useHttpClient();

  const { deal, setDeal } = React.useContext(DealContext);

  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue } = form;

  const history = useHistory();

  React.useEffect(() => {
    if (isOpen) {
      Object.keys(dealTruck).forEach((key) => setValue(key, dealTruck[key]));
    }
  }, [isOpen, dealTruck]);

  const editHandler = (values) => async () => {
    try {
      const response = await sendRequest(
        api.deals.updateTruckType(deal.id, dealTruck.truckType.id, values)
      );
      setDeal({ ...deal, truckTypes: response.truckTypes });
      toast.success(`Truck type has been updated successfully`);
      onClose();
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let values = getValues();

    let isPerTripEmpty = true;
    if (values.perTrip?.details)
      Object.keys(values.perTrip.details).forEach((key) => {
        if (values.perTrip.details[key] === NaN) {
          values.perTrip.details[key] = null;
          isPerTripEmpty = false;
        } else if (!isEmpty(values.perTrip.details[key])) {
          isPerTripEmpty = false;
        }
      });

    if (isPerTripEmpty) {
      delete values.perTrip;
    }

    let isRentalEmpty = true;
    if (values.rental?.details)
      Object.keys(values.rental.details).forEach((key) => {
        if (values.rental.details[key] === NaN) {
          values.rental.details[key] = null;
          isRentalEmpty = false;
        } else if (!isEmpty(values.rental.details[key])) {
          isRentalEmpty = false;
        }
      });

    if (isRentalEmpty) {
      delete values.rental;
    }


    handleSubmit(editHandler(values), onError)();
  };

  return {
    isLoading,
    form,
    onSubmit,
  };
};
