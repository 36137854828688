import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading/Loading";
import Tabs from "components/Tabs/Tabs";
import { dealStatusColors } from "constants/colors";
import translations from "constants/translations";
import enums from "enums/index";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DealDetails from "views/Deals/components/Tabs/DealDetails/DealDetails";
import Organization from "views/Deals/components/Tabs/Organization/Organization";
import Quotations from "views/Deals/components/Tabs/Quotations/Quotations";
import Trucks from "views/Deals/components/Tabs/Trucks/Trucks";
import Services from "views/Deals/components/Tabs/Services/Services";
import { DealContext } from "./Deal.context";
import { useDealView } from "./DealView.hook";
import styles from "./DealView.styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import LoseDealModal from "./components/LoseDealModal/LoseDealModal";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function DealView({ path }) {
  const classes = useStyles();

  const {
    deal,
    isGetDealLoading,
    currentTab,
    submitDealForPricing,
    isSubmitDealForPricingLoading,
    finalizePricing,
    isFinalizePricingLoading,
    activateDeal,
    isActivateDealLoading,
    acceptDeal,
    isAcceptDealLoading,
    rejectDeal,
    isRejectDealLoading,
    resubmitPricing,
    isResubmitPricingLoading,
    setDeal,
    getLatestDeal,
    handleLoseDeal,
    onClose,
    handleTabChange,
    isOpen,
  } = useDealView({ path });

  const { i18n } = useTranslation();

  const dealStatusButtons = useMemo(() => {
    const loseDealButton = (
      <Button
        key={Math.random()}
        className={classes.dealStatusButton}
        color="black"
        onClick={handleLoseDeal}
      >
        Lose
      </Button>
    );
    return {
      [enums.DealStatus.DRAFT]: (
        <>
          <ConfirmationButton
            key={Math.random()}
            confirmMessage="Are you sure you want to submit deal for pricing?"
            successHeaderText="Submitted"
            successMessage="Deal has been submitted for pricing successfully."
            headerMessage="Submit for pricing"
            confirmButtonText="Confirm"
            onConfirm={submitDealForPricing}
            loading={isSubmitDealForPricingLoading}
            confirmButtonColor="primary"
            buttonProps={{
              className: classes.dealStatusButton,
              color: "primary",
              textColor: "white",
            }}
          >
            Submit for pricing
          </ConfirmationButton>
          {loseDealButton}
        </>
      ),
      [enums.DealStatus.PENDING_PRICING]: (
        <>
          <ConfirmationButton
            key={Math.random()}
            confirmMessage="Are you sure you want to finalize pricing?"
            successHeaderText="Submitted"
            successMessage="Deal pricing has been finalized successfully."
            headerMessage="Finalize Pricing"
            confirmButtonText="Confirm"
            onConfirm={finalizePricing}
            loading={isFinalizePricingLoading}
            confirmButtonColor="primary"
            buttonProps={{
              className: classes.dealStatusButton,
              color: "primary",
              textColor: "white",
            }}
          >
            Finalize Pricing
          </ConfirmationButton>
          {loseDealButton}
        </>
      ),
      [enums.DealStatus.PRICED]: (
        <>
          <ConfirmationButton
            key={Math.random()}
            confirmMessage="Are you sure you want to accept this deal?"
            successHeaderText="Accepted"
            successMessage="Deal has been accepted successfully."
            headerMessage="Accept Deal"
            confirmButtonText="Confirm"
            onConfirm={acceptDeal}
            loading={isAcceptDealLoading}
            confirmButtonColor="primary"
            buttonProps={{
              className: classes.dealStatusButton,
              color: "success",
              textColor: "white",
            }}
          >
            Accept
          </ConfirmationButton>
          <ConfirmationButton
            key={Math.random()}
            confirmMessage="Are you sure you want to reject this deal?"
            successHeaderText="Rejected"
            successMessage="Deal has been rejected successfully."
            headerMessage="Reject Deal"
            confirmButtonText="Confirm"
            onConfirm={rejectDeal}
            loading={isRejectDealLoading}
            confirmButtonColor="primary"
            buttonProps={{
              className: classes.dealStatusButton,
              color: "danger",
              textColor: "white",
            }}
          >
            Reject
          </ConfirmationButton>
          {loseDealButton}
        </>
      ),
      [enums.DealStatus.REJECTED]: (
        <>
          <ConfirmationButton
            key={Math.random()}
            confirmMessage="Are you sure you want to resubmit deal for pricing?"
            successHeaderText="Resubmitted"
            successMessage="Deal has been resubmitted for pricing successfully."
            headerMessage="Resubmit Pricing"
            confirmButtonText="Confirm"
            onConfirm={resubmitPricing}
            loading={isResubmitPricingLoading}
            confirmButtonColor="primary"
            buttonProps={{
              className: classes.dealStatusButton,
              color: "primary",
              textColor: "white",
            }}
          >
            Resubmit Pricing
          </ConfirmationButton>
          {loseDealButton}
        </>
      ),
      [enums.DealStatus.ACCEPTED]: (
        <>
          <ConfirmationButton
            key={Math.random()}
            confirmMessage="Are you sure you want to activate this deal?"
            successHeaderText="Activated"
            successMessage="Deal has been activated successfully."
            headerMessage="Activate"
            confirmButtonText="Confirm"
            onConfirm={activateDeal}
            loading={isActivateDealLoading}
            confirmButtonColor="primary"
            buttonProps={{
              className: classes.dealStatusButton,
              color: "success",
              textColor: "white",
            }}
          >
            Activate
          </ConfirmationButton>
          {loseDealButton}
        </>
      ),
      [enums.DealStatus.ACTIVE]: loseDealButton,
    };
  }, [deal]);

  const tabs = useMemo(
    () => [
      {
        name: "Details",
        component: <DealDetails />,
        resource: enums.Resource.DEALS,
      },
      {
        name: "Organization",
        component: <Organization />,
        resource: enums.Resource.DEAL_ORGANIZATIONS,
      },
      // {
      //   name: "Quotation Groups",
      //   component: <QuotationGroups />,
      //   resource: enums.Resource.DEAL_QUOTATIONS,
      // },
      {
        name: "Rentals",
        component: <Quotations type={enums.QuotationType.RENTAL} />,
        resource: enums.Resource.DEAL_QUOTATIONS,
      },

      {
        name: "Per Trip Routes",
        component: <Quotations type={enums.QuotationType.PER_TRIP} />,
        resource: enums.Resource.DEAL_QUOTATIONS,
      },
      {
        name: "Trucks",
        component: <Trucks />,
        resource: enums.Resource.DEAL_TRUCKS,
      },
      {
        name: "Services",
        component: <Services />,
        resource: enums.Resource.DEAL_SERVICES,
      },
    ],
    [deal]
  );

  return (
    <DealContext.Provider
      value={{
        deal,
        path,
        isGetDealLoading,
        setDeal,
        getLatestDeal,
      }}
    >
      <div>
        <LoseDealModal isOpen={isOpen} onClose={onClose} />
        <div className={classes.upperContainer}>
          <Breadcrumbs />
          <CheckPermission
            resource={enums.Resource.DEALS}
            action={enums.Action.CREATE}
          >
            <Link to={`/admin/${path}/create`}>
              <Button create color="white" />
            </Link>
          </CheckPermission>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card raised>
              <CardBody>
                <Loading loading={isGetDealLoading} style={{ height: "70vh" }}>
                  {deal ? (
                    <React.Fragment>
                      <div className={classes.pageHeader}>
                        <div className={classes.infoContainer}>
                          <div className={classes.infoWithIcon}>
                            <AssignmentIcon className={classes.personIcon} />
                            <div className={classes.headerInfoContainer}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {`${deal?.details.name} -`} &nbsp;
                                <div
                                  style={{
                                    color:
                                      dealStatusColors[deal?.details.status],
                                  }}
                                >
                                  {
                                    translations[i18n.language].dealStatus[
                                      deal?.details.status
                                    ]
                                  }
                                </div>
                              </div>
                              <div className={classes.subtitle}>
                                {`CRM ID: ${deal?.details.crmIdentifier}`}
                              </div>
                            </div>
                          </div>
                          <div className={classes.buttonsContainer}>
                            <CheckPermission
                              resource={enums.Resource.DEALS}
                              action={enums.Action.UPDATE}
                            >
                              {dealStatusButtons[deal?.details.status]}
                            </CheckPermission>
                          </div>
                        </div>
                      </div>
                      <Tabs
                        buttonsContainerClasses={classes.tabsContainer}
                        tabs={tabs}
                        value={currentTab}
                        onChange={handleTabChange}
                      />
                    </React.Fragment>
                  ) : (
                    <div>No available data</div>
                  )}
                </Loading>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </DealContext.Provider>
  );
}
