import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import CommonValidation from "./Common.validation";
import enums from "enums/index";
import { toast } from "react-toastify";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

export default function useCommon() {
  const {
    resource,
    currentOrg,
    currentOrgIndex,
    currentTourIndex,
    setCurrentTourIndex,
    organizations,
    pickupDate,
    serviceProviderIsNull,
    driverOrTruckIsNull,
    toursCompleted,
    toursReviewed,
    currentTour,
    isOrganizationsLoading,
    isTourLoading,
    toggleDriverOrTruckIsNull,
    setPickupDate,
    toggleServiceProviderIsNull,
    toggleToursCompleted,
    toggleToursReviewed,
    setCurrentOrgIndex,
    getOrganizations,
    getTours,
  } = useContext(TourOperationsContext);

  const [assignModal, setAssignModal] = useState(enums.DispatchModals.NONE);

  const [sendTourRequest, isTourRequestLoading] = useHttpClient(true);
  const [sendTasksRequest, isTasksLoading] = useHttpClient(true);

  const tourDisabled = useMemo(
    () =>
      currentTour?.status === enums.TourStatus.CANCELLED ||
      currentTour?.status === enums.TourStatus.COMPLETED,
    [currentTour]
  );

  const tourStarted = useMemo(() => {
    // parse the pickup date string into a Date object
    if (!currentTour) return false;
    let pickupTime = new Date(currentTour.pickupTime);

    // get the current date and time
    let currentDate = new Date();
    return currentDate >= pickupTime;
  }, [currentTour]);

  const tourReviewable = useMemo(
    () =>
      currentTour?.status === enums.TourStatus.COMPLETED ||
      currentTour?.status === enums.TaskStatus.CANCELLED,
    [currentTour]
  );
  const tourInvoices = useMemo(
    () => [
      ...(currentTour?.services?.map((service) => service.invoice) || []),
      ...(currentTour?.discounts?.map((discount) => discount.invoice) || []),
    ],
    [currentTour]
  );
  const tourBills = useMemo(
    () => [
      ...(currentTour?.services?.map((service) => service.bill) || []),
      ...(currentTour?.penalties?.map((penalty) => penalty.bill) || []),
    ],
    [currentTour]
  );
  const anyInvoiceIsCompleted = useMemo(
    () =>
      tourInvoices?.some(
        (invoice) => invoice?.status !== enums.InvoiceStatus.PLANNED
      ),
    [tourInvoices]
  );
  const anyBillIsCompleted = useMemo(
    () => tourBills?.some((bill) => bill?.status !== enums.BillStatus.PLANNED),
    [tourBills]
  );

  const tourReviewed = useMemo(
    () => currentTour?.finOpsStatus === enums.FinOpsStatus.REVIEWED,
    [currentTour]
  );

  const canRevertTour = useMemo(
    () =>
      tourReviewable &&
      resource === enums.Resource.FIN_OPS &&
      !tourReviewed &&
      !(anyInvoiceIsCompleted || anyBillIsCompleted),
    [
      anyInvoiceIsCompleted,
      anyBillIsCompleted,
      tourReviewable,
      tourReviewed,
      resource,
    ]
  );

  const onOrgClick = (orgIndex) => {
    setCurrentOrgIndex(orgIndex);
  };
  const onTourClick = (tourIndex) => {
    setCurrentTourIndex(tourIndex);
  };
  const onCloseAssignModal = (onCloseAction) => {
    if (onCloseAction) onCloseAction();
    setAssignModal(enums.DispatchModals.NONE);
  };

  const completeTour = useCallback(async () => {
    try {
      await CommonValidation.validate(currentTour);
      await sendTourRequest(
        api.tours.patch({
          id: currentTour.id,
          status: enums.TourStatus.COMPLETED,
        })
      );
      getOrganizations();
      getTours();
      toast.success("Tour Completed Successfully");
    } catch (err) {
      if (err?.response?.data?.error === "Conflict") {
        await getOrganizations();
        await getTours();
      } else {
        toast.error(err.errors[0]);
      }
    }
  }, [currentTourIndex, currentTour, getTours, currentOrgIndex]);

  const revertCompleteTour = useCallback(async () => {
    try {
      await sendTourRequest(
        api.tours.patch({
          id: currentTour.id,
          status: enums.TourStatus.ONGOING,
        })
      );
      getOrganizations();
      getTours();
      toast.success("Completed Tour Reverted Successfully");
    } catch (err) {
      if (err?.response?.data?.error === "Conflict") {
        await getOrganizations();
        await getTours();
      } else {
        toast.error("An error has occurred");
      }
    }
  }, [currentTourIndex, currentTour, getTours, currentOrgIndex]);

  const getTourLabel = (tour) => {
    const date = new Date(tour.pickupTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    return `${tour.id} | ${year}-${month}-${day} | ${hour}:${minute}`;
  };

  return {
    resource,
    serviceProviderIsNull,
    driverOrTruckIsNull,
    pickupDate,
    toursCompleted,
    toursReviewed,
    organizations,
    currentOrgIndex,
    currentOrg,
    currentTour,
    tourReviewable,
    tourReviewed,
    assignModal,
    canRevertTour,
    tourDisabled,
    tourStarted,
    isOrganizationsLoading,
    isTourLoading,
    isTasksLoading,
    sendTasksRequest,
    toggleServiceProviderIsNull,
    toggleDriverOrTruckIsNull,
    setPickupDate,
    toggleToursCompleted,
    toggleToursReviewed,
    getOrganizations,
    getTours,
    completeTour,
    revertCompleteTour,
    setAssignModal,
    onOrgClick,
    onTourClick,
    onCloseAssignModal,
    getTourLabel,
  };
}
