import enums from "enums/index";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import BillModalValidation from "./BillModal.validation";

export default function useBillModal({
  serviceProviderId,
  refresh,
  handleAddNew,
  handleModalClose,
}) {
  const resolver = useYupValidationResolver(BillModalValidation);
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });
  const [sendRequest, isLoading] = useHttpClient(true);

  const { handleSubmit, setValue, getValues, watch, reset } = form;

  const onSubmit = (values) => async () => {
    try {
      if (!serviceProviderId) {
        toast.error("must provide service provider");
        return;
      }
      const newBill = {
        referenceNumber: Math.floor(Math.random() * 99999999)
          .toString()
          .slice(0, 7),
        serviceProviderId: serviceProviderId,
        name: values.name,
        actualBillDate: values.actualBillDate,
        expectedBillDate: values.expectedBillDate,
        status: enums.BillStatus.PLANNED,
      };
      const bill = await sendRequest(api.bills.create(newBill));
      toast.success("Bill Created Successfully");
      if (handleAddNew) handleAddNew(bill);
      if (handleModalClose) handleModalClose();
      if (refresh) refresh();
      reset();
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    form,
    submitHandler,
  };
}
