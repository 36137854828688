import { useForm } from "react-hook-form";
import AssignCourierModalValidation from "./AssignCourierModal.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { useContext, useEffect, useMemo, useState } from "react";
import api from "services/api";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

export default function useAssignCourierModal({ onClose, currentTour }) {
  const [sendRequest, isLoading] = useHttpClient(true);
  const [sendCouriersRequest] = useHttpClient(true);
  const [serviceProviders, setServiceProviders] = useState();
  const { getTour, getOrganizations, getTours } = useContext(
    TourOperationsContext
  );
  const resolver = useYupValidationResolver(AssignCourierModalValidation());
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const [couriers, setCouriers] = useState([]);

  const {
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { isDirty },
  } = form;
  const courier = watch("courier");
  const resetCourier = () => {
    if (currentTour) {
      setValue("courier", currentTour.courier);

      setValue("name", currentTour.courier?.nameEn || "");
      setValue("phoneNumber", currentTour.courier?.phonePrimary || "");
      setValue(
        "phoneNumberSecondary",
        currentTour.courier?.phoneSecondary || ""
      );
      setValue("nationalId", currentTour.courier?.nationalId || "");
      setValue("courierCost", currentTour.courierCost || 0);
      setServiceProviders(currentTour.courier?.serviceProviders);
      setValue("serviceProvider", currentTour.courierServiceProvider);
    }

    const fetchCouriers = async () => {
      const response = (await sendCouriersRequest(api.couriers.getAll()))?.data;
      setCouriers(response);
    };
    fetchCouriers();
  };
  useEffect(() => {
    resetCourier();
  }, [currentTour]);

  const courierId = useMemo(() => courier?.id, [courier]);

  useEffect(() => {
    setValue("name", courier?.nameEn || "");
    setValue("phoneNumber", courier?.phonePrimary || "");
    setValue("phoneNumberSecondary", courier?.phoneSecondary || "");
    setValue("nationalId", courier?.nationalId || "");
    setServiceProviders(courier?.serviceProviders);
    setValue("serviceProvider", courier?.serviceProviders[0]);
  }, [courierId]);

  const onSubmit = (values) => async () => {
    try {
      if (!currentTour) {
        toast.error("no tour is selected");
        return;
      }
      await sendRequest(
        api.orders.assignTourWorker({
          id: currentTour.id,
          courier: {
            ...values.courier,
            nameEn: values.name,
            nationalId: values.nationalId,
            phonePrimary: values.phoneNumber,
            phoneSecondary: values.phoneNumberSecondary || null,
          },
          courierCost: values.courierCost,
          courierTargetCost: currentTour.courierTargetCost,
          ...(values.serviceProvider
            ? {
                courierServiceProviderId: values?.serviceProvider.id,
              }
            : {}),
        })
      );
      getTour();
      toast.success("Courier assigned successfully");
      onClose();
    } catch (error) {
      if (error?.response?.data?.error === "Conflict") {
        await getOrganizations();
        await getTours();
        onClose();
      }
      console.log(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const handleAddNew = (courier) => {
    setCouriers((prevCouriers) => [...prevCouriers, courier]);
    setValue("courier", courier);
  };

  const serviceProviderDisabled = useMemo(() => !courier, [courier]);
  return {
    form,
    couriers,
    serviceProviders,
    serviceProviderDisabled,
    serviceProvider: currentTour?.serviceProvider,
    isLoading,
    submitHandler,
    resetCourier,
    handleAddNew,
  };
}
