import { makeStyles } from "@material-ui/core/styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import translations from "constants/translations/index";
import enums from "enums/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDateString, getMaxDate } from "utils/index";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import DealDetailsForm from "./components/DealDetailsForm/DealDetailsForm";
import styles from "./DealDetails.styles";
import CustomTable from "components/CustomTable/CustomTable";
import { dealStatusColors } from "constants/colors";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function DealDetails() {
  const classes = useStyles();

  const [isEditing, setIsEditing] = React.useState(false);

  const { i18n } = useTranslation();

  const { deal } = React.useContext(DealContext);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const dealStatusHistoryColumns = React.useMemo(
    () => [
      {
        title: "From",
        dataIndex: "from",
        getData: (status) => {
          return (
            <div
              style={{
                color: dealStatusColors[status],
                fontWeight: 600,
              }}
            >
              {translations[i18n.language].dealStatus[status]}
            </div>
          );
        },
      },
      {
        title: "To",
        dataIndex: "to",
        getData: (status) => {
          return (
            <div
              style={{
                color: dealStatusColors[status],
                fontWeight: 600,
              }}
            >
              {translations[i18n.language].dealStatus[status]}
            </div>
          );
        },
      },
      {
        title: "Updated By",
        dataIndex: ["updatedBy", "name"],
      },
      {
        title: "Updated At",
        dataIndex: "createdAt",
        getData: (data) => (data ? getDateString(data, "en-US", true) : ""),
      },
    ],
    [deal]
  );

  return isEditing ? (
    <DealDetailsForm edit={true} setIsEditing={setIsEditing} />
  ) : (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <h3 className={classes.header}>Deal details</h3>
        <CheckPermission
          resource={enums.Resource.DEALS}
          action={enums.Action.UPDATE}
        >
          <Button onClick={handleEdit} color="primary" edit={true} />
        </CheckPermission>
      </div>
      <div className={classes.section}>
        <CollapseContainer
          name="basicInfo"
          titleComponent={<h3 style={{ margin: 0 }}>Basic Info</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem label="Name" info={deal?.details?.name} />
            <InfoWithLabelGridItem label="ID" info={deal?.id} />
            <InfoWithLabelGridItem
              label="CRM Identifier"
              info={deal?.details?.crmIdentifier}
            />
            <InfoWithLabelGridItem
              label="Goods Types"
              type={enums.InfoType.LIST}
              info={deal?.details?.goodsTypes?.map(
                (goodsType) => translations[i18n.language].goodsType[goodsType]
              )}
            />
            <InfoWithLabelGridItem
              label="Temperature Control"
              type={enums.InfoType.LIST}
              info={deal?.details?.temperatureControl?.map(
                (temperatureControl) =>
                  translations[i18n.language].temperatureControl[
                    temperatureControl
                  ]
              )}
            />
            <InfoWithLabelGridItem
              label="Recurring Invoice Period"
              info={
                translations[i18n.language].invoiceRecurrence[
                  deal?.details?.recurringInvoicePeriod
                ]
              }
            />
            <InfoWithLabelGridItem
              label="Status"
              info={
                translations[i18n.language].dealStatus[deal?.details?.status]
              }
            />
            {deal?.details?.status === enums.DealStatus.LOST && (
              <InfoWithLabelGridItem
                label="Deal Loss Reasons"
                type={enums.InfoType.LIST}
                info={deal?.details?.dealLossReasons?.map(
                  (el) => translations[i18n.language].dealLossReason[el]
                )}
              />
            )}
            <InfoWithLabelGridItem
              label="Source"
              info={
                translations[i18n.language].dealSource[deal?.details?.source]
              }
            />
            <InfoWithLabelGridItem
              label="Location Types"
              info={deal?.locationTypes?.map(
                (locationType) => locationType.name
              )}
              type={enums.InfoType.LIST}
            />
            <InfoWithLabelGridItem
              label="Tags"
              info={deal?.tags?.map((tag) => tag.name)}
              type={enums.InfoType.TAGS}
            />
            <InfoWithLabelGridItem
              label="Expiry Date"
              type={enums.InfoType.DATE}
              info={getMaxDate(
                deal?.contracts?.map((contract) => contract.expiryDate)
              )}
            />
          </GridContainer>
        </CollapseContainer>
      </div>
      <div className={classes.section}>
        <CollapseContainer
          name="expectedVolumesAndAttractiveness"
          titleComponent={
            <h3 style={{ margin: 0 }}>Expected Volumes and Attractiveness</h3>
          }
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem
              label="Attractiveness"
              info={
                translations[i18n.language].attractiveness[
                  deal?.details?.attractiveness
                ]
              }
            />
            <InfoWithLabelGridItem
              label="Expected Tours Per Month"
              info={deal?.details?.expectedToursPerMonth}
            />
            <InfoWithLabelGridItem
              label="Total Number Of Outsourced Tours Per Month"
              info={deal?.details?.totalNoOfOutsourcedToursPerMonth}
            />
            <InfoWithLabelGridItem
              label="Expected Close Date"
              info={deal?.details?.expectedCloseDate}
              type={enums.InfoType.DATE}
            />
          </GridContainer>
        </CollapseContainer>
      </div>
      <div className={classes.section}>
        <CollapseContainer
          name="opsDescription"
          titleComponent={<h3 style={{ margin: 0 }}>Ops Description</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem
              label="Description"
              info={deal?.details?.description}
            />
            <InfoWithLabelGridItem
              label="On Demand Fulfillment Duration (Hours)"
              info={deal?.details?.onDemandFulfillmentDuration}
            />
            <InfoWithLabelGridItem
              label="Rental Fulfillment Duration (Days)"
              info={deal?.details?.rentalFulfillmentDuration}
            />
            <InfoWithLabelGridItem
              label="Expected Queuing Duration (Hours)"
              info={deal?.details?.expectedQueuingDuration}
            />
            <InfoWithLabelGridItem
              label="Expected Loading Duration (Hours)"
              info={deal?.details?.expectedLoadingDuration}
            />
            <InfoWithLabelGridItem
              label="Expected Drop Off Duration (Hours)"
              info={deal?.details?.expectedDropOffDuration}
            />
            <InfoWithLabelGridItem
              label="Expected Pickup Time"
              type={enums.InfoType.LIST}
              info={deal?.details?.expectedPickupTime?.map(
                (expectedPickTime) =>
                  translations[i18n.language].dealExpectedPickupTime[
                    expectedPickTime
                  ]
              )}
            />
          </GridContainer>
        </CollapseContainer>
      </div>
      <div className={classes.section}>
        <CollapseContainer
          name="contributors"
          titleComponent={<h3 style={{ margin: 0 }}>Contributors</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem label="Owner" info={deal?.owner?.name} />
            <InfoWithLabelGridItem
              label="Priced By"
              info={deal?.pricedBy?.name}
            />
            {/* <InfoWithLabelGridItem
              label="Edited By"
              info={deal?.editedBy?.name}
            /> */}
            <InfoWithLabelGridItem
              label="Accepted By"
              info={deal?.approvedBy?.name}
            />
          </GridContainer>
        </CollapseContainer>
      </div>
      <div className={classes.section}>
        <CollapseContainer
          name="dealStatusHistory"
          titleComponent={<h3 style={{ margin: 0 }}>Deal Status History</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <div className={classes.tableContainer}>
              <CustomTable
                columns={dealStatusHistoryColumns}
                data={deal?.statusHistory || []}
                addIndexColumn
              />
            </div>
          </GridContainer>
        </CollapseContainer>
      </div>
    </div>
  );
}
