import React from "react";
import styles from "./Cards.Styles";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import enums from "enums/index";
import { toast } from "react-toastify";
import CachedIcon from "@material-ui/icons/Cached";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles(styles);

function DriverCard({ viewOnly, setAssignModal, order }) {
  const classes = useStyles();

  const driver = order?.monthly?.driver;

  return (
    <Card raised className={classes.assignCard}>
      <CardBody className={classes.assignCardBody}>
        <div className={classes.headerContainer}>
          <h3 className={classes.header}>Driver</h3>
        </div>
        <GridContainer className={classes.infoContainer}>
          <GridItem>
            <InfoWithLabel label="Name" info={driver?.nameEn} />
          </GridItem>
          <GridItem>
            <InfoWithLabel label="Phone" info={driver?.phonePrimary} />
          </GridItem>
          <GridItem>
            <InfoWithLabel label="License" info={driver?.licenseId} />
          </GridItem>
          <GridItem>
            <InfoWithLabel
              label="License Expiry Date"
              info={driver?.licenseExpirationDate.split("T")[0]}
            />
          </GridItem>
        </GridContainer>
        {order?.status !== enums.OrderStatus.CANCELLED && !viewOnly && (
          <Button
            onClick={() => {
              const noServiceProvider =
                order?.monthly?.serviceProvider === undefined ||
                order?.monthly?.serviceProvider === null;

              if (noServiceProvider) {
                toast.warning("Please select service provider first");
                return;
              }

              setAssignModal(enums.DispatchModals.DRIVER);
            }}
            color="primary"
            className={classes.assignButton}
          >
            <CachedIcon className={classes.changeIcon} /> Assign Driver
          </Button>
        )}
      </CardBody>
    </Card>
  );
}

export default DriverCard;
