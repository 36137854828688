import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import enums from "enums/index";
import React from "react";
import TourOperationsView from "views/TourOperations/TourOperationsView";

// appear in breadcrumbs and page headers
const listPageName = "Financial Operations";

const resource = enums.Resource.FIN_OPS; //TODO what resources we need here

const layout = "/admin";

const path = "finops";

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: AttachMoneyIcon,
  render: () => <TourOperationsView resource={resource} path={path} />,
  action: "read",
  resource,
  layout: layout,
};
