import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import styles from "./Buttons.styles";
import React from "react";

const useStyles = makeStyles(styles);

export default function RemoveButton({ onRemove, index }) {
  const classes = useStyles();

  return (
    <Tooltip
      title={"Delete Task"}
      placement="bottom"
      classes={{ tooltip: classes.tooltip }}
      arrow
    >
      <div style={{ width: "fit-content" }}>
        <ConfirmationButton
          onConfirm={onRemove}
          confirmMessage={`Are you sure you want to delete task number ${
            index + 1
          }`}
          successHeaderText="Task deleted"
          successMessage="Task deleted successfully"
          headerMessage="Delete Task"
          confirmButtonText="Confirm"
          confirmButtonColor="success"
          buttonProps={{
            textColor: "textColor",
            className: classes.button,
          }}
        >
          <DeleteForeverIcon style={{ cursor: "pointer" }} />
        </ConfirmationButton>
      </div>
    </Tooltip>
  );
}
