import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import PropTypes from "prop-types";
import { formatEnums } from "utils/index";
import styles from "./RentalDealStatusButton.styles";
import CustomModal from "components/CustomModal/CustomModal";
import useRentalDealStatusButton from "./RentalDealStatusButton.hook";
import Button from "components/CustomButtons/Button.js";
import enums from "enums/index";
import FormField from "components/Forms/FormField/FormField";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function RentalDealStatusButton({
  rentalDeal,
  targetStatus,
  updateRentalDeal,
  ...buttonProps
}) {
  const {
    form,
    showModal,
    statusIndex,
    confirmLoading,
    shouldButtonShow,
    submitHandler,
    openModal,
    closeModal,
  } = useRentalDealStatusButton({
    rentalDeal,
    targetStatus,
    updateRentalDeal,
  });

  const classes = useStyles();
  const formHeaderMessage =
    targetStatus === enums.RentalDealStatus.ACTIVE
      ? "Activated Rental Deal"
      : targetStatus === enums.RentalDealStatus.CANCELLED
      ? "Cancel Rental Deal"
      : "Terminate Rental Deal";

  const targetStatusText = targetStatus ? formatEnums(targetStatus) : null;

  const statusColors = ["success", "warning", "danger"];

  return shouldButtonShow ? (
    <div className={classes.buttonContainer}>
      <Tooltip
        title={
          buttonProps.justIcon
            ? targetStatus === enums.RentalDealStatus.ACTIVE
              ? "Activate"
              : targetStatus === enums.RentalDealStatus.CANCELLED
              ? "Cancel"
              : "Terminate"
            : ""
        }
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
        arrow
      >
        <div style={{ width: "fit-content" }}>
          <Button
            color={statusColors[statusIndex]}
            type="submit"
            className={classes.proceedButton}
            onClick={openModal}
            {...buttonProps}
          >
            {!buttonProps.justIcon &&
              (targetStatus === enums.RentalDealStatus.ACTIVE
                ? "Activate"
                : targetStatus === enums.RentalDealStatus.CANCELLED
                ? "Cancel"
                : "Terminate")}
          </Button>
        </div>
      </Tooltip>

      <CustomModal
        open={showModal}
        onClose={closeModal}
        modalHeaderClassName={classes.modalHeader}
        modalBodyClassName={classes.modalBody}
      >
        <div className={classes.formHeader}>
          <React.Fragment>{formHeaderMessage}</React.Fragment>
        </div>
        <form
          className={classes.form}
          onSubmit={submitHandler}
          autoComplete="off"
        >
          {`Are you sure you want to change status to ${targetStatusText}?`}
          {/* need to show termination and cancellation forms */}
          {[
            enums.RentalDealStatus.CANCELLED,
            enums.RentalDealStatus.TERMINATED,
          ].includes(targetStatus) ? (
            <FormField
              md={12}
              form={form}
              name="reason"
              type="text"
              label={`${
                targetStatus === enums.RentalDealStatus.CANCELLED
                  ? "Cancellation"
                  : "Termination"
              } Reason`}
            />
          ) : null}
          {[
            enums.RentalDealStatus.ACTIVE,
            enums.RentalDealStatus.TERMINATED,
          ].includes(targetStatus) ? (
            <FormField
              md={12}
              form={form}
              name="startDate"
              type="date"
              label={`Start Date`}
              options={{
                disabled: targetStatus === enums.RentalDealStatus.TERMINATED,
              }}
            />
          ) : null}
          {targetStatus === enums.RentalDealStatus.TERMINATED ? (
            <FormField
              md={12}
              form={form}
              name="endDate"
              type="date"
              label={`${
                targetStatus === enums.RentalDealStatus.TERMINATED
                  ? "Termination"
                  : "End"
              } Date`}
            />
          ) : null}
          <div className={classes.modalButtonsContainer}>
            <Button
              color="transparent"
              textColor="black"
              onClick={closeModal}
              className={classes.cancelButton}
              loadFromRight
            >
              Cancel
            </Button>
            <Button
              color="success"
              type="submit"
              className={classes.confirmButton}
              loading={confirmLoading}
              loadFromRight
            >
              Confirm
            </Button>
          </div>
        </form>
      </CustomModal>
    </div>
  ) : null;
}

RentalDealStatusButton.propTypes = {
  rentalDeal: PropTypes.string,
  targetStatus: PropTypes.string,
  updateRentalDeal: PropTypes.func,
};
