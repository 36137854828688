import translations from "constants/translations/index";
import enums from "enums";
import i18n from "i18n";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { getDealType, toastError } from "utils/index";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import DealDetailsFormValidation from "./DealDetailsForm.validation";

//-------------------------------------------------------------------------------------

export const useDealDetailsForm = ({ edit, setIsEditing, path: pathProp }) => {
  const { path: pathContext, setDeal: setDealContext } = React.useContext(
    DealContext
  );

  const [deal, setDeal] = React.useState(null);

  const path = pathProp || pathContext;

  const [sendGetRequest, isGetDealLoading] = useHttpClient();
  const [sendRequest, isLoading, sendRequestError] = useHttpClient();

  const history = useHistory();

  const dataResource = React.useMemo(() => api.deals, []);

  React.useEffect(() => {
    (async () => {
      if (edit) {
        const id = window.location.pathname.split("/").pop().trim();
        if (id && !isNaN(id)) {
          try {
            const response = await sendGetRequest(dataResource.getOne(id));
            setDeal(response);
          } catch (err) {
            toast.error("An error has occurred");
            setIsEditing(false);
          }
        } else {
          toast.error("An error has occurred");
          setIsEditing(false);
        }
      } else {
        setValue("details.status", enums.DealStatus.DRAFT);
      }
    })();
  }, [edit, setIsEditing, dataResource]);

  React.useEffect(() => {
    if (sendRequestError?.message) {
      toastError(sendRequestError.message);
    }
  }, [sendRequestError]);

  React.useEffect(() => {
    (async () => {
      const tempDeal = { ...deal };
      if (edit && deal) {
        if (tempDeal?.details?.goodsTypes) {
          tempDeal.details.goodsTypes = [
            ...tempDeal.details.goodsTypes.map((goodsType) => ({
              name: translations[i18n.language].goodsType[goodsType],
              id: goodsType,
            })),
          ];
          setValue("details.goodsTypes", tempDeal.details.goodsTypes);
        }
        if (tempDeal?.details?.expectedPickupTime) {
          tempDeal.details.expectedPickupTime = [
            ...tempDeal.details.expectedPickupTime.map((expectedPickTime) => ({
              name:
                translations[i18n.language].dealExpectedPickupTime[
                  expectedPickTime
                ],
              id: expectedPickTime,
            })),
          ];
          setValue(
            "details.expectedPickupTime",
            tempDeal.details.expectedPickupTime
          );
        }
        if (tempDeal?.details?.temperatureControl) {
          tempDeal.details.temperatureControl = [
            ...tempDeal.details.temperatureControl.map(
              (temperatureControl) => ({
                name:
                  translations[i18n.language].temperatureControl[
                    temperatureControl
                  ],
                id: temperatureControl,
              })
            ),
          ];
          setValue(
            "details.temperatureControl",
            tempDeal.details.temperatureControl
          );
        }
        Object.keys(tempDeal).forEach((key) => {
          if (key !== "deletedAt" && key !== "updatedAt" && key !== "createdAt")
            setValue(key, tempDeal[key]);
        });
      }
    })();
  }, [edit, deal]);

  const resolver = useYupValidationResolver(DealDetailsFormValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      status: enums.DealStatus.DRAFT,
    },
  });

  const { handleSubmit, setValue, getValues } = form;

  const createHandler = (requestData) => async () => {
    try {
      const response = await sendRequest(
        dataResource.create({
          ...requestData,
        })
      );
      toast.success("Deal draft created successfully");
      history.push({
        pathname: `/admin/${path}/${response.id}`,
      });
    } catch (err) {
      // toast.error("An error has occurred");
      history.push({
        pathname: `/admin/${path}`,
      });
    }
  };

  const editHandler = (requestData) => async () => {
    try {
      const response = await sendRequest(
        dataResource.patch({
          ...requestData,
        })
      );
      const dealType = getDealType(response.quotations);
      setDealContext({ ...response, dealType });
      toast.success("Deal details edited successfully");
      setIsEditing(false);
    } catch (err) {
      // toast.error("An error has occurred");
      setIsEditing(false);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors above and submit again.");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let requestData = { ...getValues() };

    requestData = {
      ...getValues(),
      details: {
        ...requestData.details,
        goodsTypes: requestData.details.goodsTypes?.map(
          (goodsType) => goodsType.id
        ),
        expectedPickupTime: requestData.details.expectedPickupTime?.map(
          (expectedTime) => expectedTime.id
        ),
        temperatureControl: requestData.details.temperatureControl?.map(
          (temperatureControl) => temperatureControl.id
        ),
      },
      tagIds: requestData.tags?.map((tag) => tag.id),
      locationTypeIds: requestData.locationTypes?.map(
        (locationType) => locationType.id
      ),
    };

    delete requestData.tags;
    delete requestData.locationTypes;
    delete requestData.services;
    delete requestData.quotations;

    handleSubmit(
      edit ? editHandler(requestData) : createHandler(requestData),
      onError
    )();
  };
  return {
    isLoading,
    isGetDealLoading,
    onSubmit,
    form,
    deal,
  };
};
