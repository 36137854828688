import api from "services/api";
import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "nameEn",
        type: "text",
        label: "Name (English)",
        validation: yup.string().required("Required"),
      },
      {
        name: "nameAr",
        type: "text",
        label: "Name (Arabic)",
        validation: yup.string().required("Required"),
      },
    ],
  },
];
