import api from "services/api";
import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "nameEn",
        type: "text",
        label: "Name (English)",
        validation: yup.string().required("Required"),
      },
      {
        name: "nameAr",
        type: "text",
        label: "Name (Arabic)",
        validation: yup.string().required("Required"),
      },
      {
        name: "address",
        type: "text",
        label: "Address",
        validation: yup.string(),
      },
      {
        name: "latLng",
        type: "text",
        label: "Coordinates (lat,lng)",
        validation: yup.string().required("Required"),
      },
      {
        name: "locationTypeId",
        fetchedName: "locationType",
        type: "autocomplete",
        label: "Location type",
        options: {
          getData: api.locationTypes.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (option) => option?.id,
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "districtId",
        fetchedName: "district",
        type: "autocomplete",
        label: "District",
        options: {
          getData: api.districts.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (option) => option?.id,
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "isPrivate",
        type: "boolean",
        label: "Is this location private?",
        validation: yup.boolean().required("Required"),
      },
      {
        name: "flatNumber",
        type: "text",
        label: "Flat no.",
      },
      {
        name: "floor",
        type: "number",
        label: "Floor",
      },
      {
        name: "buildingNumber",
        type: "text",
        label: "Building Number",
      },
    ],
  },
];
