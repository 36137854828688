import { grayColor } from "assets/jss/material-dashboard-pro-react.js";
import { blackColor } from "assets/jss/material-dashboard-pro-react";

const styles = (theme) => ({
  header: {
    margin: "2px",
    display: "flex",
    justifyContent: "center",
  },
  headerContainer: {
    borderColor: grayColor[4],
    color: grayColor[3],
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: 60,
  },
  table: {
    display: "block",
    width: "100%",
    overflowX: "scroll",
  },
  rowHead: {
    borderColor: "#f0f0f0",
    background: "#fafafa",
    padding: "2px",
    width: "10%",
    borderWidth: `0 1px 1px 0`,
    borderStyle: "solid",
  },

  buttonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    height: 60,
  },

  buttonGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 60,
  },
  stickyCol: {
    position: "-webkit-sticky",
    position: "sticky",
    background: "#f0f0f0",
  },
  firstCol: {
    width: "100px",
    minWidth: "100px",
    maxWidth: "100px",
    zIndex: 1,
    left: "0px",
    // boxShadow: "inset -8px 0px 10px rgba(0, 0, 0, 0.14)",
  },
  lastCol: {
    width: "150px",
    minWidth: "150px",
    maxWidth: "150px",
    zIndex: 1,
    right: "0px",
    // boxShadow: "inset 8px 0px 10px rgba(0, 0, 0, 0.14)",
  },
  addContactButton: {
    padding: "5px 15px",
    zIndex: 20,
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
});

export default styles;
