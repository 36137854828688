import enums from "enums/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { deepen, flattenObject, toastError } from "utils/index";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import { QuotationsContext } from "views/Deals/components/Tabs/Quotations/Quotations.context";
import RentalQuotationFormValidation from "./RentalQuotationForm.validation";
import { useForm } from "react-hook-form";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";

//-------------------------------------------------------------------------------------

export default function useRentalQuotationForm({ quotationProp, onClose }) {
  const [sendRequest, isLoading, sendRequestError] = useHttpClient();

  const { deal, getLatestDeal } = React.useContext(DealContext);
  const { reloadQuotations } = React.useContext(QuotationsContext);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(RentalQuotationFormValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue, watch } = form;

  const numberOfDays = watch("rental.details.daysLimit");

  React.useEffect(() => {
    if (quotationProp) {
      const truckType = quotationProp.truckType;
      delete quotationProp.truckType;

      const formData = {
        ...flattenObject(quotationProp),
        truckType,
        dealId: deal.id,
      };

      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    } else {
      setValue("dealId", deal.id);
    }
  }, [quotationProp, deal.id]);

  React.useEffect(() => {
    if (sendRequestError?.message) {
      toastError(sendRequestError.message);
    }
  }, [sendRequestError]);

  const createHandler = (values) => async () => {
    try {
      await sendRequest(api.quotations.create(deepen(values)));
      toast.success(`Quotation has been created successfully`);

      reloadQuotations();
      onClose();
    } catch {}
  };

  const editHandler = (values) => async () => {
    try {
      await sendRequest(api.quotations.patch(deepen(values)));
      toast.success(`Quotation has been updated successfully`);

      getLatestDeal();
      onClose();
    } catch {}
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let values = getValues();

    if (values?.truckType?.id) {
      values.truckTypeId = values.truckType.id;
      delete values.truckType;
    }
    if (values?.rental?.pickupZones) {
      values.rental.pickupZoneIds = values.rental.pickupZones.map(
        (zone) => zone.id
      );
      delete values.rental.pickupZones;
    }
    if (values?.rental?.details?.kmLimit) {
      values.rental.details.kmLimit = parseInt(values.rental.details.kmLimit);
    }
    if (values?.rental?.details?.daysHoursLimit) {
      values.rental.details.daysHoursLimit = parseInt(
        values.rental.details.daysHoursLimit
      );
    }
    if (values?.rental?.details?.daysLimit) {
      values.rental.details.daysLimit = parseInt(
        values.rental.details.daysLimit
      );
    }
    values.details.type = enums.QuotationType.RENTAL;

    handleSubmit(
      !!quotationProp ? editHandler(values) : createHandler(values),
      onError
    )();
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    numberOfDays,
    submitHandler,
    onError,
  };
}
