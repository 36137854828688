import enums from "enums/index";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { useForm } from "react-hook-form";
import MonthlyStatusButtonValidation from "./MonthlyStatusButton.validation";

export default function useMonthlyStatusButton({
  order,
  targetStatus,
  updateOrder,
}) {
  const statusList = Object.values(enums.OrderStatus);
  const statusIndex =
    statusList.findIndex((value) => value === targetStatus) - 1;
  // add validation for each case
  const resolver = useYupValidationResolver(
    MonthlyStatusButtonValidation(targetStatus, startDate)
  );

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      startDate: null,
      endDate: null,
    },
  });

  const [showModal, setShowModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { handleSubmit, getValues, setValue, reset } = form;

  const currentStatus = useMemo(() => order?.status, [order]);
  const startDate = useMemo(() => order?.startDate, [order]);
  const orderId = useMemo(() => order?.id, [order]);

  useEffect(() => {
    if (showModal) {
      if (order?.startDate)
        setValue(
          "startDate",
          targetStatus === enums.OrderStatus.ACTIVE
            ? new Date().toISOString().split("T")[0]
            : order.startDate
        );
      if (order?.endDate) setValue("endDate", order.endDate);
    } else {
      reset();
    }
  }, [orderId, showModal]);

  const onSubmit = (values) => async () => {
    setConfirmLoading(true);
    try {
      let orderUpdate = { status: targetStatus };
      if (targetStatus === enums.OrderStatus.ACTIVE) {
        orderUpdate = {
          ...orderUpdate,
          startDate: values.startDate,
          endDate: values.endDate,
        };
        //you need to generate tours form that date
      }

      if (targetStatus === enums.OrderStatus.CANCELLED) {
        orderUpdate = { ...orderUpdate, endReason: values.reason };
      }

      if (targetStatus === enums.OrderStatus.TERMINATED) {
        orderUpdate = {
          ...orderUpdate,
          endReason: values.reason,
          endDate: values.endDate,
        };
      }
      await updateOrder(orderUpdate);

      setConfirmLoading(false);

      toast.success("Status changed successfully");
      closeModal();
    } catch (error) {
      setConfirmLoading(false);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const openModal = () => setShowModal(true);

  const closeModal = () => {
    setShowModal(false);
    reset();
  };

  const shouldButtonShow = useMemo(
    () =>
      ([enums.OrderStatus.PLANNED, enums.OrderStatus.LATE].includes(
        currentStatus
      ) &&
        [enums.OrderStatus.ACTIVE, enums.OrderStatus.CANCELLED].includes(
          targetStatus
        )) ||
      (currentStatus === enums.OrderStatus.ACTIVE &&
        targetStatus === enums.OrderStatus.TERMINATED),
    [currentStatus, targetStatus]
  );

  return {
    form,
    showModal,
    statusList,
    statusIndex,
    confirmLoading,
    shouldButtonShow,
    submitHandler,
    openModal,
    closeModal,
  };
}
