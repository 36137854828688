import React, { useEffect, useState, useCallback } from "react";
import qs from "qs";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { getMonthBoundaries } from "utils/index";

//-------------------------------------------------------------------------------------
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const currentMonthName = new Date(
  currentYear,
  currentMonth
).toLocaleString("en-US", { month: "long" });

export default function useServiceProviderRentalTours({
  serviceProviderRental,
}) {
  const history = useHistory();
  const [listData, setListData] = React.useState({ count: 0, rows: [] });
  const [sendRequest, isLoading] = useHttpClient();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filtersState, setFiltersState] = React.useState({
    month: { name: currentMonthName, value: currentMonth },
    year: currentYear.toString(),
  });
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [highlightedText, setHighlightedText] = React.useState("");
  const [tab, setTab] = useState();

  const getData = React.useCallback(
    async (
      page = currentPage,
      rowsPerPage = rowsPerPage,
      search = searchQuery,
      { month, year } = filtersState
    ) => {
      page = Math.max(page, 1);
      try {
        const { startDate, endDate } = getMonthBoundaries(month.value, year);
        const response = await sendRequest(
          api.serviceProviderRentals.getTours(1, page, rowsPerPage, search, {
            serviceProviderRentalId: serviceProviderRental?.id,
            startDate,
            endDate,
          })
        );

        setListData({ count: response.count, rows: response.data });
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [filtersState, sendRequest, setListData]
  );

  useEffect(() => {
    let currentTab = tab;
    if (currentTab === undefined) {
      currentTab = new URLSearchParams(window.location.search).get("tab");
      setTab(currentTab);
    }

    (async () => {
      const rowsPerPage = 10;
      setItemsPerPage(rowsPerPage);

      let { page, search, filters } = qs.parse(
        window.location.search?.split("?")[1]
      );

      setSearchQuery(search || "");
      setHighlightedText(search || "");

      if (page) {
        setCurrentPage(parseInt(page));
        await getData(page, rowsPerPage, search);
      } else {
        page = 1;
        const query = qs.stringify({ tab: currentTab, page });
        history.push({
          pathname: window.location.pathname,
          search: `?${query}`,
        });
        await getData(page, rowsPerPage, search);
      }
    })();
  }, []);

  useEffect(() => {
    if (listData.count > 0 && !listData.rows.length) {
      const lastPage = Math.ceil(listData.count / itemsPerPage);
      const query = qs.stringify({
        tab,
        search: searchQuery,
        page: lastPage,
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
      (async () => await getData(lastPage, itemsPerPage, searchQuery))();
    }
  }, [listData]);

  const onSearchChange = (updatedSearch) => {
    setSearchQuery(updatedSearch);
  };

  const handleChange = async (pagination) => {
    // setPageSettingsProp(`${match.path}.rowsPerPage`, pagination.pageSize);
    setCurrentPage(pagination.current);
    setItemsPerPage(pagination.pageSize);
    const query = qs.stringify({
      tab,
      search: searchQuery,
      page: pagination.current,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    await getData(pagination.current, pagination.pageSize, searchQuery);
  };

  const handleSubmit = async (search) => {
    setHighlightedText(search);
    const query = qs.stringify({ tab, search, page: 1 });
    history.push({ pathname: window.location.pathname, search: `?${query}` });
    await getData(1, itemsPerPage, search);
    setCurrentPage(1);
  };

  const onFilterChange = (updatedFilters) => {
    setFiltersState(updatedFilters);
  };

  return {
    listData,
    currentPage,
    itemsPerPage,
    searchQuery,
    highlightedText,
    filtersState,
    isLoading,
    onSearchChange,
    onFilterChange,
    handleSubmit,
    handleChange,
  };
}
