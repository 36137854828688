import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CachedIcon from "@material-ui/icons/Cached";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import CustomForm from "components/Forms/CustomForm/CustomForm";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import enums from "enums/index";
import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { createValidationSchema } from "utils";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import * as yup from "yup";
import styles from "./AssignOrganizationModal.styles";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AssignOrganizationModal(props) {
  const { isOpen, onClose } = props;

  const classes = useStyles();

  const [data, setData] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const { deal, setDeal } = React.useContext(DealContext);

  React.useEffect(() => {
    if (deal) {
      setData({ id: deal.id });
    }
  }, [deal]);

  const submitHandler = (values) => async () => {
    try {
      const response = await sendRequest(
        api.deals.assignOrganization(values.id, values.organizationId)
      );
      setDeal(response);
      toast.success(`Deal has been updated successfully`);
      onClose();
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const organization = React.useMemo(() => deal?.organization, [deal]);

  const formStructure = React.useMemo(
    () => [
      {
        column: 1,
        fields: [
          {
            name: "id",
            type: "hidden",
          },
          {
            name: "organizationId",
            fetchedName: "organization",
            type: "autocomplete",
            label: "Organization",
            options: {
              loadOnFocus: true,
              getData: () =>
                api.organizations.getAll(undefined, undefined, undefined, {
                  statuses: [
                    enums.OrganizationStatus.ACTIVE,
                    enums.OrganizationStatus.INACTIVE,
                  ],
                }),
              getOptionLabel: (option) => option.name,
              getSubmitValue: (option) => option?.id,
            },
            validation: yup.mixed().required("Required"),
          },
        ],
      },
    ],
    []
  );

  const validationSchema = React.useMemo(
    () => createValidationSchema(formStructure),
    [formStructure, createValidationSchema]
  );

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                {organization ? (
                  <CachedIcon className={classes.icon} />
                ) : (
                  <AddIcon className={classes.icon} />
                )}
                {organization ? "Change Organization" : `Assign Organization `}
              </div>
              <CustomForm
                type={enums.FormContainerType.MODAL}
                formStructure={formStructure}
                submitHandler={submitHandler}
                onError={onError}
                loading={isLoading}
                validationSchema={validationSchema}
                data={data}
                edit={!!organization}
                create={!organization}
                submitButtonText={organization ? "Change" : "Assign"}
                submitButtonColor={organization ? "primary" : "success"}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

AssignOrganizationModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

AssignOrganizationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
