import MapIcon from "@material-ui/icons/Map";
import enums from "enums/index";
import citiesRoutes from "./citiesRoutes";
import countriesRoutes from "./countriesRoutes";
import districtsRoutes from "./districtsRoutes";
import locationsRoutes from "./locationsRoutes";
import locationTypesRoutes from "./locationTypesRoutes";
import zoneMappingsRoutes from "./zoneMappingsRoutes";
import zonesRoutes from "./zonesRoutes";

export default {
  name: "Zoning",
  icon: MapIcon,
  layout: "/admin",
  collapse: true,
  state: "zoningCollapse",
  resource: enums.Resource.ZONING,
  views: [
    { ...countriesRoutes },
    { ...citiesRoutes },
    { ...districtsRoutes },
    { ...locationsRoutes },
    { ...locationTypesRoutes },
    { ...zonesRoutes },
    { ...zoneMappingsRoutes },
  ],
};
