import React from "react";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./PenaltiesTable.styles";
import PenaltyRow from "./Components/PenaltyRow/PenaltyRow";
import AddPenaltyModal from "./Components/AddPenaltyModal/AddPenaltyModal";
import usePenaltiesTable from "./PenaltiesTable.hook";

const useStyles = makeStyles(styles);

export default function PenaltiesTable({
  form,
  currentTour,
  tourReviewed,
  inFinOps,
  tourDisabled,
  tourReviewable,
}) {
  const penaltiesElements = [];
  const classes = useStyles();

  const {
    onRemove,
    penalties,
    isModalOpen,
    onModalClose,
    onModalOpen,
  } = usePenaltiesTable(form, currentTour);

  if (penalties?.length)
    penalties.forEach((penalty, index) => {
      penaltiesElements.push(
        <PenaltyRow
          tourReviewed={tourReviewed}
          tourReviewable={tourReviewable}
          onRemove={onRemove}
          key={penalty.id}
          index={index}
          data={penalty}
          form={form}
        />
      );
    });

  return (
    <div>
      <div className={classes.buttonGroup}>
        {(inFinOps || (!inFinOps && !tourDisabled)) && (
          <Button
            onClick={() => {
              onModalOpen();
            }}
            className={classes.addContactButton}
            color="primary"
            disabled={tourReviewed}
          >
            Add Penalty
          </Button>
        )}
      </div>

      <Card raised style={{ margin: 0 }}>
        <CardBody noPadding>
          {penaltiesElements.length > 0 && (
            <table>
              <tr>
                <th
                  style={{ borderTopLeftRadius: "10px" }}
                  className={classes.rowHead}
                >
                  Type
                </th>
                <th className={classes.rowHead}>Service Provider</th>
                <th className={classes.rowHead}>Penalty Price</th>
                <th className={classes.rowHead}>Notes</th>
                <th className={classes.rowHead}>Added By</th>
                {
                  <th
                    className={classes.rowHead}
                    style={{
                      borderWidth: `0 0 1px 0`,
                      borderTopRightRadius: "10px",
                    }}
                  >
                    Actions
                  </th>
                }
              </tr>
              {penaltiesElements}
            </table>
          )}
          {penaltiesElements.length === 0 && (
            <h3 className={classes.headerContainer}>No Penalties To Show</h3>
          )}
        </CardBody>
      </Card>
      <AddPenaltyModal
        currentTour={currentTour}
        isOpen={isModalOpen}
        onClose={onModalClose}
      />
    </div>
  );
}
