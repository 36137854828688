import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import LocationsFormValidation from "./LocationsForm.validation";
import enums from "enums/index";

//------------------------------------------------------------------

export const useLocationsForm = ({
  edit,
  create,
  deal,
  cities,
  districts: districtsArr,
  location,
  refresh,
  handleAddNew,
  handleModalClose,
  getLocationTaskTypes,
}) => {
  const [data, setData] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const [sendGetRequest, isGetLoading] = useHttpClient();

  const dataResource = React.useMemo(() => api.locations, []);

  const resolver = useYupValidationResolver(LocationsFormValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: { taskType: enums.LocationTaskType.ALL },
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isDirty },
  } = form;

  const districtId = watch("district")?.id;

  const cityId = watch("city")?.id;

  const locationType = watch("locationType")?.name;

  const [districts, setDistricts] = React.useState(null);

  const locationTaskTypes = React.useMemo(
    () => getLocationTaskTypes && getLocationTaskTypes(cityId, districtId),
    [cityId, districtId, getLocationTaskTypes]
  );

  const residentialOrBusiness =
    locationType === "Residential" || locationType === "Business Unit";

  React.useEffect(() => {
    (async () => {
      if (deal) {
        setValue("deal", deal);
        setValue("isPrivate", true);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (districtId) {
        const city = watch("district")?.city;
        setValue("city", city);
      }
    })();
  }, [districtId]);

  React.useEffect(() => {
    (async () => {
      if (districtsArr) {
        const newDistricts =
          cityId !== undefined && cityId !== null
            ? districtsArr.filter((district) => district.cityId === cityId)
            : districtsArr;
        setDistricts(newDistricts);
        return;
      }
      const response = await api.districts.getAll(
        undefined,
        undefined,
        undefined,
        { cityId },
        undefined
      );
      setDistricts(response.data.data);
    })();
  }, [cityId]);

  React.useEffect(() => {
    if (edit) {
      const id = window.location.pathname.split("/").reverse()[1];
      if (id) {
        (async () => {
          try {
            const response = await sendGetRequest(dataResource.getOne(id));
            setData(response);
            if (response) {
              Object.keys(response).forEach((key) =>
                setValue(key, response[key])
              );
            }
          } catch (err) {
            toast.error("An error has occurred");
          }
        })();
      } else {
        toast.error("An error has occurred");
      }
    } else if (location) {
      setData(location);
      Object.keys(location).forEach((key) => setValue(key, location[key]));
    }
  }, [edit, location]);

  const handleSelectCity = () => setValue("district", null);

  const createHandler = (values) => async () => {
    try {
      const [latitude, longitude] = values.latLng
        .replace("(", "")
        .replace(")", "")
        .split(",");
      values.latitude = latitude.trim();
      values.longitude = longitude.trim();
      values.latLng = `${latitude.trim()}, ${longitude.trim()}`;
      const response = await sendRequest(dataResource.create(values));
      if (handleModalClose) handleModalClose();
      if (refresh) refresh();
      if (handleAddNew) handleAddNew(response);
      toast.success(`Location has been created successfully`);
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const editHandler = (values) => async () => {
    try {
      const [latitude, longitude] = values.latLng
        .replace("(", "")
        .replace(")", "")
        .split(",");
      values.latitude = latitude.trim();
      values.longitude = longitude.trim();
      values.latLng = `${latitude.trim()}, ${longitude.trim()}`;
      await sendRequest(dataResource.patch(values));
      toast.success(`Location has been updated successfully`);
      if (handleModalClose) handleModalClose();
      if (refresh) refresh();
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let values = getValues();

    values.locationTypeId = values?.locationType?.id;
    delete values?.locationType;

    values.districtId = values?.district?.id;
    delete values?.district;

    values.dealId = values?.deal?.id || null;
    values.isPrivate = !!values?.deal;
    delete values?.deal;

    delete values.city;

    handleSubmit(
      create ? createHandler(values) : editHandler(values),
      onError
    )();
  };

  return {
    isLoading,
    isGetLoading,
    form,
    locationTaskTypes,
    data,
    cityId,
    cities,
    districts,
    residentialOrBusiness,
    watch,
    onSubmit,
    handleSelectCity,
  };
};
