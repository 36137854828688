export const firebaseConfig = {
  local: {
    apiKey: "AIzaSyCTK0U0OaaRewXastqodRlN6Beryt4aq3M",
    authDomain: "traxl-dev-a431b.firebaseapp.com",
    projectId: "traxl-dev-a431b",
    storageBucket: "traxl-dev-a431b.appspot.com",
    messagingSenderId: "788524046702",
    appId: "1:788524046702:web:ed9032d0762d16f50ac97f",
    measurementId: "G-BJXHKJ5P1J",
  },
  staging: {
    apiKey: "AIzaSyCHWQW2GDjFAEuvGGFG0CbOcFCtzQuTv4w",
    authDomain: "traxl-staging.firebaseapp.com",
    projectId: "traxl-staging",
    storageBucket: "traxl-staging.appspot.com",
    messagingSenderId: "22101402735",
    appId: "1:22101402735:web:2b1b169f4f7bd1d225199a",
    measurementId: "G-H0QYX67SNS",
  },
  production: {
    apiKey: "AIzaSyDl0rRmxNotw_DxO3wcQ-QmmMbiRpfdhoM",
    authDomain: "traxl-f6063.firebaseapp.com",
    projectId: "traxl-f6063",
    storageBucket: "traxl-f6063.appspot.com",
    messagingSenderId: "325914948758",
    appId: "1:325914948758:web:bbf4dc08a21742bbce7eb7",
    measurementId: "G-5WWV1H9W73",
  },
};
