import { useForm } from "react-hook-form";
import AssignSupplierModalValidation from "./AssignSupplierModal.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api";
import { useContext, useEffect, useMemo } from "react";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";

export default function useAssignSupplierModal({ currentTour, onClose }) {
  const [sendRequest, isLoading] = useHttpClient(true);
  const { getTour, getOrganizations, getTours, serviceProviders } = useContext(
    TourOperationsContext
  );
  const resolver = useYupValidationResolver(AssignSupplierModalValidation());
  const form = useForm({
    defaultValues: {
      serviceProvider: null,
      phoneNumber: null,
      phoneNumberSecondary: null,
      nationalId: null,
      dealImage: null,
      cost: null,
    },
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, setValue, getValues, watch, reset } = form;

  const serviceProvider = watch("serviceProvider");

  useEffect(() => {
    reset();
  }, [currentTour]);

  const serviceProviderId = useMemo(() => serviceProvider?.id, [
    serviceProvider,
  ]);

  useEffect(() => {
    setValue("phoneNumber", serviceProvider?.phonePrimary);
    setValue("phoneNumberSecondary", serviceProvider?.phoneSecondary);
    setValue("nationalId", serviceProvider?.nationalId);
  }, [serviceProviderId]);

  useEffect(() => {
    setValue("dealImage", currentTour?.serviceProviderContractUrl);
    setValue("cost", currentTour?.baseTruckCost || 0);
  }, [currentTour]);

  const onSubmit = (values) => async () => {
    try {
      if (!currentTour) {
        toast.error("no tour is selected");
        return;
      }
      delete values.serviceProvider["bills"];
      await sendRequest(
        api.orders.assignTourWorker({
          id: currentTour.id,
          serviceProviderContractUrl: values.dealImage,
          baseTruckCost: values.cost,
          serviceProvider: {
            ...values.serviceProvider,
            nationalId: values.nationalId,
            phonePrimary: values.phoneNumber,
            phoneSecondary: values.phoneNumberSecondary || null,
          },
        })
      );
      getTour();
      toast.success("Service Provider Assigned Successfully");
      onClose();
    } catch (error) {
      if (error?.response?.data?.error === "Conflict") {
        await getOrganizations();
        await getTours();
        onClose();
      }
      console.log(error);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    delete values.serviceProvider["bills"];
    handleSubmit(onSubmit(values), onError)();
  };

  return {
    serviceProviders,
    isLoading,
    submitHandler,
    form,
  };
}
