import { makeStyles } from "@material-ui/core/styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import translations from "constants/translations/index";
import enums from "enums/index";
import React from "react";
import { getI18n, useTranslation } from "react-i18next";
import { CourierRentalContext } from "views/CourierRentals/pages/CourierRentalView/CourierRentalView.context";
import CourierRentalForm from "../CourierRentalFrom/CourierRentalForm";
import styles from "./CourierRentalDetails.styles";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function CourierRentalDetails() {
  const classes = useStyles();

  const [isEditing, setIsEditing] = React.useState(false);

  const { i18n } = useTranslation();

  const { courierRental } = React.useContext(CourierRentalContext);

  const handleEdit = () => {
    setIsEditing(true);
  };

  return isEditing ? (
    <CourierRentalForm edit={true} setIsEditing={setIsEditing} />
  ) : (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <h3 className={classes.header}>Courier Rental details</h3>
        <CheckPermission
          resource={enums.Resource.SERVICE_PROVIDER_RENTALS}
          action={enums.Action.UPDATE}
        >
          <Button onClick={handleEdit} color="primary" edit={true} />
        </CheckPermission>
      </div>
      <div className={classes.section}>
        <CollapseContainer
          info="basicInfo"
          titleComponent={<h3 style={{ margin: 0 }}>Basic Info</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={courierRental?.serviceProvider?.name}
              label="Service Provider"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={courierRental?.courier?.name}
              label="Courier"
            />
            <InfoWithLabelGridItem
              info={courierRental?.startDate}
              type={enums.InfoType.DATE}
              label="Start Date"
            />
            <InfoWithLabelGridItem
              info={courierRental?.endDate}
              type={enums.InfoType.DATE}
              label="End Date"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.LIST}
              info={courierRental?.offDays?.map(
                (day) => translations[getI18n().language].weekDay[day]
              )}
              label="Off Days"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={courierRental?.workingDays}
              label="Number Of Working Days"
            />
            <InfoWithLabelGridItem
              type={enums.InfoType.TEXT}
              info={courierRental?.monthlyCourierCost}
              label="Monthly Courier Cost"
            />
            {courierRental?.cancellationReason && (
              <InfoWithLabelGridItem
                type={enums.InfoType.TEXT}
                info={courierRental?.cancellationReason}
                label="Cancellation Reason"
              />
            )}
          </GridContainer>
        </CollapseContainer>
      </div>
    </div>
  );
}
