import React from "react";
import api from "services/api";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import { useHttpClient } from "shared/hooks/http-hook";
import { toast } from "react-toastify";

//-------------------------------------------------------------------------------------

export default function useTrucks() {
  const [isEditTruckModalOpen, setIsEditTruckModalOpen] = React.useState(false);
  const [dealTruck, setDealTruck] = React.useState(null);

  const [sendRequest, isGetTrucksLoading] = useHttpClient();

  const { deal, setDeal } = React.useContext(DealContext);

  const getTruckTypes = React.useCallback(async () => {
    try {
      const response = await sendRequest(api.deals.getTruckTypes(deal.id));
      setDeal({ ...deal, truckTypes: response });
    } catch (err) {
      toast.error("An error has occurred");
    }
  }, [sendRequest, setDeal, api, deal.id]);

  React.useEffect(() => {
    getTruckTypes();
  }, [getTruckTypes]);


  const truckTypes = React.useMemo(() => {
    return deal?.truckTypes || [];
  }, [deal]);

  const handleEditTruck = React.useCallback(
    (truck) => {
      setDealTruck(truck);
      setIsEditTruckModalOpen(true);
    },
    [setIsEditTruckModalOpen, setDealTruck]
  );

  const onEditModalClose = React.useCallback(() => {
    setIsEditTruckModalOpen(false);
    setDealTruck(null);
  }, [setIsEditTruckModalOpen, setDealTruck]);

  return {
    deal,
    isEditTruckModalOpen,
    isGetTrucksLoading,
    dealTruck,
    truckTypes,
    onEditModalClose,
    handleEditTruck,
  };
}
