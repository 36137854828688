import { makeStyles } from "@material-ui/core/styles";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { getDateString } from "utils/index";
import TasksList from "../../TasksList/TasksList";
import styles from "./Tour.styles";
import classNames from "classnames";
import translations from "constants/translations/index";
import { getI18n } from "react-i18next";
import enums from "enums/index";
import { useTour } from "./Tour.hook";
import { TourContext } from "./Tour.context";

// ------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

function Tour(props) {
  const { onRemove, index, collapseAll, data } = props;

  const {
    tourTypes,
    truckTypes,
    distanceLimitOptions,
    durationLimitOptions,
    form,
    isNewOrder,
    plan,
    tour,
    tourType,
    tourError,
    isTourTypeDisabled,
    isTruckTypeDisabled,
    isDistanceLimitDisabled,
    isDurationLimitDisabled,
    cities,
    districts,
    locations,
    tourPickupLocation,
    selectedTourType,
    selectedTruckType,
    selectedDistanceLimit,
    selectedDurationLimit,
    handleChange,
    setLocations,
    setTourPickupLocation,
  } = useTour({ index, data });

  const classes = useStyles();

  const rootClasses = classNames({
    [classes.root]: true,
    [classes.rootError]: tourError,
  });

  const disabled =
    tour.status !== undefined && tour.status !== enums.TourStatus.UPCOMING;

  return (
    <TourContext.Provider
      value={{
        cities,
        districts,
        locations,
        tourPickupLocation,
        selectedTourType,
        selectedTruckType,
        selectedDistanceLimit,
        selectedDurationLimit,
        tour,
        setLocations,
        setTourPickupLocation,
      }}
    >
      <div id={`tour-${index}`} tabindex={index} className={rootClasses}>
        {isNewOrder && (
          <ConfirmationButton
            onConfirm={() => onRemove(index)}
            confirmMessage="Are you sure you want to remove this tour?"
            successHeaderText="Tour Removed"
            successMessage="Tour removed successfully"
            headerMessage="Remove Tour"
            confirmButtonText="Remove"
            buttonProps={{
              className: classes.removeButton,
              deleteButton: true,
              color: "danger",
              textColor: "white",
            }}
            rootClass={classes.removeButtonContainer}
          />
        )}
        <CollapseContainer
          titleComponent={
            <div className={classes.header}>
              <div className={classes.tourIndex}>{`Tour #${index + 1}: `}</div>
              {plan?.tours?.[index] && (
                <React.Fragment>
                  <div className={classes.headerInfo}>
                    <div className={classes.label}>Batch Plan No.:</div>
                    <div className={classes.info}>{tour.batchPlanNumber}</div>
                  </div>
                  <div className={classes.headerInfo}>
                    <div className={classes.label}>Type:</div>
                    <div className={classes.info}>
                      {tour.type
                        ? translations[getI18n().language].tourType[tour.type]
                        : "N/A"}
                    </div>
                  </div>
                  <div className={classes.headerInfo}>
                    <div className={classes.label}>No. of Tasks:</div>
                    <div className={classes.info}>
                      {tour.tasks?.length || 0}
                    </div>
                  </div>
                  <div className={classes.headerInfo}>
                    <div className={classes.label}>Created By:</div>
                    <div className={classes.info}>
                      {`${
                        tour.order?.createdBy?.name || "N/A"
                      } at ${getDateString(
                        tour.order?.createdAt,
                        "en-US",
                        true
                      )}`}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          }
          classes={{ header: classes.headerRoot }}
          startCollapsed={collapseAll}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <FormField
                type="select"
                label="Tour Type"
                name={`tours.${index}.type`}
                form={form}
                onChange={handleChange("type")}
                options={{
                  data: tourTypes,
                  disabled: !isNewOrder || isTourTypeDisabled || disabled,
                  ...(isNewOrder && {
                    disabledTooltipText: "Please select a deal first",
                  }),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FormField
                type="autocomplete"
                label="Truck Type"
                name={`tours.${index}.truckType`}
                form={form}
                onChange={handleChange("truckType")}
                options={{
                  data: truckTypes,
                  getOptionLabel: (option) => option.name,
                  disabled: !isNewOrder || isTruckTypeDisabled || disabled,
                  ...(isNewOrder && {
                    disabledTooltipText: "Please select a tour type first",
                  }),
                }}
              />
            </GridItem>
            {tourType !== enums.TourType.PER_TRIP && (
              <React.Fragment>
                <GridItem xs={12} sm={12} md={3}>
                  <FormField
                    type="select"
                    label="Distance Limit (km)"
                    name={`tours.${index}.distanceLimit`}
                    form={form}
                    onChange={handleChange("distanceLimit")}
                    options={{
                      data: distanceLimitOptions,
                      disabled:
                        !isNewOrder || isDistanceLimitDisabled || disabled,
                      ...(isNewOrder && {
                        disabledTooltipText: "Please select a truck type first",
                      }),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <FormField
                    type="select"
                    label="Duration Limit (hrs)"
                    name={`tours.${index}.durationLimit`}
                    form={form}
                    onChange={handleChange("durationLimit")}
                    options={{
                      data: durationLimitOptions,
                      disabled:
                        !isNewOrder || isDurationLimitDisabled || disabled,
                      ...(isNewOrder && {
                        disabledTooltipText:
                          "Please select a distance limit first",
                      }),
                    }}
                  />
                </GridItem>
              </React.Fragment>
            )}
            <GridItem xs={12} sm={12} md={3}>
              <FormField
                type="time"
                label="Pickup Time"
                name={`tours.${index}.pickupTime`}
                form={form}
                options={{ disabled }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <FormField
                type="text"
                label="Notes"
                name={`tours.${index}.notes`}
                form={form}
                options={{ disabled }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TasksList tourIndex={index} disabled={disabled} />
            </GridItem>
          </GridContainer>
        </CollapseContainer>
      </div>
    </TourContext.Provider>
  );
}

Tour.propTypes = {
  onRemove: PropTypes.func,
  index: PropTypes.number,
  collapseAll: PropTypes.bool,
  data: PropTypes.object,
};

export default Tour;
