import { useEffect, useState } from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { toast } from "react-toastify";

export default function useHistoryButton({ pathName, id }) {
  const [sendRequest, isLoading] = useHttpClient(true);
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModalOpen = () => {
    setModalOpen(!modalOpen);
  };
  useEffect(() => {
    (async () => {
      try {
        if (modalOpen && id != null) {
          const response = await sendRequest(api[pathName].getRevisions(id));
          setData(response || []);
        }
      } catch (error) {
        console.log(error);
        toast.error("An error has occurred");
      }
    })();
  }, [modalOpen, id]);

  return { toggleModalOpen, modalOpen, data, isLoading };
}
