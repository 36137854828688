const styles = (theme) => ({
  assignButton: {
    padding: "5px 15px",
    marginTop: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
});

export default styles;
