import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DoneIcon from "@material-ui/icons/Done";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import FormField from "components/Forms/FormField/FormField";
import enums from "enums/index";
import InvoiceModal from "views/TourOperations/Components/FinOps/Components/InvoiceModal/InvoiceModal";
import styles from "./ServiceRow.styles";
import useServiceRow from "./ServiceRow.hook";
import React from "react";
import BillModal from "views/TourOperations/Components/FinOps/Components/BillModal/BillModal";
import { formatEnums } from "utils/index";
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function ServiceRow({ index, data, onRemove }) {
  const {
    invoiceTypeTranslation,
    serviceName,
    addedBy,
    tourDisabled,
    serviceBilled,
    finOpsOnly,
    isServiceReviewed,
    isInvoiceAttached,
    canAttachInvoice,
    cancelledDispatchingTour,
    rowDisabled,
    resource,
    invoices,
    bills,
    billDisabled,
    quantityDisabled,
    handleAddNewInvoice,
    handleAddNewBill,
    form,
    serviceProviders,
    costDisabled,
    priceDisabled,
    reviewService,
    revertReviewService,
    serviceProviderId,
  } = useServiceRow(index, data);

  const classes = useStyles();

  const firstColClasses = classNames({
    [classes.rowCell]: true,
    [classes.stickyCol]: true,
    [classes.firstCol]: true,
  });
  const lastColClasses = classNames({
    [classes.rowCell]: true,
    [classes.stickyCol]: true,
    [classes.lastCol]: true,
  });

  return (
    <tr>
      <td className={firstColClasses}>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {serviceName}
        </span>
      </td>
      <td className={classes.bigRowCell}>
        <FormField
          name={`services.${index}.serviceProvider`}
          key={data.id}
          form={form}
          type="autocomplete"
          options={{
            data: serviceProviders,
            getOptionLabel: (option) => option.name,
            disabled: rowDisabled || cancelledDispatchingTour,
            isTableInput: true,
          }}
        />
      </td>
      {resource === enums.Resource.FIN_OPS && (
        <td className={classes.bigRowCell}>
          <FormField
            name={`services.${index}.invoice`}
            form={form}
            type="autocomplete"
            options={{
              disabled:
                rowDisabled || cancelledDispatchingTour || !canAttachInvoice,
              data: invoices,
              getOptionLabel: (option) =>
                option?.name ||
                [
                  option?.name,
                  option?.referenceNumber,
                  invoiceTypeTranslation[option.type],
                  option?.status,
                  option?.expectedBillDate?.split("T")[0],
                ]
                  .filter((item) => !!item)
                  .join(" - "),
              isTableInput: true,
              addNew: true,
              formComponent: (
                <InvoiceModal handleAddNew={handleAddNewInvoice} />
              ),
            }}
          />
        </td>
      )}
      {resource === enums.Resource.FIN_OPS && (
        <td className={classes.bigRowCell}>
          <FormField
            name={`services.${index}.bill`}
            form={form}
            type="autocomplete"
            options={{
              disabled:
                rowDisabled ||
                cancelledDispatchingTour ||
                serviceBilled ||
                billDisabled,
              data: bills,
              getOptionLabel: (option) =>
                option?.name ||
                [
                  option?.referenceNumber,
                  formatEnums(option?.status),
                  option?.expectedBillDate?.split("T")[0],
                ]
                  .filter((item) => !!item)
                  .join(" - "),
              isTableInput: true,
              addNew: true,
              formComponent: (
                <BillModal
                  serviceProviderId={serviceProviderId}
                  handleAddNew={handleAddNewBill}
                />
              ),
            }}
          />
        </td>
      )}
      <td className={classes.rowCell}>
        <FormField
          name={`services.${index}.quantity`}
          form={form}
          type="number"
          options={{
            disabled:
              quantityDisabled || rowDisabled || cancelledDispatchingTour,
            isTableInput: true,
          }}
        />
      </td>
      {resource === enums.Resource.FIN_OPS && (
        <td className={classes.rowCell}>
          <FormField
            name={`services.${index}.targetPrice`}
            form={form}
            type="number"
            options={{
              disabled: true,
              isTableInput: true,
            }}
          />
        </td>
      )}

      {resource === enums.Resource.FIN_OPS && (
        <td className={classes.rowCell}>
          <FormField
            name={`services.${index}.price`}
            form={form}
            type="number"
            options={{
              disabled: priceDisabled,
              isTableInput: true,
            }}
          />
        </td>
      )}
      {resource === enums.Resource.FIN_OPS && (
        <td className={classes.rowCell}>
          <FormField
            name={`services.${index}.totalPrice`}
            form={form}
            type="number"
            options={{
              disabled: true,
              isTableInput: true,
            }}
          />
        </td>
      )}
      <td className={classes.rowCell}>
        <FormField
          name={`services.${index}.targetCost`}
          form={form}
          type="number"
          options={{
            disabled: true,
            isTableInput: true,
          }}
        />
      </td>
      <td className={classes.rowCell}>
        <FormField
          name={`services.${index}.cost`}
          form={form}
          type="number"
          options={{
            isTableInput: true,
            disabled: costDisabled,
          }}
        />
      </td>
      <td className={classes.rowCell}>
        <FormField
          name={`services.${index}.totalCost`}
          form={form}
          type="number"
          options={{
            disabled: true,
            isTableInput: true,
          }}
        />
      </td>
      <td className={classes.rowCell}>
        <FormField
          name={`services.${index}.notes`}
          form={form}
          type="text"
          options={{
            disabled: rowDisabled || cancelledDispatchingTour,
            blurOnSelect: false,
            isTableInput: true,
          }}
        />
      </td>

      <td className={classes.rowCell}>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {addedBy}
        </span>
      </td>

      <td
        className={lastColClasses}
        style={{
          borderWidth: `0 0px 1px 0`,
        }}
      >
        <span style={{ display: "flex", justifyContent: "center" }}>
          {!rowDisabled &&
            data?.type === enums.ServiceType.ADDITIONAL &&
            !tourDisabled && (
              <ConfirmationButton
                onConfirm={() => onRemove(index)}
                confirmMessage={`Are you sure you want to remove service number ${
                  index + 1
                }`}
                successHeaderText="Service Removed"
                successMessage="Service removed successfully"
                headerMessage="Remove Service"
                confirmButtonText="Confirm"
                confirmButtonColor="success"
                buttonProps={{
                  textColor: "textColor",
                  className: classes.button,
                }}
              >
                <Tooltip
                  title={"Remove"}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                  arrow
                >
                  <DeleteForeverIcon style={{ cursor: "pointer" }} />
                </Tooltip>
              </ConfirmationButton>
            )}
        </span>

        {finOpsOnly &&
          (isServiceReviewed ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              Reviewed
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              Review Pending
            </div>
          ))}
      </td>
    </tr>
  );
}
