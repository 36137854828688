import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import styles from "./Buttons.styles";
import React from "react";

const useStyles = makeStyles(styles);

export default function StartButton({
  statusChangeDisabled,
  statusChangeMessage,
  onStart,
  errorMessage,
  index,
}) {
  const classes = useStyles();

  return (
    <Tooltip
      title={statusChangeMessage}
      placement="bottom"
      classes={{ tooltip: classes.tooltip }}
      arrow
    >
      <div style={{ width: "fit-content" }}>
        <ConfirmationButton
          onConfirm={onStart}
          confirmMessage={`Are you sure you want to start task number ${
            index + 1
          }`}
          successHeaderText="Task Started"
          successMessage="Task Started successfully"
          headerMessage="Start Task"
          confirmButtonText="Confirm"
          confirmButtonColor="success"
          errorMessage={errorMessage}
          buttonProps={{
            textColor: "textColor",
            className: classes.button,
            disabled: statusChangeDisabled,
          }}
        >
          <PlayCircleOutlineIcon style={{ cursor: "pointer" }} />
        </ConfirmationButton>
      </div>
    </Tooltip>
  );
}
