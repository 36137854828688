import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    nameEn: yup.string().required("Required"),
    nameAr: yup.string().required("Required"),
    address: yup.string().required("Required"),
    latLng: yup
      .string()
      .test(
        "is-valid-latitude",
        "Latitude must be a number between -90 and 90",
        (value) => {
          if (!value) return false;
          const number = parseFloat(
            value.replace("(", "").replace(")", "").split(",")[0].trim()
          );
          return !isNaN(number) && Math.abs(number) <= 90;
        }
      )
      .test(
        "is-valid-longitude",
        "Longitude must be a number between -180 and 180",
        (value) => {
          if (!value) return false;
          const number = parseFloat(
            value.replace("(", "").replace(")", "").split(",")[1]?.trim()
          );
          return !isNaN(number) && Math.abs(number) <= 180;
        }
      )
      .test(
        "isValidCoordinates",
        "Invalid coordinates format it should be in lat,lng format",
        (value) => {
          if (value) {
            const coordinates = value
              .replace("(", "")
              .replace(")", "")
              .split(",");
            return (
              coordinates.length === 2 &&
              /^\d/.test(coordinates[0]) &&
              /\d$/.test(coordinates[1])
            );
          }
          return false;
        }
      )
      .required("Required"),
    locationType: yup.mixed().required("Required"),
    district: yup.mixed().required("Required"),
    isPrivate: yup.string().required("Required"),
  });
};
