import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    truckType: yup.mixed().required("Required"),
    perTrip: yup.object().shape({
      pickupZone: yup.mixed().required("Required"),
      dropOffZone: yup.mixed().required("Required"),
    }),
  });
};
