import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import enums from "enums/index";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { getDateString } from "utils";
import FileCard from "./FileCard/FileCard";
import styles from "./infoWithLabelStyle";

const useStyles = makeStyles(styles);

export default function InfoWithLabel(props) {
  const { label, info, type, path, formField, classes: classesProp } = props;

  const classes = useStyles();

  let value =
    info !== null && info !== undefined
      ? type === enums.InfoType.DATE
        ? getDateString(info)
        : info
      : "-";

  if (type === enums.InfoType.BOOLEAN) {
    value = info === true ? "Yes" : info === false ? "No" : "-";
  }

  const rootClasses = classNames({
    [classes.root]: true,
    [classes.formField]: formField,
    [classesProp.root]: classesProp?.root,
  });

  const labelClasses = classNames({
    [classes.label]: true,
    [classesProp.label]: classesProp?.label,
  });

  const valueClasses = classNames({
    [classes.value]: true,
    [classes.successColor]: value === "Yes",
    [classes.dangerColor]: value === "No",
  });

  return (
    <div className={rootClasses}>
      <div className={labelClasses}>{label}:</div>
      <div className={valueClasses}>
        {type === enums.InfoType.LIST ? (
          info?.length > 0 ? (
            <ul>
              {info?.map((el, index) => (
                <li key={index}>{el}</li>
              ))}
            </ul>
          ) : (
            "-"
          )
        ) : type === enums.InfoType.TAGS ? (
          info?.length > 0 ? (
            <div className={classes.tagsContainer}>
              {info?.map((el, index) => (
                <div key={index} className={classes.tag}>
                  {el}
                </div>
              ))}
            </div>
          ) : (
            "-"
          )
        ) : type === enums.InfoType.LINK && info ? (
          <Link className={classes.link} to={path}>
            {info}
            <i className={"fas fa-link " + classes.linkIcon} />
          </Link>
        ) : type === enums.InfoType.FILE && info ? (
          <FileCard
            rootStyle={{
              marginTop: 0,
              boxShadow: "none",
              border: "1px solid #dedede",
              borderRadius: 3,
              height: 52,
            }}
            url={info}
          />
        ) : (
          value
        )}
      </div>
    </div>
  );
}

InfoWithLabel.defaultProps = {
  type: enums.InfoType.TEXT,
  classes: {
    root: "",
    label: "",
  },
};

InfoWithLabel.propTypes = {
  type: PropTypes.string,
  info: PropTypes.any,
  label: PropTypes.string,
  path: PropTypes.string,
  formField: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
  }),
};
