import enums from "enums/index";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import {
  attachTimeZoneToDate,
  convertTimeToISO,
  extractTimeFromISO,
  getFutureDate,
  replaceUrlSearchParam,
} from "utils/index";
import { PlanDetailsContext } from "../../PlanDetails.context";
import PlansFormValidation from "./PlanForm.validation";

//-------------------------------------------------------------------------------------

export const usePlanForm = ({ isNewOrder }) => {
  const [plan, setPlan] = React.useState(null);
  const [quotationGroups, setQuotationGroups] = React.useState([]);
  const [allCities, setAllCities] = React.useState([]);
  const [allDistricts, setAllDistricts] = React.useState([]);
  const [selectedPlanDetails, setSelectedPlanDetails] = React.useState({
    deal: null,
    date: getFutureDate(1),
  });
  const history = useHistory();

  const { organization, currentTab, setCurrentTab } = React.useContext(
    PlanDetailsContext
  );

  const [sendSubmitRequest, isLoading] = useHttpClient(true);
  const [sendGetRequest, isGetLoading] = useHttpClient(true);
  const [sendGetQuotationsRequest] = useHttpClient(true);
  const [sendGetCitiesRequest, isGetCitiesLoading] = useHttpClient(true);
  const [sendGetDistrictsRequest, isGetDistrictsLoading] = useHttpClient(true);

  const resolver = useYupValidationResolver(PlansFormValidation(isNewOrder));

  const initialTask = useMemo(
    () => ({
      type: enums.TaskType.PICKUP,
      city: null,
      district: null,
      location: null,
    }),
    []
  );

  const defaultTask = useMemo(
    () => ({
      type: enums.TaskType.DROP_OFF,
      city: null,
      district: null,
      location: null,
    }),
    []
  );

  const defaultTour = useMemo(
    () => ({
      durationLimit: null,
      distanceLimit: null,
      tasks: [{ ...initialTask }],
    }),
    [initialTask]
  );

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      date: getFutureDate(1),
      tours: [{ ...defaultTour }],
    },
  });

  const { handleSubmit, setValue, getValues, reset } = form;

  React.useEffect(() => {
    (async () => {
      try {
        const citiesPromise = sendGetCitiesRequest(api.cities.getAll());
        const districtsPromise = sendGetCitiesRequest(api.districts.getAll());
        const [citiesResponse, districtResponse] = await Promise.all([
          citiesPromise,
          districtsPromise,
        ]);
        setAllCities(citiesResponse.data);
        setAllDistricts(districtResponse.data);
      } catch (err) {}
    })();
  }, []);

  React.useEffect(() => {
    if (
      currentTab == enums.PlanDetailsTabs.NEW_ORDER &&
      selectedPlanDetails.deal
    ) {
      (async () => {
        try {
          const response = await sendGetQuotationsRequest(
            api.deals.getQuotationTypes(selectedPlanDetails.deal?.id)
          );
          setQuotationGroups(response);
        } catch (err) {
          // toast.error("An error has occurred");
        }
      })();
    } else if (
      currentTab == enums.PlanDetailsTabs.DAILY_PLANS &&
      organization
    ) {
      (async () => {
        try {
          const response = await sendGetQuotationsRequest(
            api.organizations.getQuotationTypes(organization?.id)
          );
          setQuotationGroups(response);
        } catch (err) {
          // toast.error("An error has occurred");
        }
      })();
    }
  }, [currentTab, organization, selectedPlanDetails.deal, setQuotationGroups]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const date = urlParams.get("date");
    if (currentTab == enums.PlanDetailsTabs.DAILY_PLANS) {
      handleViewPlan(date);
    }
  }, [currentTab]);

  React.useEffect(() => {
    if (plan) {
      let planValues = { ...plan };
      delete planValues.organization;
      Object.keys(planValues).forEach((key) => {
        if (key === "tours") {
          planValues[key].forEach((tour) => {
            Object.keys(tour).forEach((tourKey) => {
              if (tourKey === "pickupTime") {
                tour.pickupTime = extractTimeFromISO(tour.pickupTime);
              } else if (tourKey === "truckType") {
                tour.truckType = {
                  id: tour.truckType?.id,
                  name: tour.truckType?.details?.name,
                };
              }
            });
          });
        }
      });
      reset({
        ...planValues,
      });
    }
  }, [plan, setValue, reset]);

  const submitHandler = (requestData) => async () => {
    const orderApi = isNewOrder ? api.orders.create : api.orders.assignPlan;
    try {
      await sendSubmitRequest(
        orderApi({
          ...requestData,
        })
      );
      toast.success("Plan submitted successfully");
      if (isNewOrder) {
        history.push({
          pathname: window.location.pathname,
          search: `?tab=${enums.PlanDetailsTabs.DAILY_PLANS}&date=${selectedPlanDetails.date}`,
        });
        setCurrentTab(enums.PlanDetailsTabs.DAILY_PLANS);
      }
    } catch (err) {
      // toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors above and submit again.");
  };

  const handlePlanDetailsChange = (prop, value) => {
    setSelectedPlanDetails({
      ...selectedPlanDetails,
      [prop]: value,
    });
    setValue(prop, value, { shouldDirty: true });
  };

  const handleViewPlan = (queryDate) => {
    const date = queryDate || selectedPlanDetails.date;
    if (!date) {
      toast.error("Please select the date");
      return;
    }
    setSelectedPlanDetails({
      ...selectedPlanDetails,
      date,
    });
    (async () => {
      try {
        const response = await sendGetRequest(
          api.orders.getPlan(organization?.id, attachTimeZoneToDate(date))
        );
        setPlan(response);
        history.push({
          pathname: window.location.pathname,
          search: `?${replaceUrlSearchParam(
            window.location.search,
            "date",
            date
          )}`,
        });
      } catch (err) {
        // toast.error("An error has occurred");
      }
    })();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let requestData = { ...getValues() };

    requestData = {
      ...getValues(),
      startDate: selectedPlanDetails.date,
      dealId: selectedPlanDetails.deal?.id,
      tours: requestData.tours.map((tour) => {
        const truckTypeId = tour.truckType?.id;
        delete tour.truckType;
        return {
          ...tour,
          truckTypeId,
          pickupTime: tour.pickupTime
            ? convertTimeToISO(selectedPlanDetails.date, tour.pickupTime)
            : null,
          tasks: tour.tasks.map((task, taskIndex) => {
            const locationId = task.location?.id;
            delete task.city;
            delete task.district;
            delete task.location;
            return {
              ...task,
              status: enums.TaskStatus.UPCOMING,
              taskIndex,
              locationId,
            };
          }),
        };
      }),
    };

    delete requestData.deal;

    handleSubmit(submitHandler(requestData), onError)();
  };

  return {
    isLoading,
    selectedPlanDetails,
    form,
    defaultTour,
    defaultTask,
    initialTask,
    plan,
    organization,
    isGetLoading,
    quotationGroups,
    allCities,
    allDistricts,
    onSubmit,
    handleViewPlan,
    handlePlanDetailsChange,
  };
};
