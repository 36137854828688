import React from "react";
import useServicesTable from "./ServiceTable.hook";
import { ServicesTableContext } from "./ServiceTable.context";
import Button from "components/CustomButtons/Button.js";
import ServiceRow from "./Components/ServiceRow/ServiceRow";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./ServiceTable.styles";
import AddServiceModal from "./AddServiceModal/AddServiceModal";
import enums from "enums/index";
import classNames from "classnames";
import Loading from "components/Loading/Loading";

const useStyles = makeStyles(styles);

export default function ServicesTable({ tourReviewed, tourReviewable }) {
  const servicesElements = [];
  const classes = useStyles();

  const {
    currentTour,
    resource,
    onRemove,
    form,
    onSubmit,
    services,
    isLoading,
    serviceProviders,
    isModalOpen,
    onModalClose,
    onModalOpen,
    tourDisabled,
    serviceLoading,
    getServices,
  } = useServicesTable();

  if (services?.length)
    services.forEach((service, index) => {
      servicesElements.push(
        <ServiceRow
          tourReviewed={tourReviewed}
          tourReviewable={tourReviewable}
          onRemove={onRemove}
          key={service.id}
          index={index}
          data={service}
        />
      );
    });

  const firstColClasses = classNames({
    [classes.rowHead]: true,
    [classes.stickyCol]: true,
    [classes.firstCol]: true,
  });
  const lastColClasses = classNames({
    [classes.rowHead]: true,
    [classes.stickyCol]: true,
    [classes.lastCol]: true,
  });

  return (
    <ServicesTableContext.Provider
      value={{
        services,
        form,
        getServices,
        onSubmit,
      }}
    >
      <Loading loading={serviceLoading}>
        <form onSubmit={onSubmit}>
          {(!tourDisabled || resource === enums.Resource.FIN_OPS) &&
            !tourReviewed && (
              <div className={classes.buttonGroup}>
                <Button
                  onClick={() => {
                    onModalOpen();
                  }}
                  className={classes.addContactButton}
                  color="primary"
                >
                  Add Additional Service
                </Button>
              </div>
            )}
          <Card raised style={{ margin: 0 }}>
            <CardBody noPadding>
              {servicesElements.length > 0 && (
                <table className={classes.table}>
                  <tr>
                    <th
                      style={{ borderTopLeftRadius: "10px" }}
                      className={firstColClasses}
                    >
                      Service Type
                    </th>
                    <th className={classes.rowHead}>Service Provider</th>
                    {resource === enums.Resource.FIN_OPS && (
                      <th className={classes.rowHead}>Invoice</th>
                    )}
                    {resource === enums.Resource.FIN_OPS && (
                      <th className={classes.rowHead}>Bill</th>
                    )}
                    <th className={classes.rowHead}>Quantity</th>
                    {resource === enums.Resource.FIN_OPS && (
                      <th className={classes.rowHead}>Target Price</th>
                    )}

                    {resource === enums.Resource.FIN_OPS && (
                      <th className={classes.rowHead}>Price</th>
                    )}
                    {resource === enums.Resource.FIN_OPS && (
                      <th className={classes.rowHead}>Total Price</th>
                    )}
                    <th className={classes.rowHead}>Target Cost</th>

                    <th className={classes.rowHead}>Cost</th>
                    <th className={classes.rowHead}>Total Cost</th>
                    <th className={classes.rowHead}>Notes</th>
                    <th className={classes.rowHead}>Added By</th>

                    <th
                      className={lastColClasses}
                      style={{
                        borderWidth: `0 0 1px 0`,
                        borderTopRightRadius: "10px",
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                  {servicesElements}
                </table>
              )}
              {servicesElements.length === 0 && (
                <h3 className={classes.headerContainer}>No Services To Show</h3>
              )}
            </CardBody>
          </Card>
          {(!tourDisabled || resource === enums.Resource.FIN_OPS) &&
            !tourReviewed && (
              <div className={classes.buttonsContainer}>
                <Button
                  color="primary"
                  type="submit"
                  loading={isLoading}
                  style={{ marginTop: 20 }}
                  edit
                  loadFromRight
                >
                  Submit
                </Button>
              </div>
            )}
          <AddServiceModal
            currentTour={currentTour}
            isOpen={isModalOpen}
            onClose={onModalClose}
          />
        </form>
      </Loading>
    </ServicesTableContext.Provider>
  );
}
