export function parseUseFieldsArrayString(obj) {
  return Object.keys(obj)
    .sort((a, b) => a - b) // sort keys in numerical order
    .map((key) => {
      if (key !== "id") {
        return obj[key];
      }
    })
    .join("");
}
