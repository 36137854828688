import enums from "enums/index";
import api from "services/api";
import { formatEnums } from "utils";
import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "nameEn",
        type: "text",
        label: "Name (en)",
        validation: yup.string().required("Required"),
      },
      {
        name: "nameAr",
        type: "text",
        label: "Name (ar)",
        validation: yup.string().required("Required"),
      },
      {
        name: "phonePrimary",
        type: "text",
        label: "Phone (primary)",
        validation: yup
          .string()
          .matches(/^((\+2|002)?01\d{9})$/, "Invalid primary phone number")
          .required("Required"),
      },
      {
        name: "phoneSecondary",
        type: "text",
        label: "Phone (secondary)",
        validation: yup
          .string()
          .transform((value, originalValue) =>
            originalValue === "" ? null : value
          )
          .matches(/^((\+2|002)?01\d{9})$/, "Invalid secondary phone number")
          .nullable(),
      },
      {
        name: "nationalId",
        type: "text",
        label: "National ID",
        validation: yup
          .string()
          .required("Required")
          .length(14, "National ID must be 14 characters"),
      },
      {
        name: "email",
        type: "text",
        label: "Email",
        validation: yup.string().email().nullable(),
      },
      {
        name: "licenseId",
        type: "text",
        label: "License ID",
        validation: yup.string().required("Required"),
      },
      {
        name: "licenseExpirationDate",
        type: "date",
        label: "License Exp. Date",
        validation: yup.string().required("Required"),
      },
      {
        name: "licenseAttachment",
        type: "dropzone",
        label: "License Attachment",
        options: { maxFiles: 1 },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "status",
        type: "select",
        label: "Status",
        options: {
          data: Object.keys(enums.DriverStatus).map((key) => ({
            value: enums.DriverStatus[key],
            name: formatEnums(key),
          })),
        },
        validation: yup.string(),
      },
      {
        name: "serviceProviderIds",
        fetchedName: "serviceProviders",
        type: "autocomplete",
        label: "Service Providers",
        options: {
          getData: api.serviceProviders.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (options) => options?.map((option) => option?.id),
          multiple: true,
          blurOnSelect: false,
        },
        validation: yup.mixed(),
      },
    ],
  },
];
