import enums from "enums/index";
import React, { useCallback, useMemo } from "react";
import { PlanFormContext } from "../../../PlanForm.context";
import { TourContext } from "../../ToursList/Tour/Tour.context";
import { concatListsByAttribute } from "utils";
import translations from "constants/translations";
import { useTranslation } from "react-i18next";

// ------------------------------------------------------------------------------------

export const useTask = ({ index, tourIndex, data }) => {
  const [cities, setCities] = React.useState(null);
  const [districts, setDistricts] = React.useState(null);
  const [locations, setLocations] = React.useState(null);
  const { i18n } = useTranslation();

  const { plan, form, allCities, allDistricts } = React.useContext(
    PlanFormContext
  );
  const {
    cities: quotationCities,
    districts: quotationDistricts,
    locations: allLocations,
    tourPickupLocation,
    setLocations: setAllLocations,
    setTourPickupLocation,
  } = React.useContext(TourContext);

  const { setValue, getValues, watch } = form;

  const dealId = plan?.tours?.[tourIndex]?.order?.deal?.id || watch("deal")?.id;
  const tourId = plan?.tours?.[tourIndex]?.id || watch(`tours.${tourIndex}.id`);
  const cityId = watch(`tours.${tourIndex}.tasks.${index}.city`)?.id;
  const districtId = watch(`tours.${tourIndex}.tasks.${index}.district`)?.id;
  const currLocation = watch(`tours.${tourIndex}.tasks.${index}.location`);
  const locationId = currLocation?.id;
  const taskType = watch(`tours.${tourIndex}.tasks.${index}.type`);
  const truckTypeId = watch(`tours.${tourIndex}.truckType.id`);
  const tourType = watch(`tours.${tourIndex}.type`);
  const distanceLimit = watch(`tours.${tourIndex}.distanceLimit`);
  const durationLimit = watch(`tours.${tourIndex}.durationLimit`);
  const tourPickupLocationId = watch(`tours.${tourIndex}.tasks.0.location`)?.id;
  const daysLimit = watch(`tours.${tourIndex}`)?.quotation?.rental?.details
    ?.daysLimit;

  const isLocationDisabled = React.useMemo(
    () =>
      tourType === enums.TourType.PER_TRIP
        ? taskType === enums.TaskType.DROP_OFF ||
          taskType === enums.TaskType.LAYOVER
          ? !tourPickupLocationId ||
            !tourType ||
            !truckTypeId ||
            !taskType ||
            !dealId
          : !tourType || !truckTypeId || !taskType || !dealId
        : !tourType ||
          !truckTypeId ||
          distanceLimit == null ||
          !durationLimit ||
          !taskType ||
          !dealId,
    [
      tourType,
      truckTypeId,
      taskType,
      dealId,
      distanceLimit,
      durationLimit,
      tourPickupLocationId,
    ]
  );

  const getLocationTaskTypes = useCallback(
    (cityId, districtId) => {
      const translationObj = translations[i18n.language]["locationTaskType"];
      const locationTaskTypeMap = Object.keys(translationObj).reduce(
        (pre, key) => {
          pre[key] = {
            value: key,
            name: translationObj[key],
          };
          return pre;
        },
        {}
      );

      let tasks = [locationTaskTypeMap[enums.LocationTaskType.ALL]];

      if (
        taskType === enums.TaskType.PICKUP ||
        taskType === enums.TaskType.RETURN
      ) {
        tasks = [
          ...tasks,
          locationTaskTypeMap[enums.LocationTaskType.PICKUPS_AND_RETURNS_ONLY],
        ];
        return tasks;
      }

      if (
        taskType === enums.TaskType.DROP_OFF ||
        (rentalDropOffOrLayoverTask && taskType === enums.TaskType.LAYOVER)
      ) {
        tasks = [
          ...(!rentalDropOffOrLayoverTask ? tasks : []),
          locationTaskTypeMap[enums.LocationTaskType.DROPOFFS_ONLY],
        ];
        return tasks;
      }

      // in case of layover
      const inPickupCities = quotationCities?.find(
        (cityEl) => cityEl.id === cityId
      );
      const inPickupDistricts = quotationDistricts?.find(
        (districtEl) => districtEl.id === districtId
      );
      if (inPickupCities && inPickupDistricts)
        tasks = [
          ...tasks,
          locationTaskTypeMap[enums.LocationTaskType.PICKUPS_AND_RETURNS_ONLY],
        ];

      const inDropOffCities = tourPickupLocation?.dropOffCities?.find(
        (cityEl) => cityEl.id === cityId
      );
      const inDropOffDistricts = tourPickupLocation?.dropOffDistricts?.find(
        (districtEl) => districtEl.id === districtId
      );
      if (inDropOffCities && inDropOffDistricts)
        tasks = [
          ...tasks,
          locationTaskTypeMap[enums.LocationTaskType.DROPOFFS_ONLY],
        ];
      return tasks;
    },
    [taskType, quotationCities, quotationDistricts, tourPickupLocation]
  );

  const locationDisabledMessage = React.useMemo(
    () =>
      tourType === enums.TourType.PER_TRIP
        ? tourType && truckTypeId && taskType && dealId
          ? tourPickupLocationId
            ? ""
            : "Please select a location for the first task."
          : "Please select  deal, tour type, truck type and task type first."
        : tourType &&
          truckTypeId &&
          distanceLimit == null &&
          durationLimit &&
          taskType &&
          dealId
        ? ""
        : "Please select  deal, tour type, truck type, distance limit, duration limit and task type first.",

    [
      tourType,
      truckTypeId,
      taskType,
      dealId,
      distanceLimit,
      durationLimit,
      tourPickupLocationId,
    ]
  );

  const isTypeSelectDisabled = React.useMemo(() => index === 0, [index]);

  React.useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        if (key !== "id")
          setValue(`tours.${tourIndex}.tasks.${index}.${key}`, data[key]);
      });
    }
  }, []);

  React.useEffect(() => {
    if (!tourPickupLocationId) {
      setTourPickupLocation(undefined);
      return;
    }
    if ([enums.TaskType.DROP_OFF, enums.TaskType.LAYOVER].includes(taskType)) {
      const selectedPickupLocation = allLocations?.find(
        (location) => location?.id === tourPickupLocationId
      );
      setTourPickupLocation(selectedPickupLocation);
    }
  }, [taskType, tourPickupLocationId, allLocations]);

  React.useEffect(() => {
    let tempCities = quotationCities?.length ? [...quotationCities] : [];
    if (!tourPickupLocation || !(tourType === enums.TourType.PER_TRIP)) {
      // daily or monthly
      tempCities = rentalDropOffOrLayoverTask ? allCities : tempCities;
      setCities(tempCities);
      return;
    } else {
      if (taskType === enums.TaskType.DROP_OFF)
        tempCities = tourPickupLocation?.dropOffCities;
      if (taskType === enums.TaskType.LAYOVER)
        tempCities = concatListsByAttribute(
          tempCities,
          tourPickupLocation.dropOffCities
        );
      setCities(tempCities);
    }
  }, [
    quotationCities,
    allCities,
    rentalDropOffOrLayoverTask,
    taskType,
    tourType,
    tourPickupLocation,
  ]);

  React.useEffect(() => {
    const getDistricts = () => {
      let tempDistricts = quotationDistricts?.length
        ? [...quotationDistricts]
        : [];
      if (!tourPickupLocation || !(tourType === enums.TourType.PER_TRIP)) {
        return rentalDropOffOrLayoverTask ? allDistricts : tempDistricts;
      }
      if (taskType === enums.TaskType.DROP_OFF)
        tempDistricts = tourPickupLocation?.dropOffDistricts;
      if (taskType === enums.TaskType.LAYOVER)
        tempDistricts = concatListsByAttribute(
          tempDistricts,
          tourPickupLocation?.dropOffDistricts
        );
      return tempDistricts;
    };

    const tempDistricts = getDistricts()?.flatMap((district) => {
      if (cityId && cityId !== district?.cityId) return [];
      return [district];
    });
    setDistricts(tempDistricts);
  }, [
    cityId,
    quotationDistricts,
    allDistricts,
    rentalDropOffOrLayoverTask,
    taskType,
    tourType,
    tourPickupLocation,
  ]);

  React.useEffect(() => {
    const getLocations = () => {
      let tempLocations = allLocations?.length ? [...allLocations] : [];
      if (!(tourType === enums.TourType.PER_TRIP)) {
        return tempLocations.filter((loc) => {
          if (
            taskType === enums.TaskType.PICKUP ||
            taskType === enums.TaskType.RETURN
          )
            return [
              enums.LocationTaskType.ALL,
              enums.LocationTaskType.PICKUPS_AND_RETURNS_ONLY,
            ].includes(loc.taskType);
          else if (taskType === enums.TaskType.DROP_OFF)
            return [
              enums.LocationTaskType.ALL,
              enums.LocationTaskType.DROPOFFS_ONLY,
            ].includes(loc.taskType);
          else return true;
        });
      }
      if (!tourPickupLocation) {
        return tempLocations;
      }
      if (taskType === enums.TaskType.DROP_OFF) {
        tempLocations = tourPickupLocation?.dropOffLocations;
      }
      if (taskType === enums.TaskType.LAYOVER)
        tempLocations = concatListsByAttribute(
          tempLocations,
          tourPickupLocation?.dropOffLocations
        );
      return tempLocations;
    };
    const tempLocations = getLocations()?.flatMap((location) => {
      if (districtId && districtId !== location?.districtId) {
        return [];
      }
      if (cityId && cityId !== location?.cityId) {
        return [];
      }
      return [location];
    });

    setLocations(tempLocations);
  }, [
    cityId,
    districtId,
    taskType,
    tourType,
    allLocations,
    tourPickupLocation,
  ]);

  React.useEffect(() => {
    const district = districts?.find((district) => district?.id === districtId);
    const city = cities?.find((city) => city?.id === district?.cityId);
    if (city) form.setValue(`tours.${tourIndex}.tasks.${index}.city`, city);
  }, [districtId, cities]);

  React.useEffect(() => {
    const district = districts?.find(
      (district) => district?.id === currLocation?.districtId
    );
    if (district)
      form.setValue(`tours.${tourIndex}.tasks.${index}.district`, district);
    const city = cities?.find((city) => city?.id === district?.cityId);
    if (city) form.setValue(`tours.${tourIndex}.tasks.${index}.city`, city);
  }, [locationId, locations, districts]);

  const handleCityChange = () => {
    form.setValue(`tours.${tourIndex}.tasks.${index}.district`, null);
    form.setValue(`tours.${tourIndex}.tasks.${index}.location`, null);
  };

  const handleDistrictChange = () => {
    form.setValue(`tours.${tourIndex}.tasks.${index}.location`, null);
  };

  const handleMonthlyAddNew = (location) => {
    location.cityId = location.district?.cityId;
    setAllLocations((prevLocations) => [...prevLocations, location]);
    form.setValue(`tours.${tourIndex}.tasks.${index}.location`, location);
  };

  const handlePerTripAddNew = (location) => {
    const district = quotationDistricts.find(
      (district) => district.id === location.districtId
    );
    location.cityId = location.district?.cityId;
    const locationWithoutDropOff = { ...location };
    location.dropOffCities = district?.dropOffCities;
    location.dropOffDistricts = district?.dropOffDistricts;
    location.dropOffLocations = district?.dropOffLocations;

    // check if new location is in the same city list provided  by quotation groups
    const isInCities = quotationCities.find(
      (city) => city.id === location.cityId
    );

    // if it's not in the cities provided by quotation group don't append to allLocations
    // all locations  only contains locations with task  Pickup and All
    let newAllLocations = [
      ...(Array.isArray(allLocations) ? allLocations : []),
      ...(isInCities &&
      location.taskType !== enums.LocationTaskType.DROPOFFS_ONLY
        ? [location]
        : []),
    ];

    // if location taskType not dropOff or all set allLocations and return
    if (location.taskType === enums.LocationTaskType.PICKUPS_AND_RETURNS_ONLY) {
      setAllLocations(newAllLocations);
      form.setValue(`tours.${tourIndex}.tasks.${index}.location`, location);
      return;
    }
    // Adding to dropOff Locations
    // check if new location is a valid dropOff location for any of the allLocations
    newAllLocations = newAllLocations.map((locationElement) => {
      if (locationElement.id === location.id) return locationElement;

      const isInDropOffDistricts = !!locationElement.dropOffDistricts.find(
        (districtEl) => districtEl.id === location.districtId
      );
      if (isInDropOffDistricts) {
        // add location to the dropOff locations of the current element
        // note: adding location without the dropOff locations,districts, cities to save stack memory
        locationElement.dropOffLocations = [
          ...(Array.isArray(locationElement.dropOffLocations)
            ? locationElement.dropOffLocations
            : []),
          locationWithoutDropOff,
        ];
      }
      return locationElement;
    });
    setAllLocations(newAllLocations);
    if (
      taskType === enums.TaskType.LAYOVER &&
      location.taskType === enums.LocationTaskType.DROPOFFS_ONLY &&
      !tourPickupLocation?.dropOffLocations?.find(
        (locationEl) => location.id === locationEl.id
      )
    )
      return;
    form.setValue(`tours.${tourIndex}.tasks.${index}.location`, location);
  };

  const handleAddNew = (location) => {
    const realTourType = tourType || plan?.tours?.[tourIndex]?.type;
    if (
      realTourType === enums.TourType.MONTHLY ||
      realTourType === enums.TourType.DAILY
    )
      handleMonthlyAddNew(location);
    else handlePerTripAddNew(location);
  };

  const rentalDropOffOrLayoverTask = useMemo(() => {
    const realTourType = plan?.tours?.[tourIndex]?.type || tourType;
    return (
      (taskType === enums.TaskType.DROP_OFF ||
        taskType === enums.TaskType.LAYOVER) &&
      (realTourType === enums.TourType.MONTHLY ||
        realTourType === enums.TourType.DAILY)
    );
  }, [taskType, tourType, plan]);

  const currCity = watch(`tours.${tourIndex}.tasks.${index}.city`);

  return {
    cities: cities,
    formCities: currCity ? [currCity] : cities,
    districts,
    locations,
    form,
    taskType,
    tourId,
    isTypeSelectDisabled,
    isLocationDisabled,
    locationDisabledMessage,
    deal: watch("deal") || plan?.tours?.[tourIndex]?.order?.deal,
    tourType,
    truckTypeId,
    kmLimit: distanceLimit,
    daysHoursLimit: durationLimit,
    daysLimit: tourType === enums.TourType.DAILY ? 1 : daysLimit,
    tourPickupLocationId,
    getLocationTaskTypes,
    handleCityChange,
    handleDistrictChange,
    handleAddNew,
  };
};
