import enums from "enums/index";
import qs from "qs";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import api from "services/api";
import { flattenObject, setPageSettingsProp } from "utils";

//------------------------------------------------------------------------------

export default function useFinOpsEdit(props) {
  const { resource, request, statsRequest, getFilterInputs } = props;

  const history = useHistory();
  const match = useRouteMatch();
  const [id, setId] = useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [listData, setListData] = React.useState({ count: 0, rows: [] });
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filtersState, setFiltersState] = React.useState({});
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [highlightedText, setHighlightedText] = React.useState("");

  const [total, setTotal] = useState();
  const [deductions, setDeductions] = useState();
  const [info, setInfo] = useState();
  const [infoLoading, setInfoLoading] = useState(true);

  const [sendGetRequest, isGetLoading] = useHttpClient();
  const [sendInfoRequest] = useHttpClient();
  const [sendUpdateInfoRequest] = useHttpClient();

  const filterInputs = useMemo(
    () =>
      info?.serviceProviderId
        ? getFilterInputs(info.serviceProviderId, id)
        : undefined,
    [info, id, getFilterInputs]
  );

  const getData = React.useCallback(
    async (
      page = currentPage,
      rowsPerPage = rowsPerPage,
      search = searchQuery,
      filters = filtersState
    ) => {
      if (!id) return;
      page = Math.max(page, 1);
      try {
        const { data: response, count } = await sendGetRequest(
          request(id, {
            page,
            limit: rowsPerPage,
            q: search,
            ...(resource === enums.Resource.BILLS && {
              filters: {
                orderId: filters.order?.id,
                serviceProviderRentalId: filters.serviceProviderRental?.id,
              },
            }),
          })
        );
        setListData({
          count: count,
          rows: response,
        });

        const total = response.reduce(
          (total, tour) => total + tour.servicesPrice,
          0
        );
        const deductions = response.reduce(
          (total, tour) =>
            total +
            (resource === enums.Resource.INVOICES
              ? tour.discountsPrice
              : tour.penaltiesPrice),
          0
        );
        if (statsRequest) {
          const stats = await sendGetRequest(statsRequest(id));
          setInfo((prevInf) => ({ ...prevInf, ...stats }));
        }

        setTotal(total);
        setDeductions(deductions);
        setInfo((prevInfo) => ({
          ...prevInfo,
          total,
          deductions,
          numberOfTours: response.length,
        }));
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [id, filterInputs, sendGetRequest, setListData]
  );

  React.useEffect(() => {
    (async () => {
      try {
        setId(parseInt(window.location.pathname.split("/").reverse()[1]));
        const rowsPerPage = 10;

        setItemsPerPage(rowsPerPage);

        let { page, search, filters } = qs.parse(
          window.location.search?.split("?")[1]
        );

        setSearchQuery(search ? search : "");
        const tempFilters = {};
        if (filterInputs) {
          filters = filters ? flattenObject(filters) : {};
          filterInputs.forEach(
            (input, index) =>
              (tempFilters[input.name] = filters[input.name]
                ? filters[input.name]
                : filterInputs[index].defaultValue !== undefined
                ? filterInputs[index].defaultValue
                : null)
          );
          setFiltersState(tempFilters);
        }

        setHighlightedText(search ? search : "");

        if (page) {
          setCurrentPage(parseInt(page));
          await getData(page, rowsPerPage, search, tempFilters);
        } else {
          page = 1;
          history.push({
            pathname: window.location.pathname,
            search: `?page=${page}`,
          });
          await getData(page, rowsPerPage, search, tempFilters);
        }
      } catch (err) {
        toast.error("An error has occurred");
      }
    })();
  }, []);

  useEffect(() => {
    if (!id) return; // page not loaded yet
    const lastPage = Math.ceil(listData.count / itemsPerPage);
    (async () => {
      let promises = [
        getData(lastPage, itemsPerPage, searchQuery, filtersState),
      ];
      if (infoLoading) promises.push(getInfo());
      await Promise.all(promises);
    })();
  }, [id]);

  const getInfo = useCallback(async () => {
    const response = await sendInfoRequest(
      api[resource.toLowerCase()].getOne(id)
    );
    setInfoLoading(false);
    setInfo((prevInfo) => ({ ...prevInfo, ...response }));
  }, [id]);

  const updateInfo = useCallback(
    async (data) => {
      const newInfo = {
        id,
        ...(resource === enums.Resource.INVOICES && {
          organizationId: info?.organizationId,
        }),
        ...(resource === enums.Resource.BILLS && {
          serviceProviderId: info?.serviceProviderId,
        }),
        ...data,
      };
      await sendUpdateInfoRequest(api[resource.toLowerCase()].patch(newInfo));
      setInfo((prevInfo) => ({ ...prevInfo, ...data }));
      getData();
    },
    [id, info]
  );

  React.useEffect(() => {
    if (listData.count > 0 && !listData.rows.length) {
      const lastPage = Math.ceil(listData.count / itemsPerPage);
      const query = qs.stringify({
        search: searchQuery,
        filters: filtersState,
        page: lastPage,
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
      (async () =>
        await getData(lastPage, itemsPerPage, searchQuery, filtersState))();
    }
  }, [listData]);

  const onSearchChange = (updatedSearch) => {
    setSearchQuery(updatedSearch);
  };

  const onFilterChange = (updatedFilters) => {
    setFiltersState(updatedFilters);
  };

  const handleChange = async (pagination) => {
    setPageSettingsProp(`${match.path}.rowsPerPage`, pagination.pageSize);
    setCurrentPage(pagination.current);
    setItemsPerPage(pagination.pageSize);
    const query = qs.stringify({
      search: searchQuery,
      filters: filtersState,
      page: pagination.current,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    await getData(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      filtersState
    );
  };

  const handleSubmit = async (search, filters) => {
    setHighlightedText(search);
    const query = qs.stringify({
      search,
      filters,
      page: 1,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    await getData(1, itemsPerPage, search, filters);
    setCurrentPage(1);
  };

  return {
    id,
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    info,
    infoLoading,
    filterInputs,
    updateInfo,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
  };
}
