import React from "react";
import api from "services/api";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";

//-------------------------------------------------------------------------------------

export default function useContracts() {
  const [isUploadContractOpen, setIsUploadContractOpen] = React.useState(false);
  const [isEditContractOpen, setIsEditContractOpen] = React.useState(false);
  const [isRemoveContractOpen, setIsRemoveContractOpen] = React.useState(false);
  const [editItemId, setEditItemId] = React.useState(null);

  const { deal, setDeal, isGetDealLoading } = React.useContext(DealContext);

  const handleUploadContract = React.useCallback(() => {
    setIsUploadContractOpen(true);
  }, [setIsUploadContractOpen]);

  const onAddModalClose = React.useCallback(() => {
    setIsUploadContractOpen(false);
  }, [setIsUploadContractOpen]);

  const handleEditContract = React.useCallback(
    (contractId) => {
      setEditItemId(contractId);
      setIsEditContractOpen(true);
    },
    [setIsEditContractOpen]
  );

  const onEditModalClose = React.useCallback(
    (updatedContract) => {
      const updatedContracts = deal.contracts.map((contract) => {
        if (contract.id === updatedContract.id) {
          return updatedContract;
        }
        return contract;
      });
      setDeal({ ...deal, contracts: updatedContracts });
      setIsEditContractOpen(false);
    },
    [setIsEditContractOpen, deal]
  );

  const handleRemoveContract = React.useCallback(
    async (contractId) => {
      await api.contracts.delete(contractId);
    },
    [deal]
  );

  const onRemoveModalClose = React.useCallback((contractId) => {
    setIsRemoveContractOpen(false);
    const updatedContracts = deal.contracts.filter(
      (contract) => contract.id !== contractId
    );
    setDeal({ ...deal, contracts: updatedContracts });
  }, [setIsRemoveContractOpen]);

  const contracts = React.useMemo(() => {
    return deal?.contracts || [];
  }, [deal]);

  return {
    deal,
    contracts,
    isUploadContractOpen,
    isEditContractOpen,
    isRemoveContractOpen,
    isGetDealLoading,
    editItemId,
    handleUploadContract,
    onAddModalClose,
    handleEditContract,
    onEditModalClose,
    handleRemoveContract,
    onRemoveModalClose,
  };
}
