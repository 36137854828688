import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "services/api.js";
import { auth } from "services/firebase";
import { useHttpClient } from "./http-hook";

export const useAuth = () => {
  const [userData, setUserData] = useState(false);
  const [fetchUserRequest] = useHttpClient();

  const login = useCallback(async () => {
    try {
      const response = await fetchUserRequest(
        api.users.fetchUserData(auth.currentUser.uid)
      );
      console.log("response", response);
      setUserData({
        id: response.id,
        email: response.email,
        name: response.name,
        title: response.title,
        phone: response.phone,
        username: response.username,
        role: response.role,
        unit: response.unit,
        permissions: response.permissions,
        resources: response.permissions
          ? Object.keys(response.permissions)
          : [],
      });
      api.setAuthToken(auth.currentUser.accessToken);
    } catch (err) {
      console.log("err", err);
      toast.error("Login failed");
      logout();
    }
  }, [logout]);

  const logout = useCallback(async () => {
    setUserData(null);
    try {
      await auth.signOut();
      await api.clearAuthToken();
    } catch (err) {
      console.log("err", err);
      toast.error("Something went wrong");
    }
  }, []);

  const updateUserData = useCallback(
    async (updatedUserData) => {
      setUserData((prevData) => ({ ...prevData, ...updatedUserData }));
    },
    [setUserData]
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log("### user ###", user);
      if (user) {
        login();
      } else {
        logout();
      }
    });
    return () => unsubscribe();
  }, []);

  const getAccessPermission = useCallback(
    (resource, action) => {
      console.log("userData.resources?.indexOf(resource) !== -1 && userData.permissions[resource][action]");
      console.log(userData.resources?.indexOf(resource) !== -1, userData?.permissions?.[resource]?.[action]);
      if (
        userData.resources?.indexOf(resource) !== -1 &&
        userData.permissions[resource][action]
      )
        return true;
      else return false;
    },
    [userData]
  );

  return { userData, login, logout, updateUserData, getAccessPermission };
};
