import AssignmentIcon from "@material-ui/icons/Assignment";
import DataList from "components/DataList/DataList";
import api from "services/api";
import React from "react";
import translations from "constants/translations/index";
import i18n from "i18n";
import { RentalDealStatusColors } from "constants/colors";
import { getDateString } from "utils/index";
import { getI18n } from "react-i18next";
import enums from "enums/index";
// import CreateCourierRentalPage from "views/CourierRentals/pages/CreateCourierRentalPage/CreateCourierRentalPage";
import CourierRentalView from "views/CourierRentals/pages/CourierRentalView/CourierRentalView";
import CreateCourierRentalPage from "views/CourierRentals/pages/CreateCourierRentalPage/CreateServiceProviderRentalPage";

// appear in breadcrumbs and page headers
const listPageName = "Courier Rental";
const createPageName = "Create Courier Rental";
const viewPageName = "View Courier Rental";

// appears on alerts; example: Entity created successfully
const entityName = "CourierRental";

const resource = enums.Resource.COURIER_RENTALS;

const layout = "/admin";

const path = "courier-rentals";

const dataResource = api.courierRentals;

const columns = [
  {
    title: "Service Provider",
    dataIndex: ["serviceProvider", "name"],
  },
  {
    title: "Courier",
    dataIndex: ["courier", "name"],
  },
  {
    title: "Start Date",
    dataIndex: ["startDate"],
    getData: (date) => {
      return date ? getDateString(date) : "";
    },
  },
  {
    title: "End Date",
    dataIndex: ["endDate"],
    getData: (date) => {
      return date ? getDateString(date) : "";
    },
  },
  {
    title: "Status",
    dataIndex: ["status"],
    getData: (status) => {
      return (
        <div
          style={{
            color: RentalDealStatusColors[status],
            fontWeight: 600,
          }}
        >
          {translations[i18n.language].rentalDealStatus[status]}
        </div>
      );
    },
  },
];

const filterInputs = [
  {
    name: "status",
    label: "Status",
    type: "select",
    options: {
      data: Object.keys(translations[getI18n().language].rentalDealStatus).map(
        (key) => ({
          value: key,
          name: translations[getI18n().language].rentalDealStatus[key],
        })
      ),
      addAllChoice: true,
    },
  },
  {
    name: "serviceProvider",
    label: "Service Provider",
    type: "autocomplete",
    options: {
      getData: api.serviceProviders.getAll,
      getOptionLabel: (option) => option?.name,
      addAllChoice: true,
    },
  },
  {
    name: "courier",
    label: "Courier",
    type: "autocomplete",
    options: {
      getData: api.couriers.getAll,
      getOptionLabel: (option) => option?.name,
      addAllChoice: true,
    },
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: AssignmentIcon,
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: false, delete: false, view: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      filterInputs={filterInputs}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => {
        return <CreateCourierRentalPage path={path} />;
      },
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:id`,
      render: () => {
        return <CourierRentalView path={path} />;
      },
      layout: layout,
      action: "read",
      getName: async (id) => {
        try {
          const data = (await dataResource.getOne(id)).data;
          return data.name;
        } catch {
          return viewPageName;
        }
      },
      resource,
    },
  ],
};
