import api from "services/api";
import * as yup from "yup";
import { getI18n } from "react-i18next";
import translations from "constants/translations/index";

const commonFields = [
  {
    name: "originId",
    fetchedName: "origin",
    label: "Origin",
    type: "autocomplete",
    options: {
      getData: api.zones.getAll,
      getOptionLabel: (option) => option.name,
      getSubmitValue: (option) => option?.id,
    },
    validation: yup.mixed().required("Required"),
  },
  {
    name: "destinationId",
    fetchedName: "destination",
    label: "Destination",
    type: "autocomplete",
    options: {
      getData: api.zones.getAll,
      getOptionLabel: (option) => option.name,
      getSubmitValue: (option) => option?.id,
    },
    validation: yup.mixed().required("Required"),
  },
  {
    name: "routeType",
    label: "Route Type",
    type: "select",
    options: {
      data: Object.keys(translations[getI18n().language].routeTypes).map(
        (key) => ({
          value: key,
          name: translations[getI18n().language].routeTypes[key],
        })
      ),
    },
    validation: yup.mixed().required("Required"),
  },
];

const create = [
  {
    column: 1,
    fields: [...commonFields],
  },
];

const edit = [
  {
    column: 1,
    fields: [
      ...commonFields,
      {
        name: "distanceKm",
        type: "number",
        label: "Distance (km)",
        validation: yup.string().required("Required"),
      },
      {
        name: "tripDuration",
        type: "number",
        label: "Trip Duration (minutes)",
        validation: yup.string().required("Required"),
      },
    ],
  },
];

export default { create, edit };
