const styles = (theme) => ({
  buttonsGridContainer: {
    margin: "0 0 !important",
    width: "100%",
  },
  infoWithLabelRoot: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default styles;
