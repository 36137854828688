import React, { useMemo } from "react";
import Button from "components/CustomButtons/Button.js";
import { useLocationsInput } from "./LocationsInput.hook";
import CustomTable from "components/CustomTable/CustomTable";
import api from "services/api";
import Tooltip from "@material-ui/core/Tooltip";
import CustomModal from "components/CustomModal/CustomModal.js";
import styles from "./LocationsInput.styles";
import { makeStyles } from "@material-ui/core/styles";
import AddNewOptionModal from "components/GenericInput/AddNewOptionModal/AddNewOptionModal";
import enums from "enums/index";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(styles);
const LocationsInput = (props) => {
  const { formComponent, disabled, disabledTooltipText } = props;
  const {
    isLoading,
    isModalOpen,
    districts,
    cities,
    locations,
    value,
    locationsList,
    currentPage,
    itemsPerPage,
    filtersState,
    searchQuery,
    isCreateModalOpen,
    highlightedText,
    handleSubmit,
    handleChange,
    onSelect,
    toggleModal,
    onFilterChange,
    onSearchChange,
    handleCreateModalClose,
    handleCreateModalOpen,
  } = useLocationsInput(props);
  const classes = useStyles();
  const filterInputs = useMemo(() => {
    return [
      {
        name: "city",
        label: "City",
        type: "autocomplete",
        options: {
          data: cities,
          getData: cities ? undefined : api.cities.getAll,
          getOptionLabel: (option) => option?.name,
        },
      },
      {
        name: "district",
        label: "District",
        type: "autocomplete",
        options: {
          data: districts,
          getOptionLabel: (option) => option.name,
        },
      },
    ];
  }, [cities, districts]);

  const columns = [
    {
      title: "Location Number",
      dataIndex: "locationNo",
    },
    {
      title: "Name (English)",
      dataIndex: "nameEn",
    },
    {
      title: "Name (Arabic)",
      dataIndex: "nameAr",
    },
    {
      title: "Type",
      dataIndex: ["locationType", "name"],
    },
    {
      title: "District",
      dataIndex: ["district", "nameEn"],
    },
    {
      title: "City",
      dataIndex: ["district", "city", "nameEn"],
    },
    {
      title: "",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", width: "fit-content" }}>
            <Tooltip
              title={"Select Location"}
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
              arrow
            >
              <Button
                className={classes.actionButton}
                onClick={() => onSelect(record)}
                justIcon
                create
                color="primary"
                textColor="white"
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ height: "100%" }}>
      <div className={classes.valueContainer}>
        <div style={{ textAlign: "center" }}>
          {value || "No location is selected."}
        </div>
        <Tooltip
          title={disabled ? disabledTooltipText : "Select Location"}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
          arrow
        >
          <Button
            color="primary"
            onClick={() => {
              toggleModal();
            }}
            disabled={disabled}
            loading={isLoading}
            className={classes.editIcon}
            justIcon
            edit
            loadFromRight
          />
        </Tooltip>
      </div>
      {isModalOpen && (
        <CustomModal
          open
          onClose={toggleModal}
          modalHeaderClassName={classes.modalHeader}
          modalBodyClassName={classes.modalBody}
        >
          <Card className={classes.cardRoot}>
            <CardBody>
              <div className={classes.formHeader}>
                <EditIcon className={classes.icon} />

                {`Select Task Location`}
              </div>
              <div className={classes.buttonGroup}>
                <Button
                  create
                  color="primary"
                  onClick={handleCreateModalOpen}
                />
              </div>
              <div className={classes.tableContainer}>
                <CustomTable
                  columns={columns}
                  data={locationsList.rows}
                  pagination={{
                    total: locationsList.count,
                    current: currentPage,
                    pageSize: itemsPerPage,
                    position: ["topRight"],
                    pageSizeOptions: [10, 20, 50],
                  }}
                  filterProps={{
                    value: filtersState,
                    onChange: onFilterChange,
                    inputs: filterInputs,
                  }}
                  searchProps={{
                    onChange: onSearchChange,
                    value: searchQuery,
                    placeholder: "Search",
                  }}
                  loading={isLoading}
                  highlightedText={highlightedText}
                  onChange={handleChange}
                  handleSubmit={handleSubmit}
                  addIndexColumn
                />
              </div>
            </CardBody>
          </Card>
        </CustomModal>
      )}
      {isCreateModalOpen && formComponent ? (
        <AddNewOptionModal
          formComponent={formComponent}
          type={enums.FormContainerType.MODAL}
          isOpen={isCreateModalOpen}
          onClose={handleCreateModalClose}
        />
      ) : null}
    </div>
  );
};

export default LocationsInput;
