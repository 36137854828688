import * as yup from "yup";

export default () => {
  return yup.object().shape({
    driver: yup.mixed().required("Must Choose a Driver"),
    phoneNumber: yup
      .string()
      .matches(/^((\+2|002)?01\d{9})$/, "Invalid primary phone number")
      .required("Required"),
    phoneNumberSecondary: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .matches(/^((\+2|002)?01\d{9})$/, "Invalid secondary phone number")
      .nullable(),
    licenseAttachment: yup.mixed().required("Required"),
    licenseId: yup.mixed().required("Required"),
  });
};
