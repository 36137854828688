import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import AddPenaltyModalValidation from "./AddPenaltyModal.validation";
import enums from "enums/index";
import { FinOpsContext } from "views/TourOperations/Components/FinOps/FinOps.context";

export default function useAddPenaltyModal({ currentTour, onClose }) {
  const resolver = useYupValidationResolver(AddPenaltyModalValidation);
  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const [sendRequest, isLoading] = useHttpClient(true);
  const { getTour, serviceProviders } = useContext(TourOperationsContext);
  const { getTourUpdate } = useContext(FinOpsContext);

  const { handleSubmit, setValue, getValues, watch, reset } = form;
  const serviceProvider = watch("serviceProvider");
  const serviceProviderId = serviceProvider?.id;
  const [bills, setBills] = useState([]);
  const billsDisabled = useMemo(() => !serviceProvider, [serviceProvider]);

  useEffect(() => {
    if (serviceProvider?.bills) {
      const filteredBills = serviceProvider.bills.filter(
        (bill) => bill.status !== enums.BillStatus.BILLED
      );
      setBills([...filteredBills]);
    }
  }, [serviceProviderId]);

  const onSubmit = (values) => async () => {
    try {
      if (!currentTour) {
        toast.error("no current tour");
        return;
      }
      const newDiscount = {
        tourId: currentTour.id,
        billId: values.bill.id,
        ...values,
      };
      delete newDiscount["bill"];
      await sendRequest(api.penalties.create(newDiscount));
      toast.success("Penalty Added Successfully");
      reset();
      onClose();
      getTourUpdate();
    } catch (err) {
      toast.error("An error has occurred");
    }
  };
  const handleAddNewBill = (bill) => {
    setBills([...bills, bill]);
    setValue("bill", bill);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    serviceProviders,
    form,
    submitHandler,
    serviceProvider,
    bills,
    handleAddNewBill,
    billsDisabled,
  };
}
