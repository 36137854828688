import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const styles = (theme) => ({
  input: {
    border: "0.5px solid black",
    padding: "5px 10px",
    borderRadius: 5,
    width: "95%",
  },
  button: {
    height: "50px",
    padding: "7px 40px",
    borderRadius: 5,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      width: "60%",
      padding: "7px 10px",
      order: 0,
    },
  },
  loading: { marginTop: 0 },
  updatesCardLoading: {
    display: "flex",
    alignItems: "center",
  },
  updateContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    padding: 10,
    background: "#f7f7f7",
    margin: "3px 0",
  },
  senderText: { fontWeight: "bold", marginRight: 5 },
  form: { position: "absolute", bottom: -15, width: "95%" },
  card: { height: 230 },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  cardInnerContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    height: 150,
    overflowY: "auto",
  },
  formContainer: { display: "flex", alignItems: "center" },
});

export default styles;
