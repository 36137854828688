const styles = () => ({
  root: {
    width: "100%",
    marginBottom: 80,
    marginTop: 30,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
    justifyContent: "space-between",
  },
  body: {
    width: "100%",
    marginBottom: 80,
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  addButton: {
    marginRight: 15,
  },
  expandButton: {
    width: 110,
  },
});

export default styles;
