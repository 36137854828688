
const styles = (theme) => ({
  root: {
    padding: "40px 20px",
    paddingBottom: 0,
  },
  section: {
    width: "100%",
    marginBottom: 80,
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    marginBottom: -43,
  },
  header: {
    margin: 0,
    marginLeft: 20,
  },
  addContactButton: {
    padding: "5px 15px",
    zIndex: 20,
    display: "flex",
    alignItems: "center",
    marginRight: 60,
  },
  addIcon: {
    width: "16px",
    zIndex: "4",
    color: "white",
    transition: "all 0.3s",
    width: "20px !important",
    height: "20px !important",
    marginBottom: 2,
    marginRight: 5,
  },
  actionsContainer: {
    display: "flex",
    width: "fit-content",
  },
  tableContainer: {
    width: "100%",
    marginTop: 25,
  },
  removeButton: {
    margin: "0px 5px",
  },
  removeIcon: {
    width: "30px !important",
    height: "30px !important",
  }
});

export default styles;
