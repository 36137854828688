import { makeStyles } from "@material-ui/core/styles";
import OrganizationDetailsSection from "./sections/OrganizationDetailsSection/OrganizationDetailsSection";
import styles from "./Organization.styles";
import React from "react";
import ContactsSection from "./sections/ContactsSection/ContactsSection";
import { DealContext } from "views/Deals/pages/DealView/Deal.context";
import ContractsSection from "./sections/SignedContractsSection/SignedContractsSection";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function Organization() {
  const classes = useStyles();

  const { deal } = React.useContext(DealContext);

  const organization = React.useMemo(() => deal?.organization, [deal]);

  return (
    <div className={classes.root}>
      <OrganizationDetailsSection />
      {<ContactsSection />}
      {organization && <ContractsSection />}
    </div>
  );
}
