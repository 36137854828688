const styles = (theme) => ({
  rowContainer: {
    display: "flex",
    flexDirection: "row",
  },
  rowCell: {
    borderColor: "#f0f0f0",
    padding: "10px 10px",
    minWidth: "100px",
    borderWidth: `0 1px 1px 0`,
    borderStyle: "solid",
  },
  stickyCol: {
    position: "-webkit-sticky",
    position: "sticky",
    backgroundColor: "white",
  },
  firstCol: {
    width: "100px",
    minWidth: "100px",
    maxWidth: "100px",
    zIndex: 1,
    left: "0px",
    // boxShadow: "inset -8px 0px 10px rgba(0, 0, 0, 0.14)",
  },
  lastCol: {
    width: "150px",
    minWidth: "150px",
    maxWidth: "150px",
    zIndex: 1,
    right: "0px",
    // boxShadow: "inset 8px 0px 10px rgba(0, 0, 0, 0.14)",
  },
  bigRowCell: {
    borderColor: "#f0f0f0",
    padding: "10px 10px",
    minWidth: "300px",
    borderWidth: `0 1px 1px 0`,
    borderStyle: "solid",
  },
  tooltip: {
    transform: "translateY(-20px) !important",
  },
  button: {
    width: "30px",
    height: "30px",
    minWidth: "30px",
    borderRadius: "50%",
    padding: "1px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      margin: 0,
    },
  },
});

export default styles;
