import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums";
import React from "react";
import api from "services/api";
import { getDurationString } from "utils/index";
import zoneMappingsForm from "views/Zoning/zoneMappingsForm";

// appear in breadcrumbs and page headers
const listPageName = "Zone Mappings";
const createPageName = "Create Zone Mapping";
const editPageName = "Edit Zone Mapping";

// appears on alerts; example: Entity created successfully
const entityName = "Zone Mapping";

const resource = enums.Resource.ZONING;

const layout = "/admin";

const path = "zoneMappings";

const formStructure = zoneMappingsForm;

const dataResource = api.zoneMappings;

const columns = [
  {
    title: "Distance (km)",
    dataIndex: "distanceKm",
  },
  {
    title: "Trip duration",
    dataIndex: "tripDuration",
    getData: (duration) => {
      return getDurationString(duration);
    },
  },
  {
    title: "Origin",
    dataIndex: ["origin", "name"],
  },
  {
    title: "Destination",
    dataIndex: ["destination", "name"],
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "settings_ethernet",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
      getRowName={(row) =>
        row?.origin?.name && row?.destination?.name
          ? `"${row.origin.name} to ${row.destination.name}"`
          : "Zone Mapping"
      }
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          getName={(data) =>
            data?.origin?.name && data?.destination?.name
              ? `"${data.origin.name} to ${data.destination.name}"`
              : "Zone Mapping"
          }
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      resource,
      getName: async (id) => {
        try {
          const data = await api.zoneMappings.getOne(id);
          return data.origin.name + " to " + data.destination.name;
        } catch (er) {
          return "Zone Mapping";
        }
      },
    },
  ],
};
