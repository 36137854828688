import api from "services/api";
import * as yup from "yup";
import { getI18n } from "react-i18next";
import translations from "constants/translations/index";

const commonFields = [
  {
    name: "name",
    type: "text",
    label: "Name",
    validation: yup.string().required("Required"),
  },
  {
    name: "email",
    type: "text",
    label: "Email",
    validation: yup.string().required("Required"),
  },
  {
    name: "phone1",
    type: "text",
    label: "Phone Number",
    validation: yup
      .string()
      .matches(/^((\+2|002)?01\d{9})$/, "Invalid primary phone number")
      .required("Required"),
  },
  {
    name: "phone2",
    type: "text",
    label: "Secondary Phone Number",
    validation: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .matches(/^((\+2|002)?01\d{9})$/, "Invalid secondary phone number")
      .nullable(),
  },
  {
    name: "position",
    type: "text",
    label: "Position",
    validation: yup.string().required("Required"),
  },
  {
    name: "type",
    type: "select",
    label: "Contact Type",
    options: {
      data: Object.keys(translations[getI18n().language].contactType).map(
        (key) => ({
          value: key,
          name: translations[getI18n().language].contactType[key],
        })
      ),
    },
    validation: yup.string().required("Required"),
  },
];

const create = [
  {
    column: 1,
    fields: [
      ...commonFields,
      {
        name: "organizationId",
        fetchedName: "organization",
        type: "autocomplete",
        label: "Organization",
        options: {
          getData: api.organizations.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (option) => option?.id,
        },
        validation: yup.mixed().required("Required"),
      },
    ],
  },
];

const edit = [
  {
    column: 1,
    fields: [
      ...commonFields,
      {
        name: "organizationId",
        fetchedName: "organization",
        type: "autocomplete",
        label: "Organization",
        options: {
          getData: api.organizations.getAll,
          getOptionLabel: (option) => option.name,
          getSubmitValue: (option) => option?.id,
        },
        validation: yup.mixed().required("Required"),
      },
    ],
  },
];

const editDealContact = [
  {
    column: 1,
    fields: [
      ...commonFields,
      {
        name: "organizationId",
        type: "hidden",
        fetchedName: "organization",
        options: {
          getSubmitValue: (option) => option?.id,
        },
      },
    ],
  },
];

export default { create, edit, editDealContact };
