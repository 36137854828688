import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";
import styles from "./UpdatesModal.styles";
import Updates from "views/FinOps/FinOpsEdit/components/Updates/Updates";
import api from "services/api";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function UpdatesModal(props) {
  const { isOpen, onClose, currentTour } = props;
  const classes = useStyles();
  return (
    <CustomModal
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <Card style={{ margin: 0 }}>
        <CardBody>
          <div className={classes.formHeader}>
            <React.Fragment>
              <AddIcon className={classes.icon} />
              Tour Updates
            </React.Fragment>
          </div>
          <Updates
            id={currentTour?.id}
            referenceKey={"tourId"}
            dataResource={api.tourUpdates}
          />
        </CardBody>
      </Card>
    </CustomModal>
  );
}
