import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import MonthlyOrderDetails from "views/Plans/MonthlyOrderDetails/MonthlyOrderDetails";
import useMonthlyOrderDetailsModal from "./MonthlyOrderDetailsModal.hook";
import styles from "./MonthlyOrderDetailsModal.styles";
//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function MonthlyOrderDetailsModal({ order, ...buttonProps }) {
  const {
    closeModal,
    isButtonDisabled,
    openModal,
    showModal,
  } = useMonthlyOrderDetailsModal(order);

  const classes = useStyles();

  return (
    <div className={classes.buttonContainer}>
      <div style={{ width: "fit-content" }}>
        <Button
          type="submit"
          color="primary"
          className={classes.button}
          onClick={openModal}
          disabled={isButtonDisabled}
          {...buttonProps}
        >
          {!buttonProps.justIcon && "View Order"}
        </Button>
      </div>

      <CustomModal
        open={showModal}
        onClose={closeModal}
        modalHeaderClassName={classes.modalHeader}
        modalBodyClassName={classes.modalBody}
      >
        <MonthlyOrderDetails planOrder={order} viewOnly />
      </CustomModal>
    </div>
  );
}
