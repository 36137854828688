import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import React from "react";
import api from "services/api";
import enums from "enums/index";
import contactsForm from "views/Organizations/contactsForm";

// appear in breadcrumbs and page headers
const listPageName = "Contacts";
const createPageName = "Create Contact";
const editPageName = "Edit Contact";

// appears on alerts; example: Entity created successfully
const entityName = "Contact";

const resource = enums.Resource.ORGANIZATIONS;

const layout = "/admin";

const path = "contacts";

const editFormStructure = contactsForm.edit;
const createFormStructure = contactsForm.create;

const dataResource = api.contacts;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Organization",
    dataIndex: ["organization", "name"],
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "contact_mail",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={contactsForm}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={createFormStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={editFormStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      resource,
    },
  ],
};
