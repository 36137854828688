import translations from "constants/translations/index";
import i18n from "i18n";
import api from "services/api";
import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "create",
        type: "boolean",
        label: "Create",
      },
      {
        name: "read",
        type: "boolean",
        label: "Read",
      },
      {
        name: "update",
        type: "boolean",
        label: "Update",
      },
      {
        name: "delete",
        type: "boolean",
        label: "Delete",
      },
      {
        name: "resource",
        type: "select",
        label: "Resource",
        options: {
          data: Object.keys(translations[i18n.language].resource).map(
            (key) => ({
              value: key,
              name: translations[i18n.language].resource[key],
            })
          ),
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "roleId",
        fetchedName: "role",
        type: "select",
        label: "Role",
        options: {
          getData: api.roles.getAll,
          rawDataConvertor: (data) =>
            data?.map((entry) => ({
              value: entry.id,
              name: entry.name,
            })),
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "departmentId",
        fetchedName: "department",
        type: "select",
        label: "Department",
        options: {
          getData: api.departments.getAll,
          rawDataConvertor: (data) =>
            data?.map((entry) => ({
              value: entry.id,
              name: entry.name,
            })),
        },
        validation: yup.mixed().required("Required"),
      },
    ],
  },
];
