import enums from "enums/index";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";
import FinOpsValidation from "./FinOps.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import ReviewTourValidation from "./FinOpsReview.validation";

export default function useFinOps() {
  const [sendRequest, isLoading] = useHttpClient(true);
  const [sendGetRequest, isGetLoading] = useHttpClient(true);
  const {
    setCurrentTour,
    getTours,
    getOrganizations,
    currentTour,
    resource,
  } = useContext(TourOperationsContext);

  const inFinOps = useMemo(() => resource === enums.Resource.FIN_OPS, [
    resource,
  ]);
  const tourDisabled = useMemo(
    () =>
      currentTour?.status === enums.TourStatus.CANCELLED ||
      currentTour?.status === enums.TourStatus.COMPLETED,
    [currentTour]
  );
  const tourReviewable = useMemo(
    () =>
      currentTour?.status === enums.TourStatus.COMPLETED ||
      currentTour?.status === enums.TourStatus.CANCELLED,

    [currentTour]
  );
  const tourReviewed = useMemo(
    () => currentTour?.finOpsStatus === enums.FinOpsStatus.REVIEWED,
    [currentTour]
  );
  const revertReviewDisabled = useMemo(() => {
    return (
      currentTour?.penalties.find(
        (penalty) => penalty.bill.status === enums.BillStatus.BILLED
      ) ||
      currentTour?.discounts.find(
        (discount) => discount.invoice.status === enums.InvoiceStatus.INVOICED
      )
    );
  }, [currentTour]);
  const getTourUpdate = useCallback(async () => {
    const tour = await sendGetRequest(
      api.tours.getTourWithPenaltiesAndDiscounts(currentTour.id)
    );
    setCurrentTour({
      ...currentTour,
      penalties: tour?.penalties || [],
      discounts: tour?.discounts || [],
    });
  }, [setCurrentTour, currentTour]);

  const resolver = useYupValidationResolver(FinOpsValidation);

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      discounts: [],
      penalties: [],
    },
  });

  const { handleSubmit, setValue, getValues, reset, control, watch } = form;

  useEffect(() => {
    reset();
    if (currentTour?.discounts) {
      let discountsValue = currentTour?.discounts;
      setValue("discounts", discountsValue);
    }
    if (currentTour?.penalties) {
      let penaltiesValue = currentTour?.penalties;
      setValue("penalties", penaltiesValue);
    }
  }, [currentTour, reset]);

  const submitHandler = (values) => async () => {
    try {
      const newPenalties = values.penalties?.map((penalty) => {
        const newPenalty = { ...penalty };
        if (penalty.bill.serviceProvider.bills) {
          newPenalty.bill = penalty.bill.serviceProvider.bills.find(
            (bill) => bill.status === "PLANNED"
          );
        }
        delete newPenalty["billId"];
        return newPenalty;
      });
      const newDiscounts = values.discounts?.map((discount) => {
        const newDiscount = { ...discount };
        newDiscount["invoiceId"] = newDiscount["invoice"].id;
        delete newDiscount["invoice"];
        return newDiscount;
      });
      const newTour = {
        id: currentTour.id,
        penalties: newPenalties,
        ...(inFinOps && { discounts: newDiscounts }),
      };
      const tour = await sendRequest(
        api.tours.updateTourTasks(currentTour.id, newTour)
      );
      setCurrentTour({
        ...currentTour,
        penalties: tour?.penalties || [],
        discounts: tour?.discounts || [],
      });
      toast.success(`Tour has been updated successfully`);
    } catch (err) {
      console.log("error", err);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors above and submit again.");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(submitHandler(values), onError)();
  };
  const reviewTour = useCallback(async () => {
    try {
      if (!currentTour) {
        throw new Error("No tour to review.");
      }
      await ReviewTourValidation.validate(currentTour);
      const services = currentTour.services.map((service) => ({
        ...service,
        finOpsStatus: enums.FinOpsStatus.REVIEWED,
      }));
      await sendRequest(
        api.tours.patch({
          id: currentTour.id,
          finOpsStatus: enums.FinOpsStatus.REVIEWED,
          services,
        })
      );
      toast.success("Tour reviewed successfully");
      await getTours();
    } catch (err) {
      if (err?.response?.data?.error === "Conflict") {
        await getOrganizations();
        await getTours();
      } else toast.error(err.errors[0]);
    }
  }, [currentTour]);

  const revertTourReview = useCallback(async () => {
    try {
      if (!currentTour) {
        throw new Error("No tour to review.");
      }
      const services = currentTour.services.map((service) => ({
        ...service,
        finOpsStatus: enums.FinOpsStatus.PENDING,
      }));
      await sendRequest(
        api.tours.patch({
          id: currentTour.id,
          finOpsStatus: enums.FinOpsStatus.PENDING,
          services,
        })
      );
      toast.success("Tour changed to pending for review successfully.");
      await getTours();
    } catch (err) {
      if (err?.response?.data?.error === "Conflict") {
        await getOrganizations();
        await getTours();
      } else toast.error(err?.errors?.[0] || "An error has occurred");
    }
  }, [currentTour]);

  return {
    form,
    isLoading,
    isGetLoading: isGetLoading || isLoading,
    currentTour,
    tourDisabled,
    tourReviewable,
    tourReviewed,
    revertReviewDisabled,
    inFinOps,
    getTourUpdate,
    onSubmit,
    reviewTour,
    revertTourReview,
  };
}
